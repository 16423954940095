<app-info-square-provider #infoSquareProvider [handler]="infoSquareHandler" class="container">
  <cm-readonly-content [isDisabled]="!shouldDisableSearchingMessage && isSearching || isLoadingFromParent()" [message]="'Aguarde carregando...'">
    <div class="section controller search-box">
      <span *ngIf="helpTipKey" class="helper-info" [helperInfo]="helpTipKey" [hiPopover]="true"></span>
      <mat-radio-group *ngIf="allowedSearchSources.standard && allowedSearchSources.infoSquare"
        aria-label="Selecione o modo de busca" [(ngModel)]="useInfoSquare">
        <mat-radio-button [value]="false" (click)="triggerNormalSearch()">Normal</mat-radio-button>
        <mat-radio-button [value]="true" [disabled]="infoSquareLoading" (click)="
            !infoSquareLoading &&
              infoSquareInput.focus() &&
              triggerInfoSquareSearch()
          ">Info Square</mat-radio-button>
      </mat-radio-group>
      <!-- Info Square Search -->
      <mat-form-field [style.display]="useInfoSquare ? null : 'none'" appearance="fill"
        class="no-padding-bottom center-field-flex">
        <mat-label>Pesquisar</mat-label>
        <span matPrefix>
          <div [visible-vertical]="!!(infoSquareProvider.loading$ | async)">
            <mat-spinner diameter="24" style="margin-right: 10px"></mat-spinner>
          </div>
        </span>
        <input
          #infoSquareInput
          appInfoSquareSearchInput
          class="mat-input-element"
          type="text"
          [(ngModel)]="target"
          [disabled]="!infoSquareProvider.state.selectedCSBody" />
        <span matSuffix>
          <app-info-square-cs-body-selector></app-info-square-cs-body-selector>
        </span>
        <mat-progress-bar class="debounce-indicator" mode="determinate" value="100" style="transform-origin: left"
          [style.transition]="debounceSearchTransition" [style.transform]="debounceSearchTransform">
        </mat-progress-bar>
      </mat-form-field>

      <ng-container *ngIf="!useInfoSquare">
        <div class="form-field-mat-radio-container">
          <mat-radio-group *ngIf="isCustomerSearch()" name="searchBy" aria-label="Selecione como você deseja bucar:"
            [value]="searchByRadio" (change)="radioGroupHandler($event)" class="search-by-radio">
            <mat-radio-button value="phone">Número de celular</mat-radio-button>
            <mat-radio-button value="name">Nome</mat-radio-button>
            <mat-radio-button value="email">Email</mat-radio-button>
            <mat-radio-button value="businessID">Chave de negócio</mat-radio-button>
          </mat-radio-group>

          <mat-form-field 
            appearance="fill" 
            [style.display]="checkShowInput() ? null : 'none'"
            class="no-padding-bottom normal-search"
            matTooltip="{{ phoneSearchTooltip }}">
            <mat-label>{{ handleInputPlaceHolder() }}</mat-label>

            <span matPrefix>
              <div [visible-vertical]="!!(searching$ | async)">
                <mat-spinner diameter="24" style="margin-right: 10px"></mat-spinner>
              </div>
            </span>

            <input
              matInput
              #targetInput
              type="text"
              [ngModel]="target"
              (ngModelChange)="handleTargetChange($event)"
            />

            <mat-progress-bar class="debounce-indicator" mode="determinate" value="100" style="transform-origin: left"
              [style.transition]="debounceSearchTransition" [style.transform]="debounceSearchTransform">
            </mat-progress-bar>
          </mat-form-field>
        </div>
      </ng-container>
    </div>

    <app-alert *ngIf="totalCustomers && totalCustomers >= gMaxAttendenceHistoryResultSet" type="warning"
      style="margin-top: 16px">
      Sua busca teve mais resultados do que o intervalo de data selecionado. Para
      visualizá-los diminua o intervalo selecionado.
    </app-alert>

    <app-alert *ngIf="isAgentSearch() && target && (!beginDate || !endDate)" type="warning" style="margin-top: 16px">
      Preencha o intervalo de datas.
    </app-alert>

    <div class="section content">
      <!-- Info Square Search -->
      <ng-container *ngIf="useInfoSquare">
        <app-info-square-search-results *ngIf="!!(infoSquareProvider.state.result$ | async)?.length">
        </app-info-square-search-results>

        <ng-container *ngIf="infoSquareInput.value.length > 0 && ((infoSquareProvider.state.result$ | async)?.length === 0)">
          <ng-container *ngTemplateOutlet="searchListEmpty"></ng-container>
        </ng-container>

        <ng-container *ngIf="!(infoSquareProvider.state.result$ | async)">
          <ng-container *ngTemplateOutlet="searchListPlaceHolder"></ng-container>
        </ng-container>
      </ng-container>

      <!-- Normal Search -->
      <ng-container *ngIf="!useInfoSquare">
        <ng-container *ngIf="isNotFoundResults() && !(searchingObservable$ | async)">
          <ng-container *ngTemplateOutlet="searchListEmpty"></ng-container>
        </ng-container>

        <ng-container *ngIf="!searchFired">
          <ng-container *ngTemplateOutlet="searchListPlaceHolder"></ng-container>
        </ng-container>

        <button *ngIf="isAgentSearch() && searchResult?.length" mat-flat-button color="primary" style="margin-top: 16px"
          (click)="handleGoToAgentsTableButton()" class="button-w-icon">
          <mat-icon>
            <span class="material-icons-outlined"> arrow_back_ios </span>
          </mat-icon>
          Voltar para lista de agentes
        </button>

        <app-generic-table *ngIf="showAgentsTable()" [handler]="searchAgentResultTable"></app-generic-table>

        <app-generic-table *ngIf="searchResultTable && searchResult?.length" [handler]="searchResultTable">
        </app-generic-table>
      </ng-container>
    </div>
  </cm-readonly-content>
</app-info-square-provider>

<ng-template #searchListEmpty>
  <div class="place-holder-container">
    <mat-icon class="mat-128 icon-place-holder">search_off</mat-icon>

    Sem resultados

    <p *ngIf="isPhoneSearch">
      <!-- A busca <b>não</b> deve incluir o DDD ou DDI com o número de celular -->
       Certifique-se de que o número digitado corresponde a um celular válido. Ex.: 988778980
    </p>
  </div>
</ng-template>

<ng-template #searchListPlaceHolder>
  <div class="place-holder-container">
    <mat-icon style="opacity: 0.4" class="mat-128 icon-place-holder">person_search</mat-icon>
  </div>
</ng-template>
