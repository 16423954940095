<app-colmeia-window-top-bar *ngIf="isWindow"></app-colmeia-window-top-bar>

<h3 mat-dialog-title *ngIf="!isWindow">Editor de Paleta de Cores</h3>

<div class="palette-builder-wrapper">
    <div class="palette-builder" [class]="'preview-type-' + previewType">
        <div class="color-selectors nice-scroll">
            <mat-form-field appearance="fill" class="color-selector">
                <mat-label>Selecione a cor primária</mat-label>
                <input matInput [ngModel]="primaryColor" type="text" style="display: none;">
                <div class="input-color-container">
                    <input type="color" [value]="primaryColor" (input)="onColorChange($event.target.value, EPaletteType.PrimaryPalette)">
                </div>
                <mat-expansion-panel
                    class="color-palette-panel slim-format no-padding">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Paleta da cor primária
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="color-palette nice-scroll">
                        <ng-container *ngFor="let shade of sortedPrimaryPalette; trackBy: paletteToneTrackBy(EPaletteType.PrimaryPalette)">
                            <ng-container *ngTemplateOutlet="paletteToneEditor; context: { shade: shade, paletteType: EPaletteType.PrimaryPalette, palette: primaryPalette }"></ng-container>
                        </ng-container>
                    </div>
                </mat-expansion-panel>
            </mat-form-field>

            <mat-form-field appearance="fill" class="color-selector">
                <mat-label>Selecione a cor secundária</mat-label>
                <input matInput [ngModel]="accentColor" type="text" style="display: none;">
                <div class="input-color-container">
                    <input type="color" [value]="accentColor" (input)="onColorChange($event.target.value, EPaletteType.AccentPalette)">
                </div>
                <mat-expansion-panel
                    class="color-palette-panel slim-format no-padding">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Paleta da cor secundária
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="color-palette nice-scroll">
                        <ng-container *ngFor="let shade of sortedAccentPalette; trackBy: paletteToneTrackBy(EPaletteType.AccentPalette)">
                            <ng-container *ngTemplateOutlet="paletteToneEditor; context: { shade: shade, paletteType: EPaletteType.AccentPalette, palette: accentPalette }"></ng-container>
                        </ng-container>
                    </div>
                </mat-expansion-panel>
            </mat-form-field>

            <mat-form-field appearance="fill" class="font-selector">
                <mat-label
                    [style.fontFamily]="brandTheme.fontFamily + ', sans-serif'">
                        Selecione a fonte
                </mat-label>
                <mat-select [(ngModel)]="brandTheme.fontFamily" (selectionChange)="updateCSSProperties()">
                    <mat-option *ngFor="let font of fonts"
                        [value]="font"
                        [style.fontFamily]="font">
                        {{font}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div #colorPaletteContainer class="preview-container">
            <ng-container>
                <ng-container *ngComponentOutlet="previewComponent"></ng-container>
            </ng-container>
        </div>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancelar</button>
    <button mat-flat-button color="primary" (click)="onSave()" class="save-button">Salvar</button>
</div>

<ng-template #paletteToneEditor let-shade="shade" let-paletteType="paletteType" let-palette="palette">
    <div
    class="color-shade"
    #shadeContainer
    (mouseover)="shade.hover = true"
    (mouseout)="shade.hover = false"
    [style.background]="shade.hover ? getHighlightedColor(shade.value) : shade.value"
    [style.color]="palette.contrast[shade.key]"
    (click)="onShadeItemClick(shade.value, paletteType, shadeContainer, $event)">
    <span>{{shade.key}}: {{shade.value}}</span>
    <div class="buttons-container">
        <div
        *ngIf="palette.contrast[shade.key]"
        class="contrast-block"
        [style.background]="setAlphaColor(accentPalette.contrast[shade.key],0.5)">
    </div>


    <label
        [attr.for]="'ctbe_'+paletteType+'_'+shade.key"
        class="edit-button"
        >
        <i class="material-icons">
          edit
        </i>
    </label>
    <input
        class="palette-tone-input-color"
        [id]="'ctbe_'+paletteType+'_'+shade.key"
        type="color"
        [attr.data-shadekey]="shade.key"
        [attr.data-palettetype]="paletteType"
        [value]="shade.value"
        oninput="window.ColorThemeBuilderEditDialog_onToneColorChange(event.target.value, event)"
    />
    </div>
</div>
</ng-template>
