
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import { FullCalendarHandler } from './full-calendar.handler';

@Component({
  selector: 'app-full-calendar',
  templateUrl: './full-calendar.component.html',
  styleUrls: ['./full-calendar.component.scss'],
})
export class FullCalendarComponent implements OnInit, AfterViewInit {
  @Input() handler: FullCalendarHandler;
  @Output() dateClicked = new EventEmitter<Date>();
  @Output() eventClicked = new EventEmitter<any>(); 

  calendarOptions: CalendarOptions;

  constructor() {}

  ngOnInit(): void {
    this.initializeCalendarOptions();
  }

  ngAfterViewInit(): void {
    this.updateCalendarOptions();
  }

  private initializeCalendarOptions(): void {
    this.calendarOptions = this.handler.calendarOptions;
  }

  private updateCalendarOptions(): void {
    this.calendarOptions = this.handler.calendarOptions;
  }

  handleDateClick(arg: any) {
    this.dateClicked.emit(arg.date);
  }

  handleEventClick(arg: any) {
    this.eventClicked.emit(arg.event.extendedProps);
  }
}
