export enum EDashboardIdentifier {
    analytics = "analytics",
    ai = "ai",
    marketing = "marketing", 
    service = "service", 
    jobs = "jobs",
    communication = "communication",
    smartflow = "smart-flow",
    environments = "environments",
    colmeia = "colmeia", 
    servicestatus = "service-status",
    crmservices = "crm-services",
    menusn = "menu-sn"
}