import { escapeRegExp } from 'lodash';
import { ETypeOfContact } from '../../comm-interfaces/business-interfaces';
import { EDelivery360Action, EWindowOpenConversationType } from '../../comm-interfaces/interaction-interfaces';
import { IServiceChatInitiatedInteractionJSON } from '../../comm-interfaces/service-group-interfaces';
import { suggestions } from '../../tools/type-utils';
import {
    capitalizedFullName, getProperty, getReadableUniqueID, isInEnum, isThisOneOfThat,
    isValidString, isValidTrimmedString, values
} from '../../tools/utility';
import { checkEarlyReturnSwitch } from '../../tools/utility-types';
import { resetPattern } from '../../tools/utility/functions/resetPattern';
import { IBotSubject } from '../bot/bot-interfaces';
import { EMetadataNames } from '../metadata/metadata-db';
import { WhatsApp } from '../social-media/whatsapp-interface';




export const nameUniqueSplitter = '_@';

export const nameUniqueSplitterPattern = createNameUniqueSplitterPattern(nameUniqueSplitter)

export function getUniqueName(name: string): string {
    return name + nameUniqueSplitter + getReadableUniqueID(6);
}

export function isUniquizeid(name: string): boolean {
    return isValidString(name) && nameUniqueSplitterPattern.test(name);
}

export function removeUniquizer(name: string): string {
    resetPattern(nameUniqueSplitterPattern);
    if (isUniquizeid(name)) {
        return name.substr(0, nameUniqueSplitterPattern.exec(name).index);
    }
    return name;
}

export function getUniquized(name: string): string {
    resetPattern(nameUniqueSplitterPattern);
    if (isUniquizeid(name)) {
        return name.slice(nameUniqueSplitterPattern.exec(name).index);
    }
    return name;
}

export function nonUniquizedCapitalized(name: string): string {
    if (isUniquizeid(name)) {
        return capitalizedFullName(removeUniquizer(name));
    }
    return name;
}

export function isTemplateBotTriggable(socialMediaType: EDelivery360Action): boolean {
   return socialMediaType === EDelivery360Action.Delivery360WhatsApp || socialMediaType === EDelivery360Action.Delivery360RCS
}


export function getCanonicalForDeliveryAction(action: EDelivery360Action): EMetadataNames {
    if (isEmailMedia(action)) {
        return EMetadataNames.email;

    } else if (isTelephoneNumber(action)) {
        return EMetadataNames.cellular;

    } else if (isFBMessenger(action)) {
        return EMetadataNames.facebookID

    } else if (isInstagram(action)) {
        return EMetadataNames.instagramID

    } else if (isColmeiaChannel(action)) {
        return EMetadataNames.colmeiaID;
    } else if (isTelegram(action)) {
        return EMetadataNames.telegramID;
    } else if (isRCS(action)) {
        return EMetadataNames.cellular
    }

    checkEarlyReturnSwitch()(action);
};

export function isHumanKnowable(action: EDelivery360Action): boolean {
    return isTelephoneNumber(action) || isEmailMedia(action)
}

export function isChannelCanonocal(canonical: EMetadataNames): boolean {
    return isThisOneOfThat(canonical, EMetadataNames.cellular, EMetadataNames.colmeiaID, EMetadataNames.email, EMetadataNames.facebookID, EMetadataNames.instagramID)
}


export const isColmeiaChannel = isAction(EDelivery360Action.DeliveryColmeia);

export const isTelephoneNumber = isAction(
    EDelivery360Action.Delivery360SMS,
    EDelivery360Action.Delivery360WhatsApp,
    EDelivery360Action.Delivery360Voice,
);

export function isVoiceChannel(action: EDelivery360Action): boolean {
    return isThisOneOfThat(action, EDelivery360Action.Delivery360Voice);
};

export function isNoDelayChannel(action: EDelivery360Action): boolean {
    return isThisOneOfThat(action, EDelivery360Action.Delivery360Voice, EDelivery360Action.DeliveryColmeia);
};

export const isEmailMedia = isAction(EDelivery360Action.Delivery360Email);
export const isFBMessenger = isAction(EDelivery360Action.Delivery360FBMessenger);
export const isInstagram = isAction(EDelivery360Action.DeliveryInstagram);
export const isTelegram = isAction(EDelivery360Action.DeliveryTelegram);
export const isRCS = isAction(EDelivery360Action.Delivery360RCS);
export const isWhatsapp = isAction(EDelivery360Action.Delivery360WhatsApp);


function isAction<T extends [EDelivery360Action, ...EDelivery360Action[]]>(...types: T) {
    return (action: EDelivery360Action): action is T[number] => {
        return isThisOneOfThat(action, ...types);
    };
}


export function getTypeOfContact(action: EDelivery360Action): ETypeOfContact {
    if (isEmailMedia(action))
        return ETypeOfContact.email
    if (isTelephoneNumber(action))
        return ETypeOfContact.mobile;
    if (isFBMessenger(action))
        return ETypeOfContact.fbUserPageID;
    if (isInstagram(action))
        return ETypeOfContact.instagram;
    if (isColmeiaChannel(action))
        return ETypeOfContact.colmeia;
    if (isTelegram(action))
        return ETypeOfContact.telegram;
    if (isRCS(action)) {
        return ETypeOfContact.mobile;
    }

    checkEarlyReturnSwitch()(action);
};


export function getAllAllowedTypeOfContacts(): ETypeOfContact[] {
    const allowed: ETypeOfContact[] = values(hashGlobalCanonicalToTypeOfContact)
    return allowed;
}

const hashGlobalCanonicalToTypeOfContact = suggestions<{ [key in EMetadataNames]?: ETypeOfContact }>()({
    [EMetadataNames.cellular]: ETypeOfContact.mobile,
    [EMetadataNames.email]: ETypeOfContact.email,
    [EMetadataNames.colmeiaID]: ETypeOfContact.colmeia,
    [EMetadataNames.facebookID]: ETypeOfContact.fbUserPageID,
    [EMetadataNames.instagramID]: ETypeOfContact.instagram,
    [EMetadataNames.telegramID]: ETypeOfContact.telegram,
});
const hashTypeOfContactToGlobalCanonical: { [key in ETypeOfContact]?: EMetadataNames } = Object.entries(hashGlobalCanonicalToTypeOfContact).reduce((o, [k, v]) => {
    o[v] = k;
    return o
}, {});

type AllowedMetadataNames = keyof typeof hashGlobalCanonicalToTypeOfContact;

export function getTypeOfContactFromCanonical(canonical: EMetadataNames): ETypeOfContact {
    const typeOfContact = getProperty(hashGlobalCanonicalToTypeOfContact)(canonical as AllowedMetadataNames);
    return typeOfContact;
}

export function getMetadataNameByTypeOfContact(contactType: ETypeOfContact): keyof typeof hashGlobalCanonicalToTypeOfContact {
    return hashTypeOfContactToGlobalCanonical[contactType] as keyof typeof hashGlobalCanonicalToTypeOfContact;
}

export function isCanonicalCompatible(typeOfContact: ETypeOfContact, canonical: EMetadataNames): boolean {
    return getTypeOfContactFromCanonical(canonical) === typeOfContact;
}



export function isSameTypeOfContact(action: EDelivery360Action, canonical: EMetadataNames): boolean {
    return getTypeOfContact(action) === getTypeOfContactFromCanonical(canonical);
}

/**
 * @deprecated Use a função do mesmo nome do arquivo: shared-business-rules/social-cc/config-cell.ts:95
 */
export function getNoDDDPhone(phone: string): string {
    // atenção funciona para brasil, para outros países, fazer um hash de area internacional
    return phone.substr(4);
}


function createNameUniqueSplitterPattern(delimiter: string) {
    return new RegExp(`${escapeRegExp(delimiter)}(?!\\.)((\\w){6,10})$`);
}

export function getTargetFromInteraction(int: IServiceChatInitiatedInteractionJSON): string {
    return int.e360Provider[0].target;
}

