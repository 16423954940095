import { IBasicToolbarElement, TReferencedObject } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types";
import { GraphElement } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-element";
import { GraphPredicate } from "@colmeia/core/src/shared-business-rules/graph/essential/predicate";
import { Edge, Node, NodePositionChange, ReactFlowInstance } from 'reactflow';
import { GraphNodeHTMLHandler } from "../diagram-editor-node-html.handler";
import { PointPortModel } from "../diagram-editor.types";
import { DiagramReactFlowBridge } from "./diagram-reactflow-bridge";
import { StandardNode } from './custom-nodes/standard.node';
import { NestedAIRootNode } from './custom-nodes/nestedAIRoot.node';
import { KnowledgeBaseService } from 'app/services/knowledge-base.service';
import { BpmService } from 'app/services/bpm/bpm.service';
export interface IBasicNodeData {
    graphElement: GraphElement;
    htmlHandler: GraphNodeHTMLHandler;
    connectionPorts: PointPortModel[];
    bpmSvc?: BpmService;
}

export interface IBasicEdgeData {
    grapPredicate: GraphPredicate;
}

export type $Node = Node<IBasicNodeData>;
export type $DroppedNode = Node<TReferencedObject>;
export type $Edge = Edge<IBasicEdgeData>;

/**
 * Node events
 */
export interface INodeAddEvent {
    type: 'add';
    node: $Node;
}

export interface INodeDropeedEvent {
    type: 'drop';
    node: $DroppedNode;
}

export type TNodeRemoveRequest = {
    type: 'removeRequest';
    id: string;
    node?: $Node;
}

export type TNodeOptionsChangeRequest = {
    type: 'changeOptions';
    id: string;
    nodeOptions: Partial<Omit<$Node, 'id'>>;
}

export interface INodePositionChangeEvent extends NodePositionChange {
    node?: $Node;
}

/**
 * Edge events
 */
export interface IEdgeAddEvent {
    type: 'add'
    edge: $Edge;
}

export type TEdgeRemoveRequest = {
    type: 'removeRequest';
    id: string;
    edge?: $Edge;
}

export type IEdgeOptionsChangeEvent = {
    type: 'changeOptions';
    id: string;
    edgeOptions: Partial<Omit<$Edge, 'id'>>;
}

export type IEdgeCreateRequestEvent = {
    type: 'createRequest';
    sourceId: string;
    targetId: string;
    sourceType: TReferencedObject;
    targetType: TReferencedObject;
    subElementId?: string;
}

/**
 * Eventos causados por interação do usuario com o gráfico(movimentação/remoção)
 */
export type TUserInteractionNodesEvents = INodeAddEvent | INodeDropeedEvent | INodePositionChangeEvent | TNodeRemoveRequest;
export type TUserInteractionEdgeEvents = IEdgeCreateRequestEvent | TEdgeRemoveRequest;

export type TAllNodesEvents = TUserInteractionNodesEvents | TNodeOptionsChangeRequest;
export type TAllEdgeEvents = TUserInteractionEdgeEvents | IEdgeAddEvent | IEdgeOptionsChangeEvent;

export interface ReactDiagramEditorProps {
    bridge: DiagramReactFlowBridge;
}

export interface ISelectionChange {
    nodes: $Node[];
    edges: $Edge[];
}

export type TReactFlow = ReactFlowInstance<IBasicNodeData, IBasicEdgeData>

export type TBPMDropIntentionPermissionCheck = (type: TReferencedObject, node: $Node) => boolean;
export type TBPMConnectionPermissionCheck = (type: TReferencedObject, from: $Node, to: $Node) => boolean;

export interface IBPMDropIntentionData {
    toolbarElement: IBasicToolbarElement
}

export enum ENodeRenderType {
    standard = 'standard',
    nestedAIRoot = 'nestedAIRoot'
};

type TRenderMap = { [key in ENodeRenderType]: (...args: any[]) => React.JSX.Element; }

export const RenderNodeByTypeMap = (): TRenderMap => ({
    standard: StandardNode,
    nestedAIRoot: NestedAIRootNode,
});
