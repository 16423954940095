import { IRequest } from "../../request-interfaces/request-interfaces";
import { IResponse } from "../../request-interfaces/response-interfaces";
import { IEntityPickerColmeiaList, TEntityPickerQueryParams } from "../entity-picker/entity-picker.types";
import { IAccessToken, IAdAccountData, IAdCampaignData, IAdData, IAdProvider, IAdSetData, IBusinessAccountData, IColmeiaAdEvent, ICreateCustomerEventParams, ICreateTrackingTagParams, ICustomerAdEvent, ITrackingTagData, IUpdateTrackingTagParams } from "./marketing-performance-ads-shared-types";


export enum EMarketingPerformanceRequests {
    getAccessTokens = 'mp.getAccessTokens',
    saveAccessToken = 'mp.saveAccessToken',

    getBusinessAccounts = 'mp.getBusinessAccounts',
    getTrackingTags = 'mp.getTrackingTags',
    createTrackingTag = 'mp.createTrackingTag',
    updateTrackingTag = 'mp.updateTrackingTag',

    getAdProviders = 'mp.getAdProviders',
    getAdAccounts = 'mp.getAdAccounts',
    getAdCampaigns = 'mp.getAdCampaigns',
    getAdSets = 'mp.getAdSets',
    getAds = 'mp.getAds',

    synchronizeAds = 'mp.synchronizeAds',
    setAdTrackingTag = 'mp.setAdTrackingTag',

    getColmeiaEvents = 'mp.getColmeiaEvents',
    getAdEvents = 'mp.getAdEvents',
    createAdEvent = 'mp.createAdEvent',
}

export enum EMarketingPerformanceMessages {
    businessAccountNotFound,
    businessAccountDuplicated,
    businessAccountOnOtherSocialNetwork,
}

//Access tokens
export interface IMarketingPerformanceGetAccessTokensRequest extends IRequest {
    params?: TEntityPickerQueryParams;
}

export interface IMarketingPerformanceGetAccessTokensResponse extends IResponse, IEntityPickerColmeiaList<IAccessToken> {}

export interface IMarketingPerformanceSaveAccessTokenRequest extends IRequest {
    token: string;
}

export interface IMarketingPerformanceSaveAccessTokenResponse extends IResponse {
    success: boolean;
    message?: EMarketingPerformanceMessages;
}

// Business accounts
export interface IMarketingPerformanceGetBusinessAccountsRequest extends IRequest {
    params?: TEntityPickerQueryParams;
}

export interface IMarketingPerformanceGetBusinessAccountsResponse extends IResponse, IEntityPickerColmeiaList<IBusinessAccountData> {}

// Tracking Tags

export interface IMarketingPerformanceGetTrackingTagsRequest extends IRequest {
    params?: TEntityPickerQueryParams;
}

export interface IMarketingPerformanceGetTrackingTagsResponse extends IResponse, IEntityPickerColmeiaList<ITrackingTagData> {}

export interface IMarketingPerformanceTrackingTagRequest extends IRequest {
    params: ICreateTrackingTagParams;
}

export interface IMarketingPerformanceTrackingTagResponse extends IResponse {
    success: boolean;
}

export interface IMarketingPerformanceUpdateTrackingTagRequest extends IRequest {
    params: IUpdateTrackingTagParams;
}

export interface IMarketingPerformanceUpdateTrackingTagResponse extends IResponse {
    success: boolean;
}

// ADS

// Ad Providers
export interface IMarketingPerformanceGetAdProvidersRequest extends IRequest {
    params?: TEntityPickerQueryParams;
}

export interface IMarketingPerformanceGetAdProvidersResponse extends IResponse, IEntityPickerColmeiaList<IAdProvider> {}

// Ad Accounts
export interface IMarketingPerformanceGetAdAccountsRequest extends IRequest {
    params?: TEntityPickerQueryParams;
}

export interface IMarketingPerformanceGetAdAccountsResponse extends IResponse, IEntityPickerColmeiaList<IAdAccountData> {}

// Ad Campaigns

export interface IMarketingPerformanceGetAdCampaignsRequest extends IRequest {
    adAccountId: string;
    params?: TEntityPickerQueryParams;
}
export interface IMarketingPerformanceGetAdCampaignsResponse extends IResponse, IEntityPickerColmeiaList<IAdCampaignData> {}

// Ad Sets
export interface IMarketingPerformanceGetAdSetsRequest extends IRequest {
    params?: TEntityPickerQueryParams;
}
export interface IMarketingPerformanceGetAdSetsResponse extends IResponse, IEntityPickerColmeiaList<IAdSetData> {}

// Ads
export interface IMarketingPerformanceGetAdsRequest extends IRequest {
    params?: TEntityPickerQueryParams;
}
export interface IMarketingPerformanceGetAdsResponse extends IResponse, IEntityPickerColmeiaList<IAdData> {}

export interface IMarketingPerformanceSynchronizeAdsRequest extends IRequest {
    token: string;
}

export interface IMarketingPerformanceSynchronizeAdsResponse extends IResponse {}

export interface IMarketingPerformanceSetAdTrackingTagRequest extends IRequest {
    idAd: string;
    idTrackingTag: string;
}

export interface IMarketingPerformanceSetAdTrackingTagResponse extends IResponse {
    success: boolean;
}

// Ad token
export interface IMarketingPerformanceInsertTokenRequest extends IRequest {
    tokenData: IAccessToken;
}
export interface IMarketingPerformanceInsertTokenResponse extends IResponse {}

export interface IMarketingPerformanceUpdateTokenRequest extends IRequest {
    tokenData: IAccessToken;
}
export interface IMarketingPerformanceUpdateTokenResponse extends IResponse {}

export interface IMarketingPerformanceGetTokensRequest extends IRequest {
    idAdToken: string;
}
export interface IMarketingPerformanceGetTokensResponse extends IResponse {
    tokenData: IAccessToken[];
}

//Colmeia events
export interface IMarketingPerformanceGetColmeiaEventsRequest extends IRequest {
    params?: TEntityPickerQueryParams;
}
export interface IMarketingPerformanceGetColmeiaEventsResponse extends IResponse, IEntityPickerColmeiaList<IColmeiaAdEvent> {}


//Ad events
export interface IMarketingPerformanceGetEventsRequest extends IRequest {
    params?: TEntityPickerQueryParams;
}
export interface IMarketingPerformanceGetEventsResponse extends IResponse, IEntityPickerColmeiaList<ICustomerAdEvent> {}

export interface IMarketingPerformanceCreateEventsRequest extends IRequest {
    eventParams: ICreateCustomerEventParams,
}
export interface IMarketingPerformanceCreateEventsResponse extends IResponse {
    success: boolean;
}
