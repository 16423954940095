<div>
    <h4 *ngIf="title">{{title}}</h4>
    <div class="tag-picker-wrapper">
        <app-non-serializable-taggable *ngIf="tagPickerHandler" [tagPickerHandler]="tagPickerHandler"></app-non-serializable-taggable>
    </div>
    <app-alert *ngIf="error" type="error" style="margin-bottom: 16px;">
        {{ errorMsg }}
    </app-alert>

    <app-enum-picker 
      *ngIf="enumPickerHandler"
      class="enum-picker-tracker"
      style="margin-bottom: 16px;"
      [handler]="enumPickerHandler">
    </app-enum-picker>

    <mat-form-field 
      appearance="fill" 
      class="full-width-field">
        <mat-label>{{ parameterInputTitle }}</mat-label>
        <input matInput [(ngModel)]="naviTracker.parameter">
    </mat-form-field>

    <mat-dialog-actions align="end" *ngIf="!handler">
        <button mat-button (click)="cancel()">Cancelar</button>
        <button mat-flat-button color="primary" (click)="save()">Salvar</button>
    </mat-dialog-actions>
</div>