import { Directionality } from '@angular/cdk/bidi';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, TemplatePortal } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import { ApplicationRef, ChangeDetectorRef, ComponentRef, EmbeddedViewRef, Injectable, InjectFlags, InjectionToken, Injector, StaticProvider, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { isInvalid, isValidArray, isValidNumber, isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { ColmeiaWindowConfig } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-config';
import { ISystemLogoutListener } from 'app/model/signal/ps-interfaces';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { LookupService } from 'app/services/lookup.service';
import { ScreenSpinnerService, SpinType } from 'app/services/screen-spinner.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SignalListenerService } from 'app/services/signal/signal-listener';
//@ts-ignore
import { last } from 'lodash/fp';
import { BehaviorSubject, fromEvent, merge, Observable, of as observableOf, Subject, zip } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { SubscriptionGroup } from './../../../../model/client-utility';
import { ColmeiaWindowContainer } from "./colmeia-window-container";
import { DashboardWindowEditorRef } from './colmeia-window-edit-ref';
import { ColmeiaWindowRef } from './colmeia-window-ref';
import { ColmeiaWindowRuntime } from './colmeia-window-runtime';
import { EWindowStateChangeType, TWindowStateChange } from './colmeia-window.model';

let windowsIds: number = 0;

export const WINDOW_DATA = new InjectionToken<any>('ColmeiaDialogData');
export const WINDOWS_Z_INDEX_LEVEL = 800;

type TActiveWindows = ColmeiaWindowRef[];

@Injectable({
    providedIn: 'root'
})
export class ColmeiaWindowService implements ISystemLogoutListener {
    public windowRefRuntimeMap: Map<ColmeiaWindowRef, ColmeiaWindowRuntime> = new Map();
    public windowIdentifierRefMap: Map<string, ColmeiaWindowRef> = new Map();

    private _activeWindow$: Subject<number> = new Subject();
    public activeWindow$(): Observable<number> { return this._activeWindow$.asObservable(); }
    private activeHistoryLIFO: number[] = [];
    public get currentActiveIndex(): number {
        return last(this.activeHistoryLIFO);
    }

    private _windowStateChange: Subject<TWindowStateChange> = new Subject();
    public windowStateChange(): Observable<TWindowStateChange> {
        return this._windowStateChange.asObservable();
    }

    private _shift = new Subject<number>();
    public shift$ = () => this._shift.asObservable();

    private _opened = new Subject<number>();
    public opened$ = () => this._opened.asObservable();

    private _closed = new Subject<number>();
    public closed$ = () => this._closed.asObservable();

    private _afterRestore = new Subject<ColmeiaWindowRef>();
    public afterRestore$ = <T>() => this._afterRestore.asObservable() as Observable<ColmeiaWindowRef<T>>;

    private _afterMinimize = new Subject<ColmeiaWindowRef>();
    public afterMinimize$ = () => this._afterMinimize.asObservable();

    public anyUpdate$(): Observable<unknown> {
        return merge(
            this.shift$(),
            this.closed$(),
            this.opened$(),
            this.afterMinimize$(),
            this.afterRestore$(),
            this.activeWindow$()
        )
    }

    private _lastUrl: string;
    private _lastNavigatedUrl: string;

    private _activeWindows: TActiveWindows = [];
    private _activeWindows$: BehaviorSubject<TActiveWindows> = new BehaviorSubject<TActiveWindows>([]);
    public activeWindows$(): Observable<TActiveWindows> { return this._activeWindows$.asObservable() }
    public get activeWindows(): TActiveWindows { return [...this._activeWindows] };

    public getActivityHistory(): number[] {
        return [...this.activeHistoryLIFO];
    }

    public prefersReducedMotion: boolean;
    private ignoreAfterAllMinimized: boolean = false;
    private ignoreUrlChange: boolean = false;

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private dashboardSvc: DashBoardService,
        private api: ServerCommunicationService,
        private lookupSvc: LookupService,
        private router: Router,
        private location: Location,
        private listener: SignalListenerService,
        private appRef: ApplicationRef,
        private screenLoadingSvc: ScreenSpinnerService
    ) {
        this.setupRouterListener();
        this.listener.listenToLogoutEvent(this);
        this.screenLoadingSvc.lastStatus$.pipe(filter(ev => ev.spinType === SpinType.socialNetworkChange)).subscribe(() => {
            this.closeAll();
        });

        const mediaQueryList = window.matchMedia('(prefers-reduced-motion)');
        this.prefersReducedMotion = mediaQueryList.matches;

        mediaQueryList.onchange = (change) => {
            this.prefersReducedMotion = change.matches;
        }

        this._afterMinimize.subscribe(() => {
            this.isAllMinimized() && this.afterAllMinimized();
        });

        fromEvent(this.appRef.components[0].location.nativeElement, "click")
            .subscribe(async () => {
                if (this.isAllMinimized()) return;

                this.ignoreAfterAllMinimized = true;

                const c = this.getWindowRefByZIndexOrder().reverse().filter(ref => ref.isVisible);
                const initialLength = c.length;
                this.ignoreUrlChange = false;

                const next = (c: ColmeiaWindowRef[]) => {
                    const ref = c.shift();

                    if (c.length <= initialLength - 1) {
                        this.ignoreUrlChange = true;
                    }

                    if (!ref) {
                        this.ignoreUrlChange = false;
                        return;
                    }

                    this.shift$().pipe(take(1)).subscribe(() => {
                        next(c);
                    });

                    ref.minimize(true);
                    this.shiftWindow(false, ref.windowIndex);
                };

                next(c);
            });
    }

    receiveLogoutEventCallback() {
        this.closeAll();
    }

    private getWindowRefByZIndexOrder() {
        return (
            this.activeHistoryLIFO
                .map(windowIdx =>
                    this._activeWindows.find(ref => ref.windowIndex === windowIdx)
                ).filter(isValidRef)
        );
    }

    public setCurrentUrl(url: string, setLastNavigated: boolean = true, keepQueryParams?: boolean) {
        if (this.ignoreUrlChange) return;

        const isCurrentPath = url === this.location.path();
        if (isCurrentPath || (url === this._lastNavigatedUrl && isCurrentPath)) return;

        if (setLastNavigated) {
            this._lastNavigatedUrl = url;
        }


        /**
         * Mantém estado de search (query) params na url
         */
        let urlSearch = ''
        if (keepQueryParams) {
            const currentUrl = new URL(window.location.href);
            const searchParams = new URLSearchParams(currentUrl.search);
            const seachString = searchParams.toString();
            urlSearch = isValidString(seachString) ? '?' + seachString : '';
        }

        this.location.go(`${url}${urlSearch}`);
    }

    private setupRouterListener() {

        this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((e: NavigationStart) => {
            this._lastUrl = e.url;

            if (e.navigationTrigger === "imperative") {
                !this.isAllMinimized() && this._resetHistory();
            }
            this._lastNavigatedUrl = e.url;
        });

        fromEvent(window, "popstate").subscribe((event: PopStateEvent) => {
            const matchedRuntime = [...this.windowRefRuntimeMap.values()].find(rt => rt.triggerUrl === window.location.pathname);

            if (matchedRuntime) {
                matchedRuntime.dialogRef._restore();
            }
            else {
                !this.isAllMinimized() && this._resetHistory();
            }
            this._lastNavigatedUrl = window.location.pathname;
        });
    }

    open<C, D = any>(component: ComponentType<C>, config: ColmeiaWindowConfig<D>): ColmeiaWindowRef<C, D> {
        if (config.windowIdentifier) {
            const alreadyOpenedWindow = this.windowAlreadyOpen(config.windowIdentifier);

            if (alreadyOpenedWindow) {
                alreadyOpenedWindow.restore();
                return alreadyOpenedWindow as ColmeiaWindowRef<C, D>;
            }
        }

        const windowIndex: number = this.push();
        const overlayRef: OverlayRef = this.createOverlay(config);
        const windowContainer: ColmeiaWindowContainer = this._attachDialogContainer(overlayRef, config);
        const windowRef: ColmeiaWindowRef = this.getDashboardWindowRef(overlayRef, windowContainer, windowIndex, config);
        const injector: Injector = this.getInjector(config, windowRef, config.data, null, config.injectProviders);
        const contentRef = windowContainer.attachComponentPortal(
            new ComponentPortal(component, null, injector)
        );

        windowContainer._initializeWithAttachedContent();

        windowRef
            .updateSize(config.width, config.height)
            .updatePosition(config.position);

        this.setupCommonWindowEvents(windowRef, contentRef.changeDetectorRef);


        windowRef.detectChange = () => {
            contentRef.changeDetectorRef.detectChanges();
        };
        windowRef.componentInstance = contentRef.instance;

        if (config.closeOnNavigation) {
            this.router.events.pipe(take(1)).subscribe(() => windowRef.close());
        }

        windowRef.beforeClosed().pipe(take(1)).subscribe(() => {
            this.windowIdentifierRefMap.forEach((savedWindowRef) => {
                if (savedWindowRef.parentIdentifier === windowRef.windowIdentifier) {
                    savedWindowRef.close();
                }
            })

        })
        windowRef.afterClosed().pipe(take(1)).subscribe(() => {
            this.windowIdentifierRefMap.delete(windowRef.windowIdentifier);
        })

        this.windowIdentifierRefMap.set(windowRef.windowIdentifier, windowRef);

        return windowRef as ColmeiaWindowRef<C, D>;
    }

    openWindow(runtime?: ColmeiaWindowRuntime): ColmeiaWindowRef {
        if (runtime?.sourceObject?.idNS) {

            const alreadyOpenedWindow = this.windowAlreadyOpen(runtime.sourceObject.idNS);

            if (alreadyOpenedWindow) {
                alreadyOpenedWindow.restore();
                return alreadyOpenedWindow;
            }
        }

        const { component, templateRef, dialogConfig = {} } = runtime.windowConfig;

        if (!component && !templateRef) {
            throw new Error("You must define a component or templateRef to open an window");
        }

        const windowIndex: number = this.push();
        const config: ColmeiaWindowConfig = this.getDialogConfig({
            ...dialogConfig,
            closeOnNavigation: false,
            windowIdentifier: runtime?.sourceObject?.idNS,
        });
        const overlayRef: OverlayRef = this.createOverlay(config);
        const windowContainer: ColmeiaWindowContainer = this._attachDialogContainer(overlayRef, config);
        const windowRef: ColmeiaWindowRef = this.getDashboardWindowRef(overlayRef, windowContainer, windowIndex, config);
        const editorRef: DashboardWindowEditorRef = this.getGenericHomeEditorRef(runtime, dialogConfig.data);
        const injector: Injector = this.getInjector(config, windowRef, dialogConfig.data, editorRef, runtime.additionalProviders);

        const contentRef = component
            ? windowContainer.attachComponentPortal(new ComponentPortal(component, null, injector))
            : windowContainer.attachTemplatePortal(new TemplatePortal(templateRef, null, injector));


        let embeddedView = templateRef && (contentRef as EmbeddedViewRef<any>)

        const instance = component
            ? (contentRef as ComponentRef<any>).instance
            : embeddedView;

        windowContainer._initializeWithAttachedContent();

        windowRef
            .updateSize(config.width, config.height)
            .updatePosition(config.position);

        runtime && this.windowRefRuntimeMap.set(windowRef, runtime);

        this.setupCommonWindowEvents(windowRef, component ? (contentRef as ComponentRef<any>).changeDetectorRef : embeddedView);

        windowRef.componentInstance = instance;

        return windowRef;
    }

    private getDialogConfig(configOverride: ColmeiaWindowConfig): ColmeiaWindowConfig {
        return new ColmeiaWindowConfig(configOverride)
    }

    private getDashboardWindowRef(
        overlayRef: OverlayRef,
        dialogContainer: ColmeiaWindowContainer,
        windowIndex: number,
        config: ColmeiaWindowConfig): ColmeiaWindowRef {
        const dashboardWindowRef = new ColmeiaWindowRef(
            overlayRef,
            dialogContainer,
            windowIndex,
            this,
            config
        );

        this._activeWindows.push(dashboardWindowRef);
        this._activeWindows$.next(this._activeWindows);
        return dashboardWindowRef;
    };

    public removeDashboardWindowRef(instance: ColmeiaWindowRef) {
        this.windowRefRuntimeMap.delete(instance);
        this._activeWindows.splice(this._activeWindows.indexOf(instance), 1);
        this._activeWindows$.next(this._activeWindows);
        this.shiftWindow(true);
    }

    private getGenericHomeEditorRef(runtime: ColmeiaWindowRuntime, data?: any) {
        return new DashboardWindowEditorRef(
            runtime,
            data,
            this.dashboardSvc,
            this.api,
            this.lookupSvc
        );
    }

    private _attachDialogContainer(overlay: OverlayRef, config: ColmeiaWindowConfig): ColmeiaWindowContainer {
        const injector = Injector.create({
            parent: this.injector,
            providers: [{ provide: ColmeiaWindowConfig, useValue: config }]
        });

        const containerPortal = new ComponentPortal(
            ColmeiaWindowContainer,
            config.viewContainerRef,
            injector,
        );
        const containerRef = overlay.attach<ColmeiaWindowContainer>(containerPortal);

        return containerRef.instance;
    }

    private getInjector(
        config: ColmeiaWindowConfig,
        windowRef: ColmeiaWindowRef<any>,
        windowData?: any,
        editorRef?: DashboardWindowEditorRef,
        additionalProviders?: StaticProvider[]
    ) {
        const providers: StaticProvider[] = [
            { provide: ColmeiaWindowRef, useValue: windowRef },
            { provide: WINDOW_DATA, useValue: windowData },
            { provide: MAT_DIALOG_DATA, useValue: windowData },
            { provide: DashboardWindowEditorRef, useValue: editorRef }
        ];

        if (config.direction && (this.injector.get<Directionality | null>(Directionality, null, InjectFlags.Optional))) {
            providers.push({
                provide: Directionality,
                useValue: { value: config.direction, change: observableOf() }
            });
        }

        if (isValidArray(additionalProviders)) {
            providers.push(...additionalProviders);
        }

        return Injector.create({ parent: this.injector, providers });
    }

    private createOverlay(dialogConfig: ColmeiaWindowConfig): OverlayRef {
        const state = new OverlayConfig({
            positionStrategy: this.overlay.position().global(),
            panelClass: ['dialog-window'].concat(dialogConfig.panelClass || ''),
            hasBackdrop: false,
            direction: dialogConfig.direction,
            minWidth: dialogConfig.minWidth,
            minHeight: dialogConfig.minHeight,
            maxWidth: dialogConfig.maxWidth,
            maxHeight: dialogConfig.maxHeight,
            disposeOnNavigation: false
        });

        if (dialogConfig.backdropClass) {
            state.backdropClass = dialogConfig.backdropClass;
        }

        return this.overlay.create(state);
    }

    private setActiveWindow(value: number) {
        const indexOnHistory = this.activeHistoryLIFO.indexOf(value);

        if (indexOnHistory > -1)
            moveItemInArray(
                this.activeHistoryLIFO,
                indexOnHistory,
                this.activeHistoryLIFO.length - 1
            )
        else
            this.activeHistoryLIFO.push(value);

        this._activeWindow$.next(this.currentActiveIndex);
        this.updateOverlayWrapperZIndexes();
    }

    private setupCommonWindowEvents(windowRef: ColmeiaWindowRef, contentRef: ChangeDetectorRef) {
        const groupSubscription = new SubscriptionGroup();

        windowRef.afterOpened().pipe(take(1)).subscribe(() => {
            this.anyUpdate$().pipe(takeUntil(windowRef.afterClosed())).subscribe(() => {
                if (this.currentActiveIndex === windowRef.windowIndex) {
                    contentRef.reattach();
                } else {
                    contentRef.detach();
                }
            });

            this.setActiveWindow(windowRef.windowIndex);
            this._opened.next(windowRef.windowIndex);
        });

        windowRef.afterClosed().pipe(take(1)).subscribe(() => {
            this._closed.next(windowRef.windowIndex);

            this.updateState({
                windowIdx: windowRef.windowIndex,
                type: EWindowStateChangeType.Closed
            });

            groupSubscription.destroy();
        });

        groupSubscription.from(windowRef.afterMinimize()).subscribe(() => {
            this._afterMinimize.next(windowRef);
        });

        groupSubscription.from(windowRef.afterRestore()).subscribe(() => {
            this.setActiveWindow(windowRef.windowIndex);
            this._afterRestore.next(windowRef);
        });
    }

    updateState(state: TWindowStateChange) {
        this._windowStateChange.next(state);
    }

    public push({ setActive }: { setActive: boolean } = { setActive: false }): number {
        ++windowsIds;

        if (setActive) {
            this.setActiveWindow(windowsIds);
        }

        return windowsIds;
    }

    public shiftWindow(forceAllMinimizedCheck?: boolean, targetIndex?: number): void {
        let removedIndex: number;

        if (isValidNumber(targetIndex)) {
            removedIndex = this.activeHistoryLIFO[targetIndex!];
            if (isInvalid(removedIndex)) return;
            this.activeHistoryLIFO.splice(targetIndex!, 1);
        } else {
            //@ts-ignore
            removedIndex = this.activeHistoryLIFO.pop();
        }

        const isRoot = !this._activeWindows.some(ref => ref.windowIndex === removedIndex);
        this._shift.next(this.currentActiveIndex);
        this._activeWindow$.next(this.currentActiveIndex);

        if ((!isRoot || forceAllMinimizedCheck) && this.isAllMinimized()) {
            this.afterAllMinimized()
        }
    }

    private getAllWindowRefHistoryEntries() {
        return this.activeHistoryLIFO.filter(i => this._activeWindows.some(ref => ref.windowIndex === i));
    }

    private updateOverlayWrapperZIndexes() {
        this._activeWindows
            .forEach(ref => {
                ref.hostElement.style.zIndex = `${WINDOWS_Z_INDEX_LEVEL + this.activeHistoryLIFO.indexOf(ref.windowIndex)}`;
            })
    }

    private _resetHistory() {

        this._activeWindows
            .forEach((wind) => wind._minimize());

        this.activeHistoryLIFO = [];

        this._activeWindows$.next(this._activeWindows);

        this.updateOverlayWrapperZIndexes();
    }

    public minimizeAll() {
        const [_first] = this.getRootsIndexes();
        const activeWindowsByHistoryOrder = this.getActiveWindowByActiveHistoryOrder();

        activeWindowsByHistoryOrder
            .forEach((wind) => {
                console.log({ disableMinizeAllWhileVisible: (wind.data as ColmeiaWindowConfig).disableMinizeAllWhileVisible })
                if (!(wind.data as ColmeiaWindowConfig).disableMinizeAllWhileVisible) {
                    wind._minimize();
                }
            });

        if (_first) {
            this.activeHistoryLIFO = [_first];
            this._shift.next(_first);
        } else {
            this.activeHistoryLIFO = [];
            this._shift.next();
            this.afterAllMinimized();
        }

        this._activeWindows$.next(this._activeWindows);

        this.updateOverlayWrapperZIndexes();
    }

    public maxmizeAll() {
        zip(
            this._activeWindows.map((ref) => ref.afterRestore())
        ).pipe(take(1)).subscribe(() => {
            this._activeWindows$.next(this._activeWindows);
            this._shift.next(this.currentActiveIndex);
            this._activeWindow$.next(this.currentActiveIndex);
            this.updateOverlayWrapperZIndexes();
        });

        this._activeWindows.forEach((wind, i) => {
            i === this._activeWindows.length - 1 ? wind.restore() : wind._restore()
        });
    }

    private afterAllMinimized() {
        if (this.ignoreAfterAllMinimized) {
            this.ignoreAfterAllMinimized = false;
            return;
        }

        if (isValidString(this._lastNavigatedUrl)) {
            this.location.replaceState(this._lastNavigatedUrl);
        }

        this.ignoreAfterAllMinimized = false;
    }

    private getActiveWindowByActiveHistoryOrder() {
        return (
            this.activeHistoryLIFO
                .map(idx => this._activeWindows.find(ref => ref.windowIndex === idx))
                .filter(isValidRef)
        );
    }

    public get lastUrl(): string {
        return this._lastUrl;
    }

    public get lastNavigatedUrl(): string {
        return this._lastNavigatedUrl;
    }

    public isAllMinimized(): boolean {
        return this._activeWindows.every(ref => !ref.isVisible);
    }

    private getRootsIndexes(): number[] {
        return this.activeHistoryLIFO.filter(idx => !this._activeWindows.some(ref => ref.windowIndex === idx))
    }

    public closeAll() {
        zip(
            this._activeWindows.map((ref) => ref.afterClosed())
        ).pipe(take(1)).subscribe(() => {
            this._activeWindows$.next(this._activeWindows);
            this._shift.next(this.currentActiveIndex);
            this._activeWindow$.next(this.currentActiveIndex);
        });

        [...this._activeWindows].forEach((wind) => {
            wind.close(undefined)
        });
    }

    public windowAlreadyOpen(windowIndentifier: any): ColmeiaWindowRef | undefined {
        return this._activeWindows.find(window => window.windowIdentifier === windowIndentifier);
    }
}
