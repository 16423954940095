import { ETagType, ITaggable, TTagAssignmentArray } from "./tags";
import { TArrayID } from "../../core-constants/types";
import { getClock, isValidArray, isInvalidArray, typedClone, isValidRef } from "../../tools/utility";

export interface ITagConfig {
    canAddChild?: true,

}

type TTagDB = { [type: string]: ITagConfig }
const tagConfigDB: TTagDB = {
    [ETagType.socialNetwork]: {
        canAddChild: true,
    },

    [ETagType.tagGroup]: {
        canAddChild: true,
    },

    [ETagType.variable]: {
    },

    [ETagType.colmeia]: {
    },

    [ETagType.marker]: {
        canAddChild: true,
    },

}

export function getTagConfig(tagType: ETagType): ITagConfig {
    return tagConfigDB[tagType];
}

export function addTagToNS(taggable: ITaggable, idAvatar: string, defaultTagID: string, tags: TArrayID): void {
    if (isInvalidArray(taggable.tags)) {
        taggable.tags = [];
    }

    const total: TArrayID = tags.concat(defaultTagID);

    for (const idTag of total) {
        const index = taggable.tags.findIndex((i) => { return i.idTag === idTag });
        if (index === -1) {
            taggable.tags.push({
                clockTick: Date.now(),
                idAvatar,
                idTag
            });
        }
    }


    for (let k = taggable.tags.length - 1; k >= 0; --k) {
        const index = total.findIndex((i) => { return i === taggable.tags[k].idTag });
        if (index === -1) {
            taggable.tags.splice(k, 1);

        }
    }
};

/**
 * adds a tag if it does not exist
 * @param taggable 
 * @param idTag 
 * @param idAvatar 
 * @returns 
 */
export function upsertTag(taggable: ITaggable, idTag: string, idAvatar?: string): TTagAssignmentArray {
    const tag = taggable.tags.find(tag => tag.idTag === idTag)
    if (isValidRef(tag)) {
        return taggable.tags
    } else {
        return [
            ...taggable.tags, {
                clockTick: getClock(),
                idAvatar,
                idTag
            }]
    }
}

export function addTagToNSPure<Taggable extends ITaggable>(taggable: Taggable, idAvatar: string, defaultTagID: string, tags: TArrayID): Taggable {
    const otherTaggable: Taggable = typedClone(taggable);
    addTagToNS(otherTaggable, idAvatar, defaultTagID, tags);
    return otherTaggable;
}