import { Injectable } from '@angular/core';
import { PlayerCachedInfo } from "@colmeia/core/src/business/player-cached";
import { EPlatformUpdateType, versionContol } from "@colmeia/core/src/core-constants/version-control";
import { UID } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { IClientVersion } from "@colmeia/core/src/security/version";
import { getReadableUniqueID, isValidRef } from "@colmeia/core/src/tools/utility";
import * as FingerPrintJS from '@fingerprintjs/fingerprintjs';
import { createServiceLogger } from 'app/model/client-utility';
import * as Bowser from "bowser";
import { Parser } from "bowser";
import { Memoize } from 'typescript-memoize';

import { Observable, Subject } from 'rxjs';
import { clientConstants } from "../../../model/constants/client.constants";
import {
    ICameraHardwareAbstraction,
    IFileSystemLayer,
    IGeolocationHardwareAbstraction,
    IHardwareAbstractionLayer, ILocalNotificationsHardwareAbstraction,
    IMicrophoneHardwareAbstraction,
    INetworkHardwareAbstraction,
    INotificationHardwareAbstraction,
    IRedirectHardwareAbstraction,
    IStorageLayer,
    IUpdaterHardwareLayer,
    IVibrationHardwareAbstraction
} from '../hardware-interfaces';
import { SharedStorage } from "../shared/SharedStorage";
import { SharedVibration } from "../shared/SharedVibration";
import { BrowserAudio } from './browser-audio';
import { BrowserCamera } from './browser-camera';
import { BrowserFileOpener } from './browser-file-opener';
import { BrowserFilesystem } from './browser-filesystem';
import { BrowserGeneric } from "./browser-generic";
import { BrowserGeolocation } from "./browser-geolocation";
import { BrowserLocalNotification } from "./browser-local-notification";
import { BrowserMediaViewer } from './browser-media-viewer';
import { BrowserMicrophone } from "./browser-microphone";
import { BrowserNetwork } from "./browser-network";
import { BrowserNotifications } from "./browser-notifications";
import { BrowserRedirect } from "./browser-redirect";
import { BrowserUpdater } from "./browser-updater";
import { IFocusInfo } from '@colmeia/core/src/request-interfaces/response-interfaces';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Messaging, getMessaging, getToken, onMessage } from "firebase/messaging";

const ClientJS: typeof import('clientjs') = require("clientjs").ClientJS;
const loadcss = require("fg-loadcss");

Object.assign(window, {
    ClientJS,
    FingerPrintJS,
});

declare var window: any;// & typeof global['window'];

@Injectable({
    providedIn: 'root'
})
export class BrowserHardwareLayer implements IHardwareAbstractionLayer {
    protected log = createServiceLogger('BrowserHardwareLayer', 'pink');

    protected pnPromise: Promise<string>;
    protected pnPromiseResolver: (val: any) => void;
    protected parsedUA: Parser.ParsedResult = Bowser.parse(window.navigator.userAgent);

    private pageVisibility$ = new Subject<IFocusInfo>();
    private serviceWorkerRegistration: ServiceWorkerRegistration;

    constructor(
        protected geo: BrowserGeolocation,
        protected vibrate: SharedVibration,
        protected mic: BrowserMicrophone,
        protected storage: SharedStorage,
        protected camera: BrowserCamera,
        protected file: BrowserFilesystem,
        protected net: BrowserNetwork,
        protected redirect: BrowserRedirect,
        protected notifications: BrowserNotifications,
        protected generic: BrowserGeneric,
        protected localNotification: BrowserLocalNotification,
        protected audio: BrowserAudio,
        protected updater: BrowserUpdater,
        protected fileOpener: BrowserFileOpener,
        protected mediaViewer: BrowserMediaViewer,
    ) {
        this.createPnPromise();

        document.addEventListener('visibilitychange', () => {
            this.pageVisibility$.next({ isFocus: !document.hidden, });
        });
    }

    onVisibilityChange(): Observable<IFocusInfo> {
        return this.pageVisibility$.asObservable();
    }

    protected createPnPromise(): void {
        this.pnPromise = new Promise<string>((resolve, reject) => {
            this.pnPromiseResolver = resolve;
        });
    }

    setSocketConnectionOnline(isOnline: boolean): void {
        this.getNetworkLayer().setSocketIsOnline(isOnline);
    }

    getUpdater(): IUpdaterHardwareLayer {
        return this.updater;
    }

    waitUntilUnlock(): Promise<void> {
        return Promise.resolve();
    }

    setCachedPlayer(player: PlayerCachedInfo): void { }

    isFocused(): boolean {
        return !document.hidden;
    }

    bootListeners(): void {

    }

    getFile(): IFileSystemLayer {
        return this.file;
    }

    getStorage(): IStorageLayer {
        return this.storage;
    }

    getVibrationLayer(): IVibrationHardwareAbstraction {
        return this.vibrate;
    }

    getCameraLayer(): ICameraHardwareAbstraction {
        return this.camera;
    }

    getGeolocationLayer(): IGeolocationHardwareAbstraction {
        return this.geo;
    }

    getMicrophoneLayer(): IMicrophoneHardwareAbstraction {
        return this.mic;
    }

    getRedirectLayer(): IRedirectHardwareAbstraction {
        return this.redirect;
    }

    isMobile(): boolean {
        return !!window.navigator?.userAgent.match(/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/);
    }

    isNative(): boolean {
        return true;
    }

    getNetworkLayer(): INetworkHardwareAbstraction {
        return this.net;
    }

    getVersion(): IClientVersion {
        return {
            buildType: 'browser',
            build: 'latest',
            assets: 'latest',
            version: versionContol.version,
            platform: EPlatformUpdateType.Browser
        }
    }

    @Memoize()
    public async getMachineId() {
        const agent: FingerPrintJS.Agent = await FingerPrintJS.load();
        const computed: FingerPrintJS.GetResult = await agent.get();
        return computed.visitorId;
    }

    getDeviceUID(): Promise<UID> {
        return new Promise(async (resolve, reject) => {
            try {
                const machineId = await this.getMachineId();

                const completeDeviceID = `${machineId}-${this.getBrowserId()}`

                resolve(completeDeviceID);
            } catch (e) {
                this.log({ getDeviceUID: e });

                resolve(getReadableUniqueID())
            }
        });
    }

    getBrowserId(): string {
        return this.getBrowserName();
    }

    getPNClientID(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            if (this.platformHasPushNotifications()) {
                this.pnPromise.then(async () => {
                    let token = '';
                    try {
                        const firebaseApp: FirebaseApp = initializeApp(clientConstants.firebase.settings);
                        const messaging: Messaging = getMessaging(firebaseApp);
                        token = await getToken(messaging, {
                            vapidKey: clientConstants.firebase.vapidKey,
                            serviceWorkerRegistration: this.serviceWorkerRegistration
                        });
                        this.log('getPNClientID 1', { token });
                    } catch (e) {
                        this.log('getPNClientID 2', { error: e?.stack, msg: e?.message });
                    } finally {
                        this.log('getPNClientID 3', { token });
                        resolve(token);
                    }
                });
            } else {
                this.log('getPNClientID !platformHasPushNotifications');
                this.log('o dispositivo nao possui suporte a push notifications');
                resolve('');
            }
        });
    }

    getNotifications(): INotificationHardwareAbstraction {
        return this.notifications;
    }

    getDeviceName(): string {
        const browser: Parser.Parser = Bowser.getParser(window.navigator.userAgent);
        return `${browser.getOS().name} ${browser.getOSVersion() || ''} - ${browser.getBrowser().name}`;
    }

    getBrowserName(): string {
        const browser: Parser.Parser = Bowser.getParser(window.navigator.userAgent);
        return browser.getBrowserName(true)
    }

    getGenericPlatform(): any {
        return this.generic;
    }

    getLocalNotifications(): ILocalNotificationsHardwareAbstraction {
        return this.localNotification;
    }

    onMainHeaderMounted(): void { }
    onMainHeaderUnmounted(): void { }

    async beforeNotificationsActivate(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (!isValidRef(window.Notification)) {
                resolve(false);
                return;
            }
            Notification.requestPermission().then((perm: NotificationPermission) => {
                resolve(perm === 'granted');
            });
        });
    }

    getAudio() {
        return this.audio;
    }

    getFileOpener() {
        return this.fileOpener;
    }

    runOptimizations() { }

    getMediaViewer() {
        return this.mediaViewer;
    }

    async loadPlatformSpecificAssets() {
        loadcss.loadCSS('./assets/emojis/google.css');
        this.configureFirebase();
        this.pnPromiseResolver(null);
    }

    protected configureFirebase(): void {
        if (this.platformHasPushNotifications()) {
            try {


                const firebaseApp: FirebaseApp = initializeApp(clientConstants.firebase.settings);
                const messaging: Messaging = getMessaging(firebaseApp);
                window.firebase = firebaseApp;

                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker
                    .register('./assets/sw/colmeia-sw.js', {scope: '/assets/sw/', updateViaCache: 'all'})
                    .then(registration => {
                        console.log('Service Worker registered with scope:', registration.scope);
                        this.serviceWorkerRegistration = registration;
                        // messaging.useServiceWorker(registration);
                        console.log({registration})
                    })
                    .catch(function(err) {
                        console.log('Service worker registration failed, error:', err);
                      });;

                    // navigator.serviceWorker
                    //     .register('./assets/sw/colmeia-sw.js')
                    //     .then(registration => {
                    //         messaging.useServiceWorker(registration);
                    //         useServiceWorker
                    //     });
                }

                onMessage(messaging, (payload) => {
                    this.log('push notification message arrived:', { payload });
                    console.log({windowNotification: window.Notification, permission: Notification.permission})
                    if (isValidRef(window.Notification) && Notification.permission === 'granted') {
                        new Notification(payload.notification?.title!, {
                            body: payload.notification?.body
                        })
                    }
                })
            } catch (e) {
                console.error('this device does not have push notifications enabled!!!', { e })
            }
        }
    }

    platformHasPushNotifications(): boolean {
        if (!('Notification' in window)) {
            return false; // Browser doesn't support the Notification API
        }

        const platformType = this.parsedUA.platform.type.toLowerCase();
        if (platformType !== 'desktop' && platformType !== 'mobile') {
            return false; // Platform type is neither desktop nor mobile
        }

        if (platformType === 'desktop' && this.parsedUA.browser.name.toLowerCase() === 'safari') {
            return false; // Safari on desktop does not support standard push notifications
        }

        if ((top as any) !== (window as any)) {
            return false; // The web-app is in an iframe or embedded context(not allowed to request push notifications)
        }

        return true; // All checks passed, the platform supports push notifications
    }

    isAllowedToChangeNotificationsState(): boolean {
        if (!('Notification' in window)) {
            return false; // Browser doesn't support the Notification API
        }

        if ((top as any) !== (window as any)) {
            return false; // The web-app is in an iframe or embedded context can NOT change notification state
        }

        // if here we can change notification state
        return true;
    }
}
