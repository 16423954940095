import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { allUtteranceScreenOptionsTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/utterance.translations";
import { IMLCLUUtterenceLabel, IUtteranceServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/clu-core-interfaces';
import { deepUnique, formatMessage, isValidArray, MapBy } from "@colmeia/core/src/tools/barrel-tools";
import { DeepMap } from "@colmeia/core/src/tools/utility-types";

export function utteranceValidator(ns: IUtteranceServer, friendly: FriendlyMessage) {
    const mapEntities = MapBy.factory(ns.utterance.entityLabels).by(item => [item.entityId, item]).value();
    
    const entityCollisionIds = getLabelsCollisions(ns);

    // 

    if (isValidArray(entityCollisionIds)) {
        const collisionsText = entityCollisionIds
            .map(collision => [...collision.values()]
            .map(id => mapEntities.get(id)!.entityName).map(name => `- ${name}`))
            .map(names => names.join('\n'))
            .map(item => `\n${item}`)
            .join('\n')
        ;
        friendly.addReturnTranslationResponse(
            allUtteranceScreenOptionsTranslations.labelsCollisions,
            collisionsText,
        );
    }

    
    return friendly;
}


function* iterateRange(from: number, to: number) {
    let index = from;
    while (index <= to) yield index++;
}


function getLabelsCollisions(ns: IUtteranceServer) {
    const items: Set<string>[] = [...iterateRange(0, ns.utterance.example.length)].map(() => new Set());

    for (const label of ns.utterance.entityLabels) {
        for (const index of iterateRange(label.startCharIndex, label.endCharIndex)) {
            items[index].add(label.entityId);
        }
    }

    const collisions = deepUnique(items.filter(item => item.size > 1));

    return collisions;
}