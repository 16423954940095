import { IProvider } from "../../conn-provider/types";
import { DeliveryProvider } from "../../core-constants/types";
import { Miliseconds } from "../../time/time-utl";
import { IContentBasicAsset } from "../bot/bot-content-model";
import { TGraphPropertyData } from "../graph/essential/graph-types";
import { ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import { WhatsApp } from "../social-media/whatsapp-interface";

export type WAFId = string;

export enum EWAFStatus {
    UNREGISTERED = 'UNREGISTERED', // INFO: this is the DEFAULT value, this can be only changed to draft when the flow is inserted at least one time.
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED', // WARN: Once a Flow is published, it cannot be modified or deleted, but can be marked as deprecated.
    DEPRECATED = 'DEPRECATED',
    BLOCKED = 'BLOCKED', // Monitoring detected that the endpoint is unhealthy and set the status to Blocked. The Flow cannot be sent or opened in this state; the developer needs to fix the endpoint to get it back to Published state
    THROTTLED = 'THROTTLED', // Monitoring detected that the endpoint is unhealthy and set the status to Throttled. Flows with throttled status can be opened, however only 10 messages of the Flow could be sent per hour. The developer needs to fix the endpoint to get it back to the PUBLISHED state
    DELETED = 'DELETED', // The Flow is deleted and cannot be opened or sent (deletion only exists inside colmeia).
    ERROR = 'ERROR',
}

export const noPreviewFlowStatus = [EWAFStatus.UNREGISTERED, EWAFStatus.ERROR];

export enum EWAFCaterogies {
    SIGN_UP = 'SIGN_UP',
    SIGN_IN = 'SIGN_IN',
    APPOINTMENT_BOOKING = 'APPOINTMENT_BOOKING',
    LEAD_GENERATION = 'LEAD_GENERATION',
    CONTACT_US = 'CONTACT_US',
    CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
    SURVEY = 'SURVEY',
    OTHER = 'OTHER'
}

export enum EWAFComponents {
    TEXT_HEADING = 'TextHeading',
    TEXT_SUBHEADING = 'TextSubheading',
    TEXT_BODY = 'TextBody',
    TEXT_CAPTION = 'TextCaption',
    RICH_TEXT = 'RichText',
    TEXT_INPUT = 'TextInput',
    TEXT_AREA = 'TextArea',
    CHECKBOX_GROUP = 'CheckboxGroup',
    RADIO_BUTTONS_GROUP = 'RadioButtonsGroup',
    OPT_IN = 'OptIn',
    DROPDOWN = 'Dropdown',
    DATE_PICKER = 'DatePicker',
    EMBEDDED_LINK = 'EmbeddedLink',
    IMAGE = 'Image',
    FORM = 'Form',
    FOOTER = 'Footer',
}

export enum WAFInputType {
    TEXT = 'text',
    NUMBER = 'number',
    EMAIL = 'email',
    PASSWORD = 'password',
    PASSCODE = 'passcode',
    PHONE = 'phone',
}

export enum EWAFFontWeight {
    BOLD = 'bold',
    ITALIC = 'italic',
    NORMAL = 'normal',
    BOLD_ITALIC = 'bold_italic',
}

export type TWAFTextStylesClient = EWAFFontWeight.ITALIC | EWAFFontWeight.BOLD | 'strikethrough';

export enum EWAFOnSubmit {
    Navigate = 'navigate',
    Complete = 'complete',
    DataExchange = 'data_exchange'
}
export interface IWAFBasicAction {
    name: EWAFOnSubmit;
}

export enum EWAFSumbitType {
    Refresh = 'refresh',
    Continue = 'continue',
}

export interface IWAFNavigateAction extends IWAFBasicAction {
    name: EWAFOnSubmit.Navigate,
    next: { type: string, name: string },
    payload: Object
}

export interface IWAFCompleteAction extends IWAFBasicAction {
    name: EWAFOnSubmit.Complete,
    payload: Object
}

export type TWAFFieldDataType = TGraphPropertyData | boolean | Array<TWAFFieldDataType>


export interface IWAFConfigurationSearchData {
    parentId: string,
    configType: string;
    configurationId: string;
    extra: string;
}

export interface WAFSchemaMetadata {
    createdAt: string;
    target: string;
    phoneId: string;
    provider: string;
    idSocialNetwork: string;
    configuration: IWAFConfigurationSearchData,
    idWafSchema: string;
    colmeiaFlowVersion: string;
}

export interface IWAFDataExchangeAction extends IWAFBasicAction {
    name: EWAFOnSubmit.DataExchange,
    payload: {
        exchange_type: EWAFSumbitType,
        responses: Object,
        triggeredBy: string,
        metadata: WAFSchemaMetadata,
    }
}

export type TWAFActionTypeALL = IWAFNavigateAction | IWAFCompleteAction | IWAFDataExchangeAction;


export enum EWAFFieldTypes {
    string = 'string',
    number = 'number',
    interger = 'integer',
    boolean = 'boolean',
    enum = 'enum',
    object = 'object',
    array = 'array',
}

export interface IWAFIDynamicFieldType {
    type: string,
}

/**
 * INFO: Based on the documentation of the WAF, the array type only support an
 * object filed type as items. WHY? I don't know.
 */
export interface IWAFIDynamicFieldArrayType extends IWAFIDynamicFieldType {
    type: 'array'
    items: IWAFDynamicFieldTypes;
}

export interface IWAFIDynamicFieldObjectType extends IWAFIDynamicFieldType {
    type: 'object';
    properties: {
        [key: string]: IWAFIDynamicFieldType;
    }
}

export type IWAFDynamicFieldTypes =
    IWAFIDynamicFieldType |
    IWAFIDynamicFieldObjectType |
    IWAFIDynamicFieldArrayType;

export interface IDynamicField<T> {
    name: string; // unique name for every field. ainda nao esta em uso.
    id: string;
    default: T;
    typeName?: string;
}

// this is the data used for custom type of input componets for example:
// DropDown Fields, or CheckBoxGroup
export interface IWAFOption {
    id: string;
    title: string;
    description?: string;
    asideInfo?: string; // exemplo: para preços
    enabled: boolean;
}

//  Some of the Componets have a  list of dataSource
export type IWAFMultipleOptions = IWAFOption[];

export type IWAFDropdownOption = Omit<IWAFOption, 'asideInfo'>;
export type IWAFDropdownOptions = IWAFDropdownOption[];


export enum ImageScaleType {
    CONTAIN = 'contain', // default if not set
    COVER = 'cover',
    // methods not implements yet
    // FILL = 'fill',
    // FIT = 'fit',
    // INSIDE = 'inside',
    // OUTSIDE = 'outside',
}

export enum EWafEngineVersion {
    v3 = '3.0',
    v3_1 = '3.1',
    v4 = '4.0',
    v5 = '5.0',
}

export const CurrentWAFEngineVersion = EWafEngineVersion.v5;

// represents the version to use during communication with the WhatsApp Flows
// Data Endpoint. Currently, it is 3.0. If flow uses the data-channel
// capability, the validation system will ask to provide this property.
export enum EWAFDataApiVersion {
    v3 = '3.0',
}

export const CurrentWAFDataApiVersion = EWAFDataApiVersion.v3;


export enum FlowMessageVersion {
    V3 = "3",
}

export const DefaultFlowMessageVersion = FlowMessageVersion.V3;

export enum EWAFMode {
    DRAFT = 'draft',
    PUBLISHED = 'published',
}

export enum EWAFActionType {
    Navigate = 'navigate',
}
export const DefaultWAFActionType = EWAFActionType.Navigate;

/**
* base class for all action parameters types
 */
export interface IWAFActionParameter {
}

export interface IWACTAActionParameter {
    display_text: string;
    url: string;
}

export enum EWAFFooterCaptionPosition {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',

    // can have both at same time or only center, but one thing at time.
    // LeftAndRight = 'leftAndRight',
}

export interface IWAFValidationError {
    error: string;
    error_type: string;
    message: string;
    line_start: number;
    line_end: number;
    column_start: number;
    column_end: number;
}

export interface IWAFPreview {
    preview_url: string;
    expires_at: string;
}

export interface IWAFProvider extends IProvider {
    status: EWAFStatus;
    provider?: DeliveryProvider.whatsApp;
    whatsappFlowId?: WAFId;
    errorHistory?: string[];
    phoneId?: string;
}

export interface IWAFMessageComponents {
    header?: {
        format: WhatsApp.Message.Template.Structure.Format;
        content: IContentBasicAsset;
    };
    body: IContentBasicAsset;
    footer?: {
        content: IContentBasicAsset;
    };
    cta: IContentBasicAsset;
}

export interface IWAFMessageContent {
    header?: string;
    body: string;
    footer?: string;
    cta: string;
}

export type TIWAFProviderArray = IWAFProvider[];

export const MAX_WAF_OPTION_TITLE_SIZE: number = 30;
export const MAX_WAF_OPTION_DESCRIPTION_SIZE: number = 300;
export const MAX_WAF_OPTION_ASIDE_INFO_SIZE: number = 20;


export interface IFlowFieldMetadata {
    isOptional: boolean;
    type: EWAFComponents;
}

export interface IFlowField {
    idFlowField: string;  // fornecido pela Meta
    idField: string; // idFieldForm
    metadata?: IFlowFieldMetadata;
}

export type TIFlowFieldArray = Array<IFlowField>;

export type idDynamicField = string;
export type OverwriteDynamicFieldsContent = Record<idDynamicField, IContentBasicAsset>;

/**
 * @deprecated esta e a config de instancias antigas do flows.
 * nao podemos deletar caso ainda esteja sendo usado.
 * e existem breaking changes entra uma interface e a outra.
 */
export interface IFlowAreaV1 {
    id: string;
    shouldNotBeInHistoric?: boolean;
    fields: TIFlowFieldArray;
    dynamicFieldsContent: OverwriteDynamicFieldsContent;
    idFlowSchema: IdDep<ENonSerializableObjectType.wafSchema>;
    idScreen: string;
    formFlowOn: boolean;
    message: IWAFMessageComponents;
    errorMessage?: IWAFMessageComponents;
}

/**
 * @deprecated esta e a config de instancias antigas do flows.
 */
export type TIFlowAreaArray = Array<IFlowAreaV1>;

export interface IFlowAreaV2 {
    screenId: string;
    // Barbieri acha que não será usado.. em observação
    id: string;
    retry_limit?: number;
    fields: TIFlowFieldArray;
    enabled: boolean;
    dynamicFieldsContent: OverwriteDynamicFieldsContent;
    ForceShowArea: boolean; // not used yet
}

export type TIFlowAreaV2Array = Array<IFlowAreaV2>;

export enum FlowConfigurationType {
    Engagegement = 'engagement',
    Debug = 'debug',
    Template = 'template'
}

export interface IWAFLimitMessageInfo {
    header?: {
        format: WhatsApp.Message.Template.Structure.Format;
        content: IContentBasicAsset;
    };
    body: IContentBasicAsset;
    footer?: {
        content: IContentBasicAsset;
    };
}

export interface IWAFConfigurationLimits {
    retryCount: number;
    errorMessage?: IWAFLimitMessageInfo;
}

export interface IWAFConfiguration {
    id: string;
    formFlowOn: boolean;
    idFlowSchema: IdDep<ENonSerializableObjectType.wafSchema>;
    flowAreas: TIFlowAreaV2Array;
    type: FlowConfigurationType;
    //--- nao sei ainda se isso deveria estar 100% aqui, mas acho que sim
    // afinal todos tem estrutura de mensagem, mensagem de erro,
    // e abort por tentativa
    message?: IWAFMessageComponents;
    errorMessage?: IWAFMessageComponents;
    limits?: IWAFConfigurationLimits;
}

export interface IWAFConfigurationTemplate extends IWAFConfiguration {
    type: FlowConfigurationType.Template;
}

export interface IWAFConfigurationDebug extends IWAFConfiguration {
    type: FlowConfigurationType.Debug;
    idMetadataEngagement: string;
}

export interface IWAFConfigurationEngagement extends IWAFConfiguration {
    message: IWAFMessageComponents;
    errorMessage?: IWAFMessageComponents;
    type: FlowConfigurationType.Engagegement;
}

export type TIEngagementFlowConfigurationArray = Array<IWAFConfigurationEngagement>;

export type TFlowEngamentSettings = IFlowAreaV1 | IWAFConfigurationEngagement;


export enum EWAFInstanceStatus {
    Next = "next", // Significa que esse é o PRÓXIMA screen a ser executada pelo Engine
    NotUsed = 'notUsed',
    Using = 'Using',
    Completed = "completed",
    Aborted = "aborted",
}

export interface IWAFFlowAreaState {
    flowAreaId: string,
    idScreen: string,
    startedAt?: Miliseconds;
    updatedAt?: Miliseconds;
    status: EWAFInstanceStatus;
}

export interface IWAFRawPayload {
    value: TWAFFieldDataType,
    rawWasNull: boolean
};

export interface INormalizedWaf {
    [idProperty: string]: IWAFRawPayload
}

export interface IWAFInstanceState {
    idSocialContext: string;
    flowToken: string;
    createdAt: Miliseconds;
    updatedAt: Miliseconds;
    status: EWAFInstanceStatus,
    wafSchemaId: string,
    wafConfigurationId: string,
    failedRetryCount: number,
    flowAreasStates: Array<IWAFFlowAreaState>,
    currentScreen: string;
    invisibleFields: string[];
    awnserBag?: INormalizedWaf;
}

export const WAF_FOOTER = '__FOOTER__';

export enum EWAFProcessInstanceType {
    Debug = 'debug',
    Form = 'form',
    Template = 'template',
}

export enum EWAFColmeiaVersionType {
    V2 = '2.0',
    V3 = '3.0',
    // WARNING: update currentVersion BELOW whenever a new version is added
}

/**
 * displays a warning on the client if a schema with a lower version is loaded into colmeia forms
 */
export const currentWAFColmeiaVersion: EWAFColmeiaVersionType = EWAFColmeiaVersionType.V3;
