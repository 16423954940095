import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { IFormSchema } from '@colmeia/core/src/general-form/general-form-interface';
import { ISearchActiveCallResult } from '@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model';
import { EFilterConversationsOptions, IAllCustomerActiveHeader, IAllCustomerACtiveHeaderItemData, IAllCustomerActivityResponse } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendent-sp-req-resp';
import { EConversationItemType, getDefaultConversationFilterConfig, IAttendanceConversationHistoryDialogData, IFilterConfig } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/conversation-history/conversation-history.model';
import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { delay, entries, isInvalidString, isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { DateService } from 'app/services/date.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { DashboardWindowEditorRef } from '../../dashboard-foundation/colmeia-window/colmeia-window-edit-ref';
import { GenericDashboardPaginationHandler } from '../../dashboard-foundation/generic-dashboard-pagination/generic-dashboard-pagination.handler';
import { GenericDashboardPaginationParameter, IGDPerPageOptions } from '../../dashboard-foundation/generic-dashboard-pagination/generic-dashboard-pagination.parameter';
import { TAllConversationsArray, TLocalConversationData } from '../attendance-conversation-history.model';
import { AttendanceConversationService } from '../attendance-conversation.service';
import { ColmeiaWindowRef } from './../../dashboard-foundation/colmeia-window/colmeia-window-ref';
import { AttendanceConversationHistoryViewItemComponent } from './attendance-conversation-history-view-item/attendance-conversation-history-view-item.component';

export const DEFAULT_CONVERSATIONS_PER_PAGE = 50;


@Component({
    selector: 'app-attendance-conversation-history-view',
    templateUrl: './attendance-conversation-history-view.component.html',
    styleUrls: ['./attendance-conversation-history-view.component.scss']
})
export class AttendanceConversationHistoryViewComponent implements OnInit, AfterViewInit, OnDestroy {
    public EConversationItemType: typeof EConversationItemType = EConversationItemType;
    public conversations: TAllConversationsArray = [];
    public conversationsMap: Map<string, TLocalConversationData> = new Map();

    public selectedConversation: TLocalConversationData;
    public nsCache: Record<string, INonSerializable> = {};
    public schemaCache: Record<string, IFormSchema> = {};
    public idAvatarCustomer: string;
    public customerAvatarName: string;
    public showConversation: boolean = true;
    public isDecrescent: boolean = true;
    public target: string;
    public cursor: string | undefined;
    public paginationHandler: GenericDashboardPaginationHandler;
    public selectedCustomer: ISearchActiveCallResult;
    public filterConfig: IFilterConfig = getDefaultConversationFilterConfig();
    public filterConversationsOptionsObject = { ...EFilterConversationsOptions };


    @ViewChild("conversationList")
    conversationList: ElementRef<HTMLDivElement>;

    @ViewChildren("conversationListItems")
    conversationListItems: QueryList<ElementRef<HTMLDivElement>>

    @ViewChild("viewItem", { static: false })
    viewItem: AttendanceConversationHistoryViewItemComponent;

    constructor(
        private windowRef: ColmeiaWindowRef<AttendanceConversationHistoryViewComponent, void, IAttendanceConversationHistoryDialogData>,
        private attConversationSvc: AttendanceConversationService,
		private dateSvc: DateService,
        private editor: DashboardWindowEditorRef<IAttendanceConversationHistoryDialogData>,
        private snackSvc: SnackMessageService
    ) {}

    ngOnInit(): void {
        if (isValidRef(this.editor.object.customerClicked)) {
            this.selectedCustomer = this.editor.object.customerClicked;
        }
        this.idAvatarCustomer = this.editor.object.idAvatarCustomer || '';
        this.customerAvatarName = this.editor.object.customerAvatarName || '';
        this.target = this.editor.object.target || '';
        
        if (!isValidString(this.target)) {
            const targetFromQueryParams = this.getTargetFromUrl();
            if (!isValidString(targetFromQueryParams)) {
                this.snackSvc.openError('Não possível recuperar os dados corretamente. Por favor refaça sua busca.')
                return;
            }
            this.target = targetFromQueryParams;    
        } 
        
        if (this.allConversation?.cursor) this.cursor = this.allConversation.cursor;

        this.buildIterationArray();
        this.setSelectedConversation(this.conversations[0]);
    }

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {}

    getTargetFromUrl(): string | null {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        return searchParams.get('target');
    }


    async onConversationListFilterInputChange() {
        this.resetComponentState();
        await this.loadMoreItems(DEFAULT_CONVERSATIONS_PER_PAGE, true)
        this.updateSelectedConversation(true);
    }

    resetComponentState() {
        this.currentPageIndex = 0;
        this.cursor = undefined;
        this.conversationsMap.clear()
        this.conversations = [];
    }

    get allConversation() {
        return this.editor.object.allConversation;
    }

    private buildIterationArray() {
        if (this.allConversation) {
            this.generatePaginationHandler();
            this.populateConversationsSet(this.allConversation.response);
            this.updateIterationArray();
            this.generatePaginationHandler();
            return;
        }

        if (this.editor.object.oneConversation) {
            const conversation = this.editor.object.oneConversation;
            const idConversation = this.editor.object.idNS;

            this.conversations = [
                {
                    idConversation,
                    readableDate: this.dateSvc.getDateAndTimeString(conversation.clockTick),
                    dateTimestamp: conversation.clockTick,
                    hasAgentInConversation: false,
                    hasPaymentInConversation: false,
                    hasTemplateMsg: false,
                    conversationDetails: conversation
                }
            ]
        }
    }

    populateConversationsSet(response: IAllCustomerActiveHeader) {
        const conversationEntries = entries(response) as [string, IAllCustomerACtiveHeaderItemData][];

        conversationEntries
            .forEach(([idConversation, conversationData]) => {
                const conversation: TLocalConversationData = {
                    idConversation,
                    readableDate: this.dateSvc.getDateAndTimeString(conversationData.clockTick),
                    dateTimestamp: conversationData.clockTick,
                    hasAgentInConversation: conversationData.hasService,
                    hasPaymentInConversation: conversationData.hasPayment,
                    hasTemplateMsg: conversationData.hasTemplateMsg
                }
                this.conversationsMap.set(conversation.idConversation, conversation);
            })
    }
    updateIterationArray() {
        const sortedConversations = Array.from(this.conversationsMap.values()).sort((a, b) => a.dateTimestamp - b.dateTimestamp)
        if(this.isDecrescent) sortedConversations.reverse();

        this.conversations = this.paginationHandler.getPageItens(sortedConversations)
    }

    private conversationsPerPage: number = DEFAULT_CONVERSATIONS_PER_PAGE;
    private currentPageIndex: number = 0;
    private paginationOptions: IGDPerPageOptions = {
        current: this.conversationsPerPage,
        options: [DEFAULT_CONVERSATIONS_PER_PAGE]
    }

    generatePaginationHandler(): void {
        const paginatorParameter: GenericDashboardPaginationParameter = {
            totalItems: this.conversationsMap.size,
            clientCallback: this,
            perPage: this.paginationOptions,
            index: this.currentPageIndex,
            hasMoreEntitiesToLoad: !!this.cursor,
            hidePerPage: true
        };
        this.paginationHandler = new GenericDashboardPaginationHandler(paginatorParameter);
    }

    onAmountPerPageChange(amount: number) {}

    onPageIndexChange(index: number) {
        const oldIndex = this.currentPageIndex;
        this.currentPageIndex = index;
        this.updateIterationArray();

    }

    loadingParticipants: boolean;
    async loadMoreItems(perPage?: number, resetCursor?: boolean): Promise<void> {
        this.loadingParticipants = true;
        
        const cursor = resetCursor ? undefined : this.cursor;
        
        const response: IAllCustomerActivityResponse | undefined = await this.windowRef.data.loadMoreConversations?.(
            this.editor.object.idNS, 
            cursor, 
            perPage, 
            this.filterConfig.filterConversationsBy
        );

        if (response) {
            if (response.cursor) {
                this.cursor = response.cursor;
            } else {
                this.cursor = undefined;
            }
            if (isInvalidString(this.target) && response.lastConversation?.botConversation?.clientAddress) {
                this.target = response.lastConversation.botConversation.clientAddress;
            }

            if (response.response) {
                this.populateConversationsSet(response.response);
                this.updateIterationArray();
                this.generatePaginationHandler();
            }
        }

        this.loadingParticipants = false;
    }

    sortConversations() {
        if(this.isDecrescent) {
            this.conversations.reverse();
        } else {
            this.conversations.sort((a, b) => a.dateTimestamp - b.dateTimestamp);
        }
    }

    onFilterChange() {
        this.buildIterationArray();
    }

    updateSelectedConversation(resetSelected?: boolean) {
        const isSelectedOnCurrentItems = this.conversations.some( conv => conv.idConversation === this.selectedConversation?.idConversation);

        if (
            !isSelectedOnCurrentItems
            || resetSelected 
            || this.filterConfig.filterConversationsBy === EFilterConversationsOptions.withHumanAttendance && !this.selectedConversation.hasAgentInConversation
        ) {
            this.selectedConversation = this.conversations[0];
        }
    }

    async setSelectedConversation(selectedConversation: TLocalConversationData) {
        if(this.selectedConversation?.idConversation === selectedConversation?.idConversation) {
            this.attConversationSvc.removeConversationFromCache(this.selectedConversation?.idConversation);
            this.attConversationSvc.removeUserFunctionLogsFromCache(this.selectedConversation?.idConversation);
            this.showConversation = false;
            await delay(1);
        }

        this.selectedConversation = selectedConversation;
        this.showConversation = true;

        if (this.viewItem?.searchInputValue) {
            this.viewItem?.showSearchWithOwnInputWrapper();
        }
    }

    onFiltersUpdate() {
        setTimeout(() => {
            this.viewItem.scrollContentToTop();
        }, 200);
    }
}
