
<div class="top-container">
  <header>

    <div class="title-container">
      <ticket-more-options></ticket-more-options>
      <h3>{{ ticketData.title }}</h3>

      <app-colmeia-window-top-bar *ngIf="isWindow" [hideTitle]="true"></app-colmeia-window-top-bar>

    </div>
    <div class="t-summary">

      <app-crm-ticket-protocol-view [ticketData]="ticketData"></app-crm-ticket-protocol-view>

      <a [href]="ticketFullRoute" [copyToClipboard]="ticketFullRoute" (click)="$event.preventDefault()"
        class="dense t-link">
        <mat-icon>link</mat-icon>
      </a>

      <div class="date-container">
        <span>
          Criado em {{ getCreatedAt() }}
        </span>
        <span>
          Última atualização {{ getUpdatedAt() }}
        </span>
      </div>

      <!-- colocar icones de atalhos aqui -->
      <div class="right-actions-container">
        <div class="status-and-actions">
          <ticket-status-button [type]="ECRMCanonicalItemsTypes.TicketState" color="accent" shape="flat"></ticket-status-button>
          <app-crm-ticket-actions></app-crm-ticket-actions>
        </div>

        <div class="actions-wrapper">
          <button
            (click)="openActiveCall()"
            type="button"
            matTooltip="Iniciar conversa">
            <img src="./assets/icons/chat-options/init-call-icon.svg" alt="Iniciar conversa">
          </button>

          <button
            (click)="addContactToSendList()"
            type="button"
            matTooltip="Adicionar a lista de envio"
          >
            <img
              src="./assets/icons/chat-options/task-chat-option.svg"
              alt="Adicionar a lista de envio"
            />
          </button>

          <button
            (click)="addContactToList()"
            type="button"
            matTooltip="Adicionar a lista de contato"
          >
            <img
              src="./assets/icons/chat-options/open_contact_list.svg"
              alt="Adicionar a lista de contato"
            />
          </button>
        </div>
      </div>
    </div>

  </header>


</div>

<div class="main">
  <section class="interactions">

    <div class="interactions-header">
      <h4 class="section-title">
        <span style="padding-right: 20px;" helperInfo="CTRL + click para selecionar mais de um." hiVertialAlign="center" topPosition="10px">Atividade</span>
      </h4>
      
        <button class="filter-btn" [class.is-selected]="!selectedInteractionType.length" (click)="setInteractionFilterType()">TUDO</button>
        <ng-container *ngFor="let interactionType of interactionsTypes | keyvalue">
          <button class="filter-btn" [class.is-selected]="getDisplayForInteractionType(interactionType.key)" (click)="setInteractionFilterType(interactionType.key, $event)">{{ interactionType.value }}</button>
        </ng-container>
     
    </div>

    <div class="interaction-list" *ngIf="!loading; else loadingSpinner">
      <ticket-interaction *ngFor="let interaction of ticketInteractions"
        [style.display]="getDisplayForInteraction(interaction) ? 'initial' : 'none'"
        [interaction]="interaction"></ticket-interaction>
    </div>

    <ticket-add-comment (insert)="onAddComment()" [ticketId]="ticketData.idTicket"></ticket-add-comment>
  </section>

  <div class="right-column">
    <section class="details">
      <!-- <div class="right-header">
      </div> -->
      <ticket-info-list [data]="ticketData" [island]="island"></ticket-info-list>
    </section>
  </div>

</div>

<ng-template #loadingSpinner>
  <mat-progress-spinner mode="indeterminate" diameter="30">
  </mat-progress-spinner>
</ng-template>