import { Injectable } from "@angular/core";
import { IListNonSerializablesMatch } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { EContactListRequest } from "@colmeia/core/src/request-interfaces/message-types";
import { INewAgentCallBatchServer, INewAgentCallContactServer, ISearchActiveCallResult } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";
import { ISaveContactActiveCallResponse } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-req-resp";
import { factoryContactListItemNser, factoryContactListNser } from "@colmeia/core/src/shared-business-rules/contact-list/contact-list.functions";
import { IContactListItemDetail, IContactListItemServer, IContactListServer } from "@colmeia/core/src/shared-business-rules/contact-list/contact-list.model";
import { IContactListItemSaveListsBatchRequest, IContactListItemSaveListsBatchResponse, IContactListItemSaveRequest, IContactListItemSaveResponse, IContactListSaveRequest, IContactListSaveResponse } from "@colmeia/core/src/shared-business-rules/contact-list/contact-list.req-res";
import { ENonSerializableObjectType, INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";
import { isValidArray, isValidRef } from "@colmeia/core/src/tools/barrel-tools";
import { useClientPredicates } from "@colmeia/core/src/tools/utility-types";
import { AttendanceActiveEditHelperService } from "app/services/attendance-active-edit-call-init.service";
import { AttendanceActiveEditCallApiService } from "app/services/attendance-active-edit-call.service";
import { DashBoardService } from "app/services/dashboard/dashboard.service";
import { LookupService } from "app/services/lookup.service";
import { ServerCommunicationService } from "app/services/server-communication.service";
import { SessionService } from "app/services/session.service";
import { SnackMessageService } from "app/services/snack-bar";
import { secToMS } from '@colmeia/core/src/time/time-utl';
import { IContactSaveOnListBatchResult } from "@colmeia/core/src/shared-business-rules/contact-list/contact-list.utils";
import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";

export interface IAddContactToSendListsParams {
    name: string;
    target: string;
    idAvatar: string;
    channel: EDelivery360Action;
}

@Injectable({
    providedIn: 'root',
})
export class ContactListService {
    constructor(
        private sessionSvc: SessionService,
        private api: ServerCommunicationService,
        private lookupSvc: LookupService,
        private snackMessageSvc: SnackMessageService,
        private dashboardSvc: DashBoardService,
        private activeCallAPI: AttendanceActiveEditCallApiService,
        private attendanceActiveEditHelperSvc: AttendanceActiveEditHelperService,
        private snackMsgSvc: SnackMessageService,
    ) {

    }

    factoryContactListNser(): IContactListServer {
        return factoryContactListNser(
            this.sessionSvc.getCurrentSocialNetworkID(),
            this.sessionSvc.getSelectedAvatarID()
        );
    }

    factoryContactListItemNser(idParent: IdDep<ENonSerializableObjectType.contactList>, nName: string, contactData: IContactListItemDetail): IContactListItemServer {
        return {
            ...factoryContactListItemNser(
                this.sessionSvc.getCurrentSocialNetworkID(),
                this.sessionSvc.getSelectedAvatarID(),
                idParent,
                contactData,
            ),
            nName,
        };
    }

    async save(ns: IContactListServer): Promise<IContactListServer> {
        const result = await this.api.sendRequest<IContactListSaveRequest, IContactListSaveResponse>(EContactListRequest.saveList)({
            ns,
            //@ts-ignore
            multipleCursor: null
        });
        return result?.ns;
    }

    async getContactList(idNSContactList: string): Promise<IContactListServer> {
        return this.lookupSvc.getNS(idNSContactList);
    }

    async saveContactToList(parent: IContactListServer, name: string, contactData: IContactListItemDetail): Promise<IContactListItemServer> {
        const ns: IContactListItemServer = this.factoryContactListItemNser(parent.idNS, name, contactData);
        const response = await this.api.sendRequest<IContactListItemSaveRequest, IContactListItemSaveResponse>(EContactListRequest.saveListItem)({
            ns,
            //@ts-ignore
            multipleCursor: null
        });

        const savedNS = response?.ns

        if (isValidRef(savedNS)) {
            this.snackMessageSvc.openSuccess(`[b]${name}[/b] salvo em [b]${parent.nName}[/b]!`)
        }

        return savedNS;
    }

    async addContactToSendLists(contact: IAddContactToSendListsParams): Promise<any> {
        const match: IListNonSerializablesMatch = useClientPredicates<INonSerializable>()
            ($ => ({ [$.ident.idAvatar]: this.sessionSvc.getSelectedAvatarID() }))
            ;
        //@ts-ignore
        const handler = this.dashboardSvc.easyCreateNSPickerHandler({
            nsType: ENonSerializableObjectType.activeCallBatch,
            useDemandedTag: false,
            clientCallback: {
                onSaveCallback: async (selectedLists) => {
                    if (!isValidArray(selectedLists)) {
                        return;
                    }

                    const label: string = selectedLists.length === 1
                        ? `Adicionando [b]${contact.name}[/b] à [b]${selectedLists[0].nName}[/b]`
                        : `Adicionando [b]${contact.name}[/b] à ${selectedLists.length} listas`;

                    const snackRef = this.snackMessageSvc.loadingSnack({
                        label
                    });

                    const result = await Promise.all(
                        selectedLists.map(async (sendList, idx) => {
                            let partialActiveCall: INewAgentCallContactServer = {
                                target: contact.target,
                                nsType: ENonSerializableObjectType.activeCall1x1,
                                ident: undefined,
                                nName: contact.name,
                                idAvatarContact: contact.idAvatar,
                                isPartial: true,
                                channel: contact.channel,
                            };

                            partialActiveCall = this.attendanceActiveEditHelperSvc.addTagToNonSerializablePure(partialActiveCall);

                            return this.activeCallAPI.saveChildContact(
                                sendList.idNS,
                                contact.idAvatar,
                                partialActiveCall
                            );

                        })
                    );

                    const addedSuccess = result.filter(i => isValidRef(i) && !i.alreadyAdded);
                    const alreadyAddedTo = result.filter(i => isValidRef(i) && i.alreadyAdded);

                    snackRef.dismiss();

                    function getList(list: ISaveContactActiveCallResponse[]): string {
                        return list.map(r =>
                            `[b]${selectedLists.find(l => l.idNS === r.nser.idParent).nName}[/b]`).join('\n')
                    }

                    if (addedSuccess.length || alreadyAddedTo.length) {
                        this.snackMsgSvc.openInfo(
                            (isValidArray(addedSuccess)
                                ? `Adicionado com sucesso em: \n\n` + getList(addedSuccess) + `\n\n`
                                : "") +
                            (isValidArray(alreadyAddedTo)
                                ? `Esse contato já existe em: \n\n` + getList(alreadyAddedTo)
                                : ""),
                            secToMS(10),
                        );
                    }

                },
            },
            selectedId: undefined,
            match: [match],
            maxSelections: Number.MAX_SAFE_INTEGER,
        });

        this.dashboardSvc.openNSPicker(handler);
    }

    async addContactToLists(name: string, contactData: IContactListItemDetail): Promise<any> {
        const match: IListNonSerializablesMatch = useClientPredicates<IContactListItemServer>()
            ($ => ({ [$.ident.idAvatar]: this.sessionSvc.getSelectedAvatarID() }))
            ;

        //@ts-ignore
        const handler = this.dashboardSvc.easyCreateNSPickerHandler({
            nsType: ENonSerializableObjectType.contactList,
            useDemandedTag: false,
            clientCallback: {
                onSaveCallback: async (selectedLists) => {
                    if (!isValidArray(selectedLists)) {
                        return;
                    }

                    const label: string = selectedLists.length === 1
                        ? `Adicionando [b]${name}[/b] à [b]${selectedLists[0].nName}[/b]`
                        : `Adicionando [b]${name}[/b] à ${selectedLists.length} listas`;

                    const snackRef = this.snackMessageSvc.loadingSnack({
                        label
                    });

                    const response = await this.api.sendRequest<IContactListItemSaveListsBatchRequest, IContactListItemSaveListsBatchResponse>(EContactListRequest.saveListItemListsBatch)({
                        listToAddIdNSs: selectedLists.map(l => l.idNS),
                        contactName: name,
                        contactData,
                        //@ts-ignore
                        multipleCursor: null
                    });


                    const addedSuccess = response.result.filter(i => !i.alredyAdded);
                    const alreadyAddedTo = response.result.filter(i => i.alredyAdded);

                    snackRef.dismiss();

                    function getList(list: IContactSaveOnListBatchResult[]): string {
                        return list.map(r =>
                            `[b]${selectedLists.find(l => l.idNS === r.listIdNS).nName}[/b]`).join('\n')
                    }

                    if (addedSuccess.length || alreadyAddedTo.length) {
                        this.snackMsgSvc.openInfo(
                            (isValidArray(addedSuccess)
                                ? `Adicionado com sucesso em: \n\n` + getList(addedSuccess) + `\n\n`
                                : "") +
                            (isValidArray(alreadyAddedTo)
                                ? `Esse contato já existe em: \n\n` + getList(alreadyAddedTo)
                                : ""),
                            secToMS(10),
                        );
                    }

                    console.log("addto", name, contactData, selectedLists);

                },
            },
            selectedId: undefined,
            match: [match],
            maxSelections: Number.MAX_SAFE_INTEGER,
        });

        this.dashboardSvc.openNSPicker(handler);
    }
}