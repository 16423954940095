import { IBotActionAsset } from "../bot/bot-action-model";
import { TContentAssetArray } from "../bot/bot-content-model";
import { TIConfigureCanonicalArray } from "../bot/bot-model";
import { TIBPConditionalEvaluatorArray } from "../BPM/bpm-model";
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from "../non-serializable-id/non-serializable-id-interfaces";
import { IFeatureEngineerOutput } from "../knowledge-base/clu-core-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";


export enum EPersona {
    assistent = 'assistent',
    custom = 'custom',
}

export enum EAgentKind {
    QA_FEW_SHOT = 'qa-few-shot',
    ICL_FEW_SHOT = 'icl-few-shot',
}

export enum EAgentLLMTasks {
    GENERATE = 'generate',
    SUMMARIZE = 'summarize',
    // APPEND_SUMMARIZE = 'append-summarize',
    REWRITE = 'rewrite',
    // APPEND_REWRITE = 'append-rewrite',
    CHAIN_OF_THOUGHT = 'chain-of-thought',
    SELF_REFLECTION = 'self-reflection',
}


export enum ECascadeRunningMode {
    shortCircuit = 'shortCircuit',
    full = 'full'
}

export interface IAgentCollaborator {
    kind: EAgentKind;
    idConnection: IdDep<ENonSerializableObjectType.connection>;
    idempotent: boolean;
    personaType: EPersona;
    persona: string; // traz (editavel)
    transformations: EAgentLLMTasks[]; //picker selecionar task... > texto... +++
    chainedPromptBuilder: TContentAssetArray; // dar guidelones adicionais para geral o finalPrompt (default de subPrompt ligado ao Kind??????)
    ruleBasedActionConfig: TIBPConditionalEvaluatorArray;
    idempotentPrompt?: string; // provavelmente não será usado .. pois dependerá do Base.Conhe ou Ger.Conteudo
    canonicals: TIConfigureCanonicalArray;
    // parsedFeature: IFeatureEngineerOutput; // KB
}
export type TIAgentConfigArray =  Array<IAgentCollaborator>

export interface IAgentSetConfig {
    agents: TIAgentConfigArray
}


export interface ISupremacyAgent extends INonSerializableHeader, IAgentSetConfig {
    nsType: ENonSerializableObjectType.supremacyAgent;
    cascadeMode: ECascadeRunningMode
    action?: IBotActionAsset;
    idPreFunction?: IdDep<ENonSerializableObjectType.userFunction>;
    idPosFunction?: IdDep<ENonSerializableObjectType.userFunction>;
}


export interface ISupremacyAgentServer extends ISupremacyAgent, INonSerializable {
    nsType: ENonSerializableObjectType.supremacyAgent;
}

export type TISupremacyAgentServerArray = Array<ISupremacyAgentServer>;
