import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { constant } from '@colmeia/core/src/business/constant';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { EConfirmationType } from '@colmeia/core/src/core-constants/types';
import { IListNonSerializablesMatch } from '@colmeia/core/src/dashboard-control/dashboard-request-interfaces';
import { IFormSchema } from '@colmeia/core/src/general-form/general-form-interface';
import { IGeneralFileMetadata, TGeneralFileMetadataArray } from '@colmeia/core/src/request-interfaces/files-interfaces';
import { EOnOngoingConversation, ICMassCommunicationAction, ICampaign, ICampaignServer, ICampaignWithIdNS, ICampaingActionHeader, actionDefaultExpireActiveHours } from '@colmeia/core/src/shared-business-rules/campaigns/campaign-type-model';
import { ECampaignActionType } from '@colmeia/core/src/shared-business-rules/campaigns/campaing-comm-strategy';
import { TCanonicalDB } from "@colmeia/core/src/shared-business-rules/canonical-model/local-canonical";
import { ECallbackType } from '@colmeia/core/src/shared-business-rules/colmeia-apis/colmeia-callback';
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { TIVariablesClientArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import { getVariablesWithDelimeters } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { ENonSerializableObjectType, INonSerializable, TNonSerializableArray } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { EFunctionContext, IUserFunctionModel } from '@colmeia/core/src/shared-business-rules/user-function/user-function-model';
import { hourToMS, msToHour } from '@colmeia/core/src/time/time-utl';
import { getReadableUniqueID, isInvalidNumber, isThisOneOfThat, isValidArray, isValidNumber, isValidRef, isValidString, isValidTrimmedString, objectShallowReplace, typedClone, values } from '@colmeia/core/src/tools/utility';
import { useClientPredicates } from '@colmeia/core/src/tools/utility-types';
import { EEnumPickerMode, EnumPickerHandler, IEnumPickerClientCallback, IEnumPickerHandlerParameter } from 'app/components/foundation/enum-picker/enum-picker.handler';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { ETimestmapPickerElements, TTimestampElementsConfigs } from 'app/components/foundation/timestamp-picker/timestamp-picker.component';
import { INSPickerHandlerClientCallback, NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { TICampaignTypeArray } from 'app/model/dashboard-campaign.model';
import { DashboardCampaignsService } from 'app/services/dashboard-campaigns.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { GlobalWarningService } from 'app/services/global-warning.service';
import { LookupService } from 'app/services/lookup.service';
import { NSValidationService } from 'app/services/nser-validation.service';
import { SnackMessageService } from 'app/services/snack-bar';
//@ts-ignore
import { SubscriptionGroup } from 'app/model/client-utility';
import { CanonicalService } from "../../../../services/canonical.service";
import { DashboardFileDetailComponent } from '../../dashboard-file-detail/dashboard-file-detail.component';
import { ColmeiaWindowTopBarComponent } from '../../dashboard-foundation/colmeia-window-top-bar/colmeia-window-top-bar.component';
import { ColmeiaWindowRef } from '../../dashboard-foundation/colmeia-window/colmeia-window-ref';
import { TemplatesService } from '../../marketing/dashboard-channels-templates-list/templates.service';
import { ButtonAlertComponent } from './../../../button-alert/button-alert.component';
import { DashboardCampaignsActionService } from './dashboard-campaigns-action.service';

export interface IDashboardCampaignsActionDialogData {
    action: ICampaingActionHeader;
    campaign: ICampaign;
    schemas: IFormSchema[];
    files: TGeneralFileMetadataArray;
}

export interface IDashboardCampaignsActionDialogResponse {
    action?: ICampaingActionHeader,
    remove?: true
}

@Component({
    selector: 'app-dashboard-campaigns-action',
    templateUrl: './dashboard-campaigns-action.component.html',
    styleUrls: ['./dashboard-campaigns-action.component.scss']
})
export class DashboardCampaignsActionComponent extends RootComponent<
    'title' |
    'description' |
    'files' |
    'selectFile' |
    'actionType' |
    'remove' |
    'close'
> implements OnInit, OnDestroy, INSPickerHandlerClientCallback {

    private _actionSource: ICampaingActionHeader;
    private _action: ICampaingActionHeader;
    @Input() set action(value: ICampaingActionHeader) {
        this._action = typedClone(value);
        this._actionSource = typedClone(value);

        if (!isValidNumber(this._action.expireActiveHours)) {
            this._action.expireActiveHours = actionDefaultExpireActiveHours;
        }
    }

    get action(): ICampaingActionHeader {
        return this._action;
    }

    @Input() schemas?: IFormSchema[];
    @Input() files: TGeneralFileMetadataArray = [];

    @ViewChild('matSelectFile') matSelectFile: MatSelect;
    actionTypes: TICampaignTypeArray = [];
    public canonicalsDB: TCanonicalDB;
    public saving: boolean;
    public lastSaveResult: boolean;
    private campaignReference: ICampaign;

    @ViewChild(ButtonAlertComponent)
    saveButtonAlert: ButtonAlertComponent;
    private dataFromDialog: IDashboardCampaignsActionDialogData;

    @ViewChild(ColmeiaWindowTopBarComponent)
    colmeiaWindowTopBar: ColmeiaWindowTopBarComponent;

    public expireActiveHoursPickerElements: TTimestampElementsConfigs = {
        [ETimestmapPickerElements.Days]: {
            min: 0,
            max: Number.MAX_SAFE_INTEGER
        },
        [ETimestmapPickerElements.Hours]: {
            min: 0,
            max: 23,
        },
    }

    subscriptionGroup: SubscriptionGroup = new SubscriptionGroup();

    constructor(
        private cdr: ChangeDetectorRef,
        private dialogService: MatDialog,
        private canonicals: CanonicalService,
        private dashboardService: DashBoardService,
        private validator: NSValidationService,
        private dashboardCampaignsActionSvc: DashboardCampaignsActionService,
        private snackMsgSvc: SnackMessageService,
        private lookupSvc: LookupService,
        private templateSvc: TemplatesService,
        private colmeiaWindowRef: ColmeiaWindowRef<DashboardCampaignsActionComponent, IDashboardCampaignsActionDialogResponse>,
        private campaignsSvc: DashboardCampaignsService,
        private warningScreenSvc: GlobalWarningService,
    ) {
        super({
            title: gTranslations.common.title,
            description: gTranslations.common.description,
            files: gTranslations.common.files,
            selectFile: gTranslations.common.selectFile,
            actionType: gTranslations.fragments.actionType,
            remove: gTranslations.common.remove,
            close: gTranslations.common.close
        });

        this.dataFromDialog = colmeiaWindowRef.data;
    }

    updateCampaignRef(campaign: ICampaignServer) {
        this.campaignReference = campaign;
    }

    async ngOnInit() {
        if (this.dataFromDialog) {
            ({ action: this.action, schemas: this.schemas, files: this.files, campaign: this.campaignReference } = this.dataFromDialog);
        }

        this.consistency();

        this.initActionTypes();


        this.loadSchemaPickerHandler();
        this.loadBotPickerHandler();
        this.loadCallbackApiRoutePicker();
        this.loadCallbackUserFunctionPicker();
        this.loadCallbackApiTypeEnumPicker();
        console.log({ callback: this.callback })
        if (this.action.activeCallback && this.callback.mustUseConfirmationCallbacksSubset) {
            this.initEnumPickerForConfirmationTypeList();
        }

        await this.onSchemaChanged();

        this.cdr.markForCheck();
        this.subscriptionGroup.from(this.campaignsSvc.setEdit).subscribe(isEditing => this.isEditing = isEditing);

        this.subscriptionGroup.from(this.campaignsSvc.savedCampaign$).subscribe((campaign) => {
            objectShallowReplace(this.campaignReference, typedClone(campaign));
        });
    }

    ngOnDestroy() {
        this.subscriptionGroup.destroy();

    }

    private consistency() {
        this.action.idCampaigAction ??= getReadableUniqueID();
        if (!isValidRef(this.action.callback)) {
            this.action.callback = {
                type: undefined,
                idFunction: undefined,
                idRouteCallback: undefined
            }
        }
    }

    createPicker(title: string, nsType: ENonSerializableObjectType, selectedId: string, useDemandedTag?: boolean, match?: IListNonSerializablesMatch[]): NSPickerHandler {
        return this.dashboardService.easyCreateNSPickerHandler(
            {
                title,
                nsType,
                selectedId,
                clientCallback: this,
                idParent: null,
                useDemandedTag
            },
            { match }
        )
    }

    schemaPickerHandler: NSPickerHandler;
    loadSchemaPickerHandler() {
        this.schemaPickerHandler = this.createPicker(
            'Formulários',
            ENonSerializableObjectType.formSchemma,
            this.action.idSchemma,
            true
        );
    }

    botPickerHandler: NSPickerHandler;
    loadBotPickerHandler() {
        this.botPickerHandler = this.createPicker(
            'Bots',
            ENonSerializableObjectType.bot,
            this.action.idBot || '',
            false
        );
    }

    get massCommAction() {
        return this.action as ICMassCommunicationAction;
    }

    get isOnGoingConversationSendMessage() {
        return this.massCommAction?.onOngoingConversation?.action === EOnOngoingConversation.sendMessage;
    }

    get shouldShowBotPickerHandler() {
        return !this.isOnGoingConversationSendMessage;
    }

    callbackApiTypeEnumPicker: EnumPickerHandler<ECallbackType>;
    loadCallbackApiTypeEnumPicker() {
        const enumTranslations = {
            [ECallbackType.custom]: "Função customizável",
            [ECallbackType.defaultRoute]: "Formato padrão - Rota"
        }

        this.callbackApiTypeEnumPicker = new EnumPickerHandler<ECallbackType>({
            clientCallback: {},
            client: {
                onSingleEnumSelection: (type: ECallbackType) => {
                    this.action.callback.type = type;
                },
            } as IEnumPickerClientCallback<ECallbackType>,
            mode: EEnumPickerMode.Single,
            appearance: "outline",
            translations: enumTranslations,
            inputTitle: 'Selecione um tipo de canal',
            enum: ECallbackType,
            current: this.action.callback.type,
            buttonMode: true
        } as IEnumPickerHandlerParameter<ECallbackType>);
    }

    callbackApiRoutePicker: NSPickerHandler;
    loadCallbackApiRoutePicker() {
        this.callbackApiRoutePicker = this.createPicker(
            "Rota de callback",
            ENonSerializableObjectType.connectionRoute,
            this.action.callback.idRouteCallback || '',
            false
        );
    }

    callbackUserFunctionPicker: NSPickerHandler;
    loadCallbackUserFunctionPicker() {
        this.callbackUserFunctionPicker = this.createPicker(
            "Função de callback",
            ENonSerializableObjectType.userFunction,
            this.action.callback.idFunction || '',
            false,
            [useClientPredicates<IUserFunctionModel>()
                ($ => ({ [$.context]: EFunctionContext.MKTContext }))]
        );
    }



    eConfirmationTypeTranslations: Record<EConfirmationType, string> = {
        [EConfirmationType.read]: "Lido",
        [EConfirmationType.bounce]: "Esperando",
        [EConfirmationType.channelClosed]: "Canal fechado",
        [EConfirmationType.clicked]: "Clique",
        [EConfirmationType.colmeiaSent]: "Enviado pela Colmeia",
        [EConfirmationType.failed]: "Falhou",
        [EConfirmationType.ignoreByFilter]: "Ignorado no filtro",
        [EConfirmationType.ignoredByActiveConversation]: "Ignorado por conversa ativa",
        [EConfirmationType.invalidNumber]: "Número inválido",
        [EConfirmationType.noProcessFromColmeia]: "Sem processamento da Colmeia",
        [EConfirmationType.notAnswered]: "Sem resposta",
        [EConfirmationType.notRead]: "Não lido",
        [EConfirmationType.queued]: "Em fila",
        [EConfirmationType.receive]: "Recebido",
        [EConfirmationType.respond]: "Respondido",
        [EConfirmationType.sent]: "Enviado",
        [EConfirmationType.span]: "Span",
        [EConfirmationType.viewMedia]: "Visualização de Mídia",
        [EConfirmationType.brokerOptOut]: "Opt Out",
        [EConfirmationType.invalidSession]: "Sessão inválida",
        [EConfirmationType.error]: 'Erro',
        [EConfirmationType.ignored]: 'Ignorado',
    }

    confirmationTypeListHandler: EnumPickerHandler<EConfirmationType> | undefined;

    public initEnumPickerForConfirmationTypeList(): void {
        this.confirmationTypeListHandler = new EnumPickerHandler({
            client: {
                onMultipleEnumSelection: (value: EConfirmationType[]) => this.onConfirmationTypeSelect(value),
            } as IEnumPickerClientCallback<EConfirmationType>,
            mode: EEnumPickerMode.Multiple,
            appearance: 'fill',
            translations: this.eConfirmationTypeTranslations,
            inputTitle: 'Eventos do Whatsapp',
            enum: EConfirmationType,
            clientCallback: {},
            currents: this.action.callback.confirmationTypeList,
            // buttonMode: true
            useActionBtns: true,
        })
    }

    onConfirmationTypeSelect(value: EConfirmationType[]) {
        console.log({ value })
        this.action.callback.confirmationTypeList = value;
    }


    onSaveSchema(selections: TNonSerializableArray) {
        const schemaIdNS = selections[0] && selections[0].idNS;

        this.action.idSchemma = schemaIdNS;

        this.onSchemaChanged();

        this.cdr.markForCheck();
    }

    onSaveBot(selections: TNonSerializableArray) {
        const idBOT: string = selections[0] && selections[0].idNS;

        this.action.idBot = idBOT;

        this.cdr.markForCheck();
    }

    onSaveCallback(selections: TNonSerializableArray, nsType: ENonSerializableObjectType) {
        switch (nsType) {
            case ENonSerializableObjectType.formSchemma:
                this.onSaveSchema(selections);
                break;
            case ENonSerializableObjectType.bot:
                this.onSaveBot(selections);
                break;
            case ENonSerializableObjectType.connectionRoute:
                this.callback.idRouteCallback = selections[0]?.idNS || '';
                this.resetCallback(this.callback.idRouteCallback);
                break;
            case ENonSerializableObjectType.userFunction:
                this.callback.idFunction = selections[0]?.idNS || '';
                this.resetCallback(this.callback.idFunction);
        }
    }
    resetCallback(propertyValue: string) {
        if (!isValidString(propertyValue)) {
            this.callback.mustUseConfirmationCallbacksSubset = false
            this.callback.confirmationTypeList = undefined;

            this.initEnumPickerForConfirmationTypeList();
        }
    }

    private initActionTypes(): void {
        Object.values(ECampaignActionType).map(camp => {
            const serializable: Serializable = Serializable.staticFactory(camp);

            this.actionTypes.push({
                type: camp,
                text: serializable.getSerializableText(constant.serializableField.name),
            });
        })
    }

    public disableActionType(action: ECampaignActionType): boolean {
        return action !== ECampaignActionType.massComm;
    }

    isInvite(): boolean { return this.action.actionType === ECampaignActionType.invites; }
    isMassComm(): boolean { return this.action.actionType === ECampaignActionType.massComm; }
    isCallCenter(): boolean { return this.action.actionType === ECampaignActionType.activeCallcenter; }
    isBot(): boolean { return this.action.actionType === ECampaignActionType.personalizedBot; }

    get currentSchema(): IFormSchema {
        return this.schemas?.find(
            schema => schema.idSchemma === this.action.idSchemma
        );
    }

    get possibleSchemmaVariables(): TIVariablesClientArray {
        if (isValidArray(this.schemas)) {
            const schema = this.schemas.find(sc => sc.idSchemma === this.action.idSchemma);

            if (!schema) return [];

            return getVariablesWithDelimeters(schema.form.map(item => {
                return {
                    text: item.prompt,
                    idProperty: item.idProperty,
                    idLocalCanonical: item.idLocalCanonical
                }
            }));
        }
        return []
    }

    async onSchemaChanged(): Promise<void> {
        const schema = this.currentSchema;
        if (this.action.idSchemma && schema) {
            this.canonicalsDB = null;
            const db: TCanonicalDB = await this.canonicals.getGlobalCanonical(
                schema.form.map(prop => prop.idLocalCanonical)
                    .filter(val => isValidRef(val))
            );
            this.canonicalsDB = db;
            if (this.action.idSchemma !== schema.idSchemma) {
                // @TODO debater com Zé sobre zerar raw message, ou o q fazer com incosistencia do compiled e raw, uma vez que o raw existe, mas n tem como gerar compiled.
                this.action.messages = [];
            }
        } else {
            this.canonicalsDB = null;
            this.canonicalsDB = null;
        }
        this.cdr.markForCheck();
    }

    openInfo(file: IGeneralFileMetadata): void {
        this.dialogService.open<DashboardFileDetailComponent, { file: IGeneralFileMetadata }>(
            DashboardFileDetailComponent,
            {
                data: {
                    file,
                }
            }
        );
    }

    remove(): void {
        this.warningScreenSvc.askMessage(
            {
                title: "Deseja realmente remover essa ação?", message: '', yes:
                    async () => {
                        const actionIndex = this.campaignReference.campaingActions.findIndex(action => action.idCampaigAction === this.action.idCampaigAction);
                        this.campaignReference.campaingActions.splice(actionIndex, 1);

                        await this.campaignsSvc.saveCampaign(this.campaignReference);

                        this.colmeiaWindowRef.close({ remove: true });
                    }
            });
    }

    cancel(): void {
        this.colmeiaWindowRef.close({});
    }
    isEditing: boolean;

    async save(): Promise<void> {
        if (this.saving) return;

        this.saving = true;

        const isValid: boolean = await this.validate();

        if (!isValid) {
            this.saving = false;
            return;
        }

        const result = await this.saveCampaigns();

        this.colmeiaWindowRef.title = this.action.actionName;
        this.colmeiaWindowTopBar.cdr.detectChanges();

        this.saving = false;
        this.lastSaveResult = result;
        this.saveButtonAlert.show({
            theme: result ? "success" : "warn",
            duration: 3000
        });
    }

    public isNew?: boolean;

    async saveCampaigns(): Promise<boolean> {
        const actionIndex = this.campaignReference.campaingActions.findIndex(action => action.idCampaigAction === this.action.idCampaigAction);
        const oldAction = this.campaignReference.campaingActions[actionIndex];

        if (actionIndex !== -1) {
            this.campaignReference.campaingActions[actionIndex] = this.action;
        } else {
            this.campaignReference.campaingActions.push(this.action);
        }


        const campaignWithIdNS = this.campaignReference as ICampaignWithIdNS;

        this.isNew ??= !campaignWithIdNS.idNS;

        if (this.isNew && !campaignWithIdNS.idNS) campaignWithIdNS.idNS = getReadableUniqueID(32);

        const result = await this.campaignsSvc.saveCampaign(this.campaignReference, this.isNew);

        if (result) this.isNew = false;

        if (!result) {
            if (actionIndex !== -1) {
                this.campaignReference.campaingActions[actionIndex] = oldAction;
            } else {
                this.campaignReference.campaingActions.splice(actionIndex, 1);
            }
        } else {
            objectShallowReplace(this._actionSource, this._action);
        }

        return result;
    }

    get callback() {
        return this.action.callback;
    }

    preventEnter(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private async validate(): Promise<boolean> {
        const campaignMock: ICampaign = {
            campaingActions: [this.action]
        } as ICampaign;

        const massAction = this.action as ICMassCommunicationAction;

        const basicValidations: [boolean, string][] = [
            [isValidTrimmedString(this.action.actionName), "Digite um nome para a ação"],
            [isValidString(this.action.idSchemma), "Selecione um formulário"],
            [isThisOneOfThat(this.action.actionType, ...values(ECampaignActionType)), "Escolha um tipo de ação"],
        ];

        for (const [status, errorMessage] of basicValidations) {
            if (!status) {
                this.snackMsgSvc.openWarning(errorMessage, 3000);
                return false;
            }
        }

        if (this.action.activeCallback) {
            if (!this.callback.type) {
                this.snackMsgSvc.openWarning(`Selecione um tipo de canal para a Callback`, 3000);
                return false;
            }
            if (this.isRouteCallback() && !this.callback.idRouteCallback) {
                this.snackMsgSvc.openWarning(`Selecione uma Rota de Conexão para a Callback`, 3000);
                return false;
            }
            if (this.isFunctionCallback()) {
                if (!this.callback.idFunction) {
                    this.snackMsgSvc.openWarning(`Selecione uma Função de Callback`, 3000);
                    return false;
                }

                if (!this.callback.mustUseConfirmationCallbacksSubset) {
                    this.snackMsgSvc.openWarning(`Ative a opção 'Selecionar eventos do Whatsapp e selecione pelo menos um Evento de confirmação.' `, 3000);
                    return false;
                }
            }

            if (this.callback.mustUseConfirmationCallbacksSubset && (!this.callback.confirmationTypeList || this.callback.confirmationTypeList.length < 1)) {
                this.snackMsgSvc.openWarning(`Selecione pelo menos um Evento de confirmação do Whatsapp`, 3000);
                return false;
            }
        }

        const isReschedule = !massAction.onOngoingConversation.cancel || massAction.onOngoingConversation.action === EOnOngoingConversation.reschedule;

        if (isReschedule && isInvalidNumber(massAction.onOngoingConversation.reschedule)) {
            this.snackMsgSvc.openWarning(`O campo "Quantidade de horas para reagendar" é obrigatório`, 3000);
            return false;
        }

        if (isReschedule && isInvalidNumber(massAction.retries)) {
            this.snackMsgSvc.openWarning(`O campo "Número de tentativas" é obrigatório`, 3000);
            return false;
        }

        const error = this.validator.getErrorsAsStringFrom(campaignMock, ENonSerializableObjectType.campaing);

        if (isValidArray(error)) {
            this.snackMsgSvc.openError(error[0], 3000);

            return false
        }

        const shouldValidateTemplateVariables: boolean = this.dashboardCampaignsActionSvc.shouldValidateTemplateVariables(this.action);
        if (shouldValidateTemplateVariables) {

            const massCommAction = this.action as ICMassCommunicationAction;
            const hasValidTemplateVariables: boolean = await this.dashboardCampaignsActionSvc.validateTemplateVariables(massCommAction);


            if (!hasValidTemplateVariables) {
                this.snackMsgSvc.openWarning(`Preencha todas variáveis do template`, 3000);
            }

            return hasValidTemplateVariables;
        }

        return true;
    }

    public getNSs = this.lookupSvc.createNSCacheImplementation();
    public getNS = this.lookupSvc.buildSingleNSCacheGetter(this.getNSs);
    public mapIdNSToNS: Map<string, INonSerializable> = this.getNSs.cache;


    public isRouteCallback(): boolean {
        return this.action.callback.type === ECallbackType.defaultRoute
    }

    public isFunctionCallback(): boolean {
        return this.action.callback.type === ECallbackType.custom
    }

    get expireActiveHours(): number {
        return hourToMS(this.action.expireActiveHours);
    }

    set expireActiveHours(value: number) {
        this.action.expireActiveHours = msToHour(value);
    }

    handleConfirmationCallbackToggle(e: MatSlideToggleChange) {
        if (!e.checked) this.action.callback.confirmationTypeList = undefined;
        this.initEnumPickerForConfirmationTypeList();
    }

    canShowMustConfirmationToggle(): boolean {
        if (this.isRouteCallback()) {
            return this.callback.idRouteCallback ? true : false
        }
        if (this.isFunctionCallback()) {
            return this.callback.idFunction ? true : false;
        }
        return false;
    }
}
