import { Injectable } from '@angular/core';
import { EFacebookChannelIntegrationReqRes } from '@colmeia/core/src/request-interfaces/message-types';
import { IFacebookGetColmeiaAppLoginConfigRequest, IFacebookGetColmeiaLoginConfigResponse } from '@colmeia/core/src/shared-business-rules/channels/facebook-integration-req-resp.dto';
import { EEmbeddedSignupRequests, IEmbeddedSignupExchangeCodeForTokenRequest, IEmbeddedSignupExchangeCodeForTokenResponse } from "@colmeia/core/src/shared-business-rules/embedded-signup/embedded-signup.req-resp.dto";
import { isValidString } from '@colmeia/core/src/tools/utility';
import { LoginOptions } from 'app/model/facebook/login-options';
import { ServerCommunicationService } from 'app/services/server-communication.service';

export enum EEmbeddedSignupType {
    ads = 'ads',
    channels = 'channels'
}

const signupTypeToConfig: Map<EEmbeddedSignupType, LoginOptions> = new Map([
    [EEmbeddedSignupType.ads, {
        clientID: '1037336601403053',
        scope: 'ads_read,ads_management,pages_show_list,business_management',
        redirectUri: 'https://www.facebook.com/connect/login_success.html',
        state: 'ads'
    }],
    [EEmbeddedSignupType.channels, {
        clientID: '424455860714483',
        scope: 'page_events',
        redirectUri: 'https://www.facebook.com/connect/login_success.html',
        state: 'channels'
    }]
]);

@Injectable({
    providedIn: 'root'
})
export class EmbeddedSignupService {

    constructor(
        private api: ServerCommunicationService
    ) { }

    public async launchEmbeddedSignupLogin(type: EEmbeddedSignupType, overrideConfig?: LoginOptions) {
        
    }

    private async getEmbeddedSignupConfigId() {
        const result = await this.api.sendRequest<IFacebookGetColmeiaAppLoginConfigRequest, IFacebookGetColmeiaLoginConfigResponse>(
            EFacebookChannelIntegrationReqRes.getColmeiaAppLoginConfiguration
        )({});
        
        return result
    }

    async getAdsEmbeddedSignupLoginConfig() {
        // TODO: @pedro-oscar get configID from backend
        const configID: string = '1037336601403053' //very permissive
        // const configID: string = '593277606459966' //under test
        // const configID: string = '424455860714483' //too few permissions

        const config = {
            config_id: configID, // configuration ID obtained in the previous step goes here
            response_type: 'code', // must be set to 'code' for System User access token
            override_default_response_type: true,
            return_scopes: true,
            auth_type: 'rerequest',
            // scopes: 'page_events,ads_read,ads_management,pages_show_list,business_management',
            extras: {
                sessionInfoVersion: 2,
            }
        }

        return config;
    }

    async getChannelsEmbeddedSignupLoginConfig(onlyWABASharing: boolean, partnerID: string) {
        const configID: string = (await this.getWhatsappConfigLoginId()).colmeiaAppLoginConfigurationId;

        return this.getEmbeddedSignUpLoginConfig(onlyWABASharing, partnerID, configID);
    }

    async getWhatsappConfigLoginId(): Promise<IFacebookGetColmeiaLoginConfigResponse> {
        const result = await this.api.sendRequest<IFacebookGetColmeiaAppLoginConfigRequest, IFacebookGetColmeiaLoginConfigResponse>(
            EFacebookChannelIntegrationReqRes.getColmeiaAppLoginConfiguration
        )({});
        return result
    }

    async getEmbeddedSignUpLoginConfig(onlyWABASharing: boolean, partnerID: string, configID: string) {
        const bestFeatureType = onlyWABASharing ? { featureType: "only_waba_sharing" } : {}
        const bestSetup = isValidString(partnerID) ? { setup: { solutionID: partnerID } } : {}

        const config = {
            config_id: configID, // configuration ID obtained in the previous step goes here
            response_type: 'code', // must be set to 'code' for System User access token
            override_default_response_type: true,
            return_scopes: true,
            auth_type: 'rerequest',
            extras: {
                feature: "whatsapp_embedded_signup",
                // featureType: "only_waba_sharing"
                sessionInfoVersion: 2,
                ...bestSetup,
                ...bestFeatureType
            }
        }

        console.log({ WhatsappAuthServiceGetEmbeddedSignUpLoginConfig: config });

        return config
    }

    async exchangeCodeForToken(code: string) {
        const result = await this.api.sendRequest<IEmbeddedSignupExchangeCodeForTokenRequest, IEmbeddedSignupExchangeCodeForTokenResponse>(
            EEmbeddedSignupRequests.exchangeCodeForToken
        )({
            code,
        });

        return result.access_token;
    }
}
