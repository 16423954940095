import { Component, OnInit, Input, ViewChild, OnChanges } from "@angular/core";
import { GenericTableHandler, TGenericTableHandlerParameters, EGenericTableGenericRow, IGenericTableGenericRowField, TGenericTableHandlerText, IExtractColumn, IGenericTableRowFieldTinyIcon, IGenericTableRowFieldSelect, EPageSizeOption, IOnRowFieldClick, IGenericTableMovePositionMovement, GenericTableRowFieldIconType, IGenericTableRowFieldText, statusConfigDB, EGenericTableRowFieldTextStatus } from "app/handlers/generic-table/generic-table.handler"
import { isValidRef, isInvalid, isValidFunction, isValidArray, typedClone } from "@colmeia/core/src/tools/utility";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { ITranslationConfig } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { UserSettingsService } from "app/services/user-settings.service";
import { DatetimeConversions } from '../../../../services/datetime-conversions';
import { PageEvent } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatTable } from "@angular/material/table";
import * as _ from 'lodash'

type TGroupsSize = { [key: string]: number };

@Component({
    selector: "app-generic-table",
    templateUrl: "./generic-table.component.html",
    styleUrls: ["./generic-table.component.scss"],
})
export class GenericTableComponent<Row, Entity extends {} = {}> {

    ECallCenterGenericRow: typeof EGenericTableGenericRow = EGenericTableGenericRow;
    rows: Row[];

    get allRows() { return this.parameters.allRows! }
    set allRows(value) { this.parameters.allRows = value }


    groupsSize: TGroupsSize;
    translationsToPrint: Map<ITranslationConfig, string> = new Map();
    dateTimeConversor: DatetimeConversions;
    paginationOptions = Object.values(EPageSizeOption).map((item: EPageSizeOption) => Number(item));
    _pagination: EPageSizeOption;
    public currentPageIndex: number = 0;
    public hasRowClickHandler: boolean = false;

    _colorTableClass: string = '';
    @Input()
    set color(value: string) {
        if (!value) return;
        this._colorTableClass = `mat-${value}`;
    }

    @Input()
    set handler(handler: GenericTableHandler<Row, Entity>) {
        this._handler = handler;
        this.init();
    }
    get handler(): GenericTableHandler<Row, Entity> {
        return this._handler;
    }

    public _handler: GenericTableHandler<Row, Entity>

    style: object = {};

    public constructor(private userSettings: UserSettingsService) { }

    public printDateTime(time: number): string {
        return this.dateTimeConversor.timeToDateTimeString(time);
    }

    public printDate(time: number): string {
        return this.dateTimeConversor.timeToDateString(time);
    }

    get enableEqualSize(): boolean {
        return this.parameters.enableEqualSize;
    }

    public get enableChangePositions(): boolean {
        return isValidRef(this.parameters.movePositions);
    }

    public get removeShadows(): boolean {
        return this.parameters.removeShadows;
    }

    public get minWidth(): number | undefined {
        return this.parameters.minWidth;
    }

    @ViewChild(MatTable, { static: false }) table: MatTable<Row>;
    private updateTableRows(): void {
        if (isValidRef(this.table)) {
            this.table.renderRows();
        }
    }

    public async onDropTable(event: CdkDragDrop<Row[]>) {
        const row: Row = event.item.data;
        const previousIndex: number = this.rows.findIndex((item: Row) => item === row);
        const movement: IGenericTableMovePositionMovement = {
            from: previousIndex,
            to: event.currentIndex,
        };

        if (isValidArray(this.parameters.movePositions.originalElements)) {
            moveItemInArray(this.parameters.movePositions.originalElements, movement.from, movement.to);
            moveItemInArray(this.rows, movement.from, movement.to);
        }
        if (isValidFunction(this.parameters.clientCallback.onGenericTableMovePosition))
            await this.parameters.clientCallback.onGenericTableMovePosition(row, movement)
                ;

        this.updateTableRows();
    }

    public getExpiredStyle(field: IGenericTableRowFieldText): string {
        return field.status ? statusConfigDB[field.status].color : null;
    }

    public showHelpIcon(field: IGenericTableRowFieldText): string {
        return field.status ? 'block' : 'none';
    }

    public getTinyIconColor(field: IGenericTableRowFieldTinyIcon): string {
        return isValidRef(field.color) ? field.color : '#5bcc71';
    }

    public isVisible(field: IGenericTableGenericRowField) {
        return field.isVisible?.() ?? true;
    }

    get showNavigation(): boolean {
        return !this.parameters.hideNavigation;
    }

    get enableGoBackButton(): boolean {
        return this.parameters.enableGoBackButton;
    }

    get enableRefresh(): boolean {
        return this.parameters.enableRefresh;
    }

    get title(): TGenericTableHandlerText {
        return this.parameters.title;
    }

    public printText(text: TGenericTableHandlerText): string {
        switch (typeof text) {
            case 'string': return text;
            case 'object': {
                if (isInvalid(this.translationsToPrint.get(text)))
                    this.translationsToPrint.set(text, Serializable.getTranslation(text))
                        ;

                return this.translationsToPrint.get(text);
            };
        }
    }

    selectChange(row: Row, event: MatSelectChange) {
        this.parameters.clientCallback.onRowFieldSelect(row, event.value)
    }

    public refresh(): Promise<void> {
        return this.init();
    }

    public getExtraColumnsTexts(): TGenericTableHandlerText[] {
        return this.parameters.extraColumns.map((extraColumn: IExtractColumn) => extraColumn.text).map((extraColumnText: TGenericTableHandlerText) => this.printText(extraColumnText));
    }

    get extraColumns(): IExtractColumn[] {
        return this.parameters.extraColumns;
    }

    public enableExtraColumns(): boolean {
        return this.handler.enableExtraColumns();
    }

    public async init(): Promise<void> {
        await Promise.all([
            this.loadDateTimeConversor(),
            this.loadRows(),
            this.initStyle(),
            this.handler.setSlave(this),
            this.hasRowClickHandler = isValidFunction(this.parameters.clientCallback.onRowClick)
        ]);
    }

    public initStyle(): void {
        if (this.removeShadows) this.style['box-shadow'] = 'none';
        if (isValidRef(this.minWidth)) this.style['min-width'] = this.minWidth + 'px';
    }

    public loadDateTimeConversor(): void {
        this.dateTimeConversor = this.userSettings.getDatetimeConversor();
    }

    public mapRowToEntity: Map<Row, Entity> = new Map()

    public async getRowsFromEntityMode() {
        if (!this.parameters.clientCallback.entityMode) return [];

        const entities = (await this.parameters.clientCallback.entityMode.getEntities());
        return (
            entities.map(entity => {
                const row = this.parameters.clientCallback.entityMode?.mapEntityToRow(entity)!
                this.mapRowToEntity.set(row, entity as Entity);
                return row;
            })
        )
    }

    public async loadRows(): Promise<void> {
        this.rows = undefined;

        this.allRows = this.parameters.clientCallback.entityMode
            ? await this.getRowsFromEntityMode()
            : await this.parameters.clientCallback.getRows()
            ;

        if (!this.allRows) return;

        this.defineListableRows();
    }

    public localRefreshRows(): void {
        this.defineListableRows();
    }

    private defineListableRows() {
        this.rows = isValidRef(this.parameters.pagination) ? this.getCurrentPageRows() : typedClone(this.allRows);
    }

    private getCurrentPageRows(): Row[] {
        const paginationInt = parseInt(this.pagination);
        return _.chain(this.allRows)
            .filter(row => isValidFunction(this.parameters.filter) ? this.parameters.filter(row) : true)
            .chunk(paginationInt)
            .get(this.currentPageIndex)
            .defaultTo([])
            .value()
            ;

        // @freitas was the lucky guy :)
        // .filter((_, index) => {
        //     /**
        //      * You're not a lucky guy!!!
        //      * You found a functional shitty code disguised as an easter egg:
        //      * reduce this. (:
        //      */
        //     return (
        //         index < paginationInt * (this.currentPageIndex + 1) &&
        //         this.currentPageIndex > 0
        //             ? index > paginationInt * (
        //                 this.currentPageIndex > 1
        //                     ? this.currentPageIndex + 1
        //                     : 1
        //                 )
        //             : index < paginationInt * (this.currentPageIndex + 1)
        //     );
        // });
    }

    public runOptionCallback() {

    }

    public get columnNames(): (keyof Row)[] {
        return this.handler.getColumns();
    }

    public getColumnText(columnName: string): string {
        return this.parameters.rowNames[columnName];
    }

    public get paginationAsNumber(): number {
        return parseInt(this.parameters.pagination);
    }

    public get pagination(): EPageSizeOption {
        return this.parameters.pagination;
    }
    public set pagination(page: EPageSizeOption) {
        this.parameters.pagination = String(page) as EPageSizeOption;
        if (isValidFunction(this.parameters.clientCallback.onChangePagination))
            this.parameters.clientCallback.onChangePagination(this.parameters.pagination)
                ;
    }

    public emitOnChangePagination(option: PageEvent): void {
        const pageSize = String(option.pageSize) as EPageSizeOption;

        if (option.pageIndex !== this.currentPageIndex) {
            this.currentPageIndex = option.pageIndex;
            this.rows = this.getCurrentPageRows();
        }

        if (this.pagination !== pageSize) {
            this.pagination = pageSize;
            this.defineListableRows();
        }

    }

    public showContent(): boolean {
        return isValidRef(this.handler) && isValidRef(this.rows) && isValidRef(this.columnNames);
    }

    public onRefresh(): Promise<void> {
        return this.init();
    }

    public get parameters(): TGenericTableHandlerParameters<Row> {
        return this.handler.getComponentParameter();
    }

    public onFieldClick(field: IGenericTableGenericRowField, row: Row, columnName: keyof Row): void {

        if (isValidFunction(field.onClick)) {
            field.onClick();
            return;
        }

        this.onRowClicked(row);

        if (isInvalid(field.enableOnClick)) return;

        this.parameters.clientCallback.onRowFieldClick?.({
            field,
            row,
            entity: this.mapRowToEntity.get(row),
            columnName
        });
    }

    GenericTableRowFieldIconType = GenericTableRowFieldIconType;

    public onRowClicked(row: Row): void {
        if (!this.hasRowClickHandler) return;
        this.parameters.clientCallback.onRowClick?.(row);
    }

}
