import { NamedString } from "@colmeia/core/src/core-constants/named-types";
import { TArrayID } from "@colmeia/core/src/core-constants/types";
import { IBotSubject, IBruteForceAcuracyInfoCalc } from "@colmeia/core/src/shared-business-rules/bot/bot-interfaces";
import { AzureCLUCore } from "@colmeia/core/src/shared-business-rules/knowledge-base/clu/azure-clu.core.interfaces";
import { TFitterStrategyConfig } from "@colmeia/core/src/shared-business-rules/knowledge-base/fit-curve";
import { ENonSerializableObjectType, IdChunk, IdConnection, IdPrompt, IdSchema, IdVector, INonSerializableHeader, NonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";
import { EConnectionType } from "../connections/endpoint-model";



export interface IMLCreateIntent {
    intentName: string
}

export type TIUtteranceServerArray = Array<IUtteranceServer>;

export type IGenAIProviderOptions =  EConnectionType.OpenAI | EConnectionType.Gemini;

export const genAIProviderOptions = [EConnectionType.OpenAI, EConnectionType.Gemini];

export interface IUtteranceClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.utterance
}
export interface IUtteranceServer extends IUtteranceClient, NonSerializable<ENonSerializableObjectType.utterance> {
    nsType: ENonSerializableObjectType.utterance
    idKB: string;
    utterance: IMLCLUUtterenceExample;
};

export interface IMLCLUPattern {
    patternId?: string;
    pattern: string;
    intentName: string;
}
export interface IPredictiveKeywordsForGenerative {
    idPredictConfig: string;
    keyword: string;
}


export interface IToggleable {
    isOn?: boolean;
}

export interface ISemanticIntentConfig extends IToggleable {
    keywords: IPredictiveKeywordsForGenerative[]
}

export interface IGenerativeIntentConfig extends IToggleable {
    idVector: IdVector;
    idsNSChunk: IdChunk[];
}

export interface IBruteForceIntentConfig extends IToggleable, IBotSubject {}

export interface IMachineLearningIntentConfig extends IToggleable {}



export interface IOmniSenseIntentConfig {
    botSubject?: IBruteForceIntentConfig;
    generative?: IGenerativeIntentConfig,
    predictConfig?: ISemanticIntentConfig,
    machineLearning?: IMachineLearningIntentConfig,
    qaFewShortLearning?: IQAFewShortLearningIntent;
    adaptativeInContextLearning?:  IAdapativeInContextLearningIntent;
}

export type ExternalId = NamedString<'ExternalId'>;
export type IntentName = NamedString<'IntentName'>;
export type IntentId = NamedString<'IntentId'>;
export type IdIntent = IntentId;
export type IdAsset = NamedString<'IdAsset'>;


export enum EFSLearningKind {
    labeled = 'labeled',
    qa = 'qa',
    // auto?
}

export interface ISuperFewShotIntentConfig extends IToggleable {
    description: string;
    summary: string;
    featureEngineeredSubPrompt: IFewShotLearningGeneratedAssets;
}

export interface IQAFewShortLearningIntent extends ISuperFewShotIntentConfig {
    kind: EFSLearningKind.qa;
    examples: {
        examples: {question: string, answer: string}[];
    }
    negativeSamples:{
        negative: string[]
    }
}

export interface IAdapativeInContextLearningIntent extends ISuperFewShotIntentConfig {
    kind: EFSLearningKind.labeled;
    examples: {
        examples: IPredictiveKeywordsForGenerative[];
    }
}

export interface IMLCLUIntent extends IBruteForceAcuracyInfoCalc {
    intentId: IntentId; // Nosso
    externalId?: ExternalId;
    intentName: IntentName;
    utterancesCount: number;
    biasOn?: boolean;
    bias?: number; //[0..2] slide de 0 até 2
    intentOSConfig?: IOmniSenseIntentConfig;
    extractor?: IEntityExtractor;
}

export interface IEntityExtractor {
    idSchema: IdSchema;
    name?: string;
    description?: string;
    propertiesConfigs: IEntityExtractorProperty[];
}

export interface IEntityExtractorProperty {
    name?: string;
    description?: string;
    idProperty: string;
}


export interface IMLCLUPredictIntentStrategy {
    score: number;
    strategy: EOmniSenseMethods;
    weight: number
}



export interface IGenerativePredictionInfo {
    idVector: IdVector;
    idsNSChunk: IdChunk[];
    idPrompt: IdPrompt;
}
export interface IMLCLUPredictIntent {
    score: number;
    intent: IntentName;
    idIntent: IntentId;
    strategy: EOmniSenseMethods;
    strategies: { [key in EOmniSenseMethods]?: IMLCLUPredictIntentStrategy }
    // esses campos são preeenchidos no generativpo
}

export interface IMLCLUPredictEntityResolution {
    values: string[]
}

export interface IMLCLUPredictEntity {
    matched: string;
    entityName: string;
    entityType: EMLEntityType;
    role?: string;
    resolution: IMLCLUPredictEntityResolution,
    childs?: IMLCLUPredictEntity[],
    startIndex: number;
    endIndex: number;
    idIntent?: IntentId;
}

export interface IMLCLUPredictResult {
    appId: string;
    query: string;
    hasSubject?: boolean;
    originalText?: string;
    topScoreIntent: IMLCLUPredictIntent;
    intents: IMLCLUPredictIntent[];
    entities: IMLCLUPredictEntity[];
}

export interface IMLCLUAdjustedPredictIntentStrategy extends IMLCLUPredictIntentStrategy {
    adjustedScore?: number;
}

export interface IMLCLUAdjustedPredictIntent extends Omit<IMLCLUPredictIntent, "strategies"> {
    strategies: { [key in EOmniSenseMethods]?: IMLCLUAdjustedPredictIntentStrategy }
}

export interface IMLCLUAdjustedPredictResult extends Omit<IMLCLUPredictResult, "intents">{
    intents: IMLCLUAdjustedPredictIntent[];
}


export interface IMLCLUUtterenceLabel {
    entityName: string;
    entityId: string;
    startCharIndex: number;
    endCharIndex: number;
    children: IMLCLUUtterenceLabel[];
    role?: string;
}

export interface IMLCLUUtterenceEntityPrediction {
    entityName: string;
    entityId: string;
    startTokenIndex: number;
    endTokenIndex: number;
    phrase: string;
    children?: IMLCLUUtterenceEntityPrediction[];
    entityType?: EReadableEntityType;
}

export interface IMLCLUUtterenceExample {  // Simnplificação do Modelo de UTterance do Luis
    intentName: IntentName,
    intentID: string;
    example: string, // sugestões nesse apenas
    exampleId?: number,
    ignoredByEquallity?: boolean;
    isValid?: boolean;
    entityLabels: IMLCLUUtterenceLabel[];
    entityPredictions?: IMLCLUUtterenceEntityPrediction[];
    tokenizedText?: string[];
    userSentence: string; // ex: quero meu boleto de 2019 porque ... => gera o exemplo
    trainedAt?: number; // zero


    lastScore?: number;
    languge?: AzureCLUCore.Language;
}

export interface IMLCLUUtterenceIntentPrediction {
    /**
     * The intent's name
     */
    name?: string;
    /**
     * The intent's score, based on the prediction model.
     */
    score?: number;
}



// prep
// querer meu boleto 2019

export type TUtteranceArray = Array<IMLCLUUtterenceExample>;

export enum EMLCLUTrainResultType {
    inProgress = "InProgress",
    upToDate = "UpToDate",
    fail = "Fail",
    success = "Success",
    queued = "Queued",
    error = "error"
}

export const cluTrainResultTypeConfig: { [key in EMLCLUTrainResultType]: { isError: boolean } } = {
    [EMLCLUTrainResultType.inProgress]: {
        isError: false,
    },
    [EMLCLUTrainResultType.upToDate]: {
        isError: false,
    },
    [EMLCLUTrainResultType.fail]: {
        isError: true,
    },
    [EMLCLUTrainResultType.success]: {
        isError: false,
    },
    [EMLCLUTrainResultType.queued]: {
        isError: false,
    },
    [EMLCLUTrainResultType.error]: {
        isError: true,
    },
}

export interface IMLCLUTrainIntentResult {
    modelId: string,
    result: EMLCLUTrainResultType;
    exampleCount: number;
    failureReason?: string;
}

export interface IMLCLUTrainResult {
    finalResult: EMLCLUTrainResultType;
    intentsResult: IMLCLUTrainIntentResult[];
}

export interface IMLCLUPublishResult {
    appId: string;
    endpointUrl: string;
}

export interface IMLCLUPhraseList {
    phrasesId?: number,
    phrasesName: string,
    phrasesList: TArrayID,
    isExchangeable: boolean
}

export enum EMLEntityType {
    list = 'list',
    simple = 'simple',
    regex = 'regex',
    composite = 'composite',
    patternAny = 'pattern.any',
    prebuilt = 'predefined',
    machineLearned = 'machineLearned',
}

export enum EReadableEntityType {
    MachineLearned = 1,
    Child = 2,
    Hierarchical = 3,
    HierarchicalChild = 4,
    Composite = 5,
    List = 6,
    Prebuilt = 7,
    IntentClassifier = 8,
    PatternAny = 9,
    ClosedList = 10,
    Regex = 11,
}

export interface IMLCLURole {
    roleId?: string;
    value: string;
}


export interface IMLCLUEntityInfo {
    /**
     * @deprecated
     */
    roles: IMLCLURole[];
}

export namespace KBCorporateEntity {
    export namespace List {
        export interface SubList {
            listKey: string;
            synonyms: SubListSynonym[];
        }
        export interface SubListSynonym {
            language: AzureCLUCore.Language;
            values: string[];
        }
        export interface Interface {
            sublists: List.SubList[];
        }
    }

    export import List = List.Interface;

    export namespace Regex {
        export interface Expression {
            language: AzureCLUCore.Language;
            regexKey: string;
            regexPattern: string;
        }
        export interface Interface {
            expressions: Expression[];
        }
    }
    export import Regex = Regex.Interface;

    export interface Interface {
        list?: KBCorporateEntity.List;
        regex?: KBCorporateEntity.Regex;
    }
}


export interface INSKBCorporateEntityServer extends NonSerializable<ENonSerializableObjectType.kbCorporateEntity> {
    entity: KBCorporateEntity.Interface;
}

export type TMLCLUEntityInfo =
    | IMLCLUEntitySimpleInfo
    | IMLCLUEntityListInfo
    | IMLCLUEntityRegexInfo
    ;
export type IMLCLUNormalizedValueHash = {
    [canonicalForm in string]: string[]
}
export interface IMLCLUEntitySimpleInfo extends IMLCLUEntityInfo {
}

export interface IMLCLUEntityListInfo extends IMLCLUEntityInfo {
    values: IMLCLUNormalizedValueHash
}

export interface IMLCLUEntityRegexInfo extends IMLCLUEntityInfo {
    regex: string;
}

export interface IMLCLUEntityCompositeInfo extends IMLCLUEntityInfo {
    entitiesName: string[]
}

export interface IMLCLUEntityPatternAnyInfo extends IMLCLUEntityInfo {
}

export enum EFeatureType {
    Phrase = 'phrase',
    Entity = 'entity',
}
export interface IMLCLUFeatureEntity {
    type: EFeatureType.Entity;
    modelName?: string; // Entity name
    isRequired?: boolean;
}
export interface IMLCLUFeaturePhrase {
    type: EFeatureType.Phrase;
    featureName: string; // Phrase name
    isRequired?: false;
}
export type TMLCLUFeature =
    | IMLCLUFeatureEntity
    | IMLCLUFeaturePhrase
    ;
export interface IMLCLUEntity {
    entityId: string;
    entityInfo: IMLCLUEntityInfo;
    entityName: string;
    entityType: EMLEntityType;
    /**
     * @deprecated
     */
    subEntities?: IMLCLUEntityChild[];
    features: TMLCLUFeature[];
};



export interface IMLCLUEntitySimple extends IMLCLUEntity {
    entityType: EMLEntityType.simple;
    entityInfo: IMLCLUEntitySimpleInfo;
}

export interface IMLCLUEntityList extends IMLCLUEntity {
    entityType: EMLEntityType.list;
    entityInfo: IMLCLUEntityListInfo;
}

export interface IMLCLUEntityRegex extends IMLCLUEntity {
    entityType: EMLEntityType.regex;
    entityInfo: IMLCLUEntityRegexInfo;
}

export interface IMLCLUEntityPrebuilt extends IMLCLUEntity {
    entityType: EMLEntityType.prebuilt;
}

export type TMLCLUEntity =
    | IMLCLUEntitySimple
    | IMLCLUEntityList
    | IMLCLUEntityRegex
    | IMLCLUEntityPrebuilt
    ;



export interface IMLCLUPrebuiltEntity extends IMLCLUEntity {
    entityName: EAvailablePrebuiltEntity;
    entityType: EMLEntityType.prebuilt;
    subEntities?: [];
}


export enum EAvailablePrebuiltEntity {
    Age = "age",
    DatetimeV2 = "datetimeV2",
    Dimension = "dimension",
    Email = "email",
    KeyPhrase = "keyPhrase",
    Money = "money",
    Number = "number",
    Ordinal = "ordinal",
    Percentage = "percentage",
    Phonenumber = "phonenumber",
    Temperature = "temperature",
    URL = "url",
}
export interface IAvailablePrebuiltEntity {
    name: string;
    description: string;
    examples: string;
}

export interface ITranslatedAvailablePrebuiltEntity extends IAvailablePrebuiltEntity {
    type: EAvailablePrebuiltEntity;
}

export interface IMLCLUEntityChild extends IMLCLUEntity {
}

export interface IKBAppConfig {
    UseAllTrainingData?: boolean;
    NormalizeDiacritics?: boolean;
    NormalizePunctuation?: boolean;
}

export const isKBCommited = Symbol('isKBCommited');


export interface IFewShotLearningGeneratedAssets {
    isDemanded: boolean;
    generated: {
        summarization?: string;
        rewrite?: string[];
    };
}

export interface IBindAgent {
    idSupremacyAgent: string;
}

export type TIBindAgentArray = Array<IBindAgent>;
export interface IFeatureEngineerConfig {
    featureEnginner: TIBindAgentArray
}

export interface IMLCLUApp {
    appName: string; //nao pode repetir @TODO gerar readable unique id
    appId?: string; // gerado pelo luis
    lastVersionId?: string;
    appConfig: IKBAppConfig;

    extractorConfig?:  IMLCLUExtractorConfig;

    intents: IMLCLUIntent[];
    /**
     * @deprecated
     */
    phrases: IMLCLUPhraseList[];
    entities: IMLCLUEntity[];
    corporateEntities: IdDep<ENonSerializableObjectType.kbCorporateEntity>[];
    patterns: IMLCLUPattern[];
    osStrategy: IOminiSenseIAConfig;
    intentionChangeConfig?: IOmniSenseSubjectChangeConfig
    fusor?: IFusorConfig;
    clu: {
        shouldCommitWhenChange?: boolean;
        commitedAt?: number;
        config: IAzureCLUAppConfig;
        exported?: string;
        [isKBCommited]?: boolean;
    };
    providers?: {
      embedding?: IEmbeddingProvider,
      llm?: ILLMProvider
    },
    featureEngineering?: IFeatureEngineerOutput;
    agentsEnsemble?: IFeatureEngineerConfig;
}


export type TClassificationResult = Record<string, number>;
export type TGeneratedAssetsLLM = {intentName: string;
                                   generated: {summarization?: string;
                                               rewrite?: string[];
                                            }
                                        };
export interface IExample {
    question: string;
    answer?: string;
    }

export interface IFeatureEngineerOutput {
    text?: string;
    context?: string;
    examples?: IExample[];
    intentNames?: string[];
    classification?: TClassificationResult;
    priorClassification?: TClassificationResult;
    priorReasoning?: string;
    generatedAssets?: TGeneratedAssetsLLM[];
  }





export interface IProviderConn {
  idConnection?: IdDep<ENonSerializableObjectType.connection>;
  connectionType?: EConnectionType;
}

export interface ILLMProvider extends IProviderConn {
  connectionType?: IGenAIProviderOptions
}

export interface IEmbeddingProvider extends IProviderConn {
  connectionType?: IGenAIProviderOptions
}

export interface ICLUProvider extends IProviderConn {
  connectionType?: EConnectionType.CLU;
  description?: string;

  // Verificar se essas informações estão/serão  usadas ese podem ficar aqui
  // CONFIG CLU

  // shouldCommitWhenChange?: boolean;
  // commitedAt?: number;
  // config: IAzureCLUAppConfig;
  // exported?: string;
  // [isKBCommited]?: boolean;
}

export interface IFusorConfig {
    type: EFusorType,
    softmaxTemperature?: number
}

interface IMLCLUExtractorConfig {
    /** Configuration inside each intent, more specifically on: `IMLCLUIntent.extractor` */
    llm: {
        isOn: boolean;
    },
    /** Entities are configured in `IMLCLUApp.entities` */
    machineLearning: {
        isOn: boolean;
    };
}


export interface IAzureCLUAppConfig {
    language: AzureCLUCore.Language;
    projectKind: AzureCLUCore.ProjectKind;
    settings: AzureCLUCore.ConversationalAnalysisAuthoringProjectSettings;
    multilingual?: boolean;
}

export enum EOmniSenseMethods {
    machineLearning = 'OSmachLea',
    bruteForce = 'OSbrute',
    generative = 'OSGenerative',
    semanticPrediction = 'OSSemntcPredctn',
    qaFewShotLearning = 'OSQAfewShotLearning',
    adaptativeInContextLearning = 'OSadaptativeInContext',
    agentSupremacy = 'agentSupremacy',
}


export interface IGenerativeFitCurve {
    scalingFactor: number; // a // 0.0001 <-> 0.001 // 0.0005 => 0.00001
    yInterceptor: number; // o
    ratingVariation: number; // b // 3 <-> 10 // 0.005
}

export interface ILimitedGeneativeCurve extends IGenerativeFitCurve {
    type: 'exp' | 'line',
    min: number;
    max: number;
}

export interface ICurves {
    bellow: ILimitedGeneativeCurve;
    focused: ILimitedGeneativeCurve;
    above: ILimitedGeneativeCurve;
}



export interface IOmniSenseStrategyConfig {
    isOn: boolean;
    osTatic: EOmniSenseMethods;
    weight: number; // 1..10;
    fitter?: TFitterStrategyConfig;
}



export enum EReasoningTatic {
    REFLECTION = 'reflection',
    CHAIN_OF_THOUGHTS = 'chainOfThoughts'
}

export interface IReasoningConfig {
    isActive: boolean;
    reasoningType?: EReasoningTatic;
    selfReflectionRounds?: number;
}


export interface IBruteForceConfig {
    scoreComparedMatches: boolean; // Considere no Score a quantidade Comparada de Acertos
    comparedMatchWeight: number; //  Relevância    0..2, números reais (0.3, 1.5 et.,), default 1
    minScoreToBeAMatch: number; // score mínimi para ser considerado Match (default 0.5)
}


export interface IBruteForceStragtegyConfig extends IOmniSenseStrategyConfig {
    osTatic: EOmniSenseMethods.bruteForce;
    bruteConfig: IBruteForceConfig
}


export interface ISemanticConfig {}
export interface IFewShotConfig extends IReasoningConfig {}

export interface ISemanticStrategyConfig extends IOmniSenseStrategyConfig {
    osTatic: EOmniSenseMethods.semanticPrediction;
    semanticConfig: ISemanticConfig
}

export interface IGenerativeStrategyConfig extends IOmniSenseStrategyConfig {
    osTatic: EOmniSenseMethods.generative;
}

export interface IFewShotLearningStrategyConfig extends IOmniSenseStrategyConfig {
    osTatic: EOmniSenseMethods.qaFewShotLearning | EOmniSenseMethods.adaptativeInContextLearning;
    fewShotConfig: IFewShotConfig
}


export type TIOmniSenseStrategyConfigArray = Array<IOmniSenseStrategyConfig>;

export enum EFusorType {
    StaticWeights = "staticWeights",
    SoftmaxWeigths = "softmaxWeights"
}

export interface IOmniSenseStrategiesConfig {
    strategy: TIOmniSenseStrategyConfigArray;
}

export interface IOminiSenseIAConfig extends IOmniSenseStrategiesConfig {
    // Utilizar o primeiro Match
    firstMatchPrediction?: boolean; //
    firstMatchThreshold?: number;
}

export interface IOmniSenseSubjectChangeConfig extends IOmniSenseStrategiesConfig {
    enabled?: boolean;
    accuracyThreshold?: number;
}


export interface WebCLURecommendationResultRank {
    Rank: number;
    RankerName: string;
}

export interface WebCLURecommendationResult {
    Blacks: string[];
    InputWords: string[];
    RankingInfos: {
        [rankWord: string]: WebCLURecommendationResultRank
    };
    SuggestedEntries: string[];
}
