import { TArrayID } from "@colmeia/core/src/core-constants/types";
import { ENonSerializableListMode, ICustomNSPicker, IListNonSerializablesMatch, IListNonSerializablesRequest, IListNonSerializablesSort } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { ENonSerializableObjectType, ENserVisualizationType, INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { NsTypeToInterface } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-interface-mapper";
import { ITagableSearch } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp";
import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";
import { GetNS } from "@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service";
import { defaultFields, getFirstValue, initPromise, isInvalid, isInvalidArray, isValidFunction, isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { createClientPredicatesFromEntity, DeepPartial, inMemoryMatch, MetaGetDeepTypedProperty, NoInfer, TFunction, useClientPredicates } from "@colmeia/core/src/tools/utility-types";
import { IGDPerPageOptions } from 'app/components/dashboard/dashboard-foundation/generic-dashboard-pagination/generic-dashboard-pagination.parameter';
import { indexationHelper } from "app/components/dashboard/helpers/indexation-helper";
import { IComponentParameter } from "app/model/component-comm/basic";
import { DashBoardService } from "app/services/dashboard/dashboard.service";
import { GenericNonSerializableService } from "app/services/generic-ns.service";
import { BehaviorSubject, Observable } from 'rxjs';
import { IHandlerAllowingDebouncer, MainHandler } from "../main-handler";
import { NSSelecionPrefix } from "./ns-picker-selections.handler";
import { factoryOf } from "@colmeia/core/src/tools/utility/functions/factory-of";


export interface INSPickerHandlerState<NSType extends ENonSerializableObjectType> {
    nsType: NSType;
    demandedTag?: string;
    listMode?: ENonSerializableListMode;
    title?: string;
    disabledTitle?: string;
    idParent?: string;
    nonSerializables?: INonSerializable[];
    nonSerializablesIds?: IdDep<NoInfer<NSType>>[];

    allowChips?: boolean;
    maxSelections?: number;
    enableSelectAll?: boolean;

    requirementData?: ERequirementType;

    disable?: boolean;
    custom?: ICustomNSPicker;
    hideLabel?: boolean;


    //
    enableOnSelectionClickGoToItem?: true;
}

export type TNSerFilter = (ns: INonSerializable) => boolean;

// A NonSerializable from NsTypeToInterface using NSType.
export type TNsFromNSType<NSType extends ENonSerializableObjectType> = NsTypeToInterface[NSType];

// Possible property names of a given non-serializable object type.
export type TNsPropertyName<NSType extends ENonSerializableObjectType> = keyof TNsFromNSType<NSType>;

// Possible Type values of a propertie of a given non-serializable object type.
export type TNsPropertyValueType<NSType extends ENonSerializableObjectType, Prop extends TNsPropertyName<NSType>> = {
    propertyTypeValue: ValueOf<TNsFromNSType<NSType>, Prop> | null,
    translation: string
};

// Given a type `T` and a property `K` of `T`, it returns the type of `K`.
export type ValueOf<T, K extends keyof T = keyof T> = K extends any ? T[K] : never;

export interface IUseNsMatchByCustomerChoiceConfig<NSType extends ENonSerializableObjectType = ENonSerializableObjectType, Prop extends TNsPropertyName<NSType> = TNsPropertyName<NSType>> {
    label: string,
    property: string,
    possibleValues: Array<TNsPropertyValueType<NSType, Prop>>,
    value: ValueOf<TNsFromNSType<NSType>, Prop>,
    setMatch: (value: string) => void // This funciton sets the 'match' property of the nspicker handler, ATENTION on implementation
}


export interface IUseNsMatchByCustomerChoice {
    /**
     * Permite que o nsPicker modal exiba opções de filtro pro usuário filtrando os NSs a partir de um determinado valor de uma propriedade.
     *
     * As propriedades exibidas são configuradas no ns-picker.components.ts na função handleUseNsPropertyValueSelectionConfig
     */
    allowPropertyValueSelection: boolean

    /**
     * A propriedade config é preenchida dentro do ns-picker.component.ts de acordo com nsType na função 'handleUseNsPropertyValueSelectionConfig'.
     *
     * É possível sobreescrever a configuração padrão do nsPickerComponent passando o object config no handler do nsPicker sobreescrevendo uma ou mais properidades do objeto 'config'.
     */
    config?: IUseNsMatchByCustomerChoiceConfig | Partial<IUseNsMatchByCustomerChoiceConfig>
}

export enum ERequirementType {
  required = 'required',
  optional = 'optional'
}

export interface INSPickerHandlerParameter<
    NSType extends ENonSerializableObjectType = ENonSerializableObjectType,
    NS extends INonSerializable = NsTypeToInterface[NSType]
> extends INSPickerHandlerState<NSType>, IComponentParameter, IHandlerAllowingDebouncer {
    clientCallback: INSPickerHandlerClientCallback<NS>;
    genericNonSerializableService?: GenericNonSerializableService;
    filter?: TNSerFilter;
    getNonSerializables?: () => Promise<INonSerializable[]>;
    getNonSerializablesByIds?: (ids: string[]) => Promise<NS[]>;
    match?: IListNonSerializablesMatch[];
    sort?: IListNonSerializablesSort;
    shouldDisableSelectionsButton?: boolean;
    disable?: boolean;
    shouldUseCurrentDemandedTag?: boolean;
    //
    predicateFilter?: DeepPartial<MetaGetDeepTypedProperty.MapDeepTypedProperty<NS, { IsPreservingValues: true; IsIgnoringArray: true }>>
    pillMode?: boolean;
    idNSRemoteEnvAuthProvider?: string;

    /**
     * Se for utilizar 'allowCreateButton'
     * verifique se o switchcase da função createNs
     * no arquivo ns-picker-modal.component.ts
     * está tratando o caso de uso relativo
     * ao nsType que você pretende usar.
     */
    allowCreateButton?: boolean;
    useColmeiaWindowSvc?: boolean;
    colmeiaWindowConfig?: {
        windowGroup: string,
        windowIdentifier: string,
        parentIdentifier?: string
    }

    /**
     * Permite dar a opção de o usuário filtrar os NonSerializables no backend setando a propriedade 'match' desse handler, de acordo com nsType.
     * Veja a interace IUseNsMatchByCustomerChoice para configurar.
     * O objeto 'config' pode ser sobreescrevido no handler se for necessária uma config diferente da padrão.
     */
    useNsMatchByCustomerChoice?: IUseNsMatchByCustomerChoice,

    /**
     * Permite alterações no objeto da requisição antes dela ser feita
     */
    requestModifierCallback?: (request: IListNonSerializablesRequest) => IListNonSerializablesRequest
}


export interface INSPickerMainClient {
    genericNonSerializableService: GenericNonSerializableService;
    dashboardService: DashBoardService;
    onOpenModal(): Promise<void>;
}

export interface INSPickerHandlerClientCallback<NS extends INonSerializable = INonSerializable> {
    beforeOpenModal?(nsType: ENonSerializableObjectType): Promise<void>;
    mapName?(ns: INonSerializable): string;
    shouldDisableDelete?(ns: INonSerializable): boolean;

    onSelectCallback?(): void; // inside modal
    onSelectSearchTags?(): void; // inside modal

    onSaveCallback?(nonSerializables?: NS[], nsType?: ENonSerializableObjectType): void; // close modal
    onSaveNSCallback?(nonSerializable: NS | undefined, nsType?: ENonSerializableObjectType): void;

    onSelectableClick?(nonSerializable: INonSerializable): void;

    onCancelCallback?(): void; // close modal
    onClearCallback?(ns: INonSerializable): void;

    /**
     * method called when component is loaded and already has non serializables selected
     * @param nonSerializables
     */
    onLoadNonSerializables?(nonSerializables: NS[]): void; // ns-picker component only
    onListedNonSerializables?(nonSerializables: NS[]): void; // ns-picker component only

    prefixMap?(ns: INonSerializable): NSSelecionPrefix.Item | undefined;
    onClientAdd?: (client: any) => void;
    onOpenModal?(): void;
}

export interface INSPickerHandler extends INSPickerHandlerClientCallback {
    addClient(client: INSPickerHandlerClientCallback): void;

    addNonSerializable(nonSerializable: INonSerializable): void;
    removeNonSerializable(nonSerializable: INonSerializable): void;

    switchNonSerializable(nonSerializable: INonSerializable): void;
}

export class NSPickerHandler<NSType extends ENonSerializableObjectType = ENonSerializableObjectType> extends MainHandler implements INSPickerHandler, INSPickerHandlerState<NSType> {
    private static MAX_RECURRENT_REQUESTS: number = 10;
    private _maxRecurrentRequestsHitted: boolean = false;
    public invalidNonSerializablesSelections: Set<string> = new Set();

    // internal state
    clients: INSPickerHandlerClientCallback[] = [];

    // state
    nsType: NSType;
    demandedTag: string;
    title: string = '';
    allNonSerializables: INonSerializable[] = [];
    searchNonSerializables: INonSerializable[] = [];
    nonSerializables: INonSerializable[] = [];
    maxSelections: number = 1; // padrão 1 se não escolher
    enableSelectAll?: boolean = false;
    idParent: string = null;
    searchTags: TArrayID;

    requirementData?: ERequirementType;

    // state outside parameters
    allowMultipleNonSerializableSelections: boolean;
    allowChips: boolean;
    public hasInvalidSelections: boolean;
    private _cursor: string = null;
    private _searchCursor: string = null;
    get cursor(): string { return this.getCursor() };

    private _searchToken: string;

    public perPage: number = 50;
    public currentPage: number = 0;
    public perPageOptions: IGDPerPageOptions = {
        current: this.perPage,
        options: [30, 50, 100, 200]
    }

    private _loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$: Observable<boolean> = this._loading.asObservable();

    // services
    get genericNonSerializableService(): GenericNonSerializableService {
        return this.mainClient?.genericNonSerializableService ?? this.parameters.genericNonSerializableService;
    }
    set genericNonSerializableService(value) {
        if (this.mainClient && !this.mainClient.genericNonSerializableService) {
            this.mainClient.genericNonSerializableService = value;
        }
    }

    get dashboardSvc() {
        return this.mainClient?.dashboardService;
    }

    get allowCreateButton(): boolean | undefined {
        return this.parameters.allowCreateButton;
    }

    get useColmeiaWindowSvc(): boolean | undefined {
        return this.parameters.useColmeiaWindowSvc;
    }

    public cumulativeAdded: TArrayID = [];

    constructor(private parameters: INSPickerHandlerParameter<NSType>) {

        super(parameters);

        defaultFields(parameters, { allowChips: true });

        this.allowChips = parameters.allowChips;

        const $ = new Proxy({}, {
            get: (_, name) => name,
        }) as INSPickerHandlerParameter;

        const copyToState = [
            $.disable,
            $.nsType,
            $.demandedTag,
            $.title,
            $.nonSerializables,
            $.maxSelections,
            $.enableSelectAll,
            $.requirementData,
            $.genericNonSerializableService,
            $.idParent
        ].map(String);

        for (let key of copyToState)
            if (isValidRef(this.parameters[key]))
                this[key] = this.parameters[key];

        this.addClient(this.parameters.clientCallback);

        this.allowMultipleNonSerializableSelections = this.maxSelections > 1
            || this.enableSelectAll

        this.limitNonSerializablesByMaxSelections();

        if (isValidRef(this.getComponentParameter().predicateFilter)) {
            this.getComponentParameter().filter ??= (ns) => inMemoryMatch(ns, this.getComponentParameter().predicateFilter!)
        }

    }

    static factory = factoryOf(this);

    getComponentParameter(): INSPickerHandlerParameter {
        return super.getComponentParameter() as INSPickerHandlerParameter;
    }

    public resetMaxRecurrentRequest() {
        this._maxRecurrentRequestsHitted = false;
    }

    set searchToken(value: string) {
        this._searchToken = value;

        if (!isValidString(value)) {
            this._searchCursor = null;
        }
    }

    get searchToken(): string {
        return this._searchToken;
    }

    private setCursor(value: string) {
        if (isValidString(this._searchToken)) {
            this._searchCursor = value;
        } else {
            this._cursor = value;
        }
    }

    public resetCursor(): void {
        this._searchCursor = undefined;
        this._cursor = undefined;
    }

    private getCursor(): string {
        return isValidString(this._searchToken) ? this._searchCursor : this._cursor
    }


    hasSearch = true;

    public async fetchNSs() {
        if (!this.genericNonSerializableService) {
            await this.waitMainClient;
        }

        if (this.parameters.shouldUseCurrentDemandedTag) {
            this.demandedTag ??= this.dashboardSvc?.defaultTag ?? this.demandedTag;
        }

        const search: ITagableSearch = {
            demandedTag: this.demandedTag,
            searchedTags: this.searchTags,
        }


        if (this.parameters?.predicateFilter) {
            const match = createClientPredicatesFromEntity(this.parameters?.predicateFilter);
            this.parameters.match ??= [match];
        }

        const response = await this.genericNonSerializableService.getChildrenFullResponse(
            this.idParent,
            this.nsType,
            this.getCursor(),
            search,
            this.getComponentParameter().custom,
            this.getComponentParameter().listMode,
            this.perPageOptions.current,
            this._searchToken,
            this.getComponentParameter().match,
            this.getComponentParameter().sort,
            this.getComponentParameter().idNSRemoteEnvAuthProvider,
            this.getComponentParameter().requestModifierCallback,
        );
        this.hasSearch = response.hasSearch !== false;
        return response;
    }

    loadAllNonSerializables = async (cumulative: boolean = false, requestNumber: number = 0): Promise<void> => {
        if (requestNumber >= NSPickerHandler.MAX_RECURRENT_REQUESTS) {
            this._maxRecurrentRequestsHitted = true;
            return;
        }

        const hasFilter: boolean = isValidFunction(this.getComponentParameter().filter);

        this._loading.next(true);

        if (isValidFunction(this.getComponentParameter().getNonSerializables)) {
            this.allNonSerializables = await this.getComponentParameter().getNonSerializables();
        } else {
            const response = await this.fetchNSs();

            const nextCursor = getFirstValue(response.multipleCursor);
            this.setCursor(nextCursor);

            if (hasFilter) {
                const responseFiltered = response.nonSerializableArray.filter(this.getComponentParameter().filter);

                if (responseFiltered.length === 0 && isValidString(nextCursor)) {
                    return this.loadAllNonSerializables(cumulative, ++requestNumber);
                }
            }

            this.cumulativeAdded = [];

            this.allNonSerializables = cumulative
                ? [...this.allNonSerializables,
                ...response.nonSerializableArray.filter((ns) => {
                    const isOnList = this.allNonSerializables.some(n => n.idNS === ns.idNS);

                    this.cumulativeAdded.push(ns.idNS);

                    return !isOnList;

                })]
                : response.nonSerializableArray;

        }

        if (hasFilter) {
            this.allNonSerializables = this.allNonSerializables.filter(this.getComponentParameter().filter);
        }

        const match = this.getComponentParameter().match;
        if (!match || (match && !match.find(predicate => predicate['visualizationType'] === ENserVisualizationType.archived))) {
            this.allNonSerializables = this.fitlerArchived(this.allNonSerializables);
            if (this.allNonSerializables.length === 0 && isValidString(this.getCursor())) {
                return this.loadAllNonSerializables(cumulative, ++requestNumber);
            }
        }


        this.safeSetNonSerializables(this.nonSerializables);

        this._loading.next(false);

        this.onListedNonSerializables();
    }

    fitlerArchived(nss: Array<INonSerializable>) {
        if (isInvalidArray(nss)) return [];
        return nss.filter((ns) => ns.visualizationType !== ENserVisualizationType.archived)
    }

    setMaxItemsPerPage(amount: number) {
        this.perPage = amount;
        this.perPageOptions.current = this.perPage;
    }

    async setSearchTags(searchTags: TArrayID) {
        this.searchTags = searchTags;
        this.resetCursor();
        this.currentPage = 0;

        await this.loadAllNonSerializables();

        this.onSelectSearchTags();
    }

    onSelectSearchTags() {
        const propertyName: keyof INSPickerHandlerClientCallback = 'onSelectSearchTags';

        for (let client of this.clients) {
            if (client[propertyName]) client[propertyName].bind(client)();
        }
    }

    loadNonSerializablesByIds = (): void => {
        if (!this.parameters.nonSerializablesIds) return;

        const index = indexationHelper.index(this.allNonSerializables, 'idNS');

        this.safeSetNonSerializables(
            this.parameters.nonSerializablesIds.map(idNS => index[idNS])
        );

        this.onLoadNonSerializables();
    }

    onListedNonSerializables() {
        const propertyName: keyof INSPickerHandlerClientCallback = 'onListedNonSerializables';

        for (let client of this.clients) {
            if (client[propertyName]) client[propertyName].bind(client)(this.allNonSerializables);
        }
    }

    onLoadNonSerializables() {
        const propertyName: keyof INSPickerHandlerClientCallback = 'onLoadNonSerializables';

        for (let client of this.clients) {
            if (client[propertyName]) client[propertyName].bind(client)(this.nonSerializables);
        }
    }

    public mainClient?: INSPickerMainClient;
    public get waitMainClient() { return this.waitMainClientHandler.promise; }
    public waitMainClientHandler = initPromise<INSPickerMainClient>();

    addMainClient(client: INSPickerMainClient) {
        this.mainClient = client;
        this.waitMainClientHandler.resolve(this.mainClient);
    }

    addClient(client: INSPickerHandlerClientCallback): void {
        this.clients = [...this.clients, client];

        if (isValidFunction(this.getComponentParameter().clientCallback.onClientAdd)) {
            this.getComponentParameter().clientCallback.onClientAdd(client);
        }
    }

    safeSetNonSerializables(values: INonSerializable[]): void {
        this.nonSerializables = [...values].filter(Boolean);

        if (isValidFunction(this.getComponentParameter().filter)) {
            this.invalidNonSerializablesSelections = new Set(this.nonSerializables
                .filter((ns) => !this.getComponentParameter()
                    .filter(ns))
                .map(ns => ns.idNS!));
            this.hasInvalidSelections = false;
        }

        this.limitNonSerializablesByMaxSelections();

        this.onSelectCallback();
    }

    getNonSerializables<T extends NsTypeToInterface[NSType]>(): T[] {
        return this.nonSerializables as T[];
    }

    getAllNonSerializables<T extends INonSerializable>(): T[] {
        return this.allNonSerializables as T[];
    }

    limitNonSerializablesByMaxSelections(): void {
        if (isValidRef(this.maxSelections)
            && !this.enableSelectAll) {
            this.nonSerializables = this.nonSerializables.slice(this.maxSelections * -1);
        }
    }

    isSelectAllEnabled(): boolean {
        return this.enableSelectAll
    }

    switchNonSerializable(nonSerializable: INonSerializable): void {
        const exists = this.nonSerializables.find(ns => ns.idNS === nonSerializable.idNS);

        if (exists)
            this.removeNonSerializable(nonSerializable);
        else
            this.addNonSerializable(nonSerializable);
    }

    onOpenModal(): void {
        const propertyName: keyof INSPickerHandlerClientCallback = 'onOpenModal';

        for (let client of this.clients) {
            if (client[propertyName]) client[propertyName].bind(client)();
        }
    }

    async pickNSs() {
        await this.mainClient?.onOpenModal();
        return this.getNonSerializables();
    }

    addNonSerializable(nonSerializable: INonSerializable): void {
        this.safeSetNonSerializables([...this.nonSerializables, nonSerializable]);
    }
    removeNonSerializable(nonSerializable: INonSerializable): void {
        this.safeSetNonSerializables(
            this.nonSerializables.filter(ns => ns.idNS !== nonSerializable.idNS)
        );
    }


    onSelectCallback(): void {
        const propertyName: keyof INSPickerHandlerClientCallback = 'onSelectCallback';
        for (let client of this.clients) {
            if (client[propertyName]) client[propertyName].bind(client)();
        }
    }

    onSaveCallback(nonSerializables?: INonSerializable[]): void {
        if (isValidRef(nonSerializables))
            this.safeSetNonSerializables(nonSerializables);

        const propertyName: keyof INSPickerHandlerClientCallback = 'onSaveCallback';
        const otherPropertyName: keyof INSPickerHandlerClientCallback = 'onSaveNSCallback';

        for (let client of this.clients) {
            if (client[propertyName]) {
                const nonSerializables = this.getNonSerializables();

                client[propertyName].bind(client)(nonSerializables, this.nsType);
            }
        }
        for (let client of this.clients) {
            if (client[otherPropertyName]) {
                const nonSerializables = this.getNonSerializables();
                if (nonSerializables)
                    client[otherPropertyName].bind(client)(nonSerializables[0], this.nsType);
            }
        }
    }

    setAllNonSerializables(nonSerializables: INonSerializable[]) {
        this.allNonSerializables = nonSerializables;
    }


    onCancelCallback(): void {
        const propertyName: keyof INSPickerHandlerClientCallback = 'onCancelCallback';

        for (let client of this.clients) {
            if (client[propertyName]) client[propertyName].bind(client)();
        }
    }

    isDisabled(): boolean {
        return this.parameters.disable;
    }

    getTitle(): string {
        return this.parameters.title || this.parameters.disabledTitle;
    }

    setHandlerMatch(match: IListNonSerializablesMatch[] | undefined) {
        this.parameters.match = match;
    }

}
