import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { kbErrors } from "@colmeia/core/src/shared-business-rules/const-text/views/knowledge-base";
import { IKnowledgeDB, IKnowledgeDBServer } from "@colmeia/core/src/shared-business-rules/knowledge-base/kb-inferfaces";
import { KBProcessor } from "@colmeia/core/src/shared-business-rules/knowledge-base/kb-processor";
import { EOmniSenseMethods, IMLCreateIntent, INSKBCorporateEntityServer } from "@colmeia/core/src/shared-business-rules/knowledge-base/clu-core-interfaces";
import { isNSServer } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions";
import { defaultNserValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/nser";
import { ITranslationConfig } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { isValidArray, isValidRef } from "@colmeia/core/src/tools/utility";


export function kbCorporateEntityValidator(ns: INSKBCorporateEntityServer, friendly: FriendlyMessage): FriendlyMessage {
    const messages: string[] = [];
    const isEverySubListFilled = ns.entity.list?.sublists.every(item => item.synonyms.every(item => item.values.length));
    
    defaultNserValidator(ns, friendly);
    
    if (!isEverySubListFilled) {
        messages.push('Alguma lista não foi preenchida com sinônimos.');
    }
    
    if (ns.entity.regex?.expressions) for (const expression of ns.entity.regex?.expressions) {
        if (!expression.regexKey) messages.push('Expressão regular sem nome!');
        if (!expression.regexPattern) messages.push('Expressão regular sem conteúdo');
    }

    if (isValidArray(messages)) {
        friendly.addReturnGenericErrorMessages(messages);
    }

    return friendly;
}