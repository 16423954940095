import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { isValidMedia } from "@colmeia/core/src/rules/mm-functions";
import { contentVectorTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/vectors";
import { EVectorLoader, IGenerativeVectorServer, IVectorSourceConfig } from "@colmeia/core/src/shared-business-rules/generative/generative-model";
import { defaultNserValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/nser";
import { findGet, findGetOutput, isInvalidString, isValidArray, isValidRef } from '@colmeia/core/src/tools/utility';
import { checkEarlyReturnSwitch } from "@colmeia/core/src/tools/utility-types";

export function contentVectorValidator(
    ns: IGenerativeVectorServer,
    friendly: FriendlyMessage
): FriendlyMessage {
    defaultNserValidator(ns, friendly);
    console.log(ns)
    if (!ns.loader) {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noLoad);
    }

    if (!ns.splitterConfig?.type) {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noSplitter);
    }

    if (isInvalidString(ns.providers?.llm?.idConnection)) {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noLLMProvider);
    }

    if (isInvalidString(ns.providers?.embedding?.idConnection)) {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noEmbeddingProvider);
    }

    if (isValidArray(ns.sources)) {
        const sourceValidationError = findGetOutput(ns.sources!, source => getSourceValidationErrors(source)) ;
        if (sourceValidationError) {
            friendly.addReturnGenericErrorMessage(sourceValidationError);
        }
    } else if (ns.loader === EVectorLoader.PDF && (!isValidRef(ns.documentMedia) || !ns.documentMedia?.size)) {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noFile);
    }

    return friendly;
}



function getSourceValidationErrors(vectorSourceConfig: IVectorSourceConfig): string | undefined {
    switch (vectorSourceConfig.loader) {
        case EVectorLoader.PDF: {
            if (!isValidMedia(vectorSourceConfig.documentMedia)) {
                return 'Mídia não selecionada em algum do source';
            }

            return;
        }
        case EVectorLoader.URL: {
            return;
        }


        case EVectorLoader.CSV: {
            return;
        }
        case EVectorLoader.API: {
            return;
        }
        case EVectorLoader.MANUAL: {
            return;
        }
        case EVectorLoader.VIDEO: {
            return;
        }
        case EVectorLoader.USER_FUNCTION: {
            return;
        }
    }

    checkEarlyReturnSwitch()(vectorSourceConfig.loader);
}
