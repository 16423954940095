<cm-generic-dashboard-edit *ngIf="genericDashboardEdit;" [handler]="genericDashboardEdit">
  <div class="modal-w-sidebar-container">
    <div class="sidebar">
      <div class="content nice-scroll">
        <div class="l-title top-title">Detalhes</div>
        <!-- <mat-form-field class="full-width" appearance="fill">
          <mat-label>{{ translations.serviceName.value }}</mat-label>
          <input
            [(ngModel)]="deployedService.nName"
            matInput
          />
        </mat-form-field> -->

        <mat-form-field class="full-width" appearance="fill">
          <mat-label>{{ translations.serviceType.value }}</mat-label>
          <mat-select
            [(ngModel)]="parameters.serviceType"
            (selectionChange)="onServiceTypeChange()"
          >
            <mat-option
              *ngFor="let item of allServiceType"
              [value]="item"
            >
              {{ translations[item].value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-checkbox
          *ngIf="parameters.serviceType"
          color="primary"
          style="font-size: 1.2rem;"
          class="boxed boxed-14"
          [(ngModel)]="parameters.isActive"
          (ngModelChange)="isActiveChange($event)"
        >
          {{ translations.active.value }}
        </mat-checkbox>

        <ng-container *ngIf="canShowBotSelect()">
          <div class="l-title">Bot</div>
          <app-ns-picker
            *ngIf="nsSelectBotRootHandler"
            [handler]="nsSelectBotRootHandler"
          ></app-ns-picker>

          <mat-checkbox
            *ngIf="canShowBotSelect()"
            color="primary"
            class="boxed boxed-14 wrap-label"
            [checked]="allowVoiceCommandsOnBot"
            (change)="changeCheck($event)">
            Permitir comandos de voz ao BOT
          </mat-checkbox>

          <mat-checkbox
            *ngIf="canShowBotSelect()"
            color="primary"
            class="boxed boxed-14 wrap-label"
            [(ngModel)]="doNotSendTranscriptFeedbackToCustomer">
            Não enviar feedback da transcrição ao cliente
          </mat-checkbox>

          <app-alert
            *ngIf="allowVoiceCommandsOnBot && channelsWithoutVoiceSupport.length"
            type="warning"
            style="margin-bottom:16px;">
            <div>
              Um ou mais dos canais selecionados não suportam comandos de voz:
              <br>
              <strong style="margin-top: 8px;">{{ channelsWithoutVoiceSupport }}</strong>
            </div>
          </app-alert>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Palavra chave para reiniciar o bot</mat-label>
            <input
              [(ngModel)]="keyWordReset"
              required
              matInput
            />
          </mat-form-field>
        </ng-container>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>{{ translations.expireTimeInHours.value }}</mat-label>
          <input
            matInput
            type="number"
            min="1"
            autocomplete="off"
            required
            [(ngModel)]="expireBotComm"
          />
        </mat-form-field>

        <ng-container *ngIf="canShowPreferences()">
          <div class="l-title">{{ translations.preferences.value }}</div>

          <mat-form-field appearance="fill" class="full-width">
            <mat-label>{{ translations.availableChannels.value }}</mat-label>
            <mat-select
              [value]="serviceItemChannels"
              [compareWith]="compareFn"
              (selectionChange)="onChannelSelect($event)"
              multiple
            >
              <mat-optgroup
                *ngFor="let channelGroup of channelTypes"
                [label]="getChannelPlaceholder(channelGroup)"
              >
              <mat-option
                  *ngFor="let channel of channelsByType[channelGroup]"
                  [title]="channel.address"
                  [value]="channel"
                >
                  {{ getSocialMediaTitle(channel.idSocialMedia) }}
                </mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="full-width" *ngIf="hasRoutingKey()">
            <mat-label>{{ translations.routingKey.value }}</mat-label>
            <input
              matInput
              readonly
              [value]="parameters.idWhatsAppServiceRouting"
            />
            <span matSuffix>
              <button mat-icon-button matTooltip="Copiar" (click)="copyRoutingKey()">
                <mat-icon>content_copy</mat-icon>
              </button>
            </span>
          </mat-form-field>
          <button
            mat-stroked-button
            color="primary"
            class="full-width"
            *ngIf="!hasRoutingKey()"
            (click)="generateRoutingKey()"
          >
            {{ translations.generateRoutingKey.value }}
          </button>

          <mat-form-field *ngIf="canShowWelcomeText()" appearance="fill" class="full-width">
            <mat-label>{{ translations.welcomeText.value }}</mat-label>
            <input
              matInput
              [(ngModel)]="parameters.welcomeText"
            />
          </mat-form-field>
          <div>
            <button
              *ngIf="canShowCopyBtn()"
              mat-stroked-button
              color="primary"
              class="full-width"
              (click)="routeToClippboard();"
            >
              {{ translations.copyRoute.value }}
            </button>
          </div>
        </ng-container>
      </div>
      <!-- <div class="aligned-actions">
        <button mat-button [mat-dialog-close]="{ saved: false }">Fechar</button>
        <button mat-flat-button color="primary" (click)="save()">Salvar</button>
      </div> -->
    </div>
    <div class="main">
      <div class="content">
        <mat-tab-group style="height:100%;" preserveContent class="boxed-tabs boxed-dense tabs-wrap full-body-height content-full-height body-nice-scroll body-y-scroll-lock deployed-services-tab-body">
          <mat-tab label="Real Time Fusion">
            <app-ns-picker *ngIf="rtfParamsHandler" [handler]="rtfParamsHandler"></app-ns-picker>
            <app-ns-picker *ngIf="blocklistRTFHandler" [handler]="blocklistRTFHandler"></app-ns-picker>
            <app-ns-picker *ngIf="namedAccountHandler" [handler]="namedAccountHandler"></app-ns-picker>
            <app-ns-picker *ngIf="urlShakingHandsFusionHandler" [handler]="urlShakingHandsFusionHandler"></app-ns-picker>
            <app-ns-picker *ngIf="userFunctionPickerHandler" [handler]="userFunctionPickerHandler"></app-ns-picker>
          </mat-tab>

          <mat-tab *ngIf="isEmbedded()" label="Configurações de WebChat">
            <div class="grid-2">
              <mat-checkbox [(ngModel)]="deployedService.parameters.embedded.allowAnonymousTransitionToBot" color="primary" class="boxed boxed-14">
                <span style="white-space: pre-line;">
                  Permitir Transição Anônima para o Bot
                </span>
              </mat-checkbox>
              <mat-checkbox [(ngModel)]="trustBrowserFingertipOnUserIdentity" color="primary" class="boxed boxed-14">
                <span style="white-space: pre-line;">
                  Confiar no id do browser para a identidade do usuário.
                </span>
              </mat-checkbox>
            </div>

            <div class="grid-2 boxed" style="padding:0 24px 12px;">
              <div style="width: 100%;">
                <h5>Metadado de Inicialização da SDK</h5>
                <app-ns-picker
                  *ngIf="canShowSDKMetadata()"
                  style="display: inline-block;"
                  [handler]="nsSelectSDKMedatadataHandler"
                ></app-ns-picker>
              </div>
            </div>

            <mat-divider></mat-divider>

            <div>
              <h5 class="sub-title">Configurações do formuário do cliente</h5>
              <app-ns-picker
                *ngIf="canShowInitialMetadata()"
                style="display: inline-block;"
                [handler]="nsSelectInitialMetadataHandler"
              ></app-ns-picker>

              <app-dashboard-service-pack-metadata
                [allowedEngagementTypes]="allowedEngagementTypes"
                style="margin: 0 2px 0;"
                [formsNames]="metadataFormsNames"
                [nsVariables]="costumerEngagementVariables"
                [addedForms]="customerFormularyMetadataRegister"
                [disabled]="true"
                [hideEditButtons]="true"
                [dontUseDefaultTag]="true"
                [hideTitle]="true"
              ></app-dashboard-service-pack-metadata>
            </div>

            <mat-divider></mat-divider>

            <div class="grid-2 boxed">
              <div>
                <h5>Grupo de container para conversações</h5>
                <app-participant-selector
                  *ngIf="groupContainerPS"
                  [participantSelectorHandler]="groupContainerPS"
                  [hideWindowBar]="true"
                >
                </app-participant-selector>
              </div>
              <div>
                <h5>Selecione o avatar do bot</h5>
                <app-participant-selector
                  *ngIf="botAvatarSelector"
                  [participantSelectorHandler]="botAvatarSelector"
                  [hideWindowBar]="true"></app-participant-selector>
              </div>
            </div>

            <mat-divider></mat-divider>

            <div>
              <h5 class="sub-title">Domínios autorizados</h5>
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Domínios</mat-label>
                <mat-chip-grid #chipList aria-label="Domínios">
                  <mat-chip-row *ngFor="let domain of deployedService.parameters.embedded.allowedDomains"
                    removable (removed)="removeAllowedDomain(domain)">
                    {{domain}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip-row>
                  <input placeholder="Adicionar domínio..."
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="allowedDomainsSeparatorKeysCodes"
                        (matChipInputTokenEnd)="addAllowedDomain($event)">
                </mat-chip-grid>
                <mat-hint>Pressione Enter para adicionar</mat-hint>
              </mat-form-field>
            </div>

            <mat-divider></mat-divider>

            <h5 class="sub-title">Mensagens de boas vindas e mensagens de initialização</h5>
            <div class="init-messages-container">
              <asset-adder style="display: block;" class="mb-12" *ngIf="welcomeMessagePhrasesHandler" [handler]="welcomeMessagePhrasesHandler"></asset-adder>
              <asset-adder style="display: block;" class="mb-12" *ngIf="startButtonsHandler" [handler]="startButtonsHandler"></asset-adder>
            </div>

            <mat-divider></mat-divider>

            <h5 class="sub-title">Códigos para implementação</h5>

            <mat-tab-group preserveContent [selectedIndex]="1">
              <mat-tab label="Versão 1">
                <button mat-stroked-button color="primary" class="button-w-icon legacy-copy-button" [copyToClipboard]="embeddedText">
                  <mat-icon>content_copy</mat-icon>&nbsp;
                  Copiar
                </button>
                <app-code-editor readonly language="html" [content]="embeddedText"></app-code-editor>
              </mat-tab>
              <mat-tab label="Versão 2">
                <div class="grid-2">
                  <div style="padding: 12px 8px;">
                    <app-single-content-asset-editor style="display: block;" class="mb-12" *ngIf="widgetTitleHandler" [handler]="widgetTitleHandler"></app-single-content-asset-editor>
                    <asset-adder style="display: block;" class="mb-12" *ngIf="widgetDescriptionHandler" [handler]="widgetDescriptionHandler"></asset-adder>
                    <mat-form-field style="width: 220px;">
                      <mat-label>Posição</mat-label>
                      <mat-select [(ngModel)]="selectedPosition">
                        <mat-select-trigger>
                          <span *ngIf="selectedPosition" class="position-square" [ngClass]="selectedPosition">
                          </span>
                          {{ webChatPositions[selectedPosition] || ''}}
                        </mat-select-trigger>

                        <mat-option *ngFor="let pos of webChatPositions | keyvalue" [value]="pos.key">
                          <span class="position-square" [ngClass]="pos.key"></span>
                          {{ pos.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field><br>
                    <div class="bottom-buttons">
                      <button mat-stroked-button color="primary" class="button-w-icon" [copyToClipboard]="widgetsCode">
                        <mat-icon>content_copy</mat-icon>&nbsp;
                        Copiar
                      </button>

                      <button class="oppen-color-builder-dialog"
                        mat-stroked-button color="primary"
                        (click)="openColorBuilderDialog()"
                        >
                        <mat-icon>add</mat-icon>&nbsp;
                        Editor de paleta de cores
                      </button>
                    </div>
                  </div>

                  <div>
                    <mat-button-toggle-group class="dense accent-active" [(ngModel)]="initWidgetAPI" aria-label="Api de inicialização" style="margin-bottom: 8px;">
                      <mat-button-toggle value="js">Javascript</mat-button-toggle>
                      <mat-button-toggle value="html">HTML</mat-button-toggle>
                    </mat-button-toggle-group>
                    <app-code-editor readonly language="html" [content]="widgetsCode"></app-code-editor>
                  </div>
                </div>
              </mat-tab>

            </mat-tab-group>
          </mat-tab>


          <mat-tab label="Mensagens">
            <div class="deploy-msg">
              <h5>Não foi possível resetar</h5>
              <app-single-content-asset-editor
                *ngIf="messageHandlers.unableToResetSessionMessage"
                [handler]="messageHandlers.unableToResetSessionMessage"
              ></app-single-content-asset-editor>
            </div>

            <div class="deploy-msg">
              <h5>
                Mensagem de transcrição
              </h5>
              <app-single-content-asset-editor
                  *ngIf="messageHandlers.transcriptionMessage"
                  [handler]="messageHandlers.transcriptionMessage"
              ></app-single-content-asset-editor>
            </div>

            <div class="deploy-msg">
              <h5>
                Mensagem de falha de transcrição
              </h5>
              <app-single-content-asset-editor
                *ngIf="messageHandlers.failToTranscriptMessage"
                [handler]="messageHandlers.failToTranscriptMessage"
              ></app-single-content-asset-editor>
            </div>

            <div class="deploy-msg">
              <h5>Mensagem de reset</h5>
              <app-single-content-asset-editor
                *ngIf="messageHandlers.resetMessage"
                [handler]="messageHandlers.resetMessage"
              ></app-single-content-asset-editor>
            </div>

            <div class="deploy-msg">
              <h5>Última requisição ainda não processada</h5>
              <app-single-content-asset-editor
                *ngIf="messageHandlers.lastRequestStillUnprocessed"
                [handler]="messageHandlers.lastRequestStillUnprocessed"
              ></app-single-content-asset-editor>
            </div>
            <div class="deploy-msg">
              <h5>Mensagem de expiração</h5>
              <app-single-content-asset-editor
                *ngIf="messageHandlers.expirationMessage"
                [handler]="messageHandlers.expirationMessage"
              ></app-single-content-asset-editor>
            </div>

            <div class="deploy-msg">
              <h5>Feedback durante alocação do cliente</h5>
              <app-single-content-asset-editor
                *ngIf="messageHandlers.feedbackDuringCustomerAllocation"
                [handler]="messageHandlers.feedbackDuringCustomerAllocation"
              ></app-single-content-asset-editor>
            </div>
          </mat-tab>

          <mat-tab label="Configurações Adicionais">
            <mat-checkbox
              style="padding-top: 24px;"
              color="primary"
              class="boxed boxed-14 wrap-label"
              [(ngModel)]="ignoreCampaignWhenReffered">
              Ignorar mensagem de campanha ao iniciar conversa via ads ou post
            </mat-checkbox>

            <div class="tracking-tag-picker-container">
              <mat-label>Tag de Trackeamento</mat-label>
              <app-non-serializable-taggable *ngIf="trackingTagPickerHandler" [tagPickerHandler]="trackingTagPickerHandler"></app-non-serializable-taggable>
            </div>
          </mat-tab>
        </mat-tab-group>
<!--


        <mat-card>
          <h4>Real Time Fusion</h4>
          <app-ns-picker *ngIf="rtfParamsHandler" [handler]="rtfParamsHandler"></app-ns-picker>
          <app-ns-picker *ngIf="blocklistRTFHandler" [handler]="blocklistRTFHandler"></app-ns-picker>
          <app-ns-picker *ngIf="namedAccountHandler" [handler]="namedAccountHandler"></app-ns-picker>
          <app-ns-picker *ngIf="urlShakingHandsFusionHandler" [handler]="urlShakingHandsFusionHandler"></app-ns-picker>
          <app-ns-picker *ngIf="userFunctionPickerHandler" [handler]="userFunctionPickerHandler"></app-ns-picker>

        </mat-card>
        <mat-card *ngIf="isEmbedded()">
          <h4>Configurações de Embedded</h4>

          <mat-checkbox [(ngModel)]="deployedService.parameters.embedded.allowAnonymousTransitionToBot" color="primary" class="boxed boxed-14">
            Permitir Transição Anônima para o Bot
          </mat-checkbox>
          <mat-checkbox [(ngModel)]="trustBrowserFingertipOnUserIdentity" color="primary" class="boxed boxed-14">
            Confiar no id do browser para a identidade do usuário.
          </mat-checkbox>

          <div>
            <p>Metadado de Inicialização da SDK</p>
            <app-ns-picker
              *ngIf="canShowSDKMetadata()"
              [handler]="nsSelectSDKMedatadataHandler"
            ></app-ns-picker>
          </div>

          <div>
            <p>{{ translations.initialMetadataId.value }}</p>
            <app-ns-picker
              *ngIf="canShowInitialMetadata()"
              [handler]="nsSelectInitialMetadataHandler"
            ></app-ns-picker>

            <app-alert *ngIf="costumerFormularyMetadataNS && costumerFormularyWithoutCanonicalName" type="warning">
              <span>O metadado precisa ter um campo com canônico do tipo: <strong>Nome</strong>.</span>
            </app-alert>
          </div>

          <div>
            <app-dashboard-service-pack-metadata
              [allowedEngagementTypes]="allowedEngagementTypes"
              style="margin: 32px 0;"
              [formsNames]="metadataFormsNames"
              [nsVariables]="costumerEngagementVariables"
              [addedForms]="customerFormularyMetadataRegister"
              [disabled]="true"
              [dontUseDefaultTag]="true"
            ></app-dashboard-service-pack-metadata>
          </div>

          <div>
            <h5>Grupo de container para conversações</h5>
            <app-participant-selector
              *ngIf="groupContainerPS"
              [participantSelectorHandler]="groupContainerPS"
              [hideWindowBar]="true"
            >
            </app-participant-selector>
          </div>
          <div>
            <h5>Domínios autorizados</h5>
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>Domínios</mat-label>
              <mat-chip-grid #chipList aria-label="Domínios">
                <mat-chip-row *ngFor="let domain of deployedService.parameters.embedded.allowedDomains"
                  removable (removed)="removeAllowedDomain(domain)">
                  {{domain}}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip-row>
                <input placeholder="Adicionar domínio..."
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="allowedDomainsSeparatorKeysCodes"
                      (matChipInputTokenEnd)="addAllowedDomain($event)">
              </mat-chip-grid>
              <mat-hint>Pressione Enter para adicionar</mat-hint>
            </mat-form-field>
          </div>
          <div>
            <h5>Selecione o avatar do bot</h5>
            <app-participant-selector
              *ngIf="botAvatarSelector"
              [participantSelectorHandler]="botAvatarSelector"
              [hideWindowBar]="true"></app-participant-selector>
          </div>

        </mat-card>

        <mat-card *ngIf="canShowEmbeddedCode">
          <h4 class="embed-title">
            Código embedded
            <button mat-stroked-button color="primary" class="button-w-icon" (click)="onCopyEmbeddedText()">
              <mat-icon>content_copy</mat-icon>
              Copiar
            </button>
          </h4>
          <app-code-editor readonly language="html" [content]="embeddedText"></app-code-editor>
        </mat-card>

        <mat-card>
          <h4>Mensagens</h4>

          <div class="deploy-msg">
            <h5>Não foi possível resetar</h5>
            <app-single-content-asset-editor
              *ngIf="messageHandlers.unableToResetSessionMessage"
              [handler]="messageHandlers.unableToResetSessionMessage"
            ></app-single-content-asset-editor>
          </div>

          <div class="deploy-msg">
            <h5>
              Mensagem de transcrição
            </h5>
            <app-single-content-asset-editor
                *ngIf="messageHandlers.transcriptionMessage"
                [handler]="messageHandlers.transcriptionMessage"
            ></app-single-content-asset-editor>
          </div>

          <div class="deploy-msg">
            <h5>
              Mensagem de falha de transcrição
            </h5>
            <app-single-content-asset-editor
              *ngIf="messageHandlers.failToTranscriptMessage"
              [handler]="messageHandlers.failToTranscriptMessage"
            ></app-single-content-asset-editor>
          </div>

          <div class="deploy-msg">
            <h5>Mensagem de reset</h5>
            <app-single-content-asset-editor
              *ngIf="messageHandlers.resetMessage"
              [handler]="messageHandlers.resetMessage"
            ></app-single-content-asset-editor>
          </div>

          <div class="deploy-msg">
            <h5>Última requisição ainda não processada</h5>
            <app-single-content-asset-editor
              *ngIf="messageHandlers.lastRequestStillUnprocessed"
              [handler]="messageHandlers.lastRequestStillUnprocessed"
            ></app-single-content-asset-editor>
          </div>
          <div class="deploy-msg">
            <h5>Mensagem de expiração</h5>
            <app-single-content-asset-editor
              *ngIf="messageHandlers.expirationMessage"
              [handler]="messageHandlers.expirationMessage"
            ></app-single-content-asset-editor>
          </div>

        </mat-card> -->
      </div>
    </div>
  </div>
</cm-generic-dashboard-edit>
