import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {ToolSection} from "../../../model/tools.model";
import {HandlerHexagonon} from "../../../handlers/hexagono.handler";
import { environment } from 'environments/environment-client';
import { constant } from '@colmeia/core/src/business/constant';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-tools-content-visualization',
	templateUrl: './tools-content-visualization.component.html',
	styleUrls: ['./tools-content-visualization.component.scss']
})
export class ToolsContentVisualizationComponent {

    @Input() section: ToolSection;

    private _sortedHexagons: HandlerHexagonon[] | null;

    get hexagons(): HandlerHexagonon[] | null {
        if (!this.section?.hexagons) {
            return null;
        }
        if (!this._sortedHexagons || this._sortedHexagons !== this.section.hexagons) {
            this._sortedHexagons = this.sortHexagons();
        }
        return this._sortedHexagons;
    }

    sortHexagons(): HandlerHexagonon[] {
        return [...this.section.hexagons].sort((a, b) => {
            const nameA = a.getEditingSerializable()?.getName() || '';
            const nameB = b.getEditingSerializable()?.getName() || '';
            return nameA.localeCompare(nameB);
        });
    }

    public showHexagon(hexagon: HandlerHexagonon): boolean {
        return hexagon.getEditingSerializable().getPrimaryID() !== constant.interactionType.annotation.group.archive || environment.allDevFeatures;
    }

    onItemClicked(event: PointerEvent, section: ToolSection, hexagon: HandlerHexagonon) {
        event.preventDefault();

        section.onItemClick(hexagon);
    }

    getItemURL(section: ToolSection, hexagon: HandlerHexagonon) {
        if (section.getItemURL === undefined) {
            return null;
        }

        return section.getItemURL(hexagon);
    }
}
