import { BBCodeDelivery360ActionParsers, BBCodeStyleTags, EBBCodeStyles, TMarkupGenerator, IMarkupGeneratorOptions } from "../bbcode-types";
import { keys, isValidRef, isInvalid } from "../../../tools/utility";
import { parsersDefinitions } from "../bbcode-constants";
import { EDelivery360Action } from "../../../comm-interfaces/interaction-interfaces";
import { bbcodeHTMLParserDefinitions, createParser, emptyMarkupGenerator, markupNOOP } from "./bbcode-functions-simple";
import { BBCodeParser } from "@affilicon/bbcode-parser";

export function initParsers(): BBCodeDelivery360ActionParsers {
    const parsers: BBCodeDelivery360ActionParsers = {} as BBCodeDelivery360ActionParsers;
    keys(parsersDefinitions).forEach((delivery360Action: EDelivery360Action) => {
        const definition = parsersDefinitions[delivery360Action];
        if (isInvalid(definition)) return;
        parsers[delivery360Action] = createParser(definition.tags);
    });
    return parsers;
}


export function initHTMLParser(): BBCodeParser {
    return createParser<EBBCodeStyles>(bbcodeHTMLParserDefinitions);
}
export const noopParser: BBCodeParser = createParser<EBBCodeStyles>({
    [EBBCodeStyles.Italic]: markupNOOP,
    [EBBCodeStyles.Bold]: markupNOOP,
    [EBBCodeStyles.Strikethrough]: markupNOOP,
    [EBBCodeStyles.Code]: markupNOOP,
    [EBBCodeStyles.Monospace]: markupNOOP,
    [EBBCodeStyles.Menu]: markupNOOP,
    [EBBCodeStyles.Link]: markupNOOP,
    [EBBCodeStyles.Heading1]: markupNOOP,
    [EBBCodeStyles.Heading2]: markupNOOP,
    [EBBCodeStyles.Heading3]: markupNOOP,
    [EBBCodeStyles.Heading4]: markupNOOP,
    [EBBCodeStyles.Heading5]: markupNOOP,
    [EBBCodeStyles.Heading6]: markupNOOP,
    [EBBCodeStyles.UnorderedList]: markupNOOP,
    [EBBCodeStyles.OrderedList]: markupNOOP,
    [EBBCodeStyles.ListItem]: markupNOOP,
    [EBBCodeStyles.Base64Image]: markupNOOP,
    [EBBCodeStyles.Table]: markupNOOP,
    [EBBCodeStyles.TableRow]: markupNOOP,
    [EBBCodeStyles.TableHeadingCell]: markupNOOP,
    [EBBCodeStyles.TableContentCell]: markupNOOP,
});
export const emptyStylesParser: BBCodeParser = createParser<EBBCodeStyles>({
    [EBBCodeStyles.Italic]: emptyMarkupGenerator,
    [EBBCodeStyles.Bold]: emptyMarkupGenerator,
    [EBBCodeStyles.Strikethrough]: emptyMarkupGenerator,
    [EBBCodeStyles.Code]: emptyMarkupGenerator,
    [EBBCodeStyles.Monospace]: emptyMarkupGenerator,
    [EBBCodeStyles.Menu]: emptyMarkupGenerator,
    [EBBCodeStyles.Link]: emptyMarkupGenerator,
    [EBBCodeStyles.Heading1]: emptyMarkupGenerator,
    [EBBCodeStyles.Heading2]: emptyMarkupGenerator,
    [EBBCodeStyles.Heading3]: emptyMarkupGenerator,
    [EBBCodeStyles.Heading4]: emptyMarkupGenerator,
    [EBBCodeStyles.Heading5]: emptyMarkupGenerator,
    [EBBCodeStyles.Heading6]: emptyMarkupGenerator,
    [EBBCodeStyles.UnorderedList]: emptyMarkupGenerator,
    [EBBCodeStyles.OrderedList]: emptyMarkupGenerator,
    [EBBCodeStyles.ListItem]: emptyMarkupGenerator,
    [EBBCodeStyles.Base64Image]: emptyMarkupGenerator,
    [EBBCodeStyles.Table]: emptyMarkupGenerator,
    [EBBCodeStyles.TableRow]: emptyMarkupGenerator,
    [EBBCodeStyles.TableHeadingCell]: emptyMarkupGenerator,
    [EBBCodeStyles.TableContentCell]: emptyMarkupGenerator,
});
