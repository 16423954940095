import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IColmeiaDialogComponentData } from 'app/components/dialogs/dialog/dialog.component';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isValidArray, isValidRef } from '@colmeia/core/src/tools/barrel-tools';
import { IMLCLUIntent } from '@colmeia/core/src/shared-business-rules/knowledge-base/clu-core-interfaces';
import { LookupService } from 'app/services/lookup.service';
import { EBpmGraphElementInfoMode } from 'app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bpm-graph-element-info/bpm-graph-element-info.handler';

export interface SelectIntentModal {
    intents: IMLCLUIntent[];
    label: string;
    selectMode: boolean;
}

export interface SelectIntentModalReturn {
    intentId: string,
    mode: EBpmGraphElementInfoMode
}

@Component({
    selector: 'app-nested-ai-select-intent-modal',
    templateUrl: './nested-ai-select-intent-modal.component.html',
    styleUrls: ['./nested-ai-select-intent-modal.component.scss']
})
export class SelectIntentModalComponent implements OnInit {

    intents: IMLCLUIntent[];
    intentId: string;
    label: string;
    selectMode: boolean;
    selectedMode: EBpmGraphElementInfoMode = EBpmGraphElementInfoMode.Edit
    public EBpmGraphElementInfoMode = EBpmGraphElementInfoMode;

    constructor(
        @Inject(MAT_DIALOG_DATA) dataToComponent: IColmeiaDialogComponentData<SelectIntentModal>,
        private dialogRef: MatDialogRef<SelectIntentModalComponent>,
    ) {
        const { intents, label, selectMode } = dataToComponent.getParamsToChildComponent();
        this.intents = intents.filter(intent => intent.intentName.toLocaleLowerCase().trim() !== "none");
        this.label = label;
        this.selectMode = selectMode;
    }

    ngOnInit() { }

    cancel() {
        this.dialogRef.close({ intentId: undefined, mode: undefined });
    }

    save() {
        this.dialogRef.close({ intentId: this.intentId, mode: this.selectedMode });
    }
}
