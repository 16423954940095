import { MetaTranslation } from '@colmeia/core/src/shared-business-rules/const-text/meta-translation';
import { allBackupScreenOptionsTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/backup.translations';
import { allBPMMktTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/bpm-mkt-translations';
import { catalogTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/catalog-view';
import { coordinatorDeletionTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/coordinator-deletion.translations';
import { allUtteranceScreenOptionsTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/utterance.translations';
import { MetaFields } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';
import { Serializable } from "../../business/serializable";
import { Hours } from "../../time/time-utl";
import { genericTypedSuggestions } from "../../tools/type-utils";
import { values } from "../../tools/utility";
import { ITranslationConfig, ITranslationConfigWithMeta, ITranslationHash } from "../translation/translation-engine";
import { clientErrorTranslations } from "./errors/client-errors";
import { generalErrors } from "./errors/general-errors";
import { errorTranslations } from "./errors/server-errors";
import { activeAgentTranslations } from "./views/active-agent";
import { activeCallTranslations } from "./views/active-call";
import { activeSendsTranslations } from "./views/active-sends";
import { annotationsTranslations } from "./views/annotations";
import { apiTranslations } from "./views/api";
import { allAttendanceTranslations } from "./views/attendance";
import { allBotTranslations } from "./views/bot";
import { allNlpTranslatins } from "./views/nlp";
import { bpmGraphTranslations } from "./views/bpm-graph";
import { callcenterTranslations } from "./views/callcenter";
import { canonicalTranslations } from "./views/canonical";
import { allChainedBases } from "./views/chained-bases";
import { allChatTranslations } from "./views/chat";
import { cloudNaturalLanguageTranslations } from "./views/cloud-natural-language/syntax";
import { colmeiaProductsTranslations } from "./views/colmeia-products";
import { allConnections } from "./views/connections";
import { allCRMConfigTranslations } from "./views/crm/crm-config";
import { dashboardTranslations } from "./views/dashboard";
import { dataExtractorTranslations } from "./views/data-extractor";
import { deployedServiceTranslations } from "./views/deployed-service";
import { fileDetailTranslations } from "./views/file-detail";
import { integrationTranslations } from "./views/files-integration";
import { allFragmentsTranslations } from "./views/fragments";
import { generalFormTranslations } from "./views/general-form";
import { goToApplicationTranslations } from "./views/go-to-dashboard";
import { gpsTranslations } from "./views/gps-tracking";
import { groupTranslations } from "./views/group";
import { groupOrderSelectorModeTranslations } from "./views/group-order-selector-shortcut";
import { helpTipsTranslations } from "./views/help-tips";
import { jobTranslations } from "./views/job";
import { allKnowledgeBaseScreenOptionsTranslations } from "./views/knowledge-base";
import { macroTranslations } from "./views/macro";
import { metaEngagementTranslations } from "./views/meta-engagement";
import { metadataNamesOptions1, metadataNamesOptions2, metadataNamesTranslations } from "./views/metadata";
import { nserTranslations } from "./views/nonserializable";
import { searchViewTranslations } from "./views/search";
import { securityControlTranslations } from "./views/security";
import { sellableTranslations } from "./views/sellable";
import { sendCampaignTranslations } from "./views/send-campaign";
import { settingsTranslations } from "./views/settings";
import { shareTranslations } from "./views/share";
import { socialMediaTranslations, whatsAppTemplateTranslations } from "./views/social-media";
import { socialNetworkTranslations } from "./views/social-network";
import { tagTranslations } from "./views/tag";
import { templateTranslations } from "./views/templates";
import {
    allCommonScreenOptionsTranslations
} from "./views/txt-common";
import { visualElementsTranslations } from "./views/visual-elements";
import { workspaceReportsTranslations } from "./views/workspace-reports";
import { warningSVCScreenOptionsTransalation } from "./warning-svc";
import { contentVectorTranslations } from './views/vectors';
import { whatsappFlowsTranslations } from './views/whatsapp-flows';
import { paymentViewDataTranslations } from '../payments/payments.translations';


export const gTranslations = genericTypedSuggestions<Record<string, ITranslationHash>>()({
    dashboard: {
        ...dashboardTranslations,
        ...activeAgentTranslations,
        ...goToApplicationTranslations,
    },
    attendance: {
        ...allAttendanceTranslations
    },
    crm: {
        ...allCRMConfigTranslations
    },
    bpmMkt: {
        ...allBPMMktTranslations
    },
    activeCall: {
        ...activeCallTranslations,
    },
    activeSends: {
        ...activeSendsTranslations,
    },
    common: {
        ...allCommonScreenOptionsTranslations
    },
    catalog: {
        ...catalogTranslations,
    },
    bpm: {
        ...bpmGraphTranslations,
    },
    callcenter: {
        ...callcenterTranslations,
    },
    fragments: {
        ...allFragmentsTranslations
    },
    groupOrderSelectorShortcurt: {
        ...groupOrderSelectorModeTranslations
    },
    bot: {
        ...allBotTranslations
    },
    nlp: {
        ...allNlpTranslatins
    },
    canonicals: {
        ...canonicalTranslations,
        ...metadataNamesTranslations,
        // ...metadataNamesOptions1,
        // ...metadataNamesOptions2,
        // ...metadataNamesOptions2
    },
    chainedBases: {
        ...allChainedBases,
    },
    dataExtractor: {
        ...dataExtractorTranslations,
    },
    chat: {
        ...allChatTranslations
    },
    connections: {
        ...allConnections
    },
    search: {
        ...searchViewTranslations
    },
    deployedServices: {
        ...deployedServiceTranslations
    },
    visualElements: {
        ...visualElementsTranslations,
    },
    workspaceReports: {
        ...workspaceReportsTranslations
    },
    helpTips: {
        ...helpTipsTranslations
    },
    colmeiaProducts: {
        ...colmeiaProductsTranslations
    },
    files: {
        ...fileDetailTranslations,
        ...integrationTranslations
    },
    gps: {
        ...gpsTranslations
    },
    group: {
        ...groupTranslations
    },
    utterance: {
        ...allUtteranceScreenOptionsTranslations,
    },
    kb: {
        ...allKnowledgeBaseScreenOptionsTranslations
    },
    job: {
        ...jobTranslations,
    },
    macro: {
        ...macroTranslations
    },
    nser: {
        ...nserTranslations
    },
    security: {
        ...securityControlTranslations
    },
    sellable: {
        ...sellableTranslations
    },
    settings: {
        ...settingsTranslations
    },
    share: {
        ...shareTranslations
    },
    socialMedia: {
        ...socialMediaTranslations,
        ...whatsAppTemplateTranslations
    },
    template: {
        ...templateTranslations,
    },
    metaEngagement: {
        ...metaEngagementTranslations,
    },
    socialNetwork: {
        ...socialNetworkTranslations
    },
    tag: {
        ...tagTranslations
    },
    warning: {
        ...warningSVCScreenOptionsTransalation
    },
    sendCampaign: {
        ...sendCampaignTranslations
    },
    errors: {
        ...errorTranslations,
        ...clientErrorTranslations,
        ...generalErrors,
    },
    generalForm: {
        ...generalFormTranslations
    },
    annotations: {
        ...annotationsTranslations,
    },
    cloudNaturalLanguage: {
        ...cloudNaturalLanguageTranslations
    },
    api: {
        ...apiTranslations,
    },
    backup: {
        ...allBackupScreenOptionsTranslations,
    },
    coordinatorDeletion: {
        ...coordinatorDeletionTranslations,
    },
    contentVectors: {
        ...contentVectorTranslations,
    },
    whatsappFlows: {
        ...whatsappFlowsTranslations,
    },
    paymentViewData: {
        ...paymentViewDataTranslations
    }
} as const);



export function getGreetingByHours(hours: Hours): string {
    let translation: ITranslationConfig;
    if (hours > 18) {
        translation = gTranslations.common.goodEvening;

    } else if (hours > 12) {
        translation = gTranslations.common.goodAfternoon;

    } else {
        translation = gTranslations.common.goodMorning;

    }
    const text = Serializable.getTranslation(translation);

    if (!text) throw new Error('Greeting translation not found');

    return text;
}


// DOES NOT WORK!
// SEARCH PURPUSE ONLY!
// CAN OVERLAP SOME ITEMS!
// export const searchTranslations = undefined as UnionToIntersection<ValueOf<typeof gTranslations>>;
// 


export const metaTranslationsFields = getMetaFields();

export function getMetaFields(): Map<MetaFields, ITranslationConfigWithMeta> {
    return mapBy(values(gTranslations).map(values).flat().filter(MetaTranslation.isConfig), item => item.meta.text);
    function mapBy<T, E>(items: T[], fn: (item: T, index: number, items: T[]) => E) {
        const map = new Map<E, T>()
        items.forEach((item, index, items) => {
            map.set(fn(item, index, items), item)
        })
        return map;
    }
}

export function getTranslation(text: keyof typeof gTranslations) {
    return gTranslations[text];
}


