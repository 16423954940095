import { EBBCodeStyles } from "@colmeia/core/src/shared-business-rules/bbcode/bbcode-types";

/**
 * Types of Tiptap nodes that CAN'T be translated into BB Code
 */
export enum ETiptapNodeNoBBCodeTypes {
    Doc = 'doc',
    Paragraph = 'paragraph',
    Mention = 'mention',
    Text = 'text',
}

/**
 * Types of Tiptap nodes that CAN be translated into BB Code
 */
export enum ETiptapNodeHasBBCodeTypes {
    Heading = 'heading',
    BulletList = 'bulletList',
    OrderedList = 'orderedList',
    ListItem = 'listItem',
    Image = 'image',
    Table = 'table',
    TableRow = 'tableRow',
    TableHeader = 'tableHeader',
    TableCell = 'tableCell',
}

export type ETiptapNodeTypes = ETiptapNodeNoBBCodeTypes | ETiptapNodeHasBBCodeTypes;

/**
 * Can be applied to nodes, for example for inline formatting.
 */
export enum ETiptapMarkTypes {
    Link = 'link',
    Italic = 'italic',
    Bold = 'bold',
    Strike = 'strike',
    Code = 'code'
}

export type TTiptapNodesAndMarks = ETiptapNodeTypes | ETiptapMarkTypes;

interface IBBCodeTagInfo {
    tag: EBBCodeStyles;
    isParameterized?: boolean;
    tiptapAttrNameForParameter?: string;
}

export const tiptapTextStyleMarkToBBTag: Record<ETiptapNodeHasBBCodeTypes | ETiptapMarkTypes, IBBCodeTagInfo> = {
    [ETiptapMarkTypes.Italic]: {
        tag: EBBCodeStyles.Italic
    },
    [ETiptapMarkTypes.Bold]: {
        tag: EBBCodeStyles.Bold
    },
    [ETiptapMarkTypes.Strike]: {
        tag: EBBCodeStyles.Strikethrough
    },
    [ETiptapMarkTypes.Code]: {
        tag: EBBCodeStyles.Code
    },
    [ETiptapMarkTypes.Link]: {
        tag: EBBCodeStyles.Link,
        isParameterized: true,
        tiptapAttrNameForParameter: 'href'
    },
    [ETiptapNodeHasBBCodeTypes.Heading]: {
        tag: EBBCodeStyles.Heading1 // not used
    },
    [ETiptapNodeHasBBCodeTypes.BulletList]: {
        tag: EBBCodeStyles.UnorderedList
    },
    [ETiptapNodeHasBBCodeTypes.OrderedList]: {
        tag: EBBCodeStyles.OrderedList
    },
    [ETiptapNodeHasBBCodeTypes.ListItem]: {
        tag: EBBCodeStyles.ListItem
    },
    [ETiptapNodeHasBBCodeTypes.Image]: {
        tag: EBBCodeStyles.Base64Image
    },
    [ETiptapNodeHasBBCodeTypes.Table]: {
        tag: EBBCodeStyles.Table
    },
    [ETiptapNodeHasBBCodeTypes.TableRow]: {
        tag: EBBCodeStyles.TableRow
    },
    [ETiptapNodeHasBBCodeTypes.TableHeader]: {
        tag: EBBCodeStyles.TableHeadingCell
    },
    [ETiptapNodeHasBBCodeTypes.TableCell]: {
        tag: EBBCodeStyles.TableContentCell
    },
} as const;

/**
 * HTML elements that are considered node blocks in tiptap, they will not be wrapped in <p> tags
 * when parsed, but every other HTML element will be
 */
export const tipTapBlockHTMLElements = ['P', 'IMG', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'UL', 'OL', 'LI', 'TABLE', 'TR', 'TD', 'TH', 'TBODY', 'THEAD'];
