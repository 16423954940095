import { TGlobalUID } from "../../core-constants/types";
import { getIdentifiedNS, initNserClient } from "../../nser/blank-ns";
import { ENonSerializableObjectType, INonSerializableIdentification } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import { IContactListItemDetail, IContactListItemServer, IContactListServer } from "./contact-list.model";

export function factoryContactListNser(idSocialContext: TGlobalUID, idAvatar: TGlobalUID): IContactListServer {
    const ident: INonSerializableIdentification = {
        genealogy: [idSocialContext], idAvatar, idGroup: idSocialContext,
    };

    return {
        ...initNserClient(ENonSerializableObjectType.contactList, "", { ident })
    }
}

export function factoryContactListItemNser(
    idSocialContext: TGlobalUID,
    idAvatar: TGlobalUID,
    idParent: IdDep<ENonSerializableObjectType.contactList>,
    contactDetail: IContactListItemDetail,
): IContactListItemServer {
    const ident: INonSerializableIdentification = {
        genealogy: [idParent, idSocialContext], idAvatar, idGroup: idSocialContext,
    };

    return {
        idParent,
        ident,
        ...initNserClient(ENonSerializableObjectType.contactListItem, "", { contactDetail })
    }
}
