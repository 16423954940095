import { booleanAttribute, Component, HostListener, Input, OnChanges, OnInit } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { ICRMTicketDataWithCustomerName } from "@colmeia/core/src/shared-business-rules/crm/crm-entities";
import { Reactify } from "app/reactify/reactify";
import { CopyToClipboardService } from "app/services/copy-to-clipboard.service";
import { CRMTicketsService } from "../crm-tickets.service";


@Reactify.This()
@Component({
    selector: 'app-crm-ticket-protocol-view',
    styleUrl: './crm-ticket-protocol-view.component.scss',
    templateUrl: './crm-ticket-protocol-view.component.html',
    providers: [MatTooltip],
})
export class CRMTicketProtocolComponent implements OnInit, OnChanges {
    
    @Input({ transform: booleanAttribute })
    public raw: boolean = false;

    @Input()
    public ticketData: ICRMTicketDataWithCustomerName;
    
    @Input()
    public id_ticket: string;

    public protocol: string;

    @Input()
    public size: 'small' | 'medium' | 'large' = 'medium'

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        event.stopPropagation();
        this.clipBoardSvc.copy(this.protocol);
    }

    @HostListener('mouseover') mouseover() {
        this.tooltip.message = 'Clique para copiar';
        this.tooltip.show();
    }

    @HostListener('mouseleave') mouseleave() {
        this.tooltip.hide();
    }

    constructor(
        private ticketSvc: CRMTicketsService,
        private clipBoardSvc: CopyToClipboardService,
        private tooltip: MatTooltip,
    ) { }

    ngOnInit(): void {
        this.generateProtocol();

    }

    ngOnChanges(): void {
        this.generateProtocol();
    }

    private generateProtocol() {
        if (this.id_ticket) {
            this.ticketData = this.ticketSvc.getTicket(this.id_ticket);
        }

        this.protocol = `${this.ticketData?.projectKey}-${this.ticketData?.protocol}`;
    }
}