import { initNserClient } from "@colmeia/core/src/nser/blank-ns";
import { EPersona, IAgentCollaborator } from "@colmeia/core/src/shared-business-rules/agent-supremacy/supremacy-agent-model";
import { AzureCLUCore } from "@colmeia/core/src/shared-business-rules/knowledge-base/clu/azure-clu.core.interfaces";
import { MapOSStrategy, mapOSStrategy } from "@colmeia/core/src/shared-business-rules/knowledge-base/kb-processor";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { getUsedStrategies } from "@colmeia/core/src/shared-business-rules/social-cc/omini-sense-utils";
import { RemountInterface } from "@colmeia/core/src/tools/utility-types";
import { TArrayID } from "../../core-constants/types";
import { getReadableUniqueID, isInvalidArray, isValidRef } from "../../tools/utility";
import { IBasicAuthInformation, MapConnectionAuthInformation } from "../connections/connections-auth-model";
import { EConnectionType, ICLUConnectionServer, IConnectionServer } from "../connections/endpoint-model";
import { EFSLearningKind, EOmniSenseMethods, ExternalId, IAdapativeInContextLearningIntent, IAzureCLUAppConfig, IBruteForceConfig, IKBAppConfig, IMLCLUApp, IMLCLUIntent, INSKBCorporateEntityServer, IntentId, IOminiSenseIAConfig, IQAFewShortLearningIntent, KBCorporateEntity, TIBindAgentArray } from "./clu-core-interfaces";
import { CLU_NONE_INTENT, IKnowledgeDBServer } from "./kb-inferfaces";


export function initKBAppConfig(): IKBAppConfig {
    return {
        NormalizeDiacritics: true,
        NormalizePunctuation: true,
    };
}

export function initCLUAppConfig(): IAzureCLUAppConfig {
    return {
        language: AzureCLUCore.Language.PortugueseBR,
        projectKind: AzureCLUCore.ProjectKind.Conversation,
        settings: {
            confidenceThreshold: 0,
        },
        multilingual: true,
    };
}


interface IInitMLCLUApp {
    appId?: string;
    appName?: string;
    startVersion?: string;
}
export function initMLCLUApp({ appId, appName, startVersion = '0.1' }: IInitMLCLUApp = {}): IMLCLUApp {
    return {
        appName: appName || '',
        appId,
        corporateEntities: [],
        lastVersionId: startVersion,
        appConfig: initKBAppConfig(),
        intents: [
            initKBAppIntent(CLU_NONE_INTENT, CLU_NONE_INTENT),
        ],
        phrases: [],
        entities: [],
        patterns: [],
        osStrategy: {
            strategy: [],
        },
        intentionChangeConfig: {
            strategy: [],
            enabled: false,
            accuracyThreshold: 0.7
        },
        clu: {
            config: initCLUAppConfig(),
        }
    };
}

export function initKBAppIntent(intentName: string, intentId: IntentId = getReadableUniqueID()): IMLCLUIntent {
    return {
        intentName,
        utterancesCount: 0,
        intentId,
    }
}

export function shouldRequireCLU(kb: IKnowledgeDBServer) {
    const usedStrategies = getUsedStrategies(kb);
    return usedStrategies.includes(EOmniSenseMethods.machineLearning);
}

export function isConnectionAuthOfType<Type extends EConnectionType>(type: Type, auth: IBasicAuthInformation | undefined): auth is MapConnectionAuthInformation[Type] {
    return isValidRef(auth) && (auth.type === type);
}


export function isCLUConnection(connection: IConnectionServer | undefined): connection is ICLUConnectionServer {
    if (!connection) return false;
    return isConnectionAuthOfType(EConnectionType.CLU, connection.auth);
}


export function getStrategyWeight(osStrategy: IOminiSenseIAConfig | undefined, map?: MapOSStrategy) {
    if (isInvalidArray(osStrategy?.strategy)) return getDefaultValue;

    map ??= mapOSStrategy(osStrategy);

    return function execute(method: EOmniSenseMethods) {
        const weight = map!.get(method)?.weight ?? 0;
        return weight;
    }
}

function getDefaultValue() {
    return 1;
}


/**
 * From https://stackoverflow.com/questions/68837178/validate-uuid-without-regexp
 */
function isHEX(char: string) {
    return "0123456789abcdef".includes(char.toLowerCase());
}

/**
 * From https://stackoverflow.com/questions/68837178/validate-uuid-without-regexp
 */
function isUUID(guid: string): guid is ExternalId {
    guid = guid.replace(/-/g, ""); // Format it first!
    return guid.length === 32 && [...guid].every(isHEX);
}

export function migrateIntent(intent: IMLCLUIntent, ns: IKnowledgeDBServer) {
    if (isUUID(intent.intentId) && ns.idConnection) {
        intent.externalId ??= intent.intentId;
    }
}



export function migrateKBEssential(kb: IKnowledgeDBServer) {
    kb.app.clu ??= {
        config: initCLUAppConfig(),
    };
    kb.app.corporateEntities ??= [];
}

export function migrateKB(kb: IKnowledgeDBServer) {
    kb.app.intents.forEach(intent => migrateIntent(intent, kb));
    migrateKBEssential(kb);
    return kb;
}

export function initKBCorporateEntity(): RemountInterface<KBCorporateEntity.Interface> {
    return {
        list: {
            sublists: [],
        },
        regex: {
            expressions: [],
        },
    }
}
export function initKBCorporateEntityClientNS(): INSKBCorporateEntityServer {
    return initNserClient(ENonSerializableObjectType.kbCorporateEntity, '', {
        entity: initKBCorporateEntity(),
    });
}




export function initBruteForceStrategyConfig(): IBruteForceConfig {
    return {
        comparedMatchWeight: 1,
        minScoreToBeAMatch: 0.5,
        scoreComparedMatches: false,
    }
}

export function initQAFewShortLearningIntent(): IQAFewShortLearningIntent {
    return {
        /**
         * IToggleable
         */
        isOn: false,
        /**
         * ISuperFewShotIntentConfig
         */
        description: '',
        summary: '',
        featureEngineeredSubPrompt: {
            isDemanded: false,
            generated: {
                summarization: '',
                rewrite: []
            }
        },
        /**
         * IQAFewShortLearningIntent
         */
        kind: EFSLearningKind.qa,
        examples: {
            examples: [],
        },
        negativeSamples: {
            negative: [],
        },
    }
}

export function initAdapativeInContextLearningIntent(): IAdapativeInContextLearningIntent {
    return {
        /**
         * IToggleable
         */
        isOn: false,
        /**
         * ISuperFewShotIntentConfig
         */
        description: '',
        summary: '',
        featureEngineeredSubPrompt: {
            isDemanded: false,
            generated: {
                summarization: '',
                rewrite: []
            }
        },
        /**
         * IAdapativeInContextLearningIntent
         */
        kind: EFSLearningKind.labeled,
        examples: {
            examples: [],
        },
    }
}

export function initNewAIAgent(): IAgentCollaborator {
    return {
        //@ts-ignore
        kind: undefined,
        idConnection: '',
        personaType: EPersona.assistent,
        persona: '',
        idempotent: false,
        //@ts-ignore
        transformations: [],
        canonicals: [],
        chainedPromptBuilder: [],
        ruleBasedActionConfig: [],
    }
}

export function getAgentsIdsArray(agentsBind: TIBindAgentArray): TArrayID {
    return agentsBind.map( a => a.idSupremacyAgent )
} 
