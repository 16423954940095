import { EDelivery360Action } from "../../comm-interfaces/interaction-interfaces";
import { BBCodeParser, BBTag } from "@affilicon/bbcode-parser";


/**
 * All BB Code tags
 */
export enum EBBCodeStyles {
    Italic = 'i',
    Bold = 'b',
    Strikethrough = 's',
    Code = 'c',
    Monospace = 'm',
    Menu = 'menu',
    Link = 'url',
    Heading1 = 'h1',
    Heading2 = 'h2',
    Heading3 = 'h3',
    Heading4 = 'h4',
    Heading5 = 'h5',
    Heading6 = 'h6',
    UnorderedList = 'ul',
    OrderedList = 'ol',
    ListItem = 'li',
    Base64Image = 'base64img',
    Table = 'table',
    TableRow = 'tr',
    TableHeadingCell = 'th',
    TableContentCell = 'td',
}

/**
 * BB Code tags for styling text
 */
export type TBBCodeStyleTags =
    | EBBCodeStyles.Italic
    | EBBCodeStyles.Bold
    | EBBCodeStyles.Strikethrough
    | EBBCodeStyles.Code
    | EBBCodeStyles.Monospace;

export enum EBBCodeSubStyles {
    MenuItem = 'item',
}

export enum EBBCodeMenuMode {
    Enhanced = 'enhanced'
}

export type BBCodeStyleTags = { [style in EBBCodeStyles]: TMarkupGenerator; };


export interface BBCodeDelivery360ActionParsersDefinitionItem {
    interceptor?: (text: string) => string;
    tags: BBCodeStyleTags;
};

export type BBCodeDelivery360ActionParsersDefinitions = {
    [key in EDelivery360Action]: BBCodeDelivery360ActionParsersDefinitionItem; // { interceptor: (text: string) => string; tags: BBCodeStyleTags };
};
export type BBCodeDelivery360ActionParsers = {
    [key in EDelivery360Action]: BBCodeParser;
};

export interface IMarkupGeneratorOptions {
    tag: BBTag;
    content: string;
    attrs: string[];
}

export type TMarkupGenerator = (options: IMarkupGeneratorOptions) => string;

export interface BBCodeTagExample {
    opening: string;
    close: string;
}

export interface BBCodeTagConfig {
    showOnClientEditor?: true;
}

export type BBCodeTagsDB = {
    [key in EBBCodeStyles]: BBCodeTagConfig;
}
