import { typedScreenOptions } from "../../../tools/utility";
import { getTranslationsFields, EAllSerializable } from "../all-serializables";
import { typedFields } from "../../../tools/utility";
import { EWAFComponents, EWAFFontWeight, EWAFStatus, ImageScaleType, TWAFTextStylesClient, WAFInputType } from "../../waf/waf.types";

type TEditableComponentTypes = EWAFComponents | 'header' | 'footer';

export const flowsScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.WhatsappFlowsTranslations,
    fields: typedFields<TEditableComponentTypes>()({
        [EWAFComponents.TEXT_HEADING]: 1,
        [EWAFComponents.TEXT_SUBHEADING]: 2,
        [EWAFComponents.TEXT_BODY]: 3,
        [EWAFComponents.TEXT_CAPTION]: 4,
        [EWAFComponents.TEXT_INPUT]: 5,
        [EWAFComponents.TEXT_AREA]: 6,
        [EWAFComponents.CHECKBOX_GROUP]: 7,
        [EWAFComponents.RADIO_BUTTONS_GROUP]: 8,
        [EWAFComponents.OPT_IN]: 9,
        [EWAFComponents.DROPDOWN]: 10,
        [EWAFComponents.DATE_PICKER]: 11,
        [EWAFComponents.EMBEDDED_LINK]: 12,
        [EWAFComponents.IMAGE]: 13,
        [EWAFComponents.FORM]: 14,
        [EWAFComponents.FOOTER]: 15,
        ['header']: 16,
        ['footer']: 17,
        [EWAFComponents.RICH_TEXT]: 38,
    })
} as const);

export const flowsInputTypes = typedScreenOptions({
    idSerializable: EAllSerializable.WhatsappFlowsTranslations,
    fields: typedFields<WAFInputType>()({
        [WAFInputType.TEXT]: 18,
        [WAFInputType.NUMBER]: 19,
        [WAFInputType.EMAIL]: 20,
        [WAFInputType.PASSWORD]: 21,
        [WAFInputType.PASSCODE]: 22,
        [WAFInputType.PHONE]: 23,
    })
} as const);

export const flowsImageScaleTypes = typedScreenOptions({
    idSerializable: EAllSerializable.WhatsappFlowsTranslations,
    fields: typedFields<ImageScaleType>()({
        [ImageScaleType.CONTAIN]: 24,
        [ImageScaleType.COVER]: 25,
    })
} as const);

export const flowsTextFontTypes = typedScreenOptions({
    idSerializable: EAllSerializable.WhatsappFlowsTranslations,
    fields: typedFields<TWAFTextStylesClient>()({
        [EWAFFontWeight.ITALIC]: 26,
        [EWAFFontWeight.BOLD]: 27,
        ['strikethrough']: 28
    })
} as const);

export const flowsStatusTypes = typedScreenOptions({
    idSerializable: EAllSerializable.WhatsappFlowsTranslations,
    fields: typedFields<EWAFStatus>()({
        [EWAFStatus.UNREGISTERED]: 30,
        [EWAFStatus.DRAFT]: 31,
        [EWAFStatus.PUBLISHED]: 32,
        [EWAFStatus.DEPRECATED]: 33,
        [EWAFStatus.ERROR]: 34,
        [EWAFStatus.BLOCKED]: 35,
        [EWAFStatus.THROTTLED]: 36,
        [EWAFStatus.DELETED]: 37,
    })
} as const);

export const flowsComponentsTypeIconURL: { [key in TEditableComponentTypes]: string } = {
    [EWAFComponents.TEXT_HEADING]: 'assets/images/whatsapp-flows/icons/text-heading.png',
    [EWAFComponents.TEXT_SUBHEADING]: 'assets/images/whatsapp-flows/icons/text-subheading.png',
    [EWAFComponents.TEXT_BODY]: 'assets/images/whatsapp-flows/icons/text-body.png',
    [EWAFComponents.TEXT_CAPTION]: 'assets/images/whatsapp-flows/icons/text-caption.png',
    [EWAFComponents.TEXT_INPUT]: 'assets/images/whatsapp-flows/icons/text-input.png',
    [EWAFComponents.TEXT_AREA]: 'assets/images/whatsapp-flows/icons/text-area.png',
    [EWAFComponents.CHECKBOX_GROUP]: 'assets/images/whatsapp-flows/icons/checkbox.png',
    [EWAFComponents.RADIO_BUTTONS_GROUP]: 'assets/images/whatsapp-flows/icons/radio.png',
    [EWAFComponents.OPT_IN]: 'assets/images/whatsapp-flows/icons/opt-in.png',
    [EWAFComponents.DROPDOWN]: 'assets/images/whatsapp-flows/icons/dropdown.png',
    [EWAFComponents.DATE_PICKER]: 'assets/images/whatsapp-flows/icons/datepicker.png',
    [EWAFComponents.EMBEDDED_LINK]: 'assets/images/whatsapp-flows/icons/generic.png',
    [EWAFComponents.IMAGE]: 'assets/images/whatsapp-flows/icons/image.png',
    [EWAFComponents.FORM]: 'assets/images/whatsapp-flows/icons/generic.png',
    [EWAFComponents.FOOTER]: 'assets/images/whatsapp-flows/icons/generic.png',
    ['header']: 'assets/images/whatsapp-flows/icons/header.png',
    ['footer']: 'assets/images/whatsapp-flows/icons/footer.png',
    [EWAFComponents.RICH_TEXT]: 'assets/images/whatsapp-flows/icons/rich-text.png',
}

export const flowsComponentsTypeExampleURL: { [key in TEditableComponentTypes]: string | null } = {
    [EWAFComponents.TEXT_HEADING]: 'assets/images/whatsapp-flows/examples/flow-texts.png',
    [EWAFComponents.TEXT_SUBHEADING]: 'assets/images/whatsapp-flows/examples/flow-texts.png',
    [EWAFComponents.TEXT_BODY]: 'assets/images/whatsapp-flows/examples/flow-texts.png',
    [EWAFComponents.TEXT_CAPTION]: 'assets/images/whatsapp-flows/examples/flow-texts.png',
    [EWAFComponents.TEXT_INPUT]: 'assets/images/whatsapp-flows/examples/flow-input.png',
    [EWAFComponents.TEXT_AREA]: 'assets/images/whatsapp-flows/examples/flow-input.png',
    [EWAFComponents.CHECKBOX_GROUP]: 'assets/images/whatsapp-flows/examples/flow-checkbox.png',
    [EWAFComponents.RADIO_BUTTONS_GROUP]: 'assets/images/whatsapp-flows/examples/flow-radio.png',
    [EWAFComponents.OPT_IN]: null,
    [EWAFComponents.DROPDOWN]: 'assets/images/whatsapp-flows/examples/flow-dropdown.png',
    [EWAFComponents.DATE_PICKER]: null,
    [EWAFComponents.EMBEDDED_LINK]: null,
    [EWAFComponents.IMAGE]: 'assets/images/whatsapp-flows/examples/flow-image.png',
    [EWAFComponents.FORM]: null,
    [EWAFComponents.FOOTER]: null,
    ['header']: null,
    ['footer']: 'assets/images/whatsapp-flows/examples/flow-footer.png',
    [EWAFComponents.RICH_TEXT]: 'assets/images/whatsapp-flows/examples/flow-texts.png',
}

export const whatsappFlowsTranslations = {
    ...getTranslationsFields(flowsScreenOptions),
    ...getTranslationsFields(flowsInputTypes),
    ...getTranslationsFields(flowsImageScaleTypes),
    ...getTranslationsFields(flowsTextFontTypes),
    ...getTranslationsFields(flowsStatusTypes),
}
