import { EBotEventType } from "./bot-event-model";
import { ENextGenBotElementType } from "./bot-model";
import { EBotActionType, TBotActionTypeArray } from "./new-bot-action";
import { isLeafAction } from "./bot-filter-dictionary";
import { assetScreenElements } from "../const-text/asset-text";
import { KBAssetType, TKAssetTypeArray } from "./bot-asset-model";
import { getBotActionsByCharacteristic } from "./bot-other-client-functions";
import { EIslandEvent } from "./bot-other-event-config-db";
import { ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { EMetadataNames } from "../metadata/metadata-db";



export interface IBotEventTypeConfig {
    eventListOfBranch: Array<ENextGenBotElementType | EIslandEvent>;
    hasExpireTime?: true;
    disableExpireAttemps?: true;
    isAdditionalData?: true;
    safeVariables?: { [key in EMetadataNames]?: boolean };
    isDesambiguation?: true;
    hasCommandText?: true;
    canRepeatEvent?: true;
    showOptionText?: true;
    notOnNested?: true;
    isPreCondition?: true;
    askForWaitTime?: true;
    actionsAllowed?: TBotActionTypeArray;
    hasContentAsset?: true;
    allowContentAssetGrouping?: boolean;
    contentAssetTypeAllowed?: TKAssetTypeArray;
    hasPreAndPosContentAsset?: true;
    preAndPosContentTitles?: { pre: string, pos: string };
    idFieldVisualElement?: number;
    hasAction?: true;
    showConditionalForActions?: TBotActionTypeArray,
    showDisambiguationOptions?: boolean,
    showActionPanel?: boolean,
    showSingleAssetEditor?: boolean,
    aimWarningToDisambiguation?: boolean,
    showSmartFlowRTFHeaderPicker?: boolean,
    allowExecuteCampaign?: boolean;
    allowExecUserFunctionBefore?: boolean;
    allowExecUserFunction?: boolean;
    showCalendarPicker?: boolean;
    showAdvancedMenu?: boolean;
    canConfigureDynamicIntervals?: boolean;
    showLastCall?: boolean;
}

export type TBotEventCharacteristicFilter = (db: IBotEventTypeConfig) => boolean;

const leafAction: TBotActionTypeArray = getBotActionsByCharacteristic(isLeafAction);

const commandTextAction: TBotActionTypeArray = leafAction;

export type TEventDB = {
    [botEventType in EBotEventType]: IBotEventTypeConfig;
};

export const eBotEventDB: TEventDB = {
    [EBotEventType.addressSearch]: {
        eventListOfBranch: [],
    },
    [EBotEventType.exitCommand]: {
        eventListOfBranch: [],
    },
    [EBotEventType.commandText]: {
        eventListOfBranch: [ENextGenBotElementType.metadata, ENextGenBotElementType.root],
        actionsAllowed: [...commandTextAction],
        hasCommandText: true,
        canRepeatEvent: true,
        hasContentAsset: true,
        allowContentAssetGrouping: true,
        showConditionalForActions: [EBotActionType.goHuman],
        allowExecUserFunctionBefore: true,
    },

    [EBotEventType.missingInformation]: {
        eventListOfBranch: [ENextGenBotElementType.nlpTransaction, ENextGenBotElementType.metadata, ENextGenBotElementType.formTransaction],
        showOptionText: true,
        idFieldVisualElement: assetScreenElements.fields.title
    },

    [EBotEventType.defaultReturnOption]: {
        eventListOfBranch: [ENextGenBotElementType.root],
        actionsAllowed: [EBotActionType.goBack, EBotActionType.goFirstTree],
        showOptionText: true,
        idFieldVisualElement: assetScreenElements.fields.title
    },

    [EBotEventType.customerExpire]: {
        eventListOfBranch: [ENextGenBotElementType.root, EIslandEvent.island],
        hasExpireTime: true,
        notOnNested: true,
        askForWaitTime: true,
        // hasContentAsset: true,
        hasPreAndPosContentAsset: true,
        preAndPosContentTitles: {
            pre: "Mensagem de lembrete",
            pos: "Mensagem de finalização"
        },
        allowContentAssetGrouping: true,
        allowExecuteCampaign: true,
        allowExecUserFunction: true,
        showCalendarPicker: true,
        canConfigureDynamicIntervals: true,
        showLastCall: true,
    },

    [EBotEventType.desambiguation]: {
        eventListOfBranch: [ENextGenBotElementType.root],
        actionsAllowed: [],
        isAdditionalData: true,
        isDesambiguation: true,
        showOptionText: true,
        idFieldVisualElement: assetScreenElements.fields.title,
        askForWaitTime: true,
        showAdvancedMenu: true,
    },

    [EBotEventType.onError]: {
        eventListOfBranch: [ENextGenBotElementType.root, ENextGenBotElementType.metadata],
        actionsAllowed: [EBotActionType.goBack, EBotActionType.goFirstTree, EBotActionType.isEnd],
        notOnNested: true,
        hasContentAsset: true,
        allowContentAssetGrouping: true
    },

    [EBotEventType.onHumanAssignation]: {
        eventListOfBranch: [],
    },

    [EBotEventType.onIdentificationClient]: {
        eventListOfBranch: [],
        notOnNested: true,
        hasContentAsset: true
    },

    [EBotEventType.onInvalidOption]: {
        eventListOfBranch: [ENextGenBotElementType.root, ENextGenBotElementType.metadata],
        // showDefaultActionText: true,
        notOnNested: true,
        askForWaitTime: true,
        hasContentAsset: true,
        allowContentAssetGrouping: true
    },

    [EBotEventType.preCondition]: {
        eventListOfBranch: [ENextGenBotElementType.botMenuItem],
        isPreCondition: true,
        askForWaitTime: true,
        hasContentAsset: true
    },

    [EBotEventType.providerExpire]: {
        eventListOfBranch: [EIslandEvent.island],
        hasExpireTime: true,
        disableExpireAttemps: true,
        notOnNested: true,
    },

    [EBotEventType.onFinish]: {
        eventListOfBranch: [ENextGenBotElementType.root],
        actionsAllowed: [EBotActionType.goHuman, EBotActionType.goBot],
        askForWaitTime: true,
        allowExecUserFunctionBefore: true,
    },

    [EBotEventType.onConfirmation]: {
        eventListOfBranch: [ENextGenBotElementType.nlpTransaction, ENextGenBotElementType.formTransaction],
    },

    [EBotEventType.accuracyEvent]: {
        eventListOfBranch: [ENextGenBotElementType.nlpTransaction]
    },

    [EBotEventType.insuficientAccuracy]: {
        eventListOfBranch: [],
        showDisambiguationOptions: false,
        showActionPanel: true,
        showSingleAssetEditor: true,
        // aimWarningToDisambiguation: true,
    },
    [EBotEventType.inconclusiveAccuracy]: {
        eventListOfBranch: [],
        showDisambiguationOptions: true,
        showActionPanel: true,
        showSingleAssetEditor: true,
        aimWarningToDisambiguation: true,
    },
    [EBotEventType.conclusiveAccuracy]: {
        eventListOfBranch: [],
        showDisambiguationOptions: false,
        showActionPanel: false,
        showSingleAssetEditor: true,
        // aimWarningToDisambiguation: true,
    },

    [EBotEventType.reuseFormInformation]: {
        eventListOfBranch: [ENextGenBotElementType.metadata, ENextGenBotElementType.formTransaction], // ENextGenBotElementType.metadata,????
        showDisambiguationOptions: true,
    },

    [EBotEventType.useFormDataToFieldReuse]: {
        eventListOfBranch: [ENextGenBotElementType.formTransaction],
    },

    [EBotEventType.isUsefullInsight]: {
        eventListOfBranch: [ENextGenBotElementType.nlpTransaction, ENextGenBotElementType.formTransaction],
        showOptionText: true,
        idFieldVisualElement: assetScreenElements.fields.title,
    },

    [EBotEventType.onQueueAdd]: {
        eventListOfBranch: [EIslandEvent.island],
        allowExecUserFunction: true,
        hasContentAsset: true,
        allowContentAssetGrouping: true
    },
    [EBotEventType.onQueueNext]: {
        eventListOfBranch: [EIslandEvent.island],
        hasContentAsset: true,
        allowContentAssetGrouping: true
    },
    [EBotEventType.onCustomerGaveUp]: {
        eventListOfBranch: [EIslandEvent.island],
        hasContentAsset: true,
        allowContentAssetGrouping: true
    },
    [EBotEventType.onQueueOnBegin]: {
        eventListOfBranch: [EIslandEvent.island],
        hasContentAsset: true,
        allowContentAssetGrouping: true,
        allowExecUserFunction: true,
        safeVariables: {
            [EMetadataNames.attendent]: true,
        },
    },
    [EBotEventType.onQueueOnFinish]: {
        eventListOfBranch: [EIslandEvent.island],
        hasContentAsset: true,
        allowContentAssetGrouping: true,
        safeVariables: {
            [EMetadataNames.attendent]: true,
        },
        allowExecUserFunctionBefore: true,
    },
    [EBotEventType.onQueueReturn]: {
        eventListOfBranch: [EIslandEvent.island],
        hasContentAsset: true,
        allowContentAssetGrouping: true
    },
    [EBotEventType.onQueueTimeRestriction]: {
        eventListOfBranch: [EIslandEvent.island],
        hasContentAsset: true,
        allowContentAssetGrouping: true
    },
    [EBotEventType.onQueueTransfer]: {
        eventListOfBranch: [EIslandEvent.island],
        hasContentAsset: true,
        allowContentAssetGrouping: true
    },

    [EBotEventType.onCreatingCredentials]: {
        eventListOfBranch: [EIslandEvent.island],
        hasContentAsset: true,
        allowContentAssetGrouping: true
    },
    [EBotEventType.onAttendentAbsent]: {
        eventListOfBranch: [EIslandEvent.island],
        hasContentAsset: true,
        allowContentAssetGrouping: true
    },
    [EBotEventType.onRtfInjectionParameter]: {
        eventListOfBranch: [ENextGenBotElementType.root],
        showSmartFlowRTFHeaderPicker: true,
    },
    [EBotEventType.onChannelClosedOnAgentChat]: {
        eventListOfBranch: [EIslandEvent.island],
    },
    [EBotEventType.onHelp]: {
        eventListOfBranch: [],
    },
    [EBotEventType.onTooManuCustomerReturn]: {
        eventListOfBranch: [],
    },
    [EBotEventType.onTooManyFormTries]: {
        eventListOfBranch: [],
    },
    [EBotEventType.onTooManyFieldTries]: {
        eventListOfBranch: [],
    },
    [EBotEventType.onTooManyMenuTries]: {
        eventListOfBranch: [],
    },
    [EBotEventType.onMaximumCustomerQueueReach]: {
        eventListOfBranch: [EIslandEvent.island],
        hasContentAsset: true,
        allowContentAssetGrouping: true
    },
    [EBotEventType.onAgentNotAvailable]: {
        eventListOfBranch: [EIslandEvent.island],
        hasContentAsset: true,
        allowContentAssetGrouping: true,
        allowExecUserFunction: true,
    },
}

export function getBotEvent(botEventType: EBotEventType): IBotEventTypeConfig {
    return eBotEventDB[botEventType];
}

export function geEventDB(): TEventDB {
    return eBotEventDB;
}
