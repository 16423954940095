import { ComponentType } from '@angular/cdk/portal';
import { Component, ElementRef, Inject, Injector, NgZone, OnInit, Optional, ViewChild } from '@angular/core';
import { EPaletteType, IBrandTheme, ICollorPaletteWithType, IColorPallete, IForegroundColorPalette } from '@colmeia/core/src/shared-business-rules/brand-theme/brand-theme.model';
import { safeParseJSON } from '@colmeia/core/src/shared-business-rules/connections/connections-functions';
import { isValidRef, safeStringifyJSON } from '@colmeia/core/src/tools/barrel-tools';
import { ColorThemeBuilderService, EPreviewType } from 'app/services/color-theme-builder.service';
import { cloneDeep } from 'lodash';
import { BrandTheme } from '../brand-theme.functions';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';

export interface IColorThemeBuilderEditDialog {
    brandTheme: IBrandTheme;
    previewType: EPreviewType;
    previewComponent?: ComponentType<unknown>;
    previewElement?: HTMLElement;
}

@Component({
    selector: 'app-color-theme-builder-edit-dialog',
    templateUrl: './color-theme-builder-edit-dialog.component.html',
    styleUrls: ['./color-theme-builder-edit-dialog.component.scss'],
})
export class ColorThemeBuilderEditDialog implements OnInit {
    @ViewChild('colorPaletteContainer', { static: true }) colorPaletteContainer!: ElementRef<HTMLDivElement>;

    dataParams: IColorThemeBuilderEditDialog;
    embeddedCode: string;
    previewComponent: ComponentType<unknown>;
    previewType: EPreviewType;
    isWindow = false;

    brandTheme: IBrandTheme;
    primaryPalette: ICollorPaletteWithType;
    accentPalette: ICollorPaletteWithType;
    foregroundPalette: ICollorPaletteWithType;

    sortedPrimaryPalette: { key: string, value: string }[];
    sortedAccentPalette: { key: string, value: string }[];
    sortedForegroundPalette: { key: string, value: string }[];

    primaryColor: string;
    accentColor: string;
    foregroundColor: string;

    fonts: string[] = [];

    referencedDivHTMLElement: HTMLElement;

    previewInjector: Injector;

    private colorChangeRAF: number;

    private _onThemeUpdated$ = new Subject<IBrandTheme>();
    get onThemeUpdated$() {
        return this._onThemeUpdated$.asObservable()
    }

    EPreviewType: typeof EPreviewType = EPreviewType;
    EPaletteType: typeof EPaletteType = EPaletteType;

    constructor(
        @Optional()
        @Inject(MAT_DIALOG_DATA)
        data: IColorThemeBuilderEditDialog,
        @Optional()
        public dialogRef: MatDialogRef<ColorThemeBuilderEditDialog>,
        @Optional()
        private windowRef: ColmeiaWindowRef<ColorThemeBuilderEditDialog>,
        private zone: NgZone,
    ) {
        this.dataParams = data;

        this.previewType = this.dataParams.previewType;
        this.previewComponent = this.dataParams.previewComponent;

        if (isValidRef(this.windowRef)) {
            this.isWindow = true;
        }
    }

    async ngOnInit() {
        this.updateBrandTheme();
        this.initBaseColors();
        this.initFontData();
        this.updatePalleteColors();
        this.initHTMLElement();
        this.updateCSSProperties();
    }

    updateBrandTheme() {
        this.brandTheme = this.getDeepCopyBrandTheme();
    }

    getDeepCopyBrandTheme(): IBrandTheme | undefined {
        try {
            return cloneDeep(this.dataParams.brandTheme); //<< deep copy
        } catch (err) {
            console.error(err);
            return;
        }
    }

    initBaseColors() {
        this.primaryColor = this.dataParams.brandTheme.primary.palette[500];
        this.accentColor = this.dataParams.brandTheme.accent.palette[500];
    }

    initFontData() {
        this.fonts = this.getFonts();
    }

    updatePalleteColors() {
        this.updatePrimaryPalette();
        this.updateAccentPalette();
    }

    updatePrimaryPalette() {
        this.primaryPalette = this.generateColorPalette(this.primaryColor);
        this.sortedPrimaryPalette = this.sortShades(this.primaryPalette, EPaletteType.PrimaryPalette);
    }

    updateAccentPalette() {
        this.accentPalette = this.generateColorPalette(this.accentColor);
        this.sortedAccentPalette = this.sortShades(this.accentPalette, EPaletteType.AccentPalette);
    }

    initHTMLElement() {
        let element: HTMLElement;

        if (this.previewType === EPreviewType.Component) {
            element = this.colorPaletteContainer.nativeElement;
        }

        if (this.previewType === EPreviewType.HTMLElement) {
            element = this.dataParams.previewElement;
        }

        this.referencedDivHTMLElement = element
    }

    public getEditedTheme(): IBrandTheme {
        return {
            ...this.brandTheme,
            primary: this.primaryPalette,
            accent: this.accentPalette,
        }
    }

    updateCSSProperties() {
        const theme: IBrandTheme = this.getEditedTheme();
        this._onThemeUpdated$.next({
            ...this.brandTheme,
            primary: this.primaryPalette,
            accent: this.accentPalette,
        })

        // Cuidado com o carregamento dinamico do Iframe e do ShadowRoot
        const elShadowRoot = this.referencedDivHTMLElement.querySelector('colmeia-chat').shadowRoot
        if (!elShadowRoot) return
        const elIframe = elShadowRoot.querySelector('iframe').contentWindow.document.querySelector('html')
        BrandTheme.applyThemeOnElement(elIframe, theme)
    }

    //<< tinycolors methods
    getContrastColor(color: string): string {
        return BrandTheme.getContrastColor(color);
    }
    generateColorPalette(baseColor: string): IColorPallete {
        return BrandTheme.generateColorPalette(baseColor);
    }
    generateForegroundColorPalette(baseColor: string): IForegroundColorPalette {
        return BrandTheme.generateForegroundColorPalette(baseColor);
    }
    getHighlightedColor(color: string): string {
        return BrandTheme.getHighlightedColor(color);
    }
    setAlphaColor(color: string, alphaAmount: number): string {
        return BrandTheme.setAlphaColor(color, alphaAmount);
    }
    getFonts() {
        return BrandTheme.getAvailableFonts();
    }
    //<< tinycolors methods

    updatePaletteByOneKey(palette: IColorPallete, specificKeyParam: { key: string, value: string }, property: string) {
        let updatedPalette = <IColorPallete>safeParseJSON(safeStringifyJSON(palette)); // Deep copy

        if (!updatedPalette[property]) {
            console.log({
                updatePaletteByOneKey: 'Invalid property'
            }); //<< Log left
            return;
        }

        updatedPalette[property][specificKeyParam.key] = specificKeyParam.value; //<< Overriding specific key

        return updatedPalette;
    }

    shouldSwapValueOfAKey(keyPair: { key: string, value: string }, specificKey: { key: string, value: string }): boolean {
        if (keyPair.key !== specificKey.key) {
            return false;
        }
        return true
    }

    sortShades(palette: IColorPallete | IForegroundColorPalette, paletteType: EPaletteType): { key: string, value: string }[] {
        const specificProperty = paletteType === EPaletteType.ForegroundPalette ? 'foregroundPalette' : 'palette';
        const paletteEntries = Object.entries(palette[specificProperty]);

        const sortedEntries = paletteEntries
            .filter(([key, value]) => value)
            .sort((a, b) => {
                const aIsAKey = a[0].startsWith('A');
                const bIsAKey = b[0].startsWith('A');
                if (aIsAKey && !bIsAKey) return 1;
                if (!aIsAKey && bIsAKey) return -1;
                const aKey = parseInt(a[0].replace(/[^0-9]/g, ''), 10);
                const bKey = parseInt(b[0].replace(/[^0-9]/g, ''), 10);
                return aKey - bKey;
            })
            .map(([key, value]) => ({ key, value }));

        return <{ key: string, value: string }[]>sortedEntries;
    }

    specificShadeEditReturnMapShades(palette: IColorPallete | IForegroundColorPalette, paletteType: EPaletteType, specificKey: { key: string, value: string }): { key: string, value: string }[] {

        const specificProperty = paletteType === EPaletteType.ForegroundPalette ? 'foregroundPalette' : 'palette';
        const paletteEntries = Object.entries(palette[specificProperty]);

        const sortedEntries = paletteEntries
            .filter(([key, value]) => value)
            .map(([key, value]) => {
                let keyPairObject = ({ key, value });

                if (this.shouldSwapValueOfAKey(<{ key: string, value: string }>keyPairObject, specificKey)) {
                    keyPairObject.value = specificKey.value //<< overriding the value of an element
                }

                return keyPairObject
            });

        return <{ key: string, value: string }[]>sortedEntries;
    }

    onShadeItemClick(newColor: string, paletteType: EPaletteType, host: HTMLElement, event: Event) {
        if (event.target !== host) {
            return;
        }

        this.onColorChange(newColor, paletteType);
    }

    onColorChange(newColor: string, paletteType: EPaletteType) {


        this.zone.runOutsideAngular(() => {

            cancelAnimationFrame(this.colorChangeRAF);

            this.colorChangeRAF = requestAnimationFrame(() => {
                switch (paletteType) {
                    case EPaletteType.PrimaryPalette:
                        this.primaryColor = newColor;
                        this.updatePrimaryPalette();
                        this.updateCSSProperties();
                        return;
                    case EPaletteType.AccentPalette:
                        this.accentColor = newColor;
                        this.updateAccentPalette();
                        this.updateCSSProperties();
                        return;
                }
            })

        })

    }

    async onToneColorChange(newColor: string, event: Event) {
        const shadeKey = (event.target as HTMLLabelElement).dataset.shadekey;
        const paletteType = (event.target as HTMLLabelElement).dataset.palettetype as EPaletteType;
        this.editShade(newColor, shadeKey, paletteType, event)
    }

    async editShade(newColor: string, shadeKey: string, paletteType: EPaletteType, event: Event) {

        const specificKeyParam = { key: shadeKey, value: newColor };

        switch (paletteType) {
            case EPaletteType.PrimaryPalette:
                this.primaryPalette = this.updatePaletteByOneKey(this.primaryPalette, specificKeyParam, 'palette');
                this.sortedPrimaryPalette = this.sortShades(this.primaryPalette, paletteType);
                break;
            case EPaletteType.AccentPalette:
                this.accentPalette = this.updatePaletteByOneKey(this.accentPalette, specificKeyParam, 'palette');
                this.sortedAccentPalette = this.sortShades(this.accentPalette, paletteType);
                break;
            default:
                throw new Error('Invalid palette type: ' + paletteType);
        }

        this.updateCSSProperties();
    }

    applyTheme() {
        this.brandTheme.primary = this.primaryPalette;
        this.brandTheme.accent = this.accentPalette;

    }

    onSave() {
        this.applyTheme();
        this.close(this.brandTheme);
    }

    onCancel() {
        this.close();
    }

    close(result?: IBrandTheme) {
        this.isWindow ? this.windowRef.close(result) : this.dialogRef.close(result);
    }

    paletteToneTrackBy(paletteType: EPaletteType) {
        return (_index: number, item: any) => {
            return item.key + paletteType
        }
    }

}
