
import { typedFields, typedScreenOptions } from "../../tools/barrel-tools";
import { EAllSerializable, getTranslationsFields } from "../const-text/all-serializables";

export enum EPaymentViewDataTranslations {
    name = 'name',
    type = 'type',
    totalOrderAmount = 'totalOrderAmount',
    status = 'status',
    createdAt = 'createdAt',
    statusChangeClocktick = 'statusChangeClocktick',
    paymentTransactionId = 'paymentTransactionId',
    itemsDetails = 'itemsDetails',
    pending = 'pending',
    processing = 'processing',
    completed = 'completed',
    canceled = 'canceled',
    expired = 'expired',
    failed = 'failed',
    refunded = 'refunded',
    chargedBack = 'charged_back',
    pollingCompleted = 'polling_completed',

    productName = 'productName',
    price = 'price',
    quantity = 'quantity',
    total = 'total',
    description = 'description',

    PixPayment = 'PixPayment',
    PaymentOrder = 'PaymentOrder',
    Checkout = 'Checkout'
 }
 
 type TEditableComponentTypes = EPaymentViewDataTranslations;
 
 export const viewDataScreenOptions = typedScreenOptions({
     idSerializable: EAllSerializable.PaymentViewDataTranslations,
     fields: typedFields<TEditableComponentTypes>()({
         [EPaymentViewDataTranslations.name]: 1,
         [EPaymentViewDataTranslations.type]: 2,
         [EPaymentViewDataTranslations.totalOrderAmount]: 3,
         [EPaymentViewDataTranslations.status]: 4,
         [EPaymentViewDataTranslations.createdAt]: 5,
         [EPaymentViewDataTranslations.statusChangeClocktick]: 6,
         [EPaymentViewDataTranslations.paymentTransactionId]: 7,
         [EPaymentViewDataTranslations.itemsDetails]: 8,
 
         [EPaymentViewDataTranslations.pending]: 9,
         [EPaymentViewDataTranslations.processing]: 10,
         [EPaymentViewDataTranslations.completed]: 11,
         [EPaymentViewDataTranslations.canceled]: 12,
         [EPaymentViewDataTranslations.expired]: 13,
         [EPaymentViewDataTranslations.failed]: 14,
         [EPaymentViewDataTranslations.refunded]: 15,
         [EPaymentViewDataTranslations.chargedBack]: 16,
         [EPaymentViewDataTranslations.pollingCompleted]: 17,
         
         [EPaymentViewDataTranslations.productName]: 18,
         [EPaymentViewDataTranslations.price]: 19,
         [EPaymentViewDataTranslations.quantity]: 20,
         [EPaymentViewDataTranslations.total]: 21,
         [EPaymentViewDataTranslations.description]: 22,

         [EPaymentViewDataTranslations.PixPayment]: 23,
         [EPaymentViewDataTranslations.PaymentOrder]: 24,
         [EPaymentViewDataTranslations.Checkout]: 25
     })
 } as const);
 
 export const paymentViewDataTranslations = {
     ...getTranslationsFields(viewDataScreenOptions),
 }