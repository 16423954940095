import { IBotAnswerDSRegisterCampaign, IPaymentTransactionInfo } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendent-sp-req-resp';
import { TGlobalUID } from '../../business/constant';
import { IAvatarJSON } from '../../comm-interfaces/business-interfaces';
import { EDelivery360Action, EInteractionSentType } from '../../comm-interfaces/interaction-interfaces';
import { I360Media, T360MediaArray } from '../../core-constants/bot';
import { TArrayID } from '../../core-constants/types';

export type TExchangeDB = { [exchange: string]: IBotExchangeConfiguration }
export type TBotExchangeCallback = () => void;
export type TBotExchangeCondition = (db: IBotExchangeConfiguration) => boolean;
export type TCurrentExchangeArray = Array<ECurrentExchange>;

export enum ECurrentExchange {
    continue = 'continue',
    firstPing = 'first',
    dispatchToHuman = 'toHuman',
    humanAssigned = 'hAssigned',
    warningMessage = 'warnMess',
    delivery360 = 'd360',
    finished = 'fin',
    massComm = 'massComm',
};

export enum EMacroProcess {
    standard = 'std',
    disambiguationChoosed = 'AIdesambig',
    reuseFieldOfForm = 'rfield',
    virtualMenu = 'vmenu',
    multipleFieldsConfirmation = 'mFiConf',
    maps = 'mMaps',
}



export enum EBranchType {
    root = 'r', node = 'n', leaf = 'l', form = 'f'
}

export enum EEvalulatorType {
    checkAcceceTree = 'catt', checkIfAuth = 'checAuth'
}


export type TKnowledgeDB = Array<IKnowledgeRecord>;
export interface IKnowledgeRecord {
    question: string;
    answer: string;
}

export interface IReuseFieldEditingProperty {
    idProperty: string;
    value: string;
}



export interface IBotExchangeConfiguration {
    isFinished: boolean;
    cleanQueueEvents: boolean;
}


export interface IBotSubject {
    subject: string;
    action: string;
};


export interface IBotCondition {
    checkType: EEvalulatorType;
    idTreeVisited: string;
}


// TODOS ESSES dados estao aqui apenas para facilitar no calculo da forca bruta.
// Não fazem parte do Subject ou Intent
export interface IBruteForceAcuracyInfoCalc {
    actionAccuracy?: number;
    subjectAccuracy?: number;
    calculatedScore?: number;
    wordMatch?: number;

}

export type TIBruteForceAcuracyInfoCalcArray = Array<IBruteForceAcuracyInfoCalc>;

export interface IBotSubjectSearch extends IBruteForceAcuracyInfoCalc {
    idElement: TGlobalUID;
    subjectToken: TArrayID;
    actionToken: TArrayID;
    menu: string;
    idMenuParent: string;
    hasConditional: boolean;

};

export type TBotSubjectSearchArray = Array<IBotSubjectSearch>;

export enum EActionTreeIncrementor {
    numeric = 1, letter = 2,
}


export type TConversationArray = Array<IBotConversationLog>;
export type TIBotLogContentArray = Array<IBotLogContent>;
export interface IBotLogContent {
    clockTick: number;
    medias?: T360MediaArray;
    text?: string;
    form?: {
        idForm: string;
        idAnswer: string;
    };
    idElement?: string;
    isInvalidOption?: true;
    isDesambiguation?: true;
    menuTitle?: string;
    isReturn?: true;
    menuItem?: string;
    isLookup?: boolean;
    sentType?: EInteractionSentType;
    isTemplateText?: boolean;
    templateClickedBtnLabel?: string;
    isTranscription?: boolean;
    campaign?: IBotAnswerDSRegisterCampaign;
    paymentTransactionInfo?: IPaymentTransactionInfo;
}

export interface IBotConversationLog extends IBotLogContent {
    isBot: boolean;
    clockTick: number;
    idElement: string;
    idBot: string;
}

export type TBotConversationArray = Array<IBotConversation>;

export interface IBotConversation {
    avatar: IAvatarJSON;
    idPublisher: string;
    clientAddress: string;
    channelAddress: string;
    providerType: EDelivery360Action;
    idConversation: string;
    cursor: string;
    conversation: TIBotLogContentArray;

}

export interface IBotConversationId extends IBotLogContent {
    isBot: false
}


export enum ETypeOfCustomerContact {
    inbound = 'inb', outbound = 'out'
}

export interface IBusinessKey {
    sha256: string;
    busKey: string;
}
export type TIBusinessKeyArray = IBusinessKey[]

/**
 * TBusinessKey is encoded in monotonic-increased-destroyer.ts, to prevent monotonically increased(1,2,3...) because its a bad practice to store in datastore fields monotonic
 */
export type TBusinessKey = string
export type TBusinessKeyArray = TBusinessKey[]
export type TBusinessKeyMonotonic = number
export type TBusinessKeyMonotonicArray = TBusinessKeyMonotonic[]


export interface ICustomerContactRegister {
    idConversation: string;
    idSocialContext: TGlobalUID;
    idAvatar: TGlobalUID;
    target: string;
    clockTick: number;
    channel: EDelivery360Action;
    typeOfCustomerContact: ETypeOfCustomerContact;
    messageSent?: string;
    idRunCampaign?: string;
    idCampaigAction?: string;
    businessKeys?: TIBusinessKeyArray;
    idMassEntry?: string;
}

export type TICustomerContactRegisterArray = Array<ICustomerContactRegister>;


export type TBotMenuOptions = Array<IBotMenuOption>;


export interface IBotMenuOptionAllowed {
    isQuickReplyAllowed: boolean | undefined;
    isListMenuAllowed: boolean | undefined;
}
export interface IBotMenuOption extends IBotMenuOptionAllowed {
    title: string;
    media?: I360Media;
    expectedReply?: string;

    description?: string;
    sectionName?: string;
}
