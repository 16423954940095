<cm-generic-dashboard-edit [handler]="genericEditHandler" *ngIf="genericEditHandler">

  <ng-container *ngIf="canShowCreate; else editMode">
    <bot-transaction-create [botLevel]="botLevelCreation" [transaction]="transaction"></bot-transaction-create>
  </ng-container>

  <ng-template #editMode>
    <mat-tab-group style="margin-top: -6px;" class="boxed-tabs boxed-dense tabs-wrap" animationDuration="200ms"
      (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedIndex">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">playlist_add_circle</mat-icon>
          Dados para geração
        </ng-template>
        <div class="content">
          <bot-transaction-generation-data [transaction]="transaction" (typeChange)="onTransactionTypeChange($event)"
            (schemaChange)="onSchemaChange($event)" (knowledgeBaseChange)="onKnowledgeBaseChange($event)"
            [allowPickBotLevel]="this.allowPickBotLevel"></bot-transaction-generation-data>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">settings_input_composite</mat-icon>
          Mapeamento de Entidades
        </ng-template>
        <div class="content">
          <meta-engagement *ngIf="metaEngagementHandler" [handler]="metaEngagementHandler"
            (consistency)="consistencyMetagagement()" (onAllFields)="onAllFields($event)"></meta-engagement>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">tune</mat-icon>
          Váriaveis
        </ng-template>
        <div class="content">
          <app-variable-tags *ngIf="variableTagsHandler" [handler]="variableTagsHandler">
          </app-variable-tags>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">{{ showNLP ? 'theater_comedy' : 'art_track' }}</mat-icon>
          {{ showNLP ? 'Intenções' : 'Assets' }}
        </ng-template>
        <div class="content">
          <bot-transaction-assets [transaction]="transaction" [knowledgeBases]="allKBs"
            [variableTags]="selectedVariableTags" [expandedIntentsIDs]="expandedIntentsIDs"
            [preSelectedValue]="preSelectedValue" [nsPickerSelectedProperty]="nsPickerSelectedProperty">
          </bot-transaction-assets>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">event</mat-icon>
          Eventos
        </ng-template>
        <div class="content">
          <app-bot-events *ngIf="transactionEventsHandler" [handler]="transactionEventsHandler">
          </app-bot-events>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">spellcheck</mat-icon>
          Significados
        </ng-template>
        <div class="content">
          <app-canonical-picker *ngIf="canonicalPickerHandler"
            [handler]="canonicalPickerHandler"></app-canonical-picker>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">border_top</mat-icon>
          Pré Conteúdo
        </ng-template>
        <div class="content">
          <asset-adder [handler]="preContentHandler" *ngIf="preContentHandler"></asset-adder>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">border_bottom</mat-icon>
          Pós Conteúdo
        </ng-template>
        <div class="content">
          <asset-adder [handler]="posContentHandler" *ngIf="posContentHandler"></asset-adder>
        </div>
      </mat-tab>

      <mat-tab labelClass="visualizer">
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">account_tree</mat-icon>
          Visualizador
        </ng-template>
        <div class="content" style="padding: 0;">
          <bot-transaction-visualizer [ns]="transaction" [autoInit]="false"></bot-transaction-visualizer>
        </div>
      </mat-tab>

      <mat-tab *ngIf="isColmeiaForms() && hasWhatsAppFlowsAccess">
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">wysiwyg</mat-icon>
          WhatsApp Flows
        </ng-template>
        <ng-template matTabContent>
          <div class="content" style="padding: 0;">
            <colmeia-forms-whatsapp-flows [botTransaction]="transaction"></colmeia-forms-whatsapp-flows>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab >
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">trending_up</mat-icon>
          Trackeamento
        </ng-template>
        <ng-template matTabContent>
          <div class="mkt-evt-tab-header">
            <h5 class="mkt-evt-tab-title">
              <mat-icon class="tab-icon mkt-evt-tab-icon">trending_up</mat-icon>
              Trackeamento
            </h5>
          </div>
          <div class="content" style="padding: 0;">
            <div class="mkt-evt-picker-container">
              <mat-label>Trackeamento no Pré-preenchimento de formulário</mat-label>
              <app-non-serializable-taggable *ngIf="prefillAdEventTagPickerHandler" [tagPickerHandler]="prefillAdEventTagPickerHandler"></app-non-serializable-taggable>
            </div>
            <div class="mkt-evt-picker-container">
              <mat-label>Trackeamento no Pós-preenchimento do formulário</mat-label>
              <app-non-serializable-taggable *ngIf="postfillAdEventTagPickerHandler" [tagPickerHandler]="postfillAdEventTagPickerHandler"></app-non-serializable-taggable>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</cm-generic-dashboard-edit>

<ng-template #spinner>
  <app-dashboard-spinner></app-dashboard-spinner>
</ng-template>
