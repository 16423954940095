<div class="container" (click)="openTicket($event)">
    <div class="content-left">
        <div class="l-row l-header">
            <div class="title-section">
                <mat-icon class="mat-18" style="flex-shrink:0;">confirmation_number</mat-icon>
                <h4 class="l-title">{{ ticketData.title }}</h4>
                <ticket-more-options (click)="$event.stopPropagation()" [ticketData]="ticketData"></ticket-more-options>
            </div>
        </div>
        <hr style="width: 100%;" />
        <div class="l-row">
            <app-crm-ticket-attr-view *ngIf="stateId" [id_ticket]="idTicket" [attr_id]="stateId"></app-crm-ticket-attr-view>
            <!-- <app-crm-ticket-protocol-view (click)="$event.stopPropagation()" size="small" [ticketData]="ticketData"></app-crm-ticket-protocol-view> -->
            <p class="l-column" style="margin:0;"><mat-icon class="mat-18">person</mat-icon>{{ ticketData.customerName }}</p>
        </div>
    </div>
    <div class="content-right">
        <app-crm-ticket-protocol-view (click)="$event.stopPropagation()" size="small" raw [ticketData]="ticketData"></app-crm-ticket-protocol-view>
    </div>
</div>