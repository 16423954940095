import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { IBotMenuOption } from "@colmeia/core/src/shared-business-rules/bot/bot-interfaces";
import { EMenuMode, getMenuModes, IMenuContainerDisplayOptions, IRcsActionMessageTemplate, IWhatsAppActionMessageTemplate, TMenuMode } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { GenericSharedService } from "@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service";
import { FBM_MAX_BUTTONS_QUICK_REPLY, FBM_MAX_GENERIC_TEMPLATE_ITEMS, FBM_MAX_QUICK_REPLY_TITLE_LENGTH } from "@colmeia/core/src/shared-business-rules/social-media/meta/meta-constants";
import { MAX_RCS_BUTTONS_QUICK_REPLY_CALL_TO_ACTION, MAX_RCS_LIST_SECTIONS, MAX_RCS_TEMPLATE_BUTTON_TEXT_SIZE, MAX_WHATSAPP_BUTTONS_QUICK_REPLY, MAX_WHATSAPP_BUTTON_TITLE_SIZE, MAX_WHATSAPP_LIST_ROWS_PER_SECTION, MAX_WHATSAPP_LIST_ROW_DESCRIPTION_SIZE, MAX_WHATSAPP_LIST_ROW_TITLE_SIZE, MAX_WHATSAPP_LIST_SECTIONS, MAX_WHATSAPP_LIST_SECTION_TITLE_SIZE } from "@colmeia/core/src/shared-business-rules/social-media/social-media.model";
import { WhatsAppFunctions } from "@colmeia/core/src/shared-business-rules/social-media/whatsapp-functions";
import { arrayUnique, isInvalid, isUndefinedOrEmptyString, isValidSize, isValidTrimmedStringAndSize, typedCloneLodash } from "@colmeia/core/src/tools/barrel-tools";
import { PROFILE_SETTINGS_ADDRESS_CHAR_LIMIT, PROFILE_SETTINGS_DESCRIPTION_CHAR_LIMIT, PROFILE_SETTINGS_EMAIL_CHAR_LIMIT, WhatsApp } from "@colmeia/core/src/shared-business-rules/social-media/whatsapp-interface";

export namespace WhatsAppValidators.Profile {
    export class Settings {
        static isAboutValid(about: string) {
            return isValidTrimmedStringAndSize(about, 1);
        };

        static isAddressValid(address: string) {
            return isValidTrimmedStringAndSize(address, 1, PROFILE_SETTINGS_ADDRESS_CHAR_LIMIT);
        }

        static isDescriptionValid(description: string) {
            return isValidTrimmedStringAndSize(description, 1, PROFILE_SETTINGS_DESCRIPTION_CHAR_LIMIT);
        }

        static isEmailValid(email: string) {
            return isValidTrimmedStringAndSize(email, 1, PROFILE_SETTINGS_EMAIL_CHAR_LIMIT);
        }

        static areMainFieldsEmpty(about: string, address: string, description: string, email: string) {
            return isUndefinedOrEmptyString(about) && isUndefinedOrEmptyString(address) &&
                isUndefinedOrEmptyString(description) && isUndefinedOrEmptyString(email);
        }

        static areMainFieldsValid(about: string, address: string, description: string, email: string) {
            return this.isAboutValid(about) &&
                this.isAddressValid(address) &&
                this.isDescriptionValid(description) &&
                this.isEmailValid(email);
        }
    }
}

export namespace WhatsAppValidators.Menu {

    export class Item {
        static check(
            option: IBotMenuOption,

        ) {
            option.isQuickReplyAllowed = Item.isQuickAllowedForItem(option);
            option.isListMenuAllowed = Item.isListAllowedForItem(option);
            return option;
        }

        static isQuickAllowedForItem(option: IBotMenuOption) {
            return option.title.length <= MAX_WHATSAPP_BUTTON_TITLE_SIZE;
        }

        static isListAllowedForItem(option: IBotMenuOption): boolean {
            return validateListContent(option.title, option.description, option.sectionName);
        }
    }

    export function validateListSize<T>(items: T[]) {
        return items.length <= MAX_WHATSAPP_LIST_ROWS_PER_SECTION;
    }

    export function validateListContent(title: string, description: string | undefined, sectionName: string | undefined) {
        const hasValidTitle: boolean = (title.length <= MAX_WHATSAPP_LIST_ROW_TITLE_SIZE);

        const hasValidDescription = isInvalid(description)
            || description?.length <= MAX_WHATSAPP_LIST_ROW_DESCRIPTION_SIZE
            ;

        const hasValidSection: boolean =
            !sectionName || sectionName.length <= MAX_WHATSAPP_LIST_SECTION_TITLE_SIZE
            ;

        return hasValidTitle && hasValidDescription && hasValidSection;
    }

    export class Container {

        static isQuickReplyAllowedWhatsApp({ menuItems }: IGetAllowedMenuModes): boolean {
            return (menuItems.length <= MAX_WHATSAPP_BUTTONS_QUICK_REPLY)
                && menuItems.every(item => item.isQuickReplyAllowed)
                ;
        }

        static isQuickReplyAllowedFacebook({ menuItems: options }: IGetAllowedMenuModes): boolean {
            return (options.length <= FBM_MAX_BUTTONS_QUICK_REPLY) && !options.some(opt => (Container.parse(opt.title)).length > FBM_MAX_QUICK_REPLY_TITLE_LENGTH);
        }

        static isQuickReplyAllowedRCS({ menuItems: options }: IGetAllowedMenuModes): boolean {
            return (options.length <= MAX_RCS_BUTTONS_QUICK_REPLY_CALL_TO_ACTION) && !options.some(opt => (Container.parse(opt.title)).length > MAX_RCS_TEMPLATE_BUTTON_TEXT_SIZE);
        }

        static isFullAllowedFacebook({ menuItems: options }: IGetAllowedMenuModes): boolean {
            return options.length <= FBM_MAX_GENERIC_TEMPLATE_ITEMS;
        }

        static parse(content: string) {
            // return WhatsAppParser.formatEmptyMessage(content);
            return content;
        }

        static countChars(content: string) {
            return GenericSharedService.charCount(content);
        }

        static isListMenuAllowedWhatsApp(
            input: IGetAllowedMenuModes
        ): ErrorMessage | boolean {
            let {
                menuItems,
                selectorButton,
                title,
                whatsApp,
            } = input;

            if (whatsApp?.header) {
                if (whatsApp.header?.format && (whatsApp.header?.format !== WhatsApp.Message.Template.Structure.Format.Text)) {
                    return 'O menu completo não pode ter cabeçalho que não seja texto';
                }
            }

            title = Container.parse(title);
            selectorButton = Container.parse(selectorButton);
            const options: IBotMenuOption[] = WhatsAppFunctions.Menu.parseOptions(typedCloneLodash(menuItems));

            const amountSections: number = arrayUnique(options.map(item => item.sectionName).filter(Boolean))
                .filter(Boolean)
                .length
                ;

            return (!selectorButton || Container.countChars(selectorButton) <= MAX_WHATSAPP_BUTTON_TITLE_SIZE)
                && validateListSize(options)
                && options.every(item => item.isListMenuAllowed)
                && isValidSize(amountSections, 1, MAX_WHATSAPP_LIST_SECTIONS)
                ;
        }


        static isListMenuAllowedRCS(
            input: IGetAllowedMenuModes
        ): ErrorMessage | boolean {
            let {
                menuItems,
                selectorButton,
                title,
                whatsApp,
            } = input;

            if (whatsApp?.header) {
                if (whatsApp.header?.format && (whatsApp.header?.format !== WhatsApp.Message.Template.Structure.Format.Text)) {
                    return 'O menu completo não pode ter cabeçalho que não seja texto';
                }
            }

            title = Container.parse(title);
            selectorButton = Container.parse(selectorButton);
            const options: IBotMenuOption[] = WhatsAppFunctions.Menu.parseOptions(typedCloneLodash(menuItems));

            const amountSections: number = arrayUnique(options.map(item => item.sectionName).filter(Boolean))
                .filter(Boolean)
                .length;

            return (!selectorButton || Container.countChars(selectorButton) <= MAX_RCS_TEMPLATE_BUTTON_TEXT_SIZE)
                && validateListSize(options)
                && options.every(item => item.isListMenuAllowed)
                && isValidSize(amountSections, 1, MAX_RCS_LIST_SECTIONS);
        }

        static isMenuAllowedVerifiersTelegram: TMenuVerifiers = {
            [EMenuMode.Quick]: () => true,
            [EMenuMode.Full]: () => true,
            [EMenuMode.Text]: () => true,
        }

        static isMenuAllowedVerifiersWhatsApp: TMenuVerifiers = {
            [EMenuMode.Quick]: Container.isQuickReplyAllowedWhatsApp,
            [EMenuMode.Full]: Container.isListMenuAllowedWhatsApp,
            [EMenuMode.Text]: () => true,
        }

        static isMenuAllowedVerifiersMessenger: TMenuVerifiers = {
            [EMenuMode.Quick]: Container.isQuickReplyAllowedFacebook,
            [EMenuMode.Full]: Container.isFullAllowedFacebook,
            [EMenuMode.Text]: () => true,
        }

        static isMenuAllowedVerifiersRCS: TMenuVerifiers = {
            [EMenuMode.Quick]: Container.isQuickReplyAllowedRCS,
            [EMenuMode.Full]: Container.isListMenuAllowedRCS,
            [EMenuMode.Text]: () => true,
        }

        static isMenuAllowedVerifiersEmpty: TMenuVerifiers = {
            [EMenuMode.Quick]: () => 'Not allowed',
            [EMenuMode.Full]: () => 'Not allowed',
            [EMenuMode.Text]: () => true,
        }

        static mapActionToVerifier: { [key in EDelivery360Action]: TMenuVerifiers } = {
            [EDelivery360Action.DeliveryInstagram]: Container.isMenuAllowedVerifiersMessenger,
            [EDelivery360Action.Delivery360FBMessenger]: Container.isMenuAllowedVerifiersMessenger,
            [EDelivery360Action.Delivery360WhatsApp]: Container.isMenuAllowedVerifiersWhatsApp,
            [EDelivery360Action.DeliveryTelegram]: Container.isMenuAllowedVerifiersTelegram,
            [EDelivery360Action.Delivery360RCS]: Container.isMenuAllowedVerifiersRCS,

            [EDelivery360Action.Delivery360Email]: Container.isMenuAllowedVerifiersEmpty,
            [EDelivery360Action.Delivery360SMS]: Container.isMenuAllowedVerifiersEmpty,
            [EDelivery360Action.Delivery360Voice]: Container.isMenuAllowedVerifiersEmpty,
            [EDelivery360Action.DeliveryColmeia]: Container.isMenuAllowedVerifiersEmpty,
        }

        static getValidatedMenuModes(input: IGetAllowedMenuModes): ValidatedMenuMode[] {
            const { socialProvider } = input;
            const items = getMenuModes().map(mode => {
                const out = Container.mapActionToVerifier[socialProvider][mode](input);
                const errorMessage = typeof out === 'string' ? out : undefined;
                const isAllowed = typeof out === 'boolean' ? out : !errorMessage;

                return {
                    mode,
                    isAllowed,
                    errorMessage,
                }
            })
            return items;
        }

        static getAllowedMenuModes(input: IGetAllowedMenuModes): TMenuMode[] {
            const validated = Container.getValidatedMenuModes(input);
            return validated.filter(item => item.isAllowed).map(item => item.mode);
        }
    }


}

export interface ValidatedMenuMode {
    mode: TMenuMode;
    isAllowed: boolean;
    errorMessage: string | undefined;
}

export interface IGetAllowedMenuModes {
    title: string;
    selectorButton: string;
    menuItems: IBotMenuOption[];
    socialProvider: EDelivery360Action;
    displayOptions: IMenuContainerDisplayOptions;
    whatsApp?: IWhatsAppActionMessageTemplate;
    rcs?: IRcsActionMessageTemplate;
}

type ErrorMessage = string;
type TMenuVerifiers = { [key in TMenuMode]: (input: IGetAllowedMenuModes) => ErrorMessage | boolean };
