export interface ITooltipInfoModel {
    id: string,
    message: string,
    link: string
};

export enum ETooltipInfoKey {
    attendanceIslandTitle = 'attendanceIslandTitle',
    attendanceAgentFileList = 'attendanceAgentFileList',
    attendanceEventsTitle = 'attendanceEventsTitle',
    servicePackTitle = 'servicePackTitle',
    attendanceCalendarTitle = 'attendanceCalendarTitle',
    attendanceConversationHistorySearchTitle = 'attendanceConversationHistorySearchTitle',
    dashboardAttendanceAgentStatusTitle = 'dashboardAttendanceAgentStatusTitle',
    attendantFollowUpTitle = 'attendantFollowUpTitle',
    agentsOnIslandTitle = 'agentsOnIslandTitle',
    callcenterAgentsTitle = 'callcenterAgentsTitle',
    attendanceActiveCall = 'attendanceActiveCall',
    attendanceIslandInactivityAlert = 'attendanceIslandInactivityAlert',
    attendanceIslandCustomerReturnControl = 'attendanceIslandCustomerReturnControl',
    attendanceIslandNamedAccount = 'attendanceIslandNamedAccount',
    attendanceIslandSatisfactionSurveyBot = 'attendanceIslandSatisfactionSurveyBot',
    smartflowFormsListTitle = 'smartflowFormsListTitle',
    canonicalListTitle = 'canonicalListTitle',
    tagsListTitle = 'tagsListTitle',
    smartflowBotTransaction = 'smartflowBotTransaction',
    smartflowFunctionsTitle = 'smartflowFunctionsTitle',
    configurationSetListTitle = 'configurationSetListTitle',
    marketingIntegrationList = 'marketingIntegrationList',
    marketingChannelsTemplatesListTitle = 'marketingChannelsTemplatesListTitle',
    marketingAttendanceCalendarTitle = 'marketingAttendanceCalendarTitle',
    campaignActionAllowActionToActiveCall = 'campaignActionAllowActionToActiveCall',
    campaignActionAllowActiveCallRTFSelect = 'campaignActionAllowActiveCallRTFSelect',
    campaignActionSendOnlyIfChannelIsOpen = 'campaignActionSendOnlyIfChannelIsOpen',
    campaignActionEngagementControl = 'campaignActionEngagementControl',
    SNuserSettingsAgentChannelClosedControlSend = 'SNuserSettingsAgentChannelClosedControlSend',
    SNuserSettingsAttendance = 'SNuserSettingsAttendance',
    assetAdderAssetTypeSticker = 'assetAdderAssetTypeSticker',
    snUserSettingsBotTestService = 'snUserSettingsBotTestService',
    intentBias = 'intentBias',
    kbStrategies = 'kbStrategies',
    kbProviders = 'kbProviders',
    kbNlp = 'kbNlp',
    kbIntents = 'kbIntents',
    kbFeatEng = 'kbFeatEng',
    kbFitters = 'kbFitters',
    kbSoftmax = 'kbSoftmax',
    kbExponentialRegression = 'kbExponentialRegression',
    kbFusor = 'kbFusor',
    kbWeightedAverage = 'kbWeightedAverage',
    kbEntityExtractor = 'kbEntityExtractor',
    kbTests = 'kbTests',
    cron = 'cron',

}

export type TTooltipInfoModelMap = Record<ETooltipInfoKey, ITooltipInfoModel>;
