import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { MS, Miliseconds } from '@colmeia/core/src/time/time-utl';
import { TextTemplate } from '@colmeia/core/src/tools/utility/functions/TextTemplate';
import { EscapeRegExp } from '@colmeia/core/src/tools/utility/functions/escapeRegExp';
import { createGetClassNames } from "@colmeia/core/src/tools/utility/functions/getClassNames";
import { MapDeep, Mapper } from '@colmeia/core/src/tools/utility/functions/map-by';
import { Define } from '@colmeia/core/src/tools/utility/types/entities/define';
import safeStringify from 'fast-safe-stringify';
import * as _ from 'lodash';
import { invertObj as _invertObj } from "lodash/fp";
import { Serializable } from '../business/serializable';
import { NamedString, SmartDate, TArrayID, TGlobalUID, brPhoneNumberPattern, brazilianDDDs, cleanedBRPhoneNumberPattern, cnpjPattern, cpfPattern, emailPattern, genericBrazilianCityPattern, pixRandomKeyPattern } from '../core-constants/types';
import { CustomError, CustomErrorField, ErrorPropagate, ErrorPropagateField } from '../error-control/custom-error';
import { IRequest } from '../request-interfaces/request-interfaces';
import { EActionTreeIncrementor } from '../shared-business-rules/bot/bot-interfaces';
import { EColmeiaAPIErrors } from '../shared-business-rules/colmeia-apis/api-errors.model';
import { IScreenOptions } from "../shared-business-rules/const-text/atext-types";
import { ITranslationConfig } from '../shared-business-rules/translation/translation-engine';
import { getFnName } from './get-fn-name';
import { genericTypedSuggestions, getAllClassStaticMethods } from './type-utils';
import { $ValueOf, AfterNarrow, AllSimpleLiteralTypeNames, AnyOrUnknownTo, Assert, AsyncReturnType, AsyncType, CheckIfIsInEnum, Compute, DeepFindValuesOfType, DeepInfer, DeepMap, DeepPartial, Entries, EntriesHash, FindKeysWithValueOf, FromEntries, GetByKey, HasKey, HashObjectLiteralMetadata, ICreateWrapSlot, IDeferedPromise, IGetTimeInfo, IHashSet, IHashSetNumberIndexed, IHashTableAlias, IIgnoreProperty, IObjectLiteralMetadata, IPluralAndSingularText, IPrintTimeInfoTranslatedText, IsEmpty, IsEqual, IsNever, IsUnknown, ItPropertyType, KeyOf, Narrow, Narrowable, Nullable, Nullish, ObjectLiteralMetadata, Primitive, SpecificOverload, TDeepMap, TFunction, TGenericTypeGuardVerifier, TOcurrencesHash, TPrintTimeInfoShouldHideOptions, ToSimpleLiteralTypeName, ToTuple, ToTypeOf, TurnURLParametersIntoJSON, TypeDeepMap, UnionLast, UnionToArray, UnionToIntersection, ValueOf, _TImplementPrivate, isPrimitive } from './utility-types';
import { getDeepTypedProperty } from './utility/functions/getDeepTypedProperty';
import { getTypedProperty } from './utility/functions/getTypedProperty';
import { cloneDeep, isEqual, omit } from './utility/functions/lodash-fns';
import { Unsafe } from "./utility/functions/unsafe";
import { Explicit } from './utility/types';
import { StringNumber } from './utility/types/basic';
import { Merger } from './utility/types/entities/merger';
import { $$ } from './utility/types/error';
import { $Extends, $Narrow } from './utility/types/validate';
import { isValidGeneralPhoneNumber } from '../shared-business-rules/social-cc/config-cell';
export * from './utility/functions/lodash-fns';

export {
    getDeepTypedProperty,
    getTypedProperty
};

const path = getPath();

function setProcess() {
    globalThis.process ??= require('process');
}

export function getPath(): typeof import('path') {
    setProcess();
    return require('path');
}




const globalValidCharacters = /([^\u0000-\u007F]|\w)+/g;
const notAllowdTokenCharacter = /['"`\xA0]/ug;
const emoticons = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug;
const forbidenAsc: string = `,$+=&@:?;{}|^[]<>#%\x20'"\\/` + '`'; //String.fromCharCode(96)
export const accentList = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž ' as const;
export const accentReplacements = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz ' as const;


export function isValidRef<T>(value: T | Nullish): value is T
export function isValidRef(value: any): boolean {
    return value !== undefined && value !== null
};
export function isInvalid(value: any): value is Nullish {
    return value === undefined || value === null
};

const lodash = require('lodash');

const allowedCharTable: Array<string> = getValidASCIITable();
const readbleCharTable: Array<string> = getReadbleASCII();
const fromaToZ: Array<string> = getReadbleAlphabet();
const lowfromAtoZ: Array<string> = getLowCaseAlphabet();
const upperFromAtoZ: Array<string> = getUpperFromAtoZ();
const from0To9: Array<string> = get0To9();
const specialCharConst = '@!.*&%=+-';

export type SafeIdText = NamedString<'SafeId'>;


export class SafeId {

    private time: number;
    private date: Date;

    constructor(
        public id?: SafeIdText,
    ) {
        if (!SafeId.isValidSafeIdBySize(this.id)) {
            throw new Error('Invalid safe id');
        }
        const info = this.id ? SafeId.getInfoFromId(this.id) : SafeId.getSafeRandomIdInfo();
        this.id = SafeId.create(info);
        this.time = info.timestamp.time;
        this.date = info.timestamp.date;
    }

    private static isValidSafeIdBySize(id: string | undefined): id is string {
        return !!(id && id.length === 24);
    }

    static safeIdCreation = new Date('02/11/2009');
    static safeIdCreationTime = SafeId.safeIdCreation.getTime();

    public static isValidSafeId(id: string | undefined): id is SafeIdText {
        try {
            if (!SafeId.isValidSafeIdBySize(id)) return false;
            const info = SafeId.getInfoFromId(id);
            if (Number.isNaN(info.timestamp.time)) return false;
            if (info.timestamp.time > getClock()) return false;
            if (info.timestamp.time < SafeId.safeIdCreationTime) return false;
            return true;
        } catch {
            return false;
        }

    }

    static getInfoFromId(id: SafeIdText): SafeIdInfo {
        const hexTimestamp = id.slice(0, 8);
        const time = parseInt(hexTimestamp, 16) * 1000;
        const date = new Date(time);
        const generated = id.slice(8);

        return {
            generated,
            timestamp: {
                date,
                time,
                hex: hexTimestamp,
            }
        }
    }

    public toHexString() {
        return this.id;
    }

    public static generateHexTimestamp() {
        const { hex } = this.generateTimestamp()
        return hex;
    }

    public static generateTimestamp(time: number = getClock()) {
        const date = new Date(time)
        const hex = (time / 1000 | 0).toString(16);

        return {
            hex,
            time,
            date,
        };
    }

    public getTimestamp(): Date {
        return this.date;
    }

    public getTime(): number {
        return this.time;
    }

    public static getUUID() {
        const generated = getRandomIdentifier(16, randomChar);
        return generated;

        function randomChar() {
            return (Math.random() * 16 | 0).toString(16).toLowerCase();
        }
    }

    public static getSafeRandomIdInfo(): SafeIdInfo {
        const generated = SafeId.getUUID();

        return {
            timestamp: SafeId.generateTimestamp(),
            generated,
        };
    }

    public static create(info: SafeIdInfo = SafeId.getSafeRandomIdInfo()): SafeIdText {
        const { generated, timestamp: { hex } } = info;
        return `${hex}${generated}`;
    }

}

export interface SafeIdInfo {
    timestamp: {
        hex: string;
        time: number;
        date: Date;
    };
    generated: string;
}


export function getRandomIdentifier(size: number, generator: () => string = () => _.sample(readbleCharTable)!) {
    let out: string = '';
    while (out.length < size) out += generator();
    return out;
}

export interface IChangePoint<A, B> {
    indexOfNew: number;
    indexOfOld: number;
    oldState: A;
    newState: B
}

export type TIChangePointArray<A, B> = Array<IChangePoint<A, B>>
export interface IChangedArrayAnalysis<A, B> {
    onlyInA: A[];
    onlyInB: B[];
    changesFromAtoB: TIChangePointArray<A, B>;

    isChanged: boolean
}

export function nop() { };

export function stringValidCharactersTokenizer(input: string): Array<string> {
    let emojis: string[] = input.match(emoticons);
    input = input.replace(emoticons, ' ').replace(notAllowdTokenCharacter, '');
    let result: string[] = input.toLocaleLowerCase().match(globalValidCharacters);
    result = result ? result : [];
    for (let index in result) {
        result[index] = removeAccents(result[index]).trim();
    };
    emojis = emojis ? emojis : [];
    return result.concat(emojis);
};


export function removeEmoji(input: string): string {
    const emojis = input.match(emoticons);
    if (isValidArray(emojis)) {
        let st: string = input;
        for (const emoji of emojis) {
            st = replaceAll(st, emoji, '');
        }
        return st;
    }
    return input;
}

export function hasEmoticon(input: string): boolean {
    if (isValidRef(input)) {
        const str = input.match(emoticons)
        return isValidArray(str);
    }
    return false;

}

export function matchStringOnTokenized(searchTokens: Array<string>, searchable: string): number {
    let ok: boolean = true;
    let tokens: Array<string> = stringValidCharactersTokenizer(searchable);
    let x: number = 0;

    while (ok && x < searchTokens.length) {
        ok = tokens.find((txt) => { return txt == searchTokens[x] }) ? true : false;
        ++x;
    };
    return x;
};


export function putDelimiters(what: string, begin: string, end?: string): string {
    return begin + what + (isValidRef(end) ? end : begin);
}

export function getNormalizedName(nick: string): string {
    return getNormalizedValue(nick, false).replace(/[^\w\s]/gi, '');
}

export function getValidName(name: string): string {
    return isValidString(name) ?
        name.replace(/[^\w\sÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž]/gi, '') :
        name
}

export function toLowerCase<Text extends string>(input: Text): Lowercase<Text>;
export function toLowerCase(input: undefined): undefined;
export function toLowerCase<Text extends string>(input: Text | undefined): Lowercase<Text> | undefined;
export function toLowerCase(input: string | undefined): string | undefined {
    return input?.toLowerCase();
}
export function toUpperCase<Text extends string>(input: Text): Uppercase<Text>;
export function toUpperCase(input: undefined): undefined;
export function toUpperCase<Text extends string>(input: Text | undefined): Uppercase<Text> | undefined;
export function toUpperCase(input: string | undefined): string | undefined {
    return input?.toUpperCase();
}

export function getNormalizedValue(nick: string, toLowerCase: boolean = true): string {
    if (isInvalidString(nick)) {
        return '';
    }
    let st: string = removeAccents(toLowerCase ? nick.toLocaleLowerCase() : nick);
    st = replaceString(st, '.', '');
    st = replaceString(st, '_', '')
    return st.trim(); // .replace(nameUniqueSplitter, '');
}

export function arrayDiff<A, B>(setOfA: A[],
    setOfB: B[],
    getKey: (a: A | B) => any = (a) => a,
    isChangedFn: (a: A, b: B) => boolean = undefined): IChangedArrayAnalysis<A, B> {
    const setAMap: Map<any, any> = new Map();
    const setBMap: Map<any, any> = new Map();

    const indexAMap: Map<any, number> = new Map();
    const indexBMap: Map<any, number> = new Map();

    for (let k = 0; k < setOfA.length; ++k) {
        const c = setOfA[k];
        const key = getKey(c);
        setAMap.set(key, c);
        indexAMap.set(key, k)
    }

    for (let k = 0; k < setOfB.length; ++k) {
        const c = setOfB[k];
        const key = getKey(c);
        setBMap.set(key, c);
        indexBMap.set(key, k)
    }


    const onlyInA: A[] = [];
    const onlyInB: B[] = [];
    const changesFromAtoB: TIChangePointArray<A, B> = [];
    const hasChangeFN: boolean = isValidRef(isChangedFn);


    setAMap.forEach((setAValue, keyA) => {
        if (setBMap.has(keyA)) {
            if (hasChangeFN) {
                const setBvalue = setBMap.get(keyA);
                if (isChangedFn(setAValue, setBvalue)) {
                    const regChanged: IChangePoint<A, B> = {
                        oldState: setBvalue,
                        newState: setAValue,
                        indexOfNew: indexAMap.get(keyA),
                        indexOfOld: indexBMap.get(keyA)
                    }
                    changesFromAtoB.push(regChanged);
                }
            }

        } else {
            onlyInA.push(setAValue)
        }
    });

    setBMap.forEach((setBValue, keyB) => {
        if (!setAMap.has(keyB)) {
            onlyInB.push(setBValue)
        }
    });


    return {
        onlyInA,
        onlyInB,
        isChanged: isValidArray(onlyInA) || isValidArray(onlyInB) || isValidArray(changesFromAtoB),
        changesFromAtoB
    };
}

export function getNormalizedEmail(email: string): string {
    let st: string = removeAccents(email.toLocaleLowerCase());
    let strings: TArrayID = st.split('@')
    st = getNormalizedValue(strings[0]);
    return st + (isValidRef(strings[1]) ? '@' + strings[1] : '')
}



export function noAccentsAndLower(str: string): string {
    if (isInvalid(str)) {
        return '';
    }
    return removeAccents(str.toLowerCase()).trim();
};




export function removeAccents(strWithAccents: string): string {
    let strWithoutAcctens: string = '';

    for (const letter of strWithAccents) {
        const index: number = accentList.indexOf(letter);
        strWithoutAcctens += index == -1 ? letter : accentReplacements[index];
    };

    return strWithoutAcctens;
}

type TCRC = Array<any>;
const tableCRC: TCRC = makeCRCTable();
export const delay: (ms: number) => Promise<unknown> = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export function nextTick<Fn extends TFunction>(fn: Fn): ReturnType<Fn> {
    let result: ReturnType<Fn>;
    setTimeout(() => {
        result = fn();
    }, 0);
    return result;
}

export function validateRequest(request: IRequest): boolean {
    return isAllValid(request, request.dateTime, request.timezone, request.requestType);
};

function makeCRCTable(): TCRC {
    let crcTable: TCRC = [];
    let c: number;
    for (let n = 0; n < 256; n++) {
        c = n;
        for (let k = 0; k < 8; k++) {
            c = ((c & 1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
        }
        crcTable.push(c);
    }
    return crcTable;
};

export function crCheck32(src: string): number {
    let crc: number = 0 ^ (-1);

    for (let i = 0; i < src.length; i++)
        crc = (crc >>> 8) ^ tableCRC[(crc ^ src.charCodeAt(i)) & 0xFF];
    return (crc ^ (-1)) >>> 0;
};

export function isNumeric(num: any): boolean {
    return !isNaN(num)
}

export function intOrInvalid(num: any): number {
    return (isInvalid(num) || isNaN(num)) ? undefined : parseInt(num);
}

export function intOrZero(num: any): number {
    return (isInvalid(num) || isNaN(num)) ? 0 : parseInt(num);
}
export function floatOrInvalid(num: any): number {
    return (isInvalid(num) || isNaN(num)) ? undefined : parseFloat(num);
}



// retorna true se NaN
export function isCrazyNaN(value: any): boolean {
    return Number.isNaN(value)
}


export function isAllNumeric(...nums: Array<any>): boolean {
    return isValidArray(nums) && nums.every((n) => { return isNumeric(n) });
}

export function auxTypeOf(value) {
    return ({}).toString.call(value).toLowerCase().slice(8, -1)
}

export function getCRC(src: any): number {
    if (typeof src == 'string')
        return crCheck32(src);
    else if (src instanceof Serializable)
        return crCheck32(JSON.stringify(src.toJSON()));
    else
        return crCheck32(JSON.stringify(src));
};

export function validateEmail(email: string): boolean {
    let re: RegExp = emailPattern;
    return re.test(email);
};


export function isValidCityInputByRegex(input: string | undefined): boolean { 
    if(!input) { 
        return false;
    }

    let re: RegExp = genericBrazilianCityPattern;
    return re.test(input);
}

export function isValidCNPJByRegex(input: string | undefined): boolean { 
    if(!input) { 
        return false;
    }

    let re: RegExp = cnpjPattern;
    return re.test(input);
}

export function isValidCPFByRegex(input: string | undefined): boolean { 
    if(!input) { 
        return false;
    }

    let re: RegExp = cpfPattern;
    return re.test(input);
}

export function isValidPixRandomKeyByRegex(input: string | undefined): boolean { 
    if(!input) { 
        return false;
    }

    let re: RegExp = pixRandomKeyPattern;
    return re.test(input);
}

export function getCleanedPhoneNumber(input: string | undefined): string | undefined {
    if(!input) { 
        return;
    }
    return input.replace(/\D/g, '');
}

export function isValidBRPhoneNumberByRegex(input: string | undefined): boolean { 
    if(!input) { 
        return false;
    }

    let re: RegExp = brPhoneNumberPattern;
    return re.test(input);
}

export function isValidCleanedBRPhoneNumberByRegex(input: string | undefined): boolean { 
    if(!input) { 
        return false;
    }

    let re: RegExp = cleanedBRPhoneNumberPattern;
    return re.test(input);
}

export function enforcedIsValidBrPhoneNumber(input: string | undefined): boolean {
    if (!input) {
        return false;
    }

    const cleanedString = getCleanedPhoneNumber(input);
    if (!cleanedString) {
        return false;
    }
    
    return isValidGeneralPhoneNumber(cleanedString);
}

export function safeGetCleanedPhoneNumber(input: string | undefined, completeNumber: boolean = true): string | undefined { 
    if(!input) { 
        return;
    }

    if(!enforcedIsValidBrPhoneNumber(input)) {
        return;
    }

    const cleanedString = getCleanedPhoneNumber(input);
    return completeNumber 
        ? cleanedString
        : cleanedString?.slice(-9);
}


//6 caracteres, um especial, um maiusculo
export const ValidPWDRegex = /(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%&*(),+.¹²³^ˆ_˜=<>`:;\-~'\\"\[\]\{\}\|]).{6,}/;
const invalidUTF8 = /[\u0080-\uffff]/;

export function isStrongPWD(password: string): boolean {
    return ValidPWDRegex.test(password) && !invalidUTF8.test(password);
};

function getValidASCIITable(): Array<string> {
    let r: Array<string> = [];
    addCharIntervalToTable(r, 40, 46); //-().+  //7+10+26+1+26 = 70
    addCharIntervalToTable(r, 48, 57); // 0..9
    addCharIntervalToTable(r, 65, 90); // A..Z
    addCharIntervalToTable(r, 95, 95); //_
    addCharIntervalToTable(r, 97, 122); // a..z
    return r;
}

function getReadbleASCII(): Array<string> {
    let r: Array<string> = [];
    addCharIntervalToTable(r, 48, 57); // 0..9
    addCharIntervalToTable(r, 65, 90); // A..Z
    addCharIntervalToTable(r, 97, 122); // a..z
    return r;
}


function getReadbleAlphabet(): Array<string> {
    let r: Array<string> = [];
    addCharIntervalToTable(r, 65, 90); // A..Z
    addCharIntervalToTable(r, 97, 122); // a..z
    return r;
}


function getUpperFromAtoZ(): Array<string> {
    let r: Array<string> = [];
    addCharIntervalToTable(r, 65, 90); // A..Z
    return r;
}


function getLowCaseAlphabet(): Array<string> {
    let r: Array<string> = [];
    addCharIntervalToTable(r, 97, 122); // a..z
    return r;
}


function get0To9(): Array<string> {
    let r: Array<string> = [];
    addCharIntervalToTable(r, 48, 57); // 0..9
    return r;
}


function addCharIntervalToTable(table: Array<string>, init: number, end: number): void {

    for (let char = init; char <= end; ++char) {
        table.push(String.fromCharCode(char));
    };
}


function readbleASCII(exaDigits: string): string {
    const charIni: number = 32;
    const charEnd: number = 126;
    const interval: number = charEnd - charIni + 1;
    let ct: number = 0;
    let ret: string = '';
    let readableDigit: number;
    while (ct < 32) {
        readableDigit = (parseInt(exaDigits.substr(ct, 2), 16) % interval) + charIni;

        if (!forbidenAsc.includes(String.fromCharCode(readableDigit))) {
            ret += String.fromCharCode(readableDigit);
        } else {
            ret += String.fromCharCode(Math.random() * (90 - 65) + 65);
        }
        ct += 2;

    };
    return ret;

}

export function random(limit: number, inferiorLimit: number = 0): number {
    if (inferiorLimit >= limit) {
        return undefined;
    }
    let rd: number = 0;

    do {
        rd = Math.floor(Math.random() * (limit)) + inferiorLimit;
    } while (rd > limit);

    return rd;
};


export function playYourChance(chanchesPercentage: number, totalChance: number): boolean {
    const dice = random(totalChance, 0);
    return dice <= chanchesPercentage;
}

export function stringToNumber(txt: string): number {
    let sum: number = 0;
    for (let i of txt) {
        sum += i.charCodeAt(0);
    };
    return sum;
};

export function fromStringToBoolean(stBoolean: string): boolean {
    return stBoolean == 'true' ? true : false;
}

export function isValidStringAndSize(str: string, min: number, max?: number): boolean {
    return isValidRef(str)
        && isValidRef(str.length)
        && str.length >= min
        && (isInvalid(max) || str.length <= max);
}

export function getUniqueStringID(codeSize: number = 30): string {
    return getReadableUniqueID(codeSize);
};


export function _getUniqueStringID(codeSize: number = 30): string {
    let ret: string = '';
    let limit: number = allowedCharTable.length;
    for (let k = 0; k < codeSize; ++k) {
        ret += allowedCharTable[random(limit)];
    };
    return ret;
};

export enum ERandonCharType {
    allReadable = 'all',
    alphabetOnly = 'letterOnly',
    numberOnly = 'number',
    numberAndChar = 'numberAndLetter',
    lowCaseChar = 'lowcase',
    specialChars = 'special',
    upperCaseChar = 'upperCase',
}
export function getUniqueId(): string {
    return getReadableUniqueID(32);
}
export function getUniqueIdNS(): string {
    return getReadableUniqueID(32);
}

export function getReadableUniqueID(codeSize: number = 30, randomType: ERandonCharType = ERandonCharType.allReadable, specials: string = specialCharConst): string {
    let table: Array<string>;
    if (randomType === ERandonCharType.allReadable) {
        table = readbleCharTable

    } else if (randomType === ERandonCharType.alphabetOnly) {
        table = fromaToZ;

    } else if (randomType === ERandonCharType.lowCaseChar) {
        table = lowfromAtoZ;

    } else if (randomType === ERandonCharType.upperCaseChar) {
        table = upperFromAtoZ;

    } else if (randomType === ERandonCharType.numberAndChar) {
        table = fromaToZ.concat(from0To9);

    } else if (randomType === ERandonCharType.specialChars) {
        table = specials.split('');

    } else {
        table = from0To9;
    }
    let ret: string = '';
    let limit: number = table.length;
    for (let k = 0; k < codeSize; ++k) {
        if (k === 0 && randomType === ERandonCharType.allReadable) { // readables sempre devem retornar o primeiro caracter como letra
            ret += fromaToZ[random(fromaToZ.length)]
        } else {
            ret += table[random(limit)];
        }
    };
    return ret;
};


export type EMaxPerElement = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

export interface IElementPWD {
    type: ERandonCharType;
    times: EMaxPerElement;
}
export type TArrayIElementPWD = Array<IElementPWD>;

export function generateNormalizedPWD(elements: TArrayIElementPWD, validSpecials: string = specialCharConst): string {
    let i = 0;
    elements = elements.filter(e => isValidRef(e));
    const size = elements.length;
    const allElements: Array<number> = Array.from(Array(size), () => i++);

    let st: string = '';

    while (isValidArray(allElements)) {
        const index = random(allElements.length, 0);
        const rd = allElements.splice(index, 1);
        const el = elements[rd[0]];
        st += getReadableUniqueID(el.times, el.type, validSpecials)
    }
    return st;
};

export function getDomainFromEmail(email: string): string {
    return email.split('@').pop();
}

export function getUniqueExaID(size: number = null): string {
    let gen: string =
        'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    return size ? gen.substr(0, size) : gen;
}

export function getSumAscii(str: string): number {
    let sum: number = 0;
    for (const char of str) {
        sum += char.charCodeAt(0);

    }

    return sum;

}

const regexfromLeft: RegExp = /^[^A-Za-z0-9+]+/;
export function leftTrim(str: string): string {
    if (isValidString(str)) {
        return str.replace(regexfromLeft, '');
    }
    return undefined;
}


export function hasInvalidCharaterOnBegin(str: string): boolean {
    return isValidString(str) && regexfromLeft.test(str);
}


export function padLeft(code: string | number, size: number, padChar: string = '0'): string {
    return Array(size - String(code).length + 1).join(padChar || '0') + code;
};

export function padRight(code: string | number, size: number, padChar: string = '0'): string {
    return code + Array(size - String(code).length + 1).join(padChar || '0');
};

export function dateToString(d: Date): string {
    return d.getFullYear().toString() + padLeft(d.getMonth() + 1, 2) + padLeft(d.getDate(), 2);
};


export function getHourMinuteFromDate(d: Date): string {
    return d.getHours().toString() + padLeft(d.getMinutes(), 2) + '0000';
}

export function toDate(st: any): Date {
    let d: Date;
    if (st) {
        d = new Date(Date.parse(st));
    };
    return d;
};



export function getSlicedArray<T>(array: Array<T>, slice: number): Array<Array<T>> {
    const ret: Array<Array<T>> = [];
    const size: number = array.length;
    let k: number = 0;
    while (k < size) {
        const jump: number = k + slice < size ? k + slice : size;
        const offset: number = jump - k;
        ret.push(array.slice(k, jump));
        k += offset;
    };
    return ret;
};



export function arrayToString(array: Array<any>): string {
    return array.reduce((previous, current) => { return previous.toString() + current.toString() + ' ' }, '').trim();
}

export function hashToArray<T>(hash: IHashTableAlias): Array<T> {
    return Object.keys(hash).filter(key => hash[key] != null).map(key => hash[key]);
};

export function arrayToHash<T extends {}, Property extends (keyof T), Target extends T[Property] & string>(propertyName: Property, array: T[], fillWith?: any): { [id in Target]: T };
export function arrayToHash<T extends {}>(propertyName: string, array: T[], fillWith?: any): { [id in string]: T };
export function arrayToHash<T extends {}>(propertyName: string, array: T[], fillWith?: any): { [id in string]: T } {
    const ret: { [id: string]: T } = {};
    array.forEach((el) => {
        ret[el[propertyName]] = fillWith ?? el;
    })
    return ret;
}

export function typedArrayToHash<T extends object, Property extends keyof T>(list: T[], property: Property) {
    return (() => arrayToHash(property as string, list)) as T[Property] extends string ? () => Record<T[Property], T> : unknown;
}


export function arrayToHashByPredicate<T extends Object>(array: Array<T>, getPropertyName: (item: T) => string): { [id: string]: T } {
    const ret: { [id: string]: T } = {};
    array.forEach((el) => {
        ret[el[getPropertyName(el)]] = el;
    })
    return ret;
}

export function getObjectFields(obj: Object): Array<string> {
    return Object.keys(obj);
};

export function regexMatchAll(str: string, reg: RegExp): TArrayID {
    const ret: TArrayID = [];
    const res = str['matchAll'](reg);
    for (const r of res) {
        ret.push(r[1]);
    }
    return ret;
}

export function getAllValuesFromPattern(text: string, pattern: RegExp): string {
    if (isInvalidString(text)) {
        return '';
    }
    let result: string[] = [];
    text.replace(pattern, (item: string) => (result.push(item), undefined));
    return result.join('');
}

export function getOnlyDigits(data: string): string {
    return getAllValuesFromPattern(data, /\d/g);
}


export function hashToArrayTyped<T>(hash: IHashSet<T>): Array<T> {
    return Object.keys(hash).filter(key => hash[key] != null).map(key => hash[key]);
};

export function hashToArrayTypedNumberIndexed<T>(hash: IHashSetNumberIndexed<T>): Array<T> {
    return Object.keys(hash).filter(key => hash[key] != null).map(key => hash[key]);
};



export function serializableArrayToHash(array: Array<Serializable>): IHashTableAlias {
    let hash: IHashTableAlias = {};
    for (let serializable of array)
        hash[serializable.getPrimaryID()] = serializable;
    return hash;
};


export function propertyValueArrayToObject(property: any[]): Object {
    let obj: { [property: string]: any } = {};
    let idx: number = 0;
    while (idx < property.length) {
        obj[property[idx]] = isValidRef(property[idx + 1]) ? property[idx + 1] : '';
        idx += 2;
    };
    return obj;
}

export function formatDataTime(date: Date): string {
    return date.toLocaleDateString();
};

export function isValidDate(d: Date): boolean {
    return d instanceof Date && !isNaN(d.getTime());
}

export class PercentageCalculator {
    public static percentageConstant: number = 100;
    public static fromPercentage(value: number): number { return value / PercentageCalculator.percentageConstant }
    public static toPercentage(value: number): number { return value * PercentageCalculator.percentageConstant }
}

export function getTimeInfoByMiliseconds(ms: number): IGetTimeInfo {
    const seconds: number = Math.trunc((ms / 1000) % 60);
    const minutes: number = Math.trunc((ms / 1000 / 60) % 60);
    const hours: number = Math.trunc((ms / 1000 / 60 / 60) % 24);
    const days: number = Math.trunc((ms / 1000 / 60 / 60 / 24));

    return {
        seconds,
        minutes,
        hours,
        days,
        months: 0
    }
}

export function isEven(number: number): boolean {
    return number % 2 === 0;
}
export function isOdd(number: number): boolean {
    return !isEven(number);
}



export function printTimeInfo(ms: number, translatedText: IPrintTimeInfoTranslatedText, shouldHide: TPrintTimeInfoShouldHideOptions = {}): string {
    const info: IGetTimeInfo = getTimeInfoByMiliseconds(ms);

    const get: (keyof TPrintTimeInfoShouldHideOptions)[] = genericTypedSuggestions<(keyof IGetTimeInfo)[]>()([
        'days',
        'hours',
        'minutes',
        'seconds',
    ]);

    const toPrint: string[] = get
        .filter((item: typeof get[number]) => !shouldHide[item])
        .filter((item: typeof get[number]) => info[item])
        .map((item: typeof get[number]) => print(info[item], item))
        ;

    const beforeLast: string = toPrint.slice(0, -1).join(', ');
    const lastItem: string = last(toPrint);

    return [beforeLast, lastItem].filter(Boolean).join(` ${translatedText.and} `);

    function print(time: number, fromTranslation: keyof IGetTimeInfo) {
        let result: string = `${time} ${printWordText(info[fromTranslation], translatedText[fromTranslation])}`;
        return result;
    }
    function printWordText(info: number, translation: IPluralAndSingularText) {
        return (isSingular(info) ? translation.singular : translation.plural);
    }
}

function isSingular(number: number = 0): boolean {
    return number <= 1;
}


export function getDate(): SmartDate {
    return new Date();
};




export function getClock(): Miliseconds {
    return Date.now();
}


export function fixTimestamp(clockTick: number, offset: number): Miliseconds {
    return clockTick + offset;
}



export function isBetween(numeric: number, init: number, end: number): boolean {
    return isValidRef(numeric) && (numeric >= init && numeric <= end);
}

export function revertObject(object: Record<string, string>) {
    return Object.fromEntries(
        Object
            .entries(object)
            .map(([key, value]) => [value, key])
    )
}

export function reverseString(st: string): string {
    let newSt: string = '';
    for (let c = st.length - 1; c >= 0; c--)
        newSt += st[c];
    return newSt;
};

export function numberToReverse(id: number): string {
    return reverseString(id.toString());
};

export function reverStringToID(st: string): number {
    return parseInt(reverseString(st));
};


export function concat(...msgArray: any[]): string {
    let finalMessage: string = '';
    for (let msg of msgArray) {
        if (msg) {
            finalMessage += msg.toString();
        };
    };
    return finalMessage;
};


export function putBrackets(...elements: string[]): string {
    let result = '';

    for (let element of elements) {
        result += element + '/';
    }

    return result.slice(0, -1);
}

export function throwNewAPICustomError(idError: EColmeiaAPIErrors, description: string, extraInfo?: object): void {
    throw CustomError.getNewAPIError(idError, description, extraInfo)
};

export function throwCustomError(idError: TGlobalUID, isError: boolean, functionName: string, ...msgArray: any[]): void {
    throw new CustomError(idError, isError, functionName, msgArray);
};

export function throwCustomFieldError(idError: TGlobalUID, idField: number, isError: boolean, functionName: string, ...msgArray: any[]): void {
    throw new CustomErrorField(idError, isError, idField, functionName, msgArray);
};

export function throwErrorIfTrue(expression: boolean, errorCode: TGlobalUID, isError: boolean, functionName: any, ...msgArray: any[]): void {
    if (expression) {
        throw new CustomError(errorCode, isError, functionName, msgArray);
    };
};

export function throwErrorIfTrueField(expression: boolean, errorCode: TGlobalUID, idField: number, isError: boolean, functionName: any, ...msgArray: any[]): void {
    if (expression) {
        throw new CustomErrorField(errorCode, isError, idField, functionName, msgArray);
    };
};

export function getErrorFromTranslation(translation: ITranslationConfig, functionName: any, ...msgArray: any[]): CustomErrorField {
    const errorCode: TGlobalUID = translation.serializableId;
    const idField: number = translation.idField;
    return new CustomErrorField(errorCode, true, idField, functionName, msgArray);
}

export function throwErrorIfTrueTranslation(expression: boolean, translation: ITranslationConfig, isError: boolean, functionName: any = getFnName(2), ...msgArray: any[]): void {
    const errorCode: TGlobalUID = translation.serializableId;
    const idField: number = translation.idField;

    if (expression) {
        throw new CustomErrorField(errorCode, isError, idField, functionName, msgArray);
    };
};


export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> =
    Keys extends unknown ? Compute<(Required<Pick<T, Keys>> & T)> : never
    ;



export function throwErrorWithAditionalMessages(translation: ITranslationConfig, ...msgArray: any[]): never {
    // functionName: any = getFnName(2),
    throwErrorTranslation(translation, getFnName(2), msgArray)
}
export function throwErrorTranslation(translation: ITranslationConfig, functionName: any = getFnName(2), ...msgArray: any[]): never {
    const errorCode: TGlobalUID = translation.serializableId;
    const idField: number = translation.idField;

    throw new CustomErrorField(errorCode, true, idField, functionName, msgArray);
};

export function throwErrorIfTrueExpr(expression: boolean, translation: ITranslationConfig, ...errorDescription: string[]): void {
    return throwErrorIfTrueTranslation(expression, translation, true, (new Error()).stack.split('\n')[2], errorDescription)
}

export function throwPropagateError(idError: TGlobalUID, err: Error, functionName: string, ...messageArray: any[]): never {
    throw new ErrorPropagate(idError, err, functionName, messageArray)
};

export function throwPropagateErrorField(idError: TGlobalUID, idField: number, err: Error, functionName: string, ...messageArray: any[]): never {
    throw new ErrorPropagateField(idError, err, idField, functionName, messageArray);
};

export function dehydrate(object: any): string {
    var seenObjects = [];
    function inspectElement(key, value) {
        if (detectCycle(value)) {
            return '[Ciclical]';
        } else {
            return value;
        };
    };
    function detectCycle(obj): boolean {
        if (obj && (typeof obj == 'object')) {
            for (let r of seenObjects) {
                if (r == obj) {
                    return true;
                };
            };
            seenObjects.push(obj);
        };
        return false;
    };
    let json: string = JSON.stringify(object, inspectElement, '  ');
    return json;
};

export function plainDehydrate(object: any): string {
    var firstObject = true;
    function inspectElement(key, value) {
        if (firstObject) {
            firstObject = false;
            return value;
        } else if (value && (typeof value == 'object')) {
            return '[Object]';
        } else {
            return value;
        }
    };
    let json: string = JSON.stringify(object, inspectElement, '  ');
    return json;
};



export function compareCanonicalObj(canonical: any, actual: any, ignore: IIgnoreProperty = {}): boolean {
    let ok: boolean = true;
    let count: number = 0;
    if (canonical == actual)
        return true;

    // Data são objetos mas não tem property portanto não irão interagir no for abaixo
    else if (canonical instanceof Date) {
        if (!(actual instanceof Date)) {
            return false;
        }
        return canonical.getTime() == actual.getTime();

    } else if (!(canonical instanceof Object && actual instanceof Object))
        return false;

    for (let property in canonical) {

        if (ignore[property]) {
            continue;
        }

        ++count;  // é um objeto e tem propriedades

        if (actual.hasOwnProperty(property)) {
            ok = compareCanonicalObj(canonical[property], actual[property]);
            if (!ok) {
                return false;
            };
        } else {
            let ret = !canonical[property]
            return ret; // se for null, podemos dar ok.. bug no fullclone
        };
    };
    if (count == 0 && Array.isArray(canonical)) { // apenas Array[0] não iria ser processado acima
        let ret = canonical.length == 0 && actual.length == 0
        return ret;
    }
    return true;
};

export function cloneArrayKeepingElements<T extends Array<any>>(vet: T): T {
    return <T>vet.filter((ele) => { return true })
};

export function partialCloneObject(canonical: any): any {
    return Object.assign(Object.create(canonical), canonical);
};

export function typedClone<T>(canonical: T): T {
    return <T>fullCloneObject(canonical);
}


export function fullCloneObject(canonical: any): any {
    return cloneDeep(canonical)
};


/**
 * This method is like _.clone except that it recursively clones value.
 *
 * @param value The value to recursively clone.
 * @return Returns the deep cloned value.
 */
export const typedCloneLodash = cloneDeep;

export function typedCloneNative<T>(source: T): T {
    return globalThis.structuredClone(source);
}

export function isObjectJSWithInvalidJSPropertyName(obj: Object): boolean {
    return isValidArray(getInvalidJSPropertyName(obj));
}

export function getInvalidJSPropertyName(obj: Object): Array<string> {
    const invalidFields: Array<string> = [];
    getInvalidPropertyNameAux(obj, invalidFields, '');
    return invalidFields;
}

function getInvalidPropertyNameAux(canonical: any, fields: Array<string>, propParent: string): void {
    if (canonical === null) {
        return

    } else if (Array.isArray(canonical)) {
        for (let count = 0; count < canonical.length; ++count) {
            getInvalidPropertyNameAux(canonical[count], fields, propParent)
        };


    } else if ((canonical instanceof Object)) {
        for (let property in canonical) {
            if (isJSInvalidPropertyName(property)) {
                const error = (isValidString(propParent) ? propParent + '.' : '') +
                    isInvalidValidTrimmedString(property) ? 'null/empty' : property;

                fields.push(error);
            } else {
                const next = (isValidString(propParent) ? (propParent + '.') : '') + property;
                (getInvalidPropertyNameAux(canonical[property], fields, next));
            }
        };
    };
    return;
};

const validRegexPropName = /^@?[a-zA-Z_]\w*(\.@?[a-zA-Z_]\w*)*$/

function isJSInvalidPropertyName(prop: string): boolean {
    return isInvalidString(prop) || !validRegexPropName.test(prop)
}






export async function execBackground(promise: Promise<any>): Promise<void> {
    await promise;
};


export function cloneObject<T>(toBeCloned: any): T {
    let cloned: T = <T>JSON.parse(JSON.stringify(toBeCloned));
    fixDate(toBeCloned, cloned);
    return cloned;
};

export function toStringIfNotString(v: any): string {
    return isValidRef(v) ? v.toString() : null
}

// processo de clonagem irá transformar data em texto..
// retornamos isso
function fixDate(original: any, cloned: any): void {
    if (original instanceof Date) {
        cloned = toDate(cloned);
    }
    for (let property in original) {
        if (original[property] instanceof Date) {
            cloned[property] = toDate(cloned[property]);
        } else if (original[property] instanceof Object) {
            fixDate(original[property], cloned[property]);
        };
    };
};

export function comparedObject(obj1: any, obj2: any, ignore: IIgnoreProperty = {}): boolean {
    return compareCanonicalObj(obj1, obj2, ignore) && compareCanonicalObj(obj2, obj1, ignore);
};

export function removeDuplicates(a: any[]): any[] {
    let seen = {};
    return a.filter(item => seen.hasOwnProperty(item) ? false : (seen[item] = true));
}

export function dictionaryHasKey(dictionary: any, key: string) {
    for (let dicKey in dictionary) {
        if (dicKey === key)
            return true;
    }
    return false;
}

export function turnJSONIntoURLParameters(json: Object): string {
    return Object.keys(json).map((k) => {
        return encodeURIComponent(k) + '=' + encodeURIComponent(json[k])
    }).join('&');
}

export function turnURLParametersIntoJSON<Url extends string & SpecificOverload<Url>>(url: Url): TurnURLParametersIntoJSON.Execute<Url>;
export function turnURLParametersIntoJSON(url: string): TurnURLParametersIntoJSON.Output;
export function turnURLParametersIntoJSON(url: string): TurnURLParametersIntoJSON.Output {
    let hash: string[];
    let myJson: TurnURLParametersIntoJSON.Output = {};
    const hashes: string[] = url.slice(url.indexOf('?') + 1).split('&');

    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        myJson[hash[0]] = hash[1];
    }

    return myJson;
}


export function getPropertyFromParams(params: string, property: string): string {
    return turnURLParametersIntoJSON(params)[property]!;
};

export function isAllTrue(...params: Array<boolean>): boolean {
    return params.every((p) => { return p });
};

export function isValidArray(array: Array<any> | Nullish, min: number = 1): boolean {
    return isValidRef(array) && Array.isArray(array) && array.length >= min;
};

export function isValidArrayAndRef<T>(array: T[]): boolean {
    return (isValidRef(array) && Array.isArray(array)) && ((array.length >= 1) && isValidRef(array?.[0]));
};


export function isValidArrayWithFilter<T>(array: T[], min: number = 1): boolean {
    return isValidRef(array) && array.filter((item: T) => isValidRef(item)).length >= min;
};

export function isInvalidArrayWithFilter<T>(array: T[], min: number = 1): boolean {
    return !isValidArrayWithFilter(array, min);
};


export function isInvalidArray(array: Array<any> | Nullish, min: number = 1): boolean {
    return !isValidArray(array, min);
};


export function isValidRegex(regex: string): boolean {
    try {
        const match: string = '   ';
        // instancia para regex
        const ret = match.match(regex);
        return true;

    } catch (err) {
        return false;

    }
}

export function noop(): void { }
export async function asyncNoop(): Promise<void> { }

export function isValidFunction<T>(fn: Function | T): fn is Function {
    return typeof fn === 'function';
}


/**
 * A little bit differnt from isValidFunction, works maintaining the original function type.
 */
export function isFunctionType<T>(fn: T): fn is Extract<T, Function> {
    return typeof fn === 'function';
}


export function ifValid<T>(main: T, contingence: T): T {
    return isValidRef(main) ? main : contingence;
}

export function getIfNotValid<T>(value: T | undefined, valueIfNotValid: T): T {
    return isValidRef(value) ? value : valueIfNotValid;
};


export function isInvalidOrEqual(value: any, equalTo: any): boolean {
    return isInvalid(value) || value === equalTo;
}

export function isInvalidNumber(numeric: number | undefined, min: number = 1): boolean {
    return !isValidNumber(numeric, min);
}

export function isValidNumber(numeric: number | undefined, min: number = 1): boolean {
    return isValidRef(numeric) && numeric >= min;
}


export function isAllValid(...value: Array<any>): boolean {
    return value.every((x) => { return isValidRef(x) });
}

export function hasInvalid(...value: Array<any>): boolean {
    return !isAllValid(...value);
}

export function isAtLeastOneValid(...value: Array<any>): boolean {
    return value.some((x) => { return isValidRef(x) });
}

export function isValidAndEqual<T>(value1: T, value2: T): boolean {
    return isValidRef(value1) && isValidRef(value2) && value1 === value2;
};
export function isValidAndDifferent(value1: any, value2: any): boolean {
    return isValidRef(value1) && isValidRef(value2) && value1 !== value2;
};

export function isValidString<T>(str: T | undefined, min: number = 1): str is (T & string) {
    return isValidRef(str) && typeof str === 'string' && str.length >= min;
};

export function isValidTrimmedString(str: string | undefined, min: number = 1): boolean {
    return isValidString(str, min) && str.trim().length >= min;
};

export function isInvalidValidTrimmedString(str: string, min: number = 1): boolean {
    return !isValidTrimmedString(str, min)
}


export function isValidSize(value: number, min?: number, max?: number) {
    if (isInvalid(value)) return false;
    if (isValidRef(min) && value < min) return false;
    if (isValidRef(max) && value > max) return false;
    return true;
}

export function isInvalidString(str: string | undefined, min: number = 1): boolean {
    return !isValidString(str, min);
}

export function fromBase64(s: string): string {
    return Buffer.from(s, 'base64').toString('utf8')
}

export function toBase64(s: string): string {
    return Buffer.from(s).toString('base64');
}

export function isDigitOnly(str: string) {
    const regex = /^\d+$/;
    return regex.test(str);
}



export function getFirstField(obj: {} | undefined): string | null {
    if (isInvalid(obj)) {
        return null;
    }
    for (const keys in obj) {
        return keys;
    };
    return null;
};

export function getFirstValue(obj: Object): string {
    const property: string = getFirstField(obj);
    return isValidString(property) ? obj[property] : null;
}


export const empty: any = undefined;

export function isEmptyObject(obj: {} | undefined): boolean {
    return isInvalid(getFirstField(obj))
}

export function isValidObject<T extends {}>(obj: T | undefined): boolean {
    return isValidRef(obj) && typeof obj === 'object' && !isEmptyObject(obj);
}

export function isSuperValidObject<T extends {}>(obj: T | undefined): boolean {
    if (isValidObject(obj)) {
        for (const property of Object.keys(obj)) {
            if (isValidRef(obj[property])) {
                return true;
            }
        }

    }
    return false;
}



export function isInvalidObject(obj: Object): boolean {
    return !isValidObject(obj);
}

export function flatMap<T, U>(array: T[], mapFunc: (x: T) => U[]): U[] {
    return array.reduce((cumulus: U[], next: T) => [...mapFunc(next), ...cumulus], <U[]>[]);
}

export function removeElement<T>(array: Array<T>, func: (x: T) => boolean): boolean {
    const index: number = array.findIndex((element) => { return func(element) });
    if (index > -1) {
        array.splice(index, 1);
    };
    return index > -1;
};


export function toUTCDate(clockTick: number): Date {
    return new Date(clockTick);
}


export function isThisOneOfThat(is: any, ...idArray: Array<any>): boolean {
    for (const compare of idArray) {
        if (isValidRef(compare) && compare == is) {
            return true;
        };
    };
    return false;
};

export function thisIsNotOneOfThat(is: any, ...idArray: Array<any>): boolean {
    return !isThisOneOfThat(is, ...idArray)
};


export function* hashMapToIterator<K, V>(obj: { [idx: string]: V }): IterableIterator<[string, V]> {
    for (let key in obj)
        yield [key, obj[key]];
};

export function replaceString(source: string, what: string, by: string): string {
    return source.split(what).join(by);
};

export function replaceExactString(source: string, what: string, by: string): string {
    return source.split(getExactExpressionRegex(what)).join(by)
}

export function getExactExpressionRegex(expression: string): RegExp {
    const limiter = createDelimiter(`[${expression.split('').map(item => lodash.escapeRegExp(item)).filter(item => !/\w/.test(item)).join('|').concat('\\w')}]`)
    return new RegExp(limiter + lodash.escapeRegExp(expression) + limiter, 'g')
}

export function getExactWordRegex(word: string, isCaseInsensitive: boolean = false): RegExp {
    const scope = isCaseInsensitive ? 'gi' : 'g';
    return new RegExp("\\b" + word + "\\b", scope)
}


function createDelimiter(limiter: string): string {
    return `(?:(?<!${limiter})(?=${limiter})|(?<=${limiter})(?!${limiter}))`
}

export function assertNever(x: never): never {
    throw new Error("Unexpected object: " + x);
}

export function getProfoundPropertyValue<T>(obj: Object, property: string): T {
    for (const field in obj) {
        if (field === property) {
            return obj[field]
        } else if (obj[field] instanceof Object) {
            const ret: T = getProfoundPropertyValue(obj[field], property);
            if (ret !== undefined) {
                return ret;
            }
        }
    };
    return undefined;
};


export function getProfoundPropertyWithValue(obj: Object, value: any, full: boolean): string {
    for (const property in obj) {
        if (obj[property] === value) {
            return property;
        } else if (obj[property] instanceof Object) {
            const prop: string = getProfoundPropertyWithValue(obj[property], value, full);
            if (prop !== undefined) {
                return full ? property + '.' + prop : prop;
            };
        };
    };
    return undefined;
};

export function fixedRound(num: number, decimalNumber: number): number {
    const fixed = parseFloat(num.toPrecision(decimalNumber));
    return fixed;
}

export function round(num: number, decimalNumber: number): number {
    const baseNum: number = Math.pow(10, decimalNumber);
    return Math.round(num * baseNum) / baseNum
}

export function shuffleArray<T>(array: Array<T>): Array<T> {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
}


export function polynary(...booleanAndValues: Array<any>): boolean {
    const size: number = booleanAndValues.length;
    const isPair: boolean = (size % 2) === 0
    let k = 0;
    while (k < (isPair ? size : size - 1)) {
        if (booleanAndValues[k]) {
            return (booleanAndValues[k + 1]);
        };
        k += 2;
    };
    return isPair ? null : booleanAndValues[size - 1];
};


export function getDeepPropertyWithIgnoredArray<T>(source: T, inputProperties: string[]) {
    const properties: string[][] = inputProperties.map(item => item.split('.'))
    const values = properties.map(item => getValues(source, item))
    return values
    function getValues<T>(source: T, properties: string[]) {
        try {
            let computed = source
            let index = 0
            for (let property of properties) {
                index++;
                if (Array.isArray(computed)) return computed.map((item) => getValues(item, properties.slice(index)))
                computed = computed[property]
            }
            return computed
        } catch { }
    }
}

const castPath: { (value: string | string[], object?: object): string[] } = require('lodash/_castPath')
const toKey: { (value: string): string | symbol } = require('lodash/_toKey')

export function getIgnoringArray<T extends { [key in PropertyKey]: any }, Out>(source: T, path: string | string[]): Out;
export function getIgnoringArray(source: { [key in PropertyKey]: any }, path: string | string[]): unknown {
    path = castPath(path, source)

    let index = 0
    const length = path.length

    while (source != null && index < length) {
        if (Array.isArray(source)) return source.map(item => getIgnoringArray(item, path.slice(index))).flat()
        source = source[toKey(path[index++])];
    }
    return _.flattenDeep((index && index == length) ? [source] : [])
}

const isSymbol: { (value:any): boolean } = require('lodash/isSymbol');
export function toSymbol(value: any): Symbol {
    return isSymbol(value) ? value : Symbol(value);
}

export function getDeepPropertyValue<T>(object: Object, property: string): T {
    let lastPointer: Object = object;
    const properties: Array<string> = property.split('.');
    for (const prop of properties) {
        if (isValidRef(lastPointer[prop])) {
            lastPointer = lastPointer[prop];
        } else {
            return undefined;
        }
    }

    return <T>lastPointer;
}

export function isValidDeepProperty(property: string, obj: Object): boolean {
    const value = getDeepPropertyValue(obj, property);
    return isValidRef(value);
}




export function setDeepProperty(object: Object, property: string, value: any): void {
    let lastPointer: Object = object;
    const properties: Array<string> = property.split('.');
    const size: number = properties.length;
    for (let k = 0; k < size; ++k) {
        const prop: string = properties[k];
        if (isInvalid(lastPointer[prop] && k < size - 1)) {
            lastPointer[prop] = {}
        }
        if (k === size - 1) {
            lastPointer[prop] = value
        } else {
            lastPointer = lastPointer[prop];
        }
    }

};

export function addObjectToObjectProperty(object: Object, properties: Array<string>): Object {
    let lastPointer: Object = object;
    for (const prop of properties) {
        if (!lastPointer[prop]) {
            lastPointer[prop] = {};
        };
        lastPointer = lastPointer[prop];
    }
    return lastPointer;
};


const fileDimensions = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function humanReadableFileSize(bytes: number): string {
    if (bytes == 0) return '0.00 ' + fileDimensions[0];
    const i = Math.floor(Math.log2(bytes) / 10);
    const base: number = bytes / Math.pow(1024, i);
    return base.toFixed(2) + ' ' + fileDimensions[i];
};


export function replaceAll(text: string, textToReplace: string, replacement: string): string {
    return text.replace(new RegExp(textToReplace, 'g'), replacement);
}

export function replaceAllByRegex(text: string, regex: RegExp, replacement: string): string {
    return text.replace(regex, replacement);
}

export function printObject(canonical: any, level: number = 0): string {
    return safeStringifyAnything(canonical);
};

export function isInEnum<T extends object, Value extends string | number>(_enum: T, _value: Value & CheckIfIsInEnum<ValueOf<T>, Value>): boolean {
    return Object.values(_enum).includes(_value);
}

export function enumToArray<T>(_enum: any): TArrayID { return Object.values(<T>_enum); }


export function requiredUpdateFieldSubset<T extends object>(toBeUpdated: T, setter: T): void {
    return updateFieldSubset(toBeUpdated, setter);
}

export function updateFieldSubset<T extends object>(toBeUpdated: T, setter: Partial<T>): void {
    for (const el in setter) {
        if (isValidRef(setter[el]) && setter[el] !== toBeUpdated[el]) {
            toBeUpdated[el] = setter[el]; // não entedi porque tive que por any
        };
    };
};

export function updateFieldSubsetPure<T extends object>(toBeUpdated: T, additionalInfo: Partial<T>): T {
    let newUpdatedObject: any = new Object();
    for (const el in additionalInfo) {
        if (isValidRef(additionalInfo[el]) && additionalInfo[el] !== toBeUpdated[el]) {
            newUpdatedObject[el] = additionalInfo[el]; // não entedi porque tive que por any
        };
    };
    return newUpdatedObject;
};

export function resetObject<T extends object>(source: T): void {
    for (const key in source) {
        delete source[key];
    }
}

export function resetAndUpdateObject<T extends object>(source: Nullable<T>, setter: Nullable<T>): void {
    resetObject(source);
    Object.assign(source, setter);
}

export function defaultFields<T extends object>(originalFields: T, defaultFields: Partial<T>) {
    for (let field in defaultFields)
        if (isInvalid(originalFields[field]))
            originalFields[field] = defaultFields[field];
}



export function replaceFileNameOfDirectory(fullName: string, newName: string): string {
    const parts: TArrayID = fullName.split('/');
    if (isValidArray(parts)) {
        parts[parts.length - 1] = newName;
        return parts.join('/');
    } else {
        return newName
    }

}

export function isKeysIn<T extends {}, Properties extends [...Property], Property extends (KeyOf<T> & string)[], Is extends T = T extends unknown ? Properties[number] extends infer Property ? [Property] extends [unknown] ? (HasKey<T, Assert<Property, string>> extends true ? T : never) : never : never : never>(source: T, properties: Properties):
    source is Is {
    if (source === undefined) return false;
    return properties.every(property => property in source)
}

export function isKeysInAndValid<T extends {}, Properties extends [...Property], Property extends (KeyOf<T> & string)[], Is extends T = T extends unknown ? Properties[number] extends infer Property ? Property extends unknown ? (HasKey<T, Assert<Property, string>> extends true ? T : never) : never : never : never>(source: T, properties: Properties):
    source is Is {
    return properties.every(property => property in source && isValidRef(source[property]))
}

export function createKeyChecker<T extends {}>(properties: (keyof T)[]) {
    return function execute(source: T) {
        return properties.every(property => (property in source) && isValidRef(source[property]))
    }
}

export function isKeyIn<T extends {}, Key extends Extract<KeyOf<T>, PropertyKey>>(source: T, key: Key): source is (T extends unknown ? Key extends keyof T ? T : never : never) {
    return key in source;
}

export function isIn<T extends {}>(source: T) {
    return function execute(key: PropertyKey): key is keyof T {
        return key in source;
    }
}

export function lastArrayItem<T>(arr: Array<T>): T {
    return arr[arr.length - 1];
}

export function swap<T extends object>(obj: T): Swap<T> {
    return Object.assign({}, ...Object.entries(obj).map(([a, b]) => ({ [b]: a })));
}

export type Swap<T extends object, Values = ValueOf<{ [key in keyof T]: { [$key in Extract<T[key], PropertyKey>]: key } }>> =
    { [key in KeyOf<Values>]: Values extends unknown ? IsEqual<Values[key], unknown> extends true ? never : Values[key] : never }
    ;

export type SwapList<T extends string[]> =
    { [value in _Entries<T>[number][1]]: number }
    ;

export function swapList<T extends string[]>(obj: T): SwapList<T> {
    return assignPure(...entries(obj).map(([key, value]) => ({ [value]: Number(key) }))) as SwapList<T>;
}

export function arrayUnique<T>(arr: T[]): T[] {
    return [...(new Set(arr))];
}

/*
* Typescript não garante tipo da prop ".fields", entretanto o work around feito pelo Gabriel "garante" tipo do fields
* USAR O PARÂMETRO COMO as const.
*/

export type CreateRangeOfNumbers<N extends number, T extends unknown[] = ToTuple<`${N}`>> = { [key in keyof T]: [never, ...ToTuple<Assert<key, StringNumber>>]['length'] }[number];


type _CreateIdFields<T extends unknown[] = ToTuple<`${25}`>> = { [key in keyof T]: [never, ...ToTuple<Assert<key, StringNumber>>]['length'] };
export type IdField = _CreateIdFields[number];

export const typedScreenOptions = genericTypedSuggestions<{
    idSerializable: IScreenOptions['idSerializable'],
    fields: { [enumID in EnumValue]: number }
}>();

export function typedFields<Keys extends string, Options extends { IsPartial?: boolean; IsLimitingFields?: boolean } = { IsPartial: false; IsLimitingFields: false }>() {
    // return <Fields extends { [key in string]: Primitive }>(fields: Fields) => fields;

    type TIdField = Options['IsLimitingFields'] extends true ? IdField : number;
    type Extends = { [key in Keys]: TIdField };
    type TExtends = (Options['IsPartial'] extends true ? Partial<Extends> : Extends);

    return <Fields extends { [key in string]: Values }, Values extends Primitive>(
        fields: Fields
            & UnionToIntersection<IsEqual<keyof Fields, string> extends false ?
                & $$.DeepLiteral<Fields>
                & $$.ExtendsObject<Fields, Options['IsPartial'] extends true ? Partial<Extends> : Extends>
                & $$.NonRepeatedValues<Fields>
                : unknown>
    ): Fields => fields;
}




const getProperties = Symbol();

export function getPropertiesFromReusableProxy<T extends {}>(target: T): string[] {
    return Unsafe.get(target, getProperties);
}

export function getReusableTypedProperty<T>(onToString?: (properties: string[]) => () => unknown, properties?: string[]): T
export function getReusableTypedProperty(onToString?: (properties: string[]) => () => unknown, properties: string[] = []) {
    const me: unknown = new Proxy({}, {
        get: (_target, propertyName) => {
            switch (propertyName) {
                case getProperties: {
                    return properties;
                };
                case Symbol.toPrimitive: {
                    return onToString(properties);
                };
                default: {
                    return getReusableTypedProperty(onToString, [...properties, propertyName as string]);
                }
            }
        },
    });

    return me
}



export type HideFields<T, Key extends ItPropertyType> = Merger<{ [key in Key]: never }, T>;

/**
 * @example
 * class Tester {
 *      public name: string = '';
 *      private a: string = '';
 *      private b: string = '';
 *      private c: number = 2;
 *  }
 * toJSON<Tester>()({
 *      name: '',
 *      a: '',
 *      b: '',
 *      c: 22,
 *  })
 */
export function toJSON<T>() {
    return execute;

    function execute<
        Input,
        Hidden = HideFields<T, keyof Input>,
        Missing = [Hidden] extends [{ [key in infer K]: infer V }] ? V : never
    >(json: (T | Input) & AfterNarrow<[Missing] extends [never] ? unknown : T>): Input {
        return json as Input;
    }
}

export function defaults<T>(target: Nullable<T>, ...setters: Partial<Nullable<T>>[]): T {
    return lodash.defaults(target, ...setters)
}

export function flat<T>(items: T[][]): T[] {
    return [].concat(...items);
}

export function assignPure<T extends object>(...targets: T[]): T {
    return Object.assign({}, ...targets);
}

export function assign<T extends object>(...targets: T[]): T {
    const [target, ...otherTargets] = targets;
    return Object.assign(target, ...otherTargets);
}

export function partialAssign<T extends object>(...[target, ...otherTargets]: [T, ...Partial<T>[]]): T {
    return Object.assign(target, ...otherTargets);
}

export type ArrayEntries<T extends any[]> = {
    [key in keyof T]: [key extends string ? key : string, T[key]]
};

export type _Entries<T> = T extends any[] ? ArrayEntries<T> : ([keyof T, T[keyof T]])[];
export function entries<T>(target: T): _Entries<T> {
    return Object.entries(target) as _Entries<T>;
}

export function mapAssign<T, IT extends (item: T, index: number) => any>(items: T[], iterator: IT): ReturnType<IT> extends object ? ReturnType<IT> : never {
    return assignPure(...items.map(iterator));
}

export function makeImmutable<Fn extends TFunction>(fn: Fn): Fn {
    return (new Proxy(fn, {
        apply: (target, context, params) => {
            const fn = target.apply(context, typedCloneLodash([...params]));
            if (typeof fn === 'function') return makeImmutable(fn)
            return fn;
        }
    }))
}

export const addImmutable = makeImmutable(add);


export function attempt<Fn extends TFunction>(fn: Fn): ReturnType<Fn> {
    return typeOf(fn) === ETypeOf.AsyncFunction ? asyncAttempt()() : syncAttempt()();

    function syncAttempt() {
        return ((...params) => {
            try {
                return fn(...params);
            } catch { }
        }) as typeof fn;
    }

    function asyncAttempt() {
        return (async (...params) => {
            try {
                return fn(...params);
            } catch { }
        }) as typeof fn;
    }

}

/**
 * Execute functions in parallel
 * @returns Promise
 */
export async function implementMapLimit<T, R>(items: T[], limit: number, call: (item: T, index: number) => Promise<R>): Promise<R[]> {
    const mappedLeft: { item: T; index: number; }[] = items.map((item, index) => ({ item, index }));
    const result: R[] = [];
    const workers: Promise<void>[] = [];

    for (let i = 0; i < limit; i++) workers.push((async () => {
        while (mappedLeft[0]) {
            const { index, item } = mappedLeft.shift()
            result[index] = await call(item, index);
        }
    })());

    await Promise.all(workers);

    return result;
}


namespace Fn {
    export function random(max: number, min: number = 0) {
        const generated = Math.random() // 0 >= x < 1
        const out = ((generated * (max - min + 1)) + min) | 0 // min >= x < (max - min + 1)
        return out;
    }
}

export namespace Arrange {
    export enum Mode {
        Combinations = 'Combinations',
        Permutations = 'Permutations',
        Mix = 'Mix',
    }

    export interface Options<T> {
        items: T[];
        size: number;
        mode: Mode;
    }

    function select<T>(mode: Mode, items: T[], index: number) {
        switch (mode) {
            case Mode.Combinations: return items.slice(index + 1);
            case Mode.Mix: return items;
            case Mode.Permutations: return [...items.slice(0, index), ...items.slice(index + 1)];
        }
    }

    export function arrange<T>({ items, size, mode }: Options<T>): T[][] {
        if (size <= 1) return items.map(item => [item]);

        return items
            .map((item, index) => select(mode, items, index))
            .map((list, index) => arrange({ items: list, size: size - 1, mode }))
            .map((lists, index) => lists.map(subList => [items[index], ...subList]))
            .flat()
            ;
    }
}

export function countOcurrencesHash<T extends string>(hash: TOcurrencesHash, items: T[]) {
    for (let item of items) {
        if (!hash[item]) hash[item] = 0;
        hash[item]++;
    }
}

export function createOcurrencesHash<T extends string>(items: T[]) {
    const hash: TOcurrencesHash = {};
    countOcurrencesHash(hash, items)
    return hash;
}


export function difference(...allItems: string[][]): string[] {
    const hash: TOcurrencesHash = {};

    allItems.forEach((items: string[]) => countOcurrencesHash(hash, items));

    const commonItems: string[] = [];

    for (let item in hash) {
        if (hash[item] <= 1) commonItems.push(item);
    }

    return commonItems;
}


export function intersection(...allItems: string[][]): string[] {
    const hash: TOcurrencesHash = {};

    allItems.forEach((items: string[]) => countOcurrencesHash(hash, items));

    const commonItems: string[] = [];

    for (let item in hash) {
        if (hash[item] >= allItems.length) commonItems.push(item);
    }

    return commonItems;
}

function _addKey(key: string) {
    return (otherKey: string) => `${key}.${otherKey}`;
}


export function recursiveKeys<T extends object>(source: T, ignore?: (value: T, key: string) => boolean): string[] {
    const mapEntries: string[] = Object.entries(source).map(([key, value]): undefined | string | string[] => {
        if (ignore?.(value, key)) return key;
        if (deprecatedTypeOf(value) === 'object') return recursiveKeys(value, ignore).map(_addKey(key));
        return key;
    }).flat().filter(isValidRef);

    return mapEntries;
}

export enum ETypeOf {
    Boolean = 'Boolean',
    Null = 'Null',
    Undefined = 'Undefined',
    Number = 'Number',
    BigInt = 'BigInt',
    String = 'String',
    Symbol = 'Symbol',
    AsyncFunction = 'AsyncFunction',
    Function = 'Function',
    GeneratorFunction = 'GeneratorFunction',
    Object = 'Object',
    Math = 'Math',
    RegExp = 'RegExp',
    Promise = 'Promise',
    Error = 'Error',
    EventTarget = 'EventTarget',
    Array = 'Array',
    Date = 'Date',
    ArrayBuffer = 'ArrayBuffer',
    Uint8Array = 'Uint8Array',
    Int8Array = 'Int8Array',
    Uint16Array = 'Uint16Array',
    Int16Array = 'Int16Array',
    Uint32Array = 'Uint32Array',
    Int32Array = 'Int32Array',
    Float32Array = 'Float32Array',
    Float64Array = 'Float64Array',
    Uint8ClampedArray = 'Uint8ClampedArray',
    BigUint64Array = 'BigUint64Array',
    BigInt64Array = 'BigInt64Array',
    Map = 'Map',
    Set = 'Set',
    WeakMap = 'WeakMap',
    WeakSet = 'WeakSet',
    URLSearchParams = 'URLSearchParams',
    TextEncoder = 'TextEncoder',
    TextDecoder = 'TextDecoder',
    Event = 'Event',
    SharedArrayBuffer = 'SharedArrayBuffer',
    JSON = 'JSON',
    Intl = 'Intl',
    Reflect = 'Reflect',
    Atomics = 'Atomics',
    WebAssembly = 'WebAssembly',
}
export function typeOf<T>(source: T): ETypeOf {
    return ({}).toString.call(source).slice(8, -1) as ETypeOf;
}

export function deprecatedTypeOf<T>(source: T): string | ETypeOf {
    return typeOf(source).toLowerCase();
}

// $tools.Union.ListOf<DeepFindValuesOfType<T, $tools.Misc.Primitive>> => CAN'T EXISTS BECAUSE OF ANGULAR VERSION LIMITATIONS
export function recursiveValues<T extends object>(source: T) {
    const output: any[] = [].concat(...Object.values(source).map((item) => {
        if (typeof item === 'object') return recursiveValues(item)
        return item;
    }));
    return output;
}

export function values<T extends object>(object: T): $ValueOf<T>[]
export function values<T extends object>(object: T) {
    return Object.values(object);
}



export function getProperty<T>(source: T) {
    return (property: keyof T) => source[property];
}

export function getPropertyFromUnion<T>(source: T) {
    return execute

    function execute<Property extends KeyOf<T>>(property: Property): (
        T extends unknown ?
        Property extends unknown ?
        AnyOrUnknownTo<GetByKey<T, Property>, never>
        : never
        : never
    )
    function execute<Property extends KeyOf<T>>(property: Property): unknown {
        return typedCloneLodash(source?.[property]);
    }
}

export function getPropertyName<T, Search = any>(source?: T) {
    return <P extends FindKeysWithValueOf<T, Search>>(property: P) => property;
}

export function keys<T, P extends keyof T>(source: T): P[] {
    return Object.keys(source) as P[];
}


// function advancedFind<T>(items: T[]) {
//     // const properties: (keyof T)[] = keys(items[0]);
//     const hash: Record<keyof T, object> =_advancedHash(items, properties);
//     return <M extends Partial<T>>(match: M) => {
//         for (let property in match) hash[]

//     }
// }


function _advancedHash<T, P extends keyof T>(items: T[], properties: P[]) {
    const hash: Record<P, object> = {} as Record<P, object>; // not limited

    for (let item of items) {
        let current = hash as Record<P, any>;

        for (let property of properties.slice(-1)) {
            if (isInvalid(current[property])) current[property] = {};
        }

        const lastProperty = last(properties);
        current[lastProperty] = item[lastProperty];
    }

    return hash;
}


export function last<T>(items: T[]): T {
    return items[items.length - 1];
}






export function pick<T extends object, P extends keyof T>(source: T, properties: P[]): Pick<T, P> {
    const result: Partial<Pick<T, P>> = {};

    for (let property of properties)
        result[property] = source[property];

    return result as Pick<T, P>;
}






// cant exists because of angular limitation >> $tools.Union.ListOf<DeepFindValuesOfType<T, $tools.Misc.Primitive>>
// @ts-ignore
export function recursiveText<T extends object,>(source: T): DeepFindValuesOfType<T, Primitive>[] {
    return [].concat(...Object.values(source).map((item: string | object) => {
        // @ts-ignore
        if (typeof item === 'object') return recursiveText(item)
        return item;
    })) as unknown as DeepFindValuesOfType<T, Primitive>[]
}


export function countMatches(text: string, pattern: RegExp | string): number {
    let result: number = 0;
    text.replace(pattern, () => ++result as any);
    return result;
}


type AddDefaultCall<T> = (value: T, index: number, values: T[]) => T;
export function add<Property extends T extends Primitive ? string : string | ((source: $PickKeys<T>) => Extract<keyof T, string>), T extends object | Primitive, C extends (value: T, index: number, values: T[], state: object) => any = AddDefaultCall<T>>(inputProperty: Property, call?: C) {
    const property: Extract<Property, string> = getProperty(inputProperty);

    type New = { [key in typeof property]: ReturnType<C> };

    return (source: T, index: number, sources: T[]) => {
        const state: object = {}
        type Result =
            T extends object ?
            { [key in keyof (UnsafeOmit<T, keyof New> & New)]: (UnsafeOmit<T, keyof New> & New)[key] } :
            { [key in keyof New]: New[key] }
            ;

        if (!isValidFunction(call)) {
            call = ((value: T, index: number, sources: T[], state: object) => value) as C;
        }
        const result = typeof source === 'object' ? ((source as {} as Result)[property] = call(source, index, sources, state), source) : ({ [property]: call(source, index, sources, state) })
        return result as Result; // & IsEqual<C, (value: T) => T> extends true ? $$<'Optional call must not have an object'>  : unknown
    };

    function getProperty(inputProperty: Property): Extract<Property, string> {
        if (typeof inputProperty === 'function') {
            fakeAssertsGuard<Extract<Property, Function>>(property);
            const result = inputProperty(pickKeys<Extract<T, object>>()) as string as Extract<Property, string>;
            return result;
        }
        return inputProperty as Extract<Property, string>;
    }
}

export type PickKeys<T> = { [key in keyof T]-?: key }
export type $PickKeys<T> = { [key in keyof T]: key }



export enum EToPath {
    array = 'array',
    property = 'property',
}
export interface IToPath {
    type: EToPath;
    value: string;
}

export function toPath<Property extends string>(property: Property): IToPath[] {
    return property
        .split(/\.|(\[\])|\[|\]/)
        .map(add('value'))
        .map(add('type', (item, index: number) => (isOdd(index) && item.value === '[]') ? EToPath.array : EToPath.property))
        .filter(item => item.value)
}

export function asyncAdd<P extends string, T extends object | any, C extends (value: T) => any>(property: P, call: C) {
    type New = { [key in P]: AsyncReturnType<C> };

    return async (source: T) => {
        type Result =
            T extends object ?
            { [key in keyof (T & New)]: (T & New)[key] } :
            { [key in keyof New]: New[key] }
            ;
        const result = typeof source === 'object' ? ((source as Result)[property] = await call(source), source) : ({ [property]: call(source) })
        return result as Result;
    };
}



export function createOptions<T extends string[]>(...source: T) {
    const options: { [key in T[number]]: key } = {} as { [key in T[number]]: key };
    for (let key of source) options[key] = key;
    return options;
}

export function captalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function presentableFirstName(name: string): string {
    const first: string = name.split(' ')[0];
    return captalizeFirstLetter(first);
}



export function capitalizedFullName(name: string): string {
    let ret: string = '';
    for (const n of name.split(' ')) {
        ret += captalizeFirstLetter(n) + ' ';
    }
    return ret.trimEnd();
}

function _returnTrue(): true {
    return true;
}
export function createTypeGuard<Generic>(verifier: TGenericTypeGuardVerifier = _returnTrue) {
    return (value: any): value is Generic => verifier(value);
}


export function createTypeGuardByInstructions<Generic extends object>(instructions: { [key in keyof Generic]?: ToSimpleLiteralTypeName<Generic[key]> }) {
    return (value: any): value is Generic => {
        for (const property in instructions) {
            const propertyType: AllSimpleLiteralTypeNames = instructions[property];
            const result: AllSimpleLiteralTypeNames = deprecatedTypeOf(value[property]) as AllSimpleLiteralTypeNames;
            if (propertyType !== result) return false;
        }

        return true;
    };
}

export function useTypeGuardByInstructions<Generic>(instructions: { [key in keyof Generic]?: ToSimpleLiteralTypeName<Generic[key]> }, value: any): value is Generic {
    return createTypeGuardByInstructions(instructions)(value);
}

export function useTypeGuard<Generic extends object>(source: any): source is Generic {
    return true;
}

export function safeGetProperty<T, R>(source: T, call: (entity: T) => R): R {
    const property: string = getDeepTypedProperty(call);
    return getDeepPropertyValue(source, property) as R;
}

export function createExtendedObjectHash<T, E extends { [key in keyof T]: object }>(original: T, extension: E) {
    const result = {} as { [key in keyof T]: { [subKey in keyof (T[key] & E[key])]: (T[key] & E[key])[subKey] } };

    for (let property in original) {
        result[property] = { ...original[property], ...extension[property] };
    }

    return result;
}

export const getClassNames = createGetClassNames();

export function createWorkersWithTimeLimit(amountSlots: number, time: number) {
    const slots: Promise<ICreateWrapSlot>[] = Array(amountSlots).fill(undefined).map((value, index) => ({ value, index })).map(slot => Promise.resolve(slot))

    return (call: TFunction) => async (...paramsToCall: Parameters<TFunction>) => {
        const result = await Promise.race(slots)
        const promise: Promise<ICreateWrapSlot> = wrap(call(...paramsToCall), result.index)
        return setSlotAndGetResult(promise, result.index)
    }

    async function wrap(promise: Promise<TFunction>, index: number): Promise<ICreateWrapSlot> {
        const [result] = await Promise.all([promise, delay(time)])
        return { value: result, index }
    }

    async function setSlotAndGetResult(promise: Promise<ICreateWrapSlot>, index: number): Promise<ReturnType<TFunction>> {
        slots[index] = promise
        const result = await promise
        return result.value
    }
}

export function endsWithSlashOrAddSlash(str: string): string {
    return str.endsWith('/') ? str : `${str}/`;
}




export const tokenSpecialCaracters = [
    '+', '(', ')', '*', '/', ':', '?', '.', '!', ',', ';',
    '~', '^', '{', '}', '[', ']', '-', '_'
]

export const tokenSpecialCharacterRegex = new RegExp(`\\${tokenSpecialCaracters.join('|\\')}`, 'g');

export function stringIsValidRegex(regexStr: string): boolean {
    let isValid: boolean = true;
    try {
        const temp = new RegExp(regexStr);
    } catch (e) {
        isValid = false;
    } finally {
        return isValid;
    }
}

const numberMaps: { [key: number]: string } = {
    0: '0️⃣',
    1: '1️⃣',
    2: '2️⃣',
    3: '3️⃣',
    4: '4️⃣',
    5: '5️⃣',
    6: '6️⃣',
    7: '7️⃣',
    8: '8️⃣',
    9: '9️⃣'
};


export function replaceNumbersToNumbersEmoji(num: number): string {
    return `${num}`.split('')
        .map(char => numberMaps[char] || '')
        .join('');
}




export function generateIncrement(type: EActionTreeIncrementor, index: number, convertNumbersToEmoji: boolean = false): string {
    switch (type) {
        case EActionTreeIncrementor.letter:
            return String.fromCharCode('A'.charCodeAt(0) + index);
        case EActionTreeIncrementor.numeric:
            return convertNumbersToEmoji
                ? replaceNumbersToNumbersEmoji(++index)
                : `${++index}`;
    }
}


export type $FakeEnum<List extends string> = { [key in List]: key };
export function $fakeEnum<List extends string>(): $FakeEnum<List> {
    return pickKeys<{ [key in List]: key }>();
}

export function pickKeys<T extends object>(): PickKeys<T> {
    return new Proxy({}, {
        get: (_, property) => property,
    }) as PickKeys<T>;
}


//

export function listKeys<T>(source: { [key in keyof T]: undefined }): (keyof T)[] {
    return keys(source);
}

export function bind<T extends { [key in string]: any }>(getSource: () => T) {
    return <R extends keyof T>(getProperty: (pick: { [key in keyof T]: key }) => R) => (...params: Parameters<T[R]>) => {
        const source: T = getSource();
        const pick = pickKeys<T>();
        return source[getProperty(pick)](...params) as ReturnType<T[R]>;
    };
}

export function $method<T extends {}>(target: T) {
    const proxy = new Proxy(target, {
        get(_: T, propertyKey: string) {
            return (target[propertyKey as keyof T] as {} as TFunction).bind(target);
        }
    });
    return target;
}

const isObjectTypeGuard = createTypeGuard<object>(value => typeof value === 'object')

export function isEqualObject<T extends object>(current: T, next: T) {
    const sourceCurrent: (keyof T)[] = keys(current);
    const otherNext: (keyof T)[] = keys(next);

    if (sourceCurrent.length !== otherNext.length) return false;

    return sourceCurrent.every(
        key => {
            const isWithTheSameType: boolean = typeof current[key] === typeof next[key];
            if (!isWithTheSameType) return false;

            if (current[key] !== next[key]) return false;

            const currentValue = current[key];
            const currentNext = next[key];

            if (isObjectTypeGuard(currentValue) && isObjectTypeGuard(currentNext)) {
                return isEqualObject(currentValue, currentNext);
            }
        }
    );
}

export function cutPhrase(phrase: string, index: number, suffix: string = ''): string {
    if ((phrase.length - 1) <= index) return phrase;

    const indexOfSpace: number = findPreviousSpaceOnPhrase(phrase, index);
    if (indexOfSpace === -1) return phrase;

    const cuttedPhrase: string = phrase.slice(0, indexOfSpace + 1);
    return `${cuttedPhrase}${suffix}`;
}


function findPreviousSpaceOnPhrase(phrase: string, index: number): number {
    const foundSpaceIndex: number = phrase.split('').slice(0, index + 1).lastIndexOf(' ');
    return foundSpaceIndex;
}

function chunkWords(words: string, limit: number, delimiter: string | RegExp) {

}
export function cutWordSinceIndexAndCharacters(word: string, since: number, search: string | RegExp) {
    return word.slice(0, findLastIndexFromSearch(word.slice(0, since), search))
}
export function findLastIndexFromSearch(word: string, search: string | RegExp) {
    let lastIndex: number = -1
    word.replace(search, (word: string, text: string, index: number) => (lastIndex = index, word))
    return lastIndex;
}

export function deferedPromise<T = unknown>(): IDeferedPromise<T> {
    let resolve: IDeferedPromise<T>["resolve"];
    let reject: IDeferedPromise<T>["reject"];
    const promise = new Promise<T>((res, rej) => {
        resolve = res;
        reject = rej;
    })

    const deferedObject = {
        resolve, reject, promise
    }

    return deferedObject;
}

export function assertUnreachable(x: never): never {
    throw new Error("Didn't expect to get here");
}


// function uniqueExpression(expression) {
// const limiter = createBound(`[${expression.split('').filter(item => !/\w/.test(item)).join('|').concat('\\w')}]`)
// return new RegExp(limiter + expression + limiter, 'g')
// }

// function createBound(limiter) {
// return `(?:(?<!${limiter})(?=${limiter})|(?<=${limiter})(?!${limiter}))`
// }



export function insertContentAt(source: string, stringIndex: number, value: string): string {
    const leftPart = source.substring(0, stringIndex);
    const rightPart = source.substring(stringIndex);

    return `${leftPart}${value}${rightPart}`;
}

export function isEntities<Entities extends Entity, Entity = Entities>() {
    return <Condition extends Partial<Entities>>(condition: Condition) => {
        return (source: Entity): source is Extract<Entities, Condition> => lodash.isMatch(source as unknown as object, condition);
    };
}

export type UnsafeOmit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;


export type HighlightUtteranceColors = "red" | "blue" | "grey";
export type HighlightUtteranceEntityConfig = { value: string, label: string, color: HighlightUtteranceColors, lvl?: 0 | 1 | 2 | 3 };

export function highlightUtterance(content: string, items: HighlightUtteranceEntityConfig[]): string {
    let response = content;

    items.sort((a, b) => a.value.length - b.value.length).reverse();

    for (const item of items) {
        response = response.replace(getExactWordRegex(lodash.escapeRegExp(item.value)), (v) => {
            const { lvl } = items.find(({ value }) => v === value);
            return `<span class="item ${item.color}${lvl ? ` lvl${lvl}` : ""}"><span class="ut-title">${item.label}</span>${v}</span>`
        });
    }
    return response;
}


interface IMapByOptions {
    isToMany?: boolean
}

export function mapBy<T, Call extends (item: T, index: number, items: T[], state: State) => any, State extends object, Options extends IMapByOptions>(items: T[], call: Call, options?: Options) {
    type TMap<Source> = TDeepMap<[ReturnType<Call>, Source]>
    options ??= ({}) as Options;
    const map: TMap<T | T[]> = new Map();
    const state: State = {} as State;
    if (isToMany(map)) {
        items.forEach((item: T, index: number, items: T[]) => {
            const property = call(item, index, items, state);
            if (!map.has(property)) map.set(property, [])
            map.get(property).push(item);
        })
    } else {
        items.forEach((item: T, index: number, items: T[]) => map.set(call(item, index, items, state), item));
    }

    return map as IsEqual<Options['isToMany'], true> extends true ? TMap<T[]> : TMap<T>;

    function isToMany(input: typeof map): input is TMap<T[]> {
        return options.isToMany
    }
}

export namespace MapBy {
    export const factory = Mapper.Mapper.resolve;

    /**
     *
     */
    export function swap<T, U>(
        input: Map<U, T>,
    ) {
        const map = new Map<T, U>()
        for (const [key, value] of input) {
            map.set(value, key);
        }
        return map;
    }


    /**
     *
     */
    export function single<T, U>(
        items: T[],
        fn: (item: T, index: number, items: T[]) => U,
        isUsingLastItem: boolean = true,
    ) {
        const map = new Map<U, T>()
        items.forEach((item, index, items) => {
            const key = fn(item, index, items)
            if (!isUsingLastItem && map.has(key)) return;
            map.set(key, item);
        })
        return map;
    }

    /**
     *
     */
    export function multiple<T, U>(items: T[], fn: (item: T, index: number, items: T[]) => U) {
        const map = new Map<U, T[]>()
        items.forEach((item, index, items) => {
            const key = fn(item, index, items);
            const collection = map.get(key) ?? [];
            collection.push(item);
            map.set(key, collection);
        })
        return map;
    }

}


export const _deepSet = <TMap extends Map<unknown, unknown>>(map: TMap, isLastItemLastSetValue?: boolean) => <Values extends TValue[], TValue extends (Narrowable | {})>(...values: Values): void => {
    values.reduce((map: Map<unknown, unknown>, value: TValue, index: number, items: TValue[]) => {
        if (!map.has(value)) {
            const isLastItem: boolean = ((items.length - 1) === index)
            map.set(value, (isLastItemLastSetValue && isLastItem) ? value : new Map());
        }
        return map.get(value);
    }, map)
}


export function deepSet<TMap extends Map<unknown, unknown> & TypeDeepMap>(map: TMap) {
    type TValues = TMap extends TDeepMap<infer Values> ? Values : never;
    type TValue = TValues[number];
    return (...items: TValues): void => {
        const previousItems: TValue[] = items.slice(0, -2);
        const lastItems: TValue[] = items.slice(-2);
        _deepSet(map)(...previousItems)
        const lastMap: Map<unknown, unknown> = previousItems.reduce((map, item) => map.get(item), map);
        // @ts-expect-error
        lastMap.set(...lastItems)
    }
}



export function deepGet<TMap extends Map<unknown, unknown> & TypeDeepMap>(map: TMap) {
    type TValues = TMap extends TDeepMap<infer Values> ? Values : never;
    type TValue = TValues extends [...infer Items, infer LastItem] ? [Items, LastItem] : never;
    type TItems = TValue[0]
    type TLastValue = TValue[1]
    return (...items: TItems): TLastValue => {
        try {
            const content = items.reduce((map: Map<TValues[number], unknown>, item: TValues[number]) => map.get(item), map);
            return content;
        } catch { }
    }
}
export function deepHas<TMap extends Map<unknown, unknown> & TypeDeepMap>(map: TMap) {
    type TValues = TMap extends TDeepMap<infer Values> ? Values : never;
    type TValue = TValues extends [...infer Items, infer LastItem] ? [Items, LastItem] : never;
    type TItems = TValue[0]
    type TLastValue = TValue[1]
    return (...items: TItems): TLastValue => {
        try {
            const lastMap = items.slice(-1).reduce((map: Map<TValues[number], unknown>, item: TValues[number]) => map.get(item), map) as Map<TValues[number], unknown>;
            return lastMap.has(last(items));
        } catch {
            return false;
        }
    }
}


export function Debounce(time: number = 0) {
    return function execute<T, Property extends keyof T,>(target: T, property: Property, descriptor: TypedPropertyDescriptor<T[Property]>) {
        if (descriptor.value) {
            descriptor.value = debounce(descriptor.value as {} as TFunction, time) as {} as T[Property];
        }
    }
}


export function debounce<T extends any[], U, Context>(fn: (this: Context, ...params: T) => U, time: number) {
    const state: { resolve: ((value: AsyncType<U>) => void)[], timeout?: NodeJS.Timeout; } = { resolve: [] }

    return function execute(this: Context, ...params: T): Promise<AsyncType<U>> {
        if (isValidRef(state.timeout)) {
            clearTimeout(state.timeout);
            delete state.timeout;
        }

        state.timeout = setTimeout(async () => {
            const promise = await fn.apply(this, params);
            resolveAll(promise as AsyncType<U>);
        }, time);

        const promise = new Promise<AsyncType<U>>((resolve, reject) => {
            state.resolve.push(resolve);
        });

        return promise;
    }
    function resolveAll(result: AsyncType<U>) {
        state.resolve.map(resolve => resolve(result))
        state.resolve.splice(0)
    }
}

/**
 * Replace first level props of object
 *
 * @example
 * let objA = { foo: "bar", arr: [1,2], obj: { bar: "foo" } }
 * let objB = { arr: [3,4], obj: { bar2: "foo1" } }
 *
 * objectShallowReplace(objA, objB) // objA = { foo: "bar", arr: [3,4], obj: { bar2: "foo1" } }
 */
export function objectShallowReplace<T extends object>(
    target: T,
    replaceWith: | undefined | Partial<T>,
    validate?: boolean
): void {
    if (!replaceWith) return;
    for (const prop in replaceWith) {
        if (validate && !isValidRef(replaceWith[prop])) continue;

        target[prop] = replaceWith[prop];
    }
}

export const validPropertyNameRegex = /^\w+$/;
export const isValidPropertyNameForCorporateSearchRegex = /^[a-z_]+$/;

export function isValidPropertyName(propertyName: string): boolean {
    return validPropertyNameRegex.test(propertyName);
}

/**
 * Check if is a valid property name to use in Corporate Search
 */
export function isValidPropertyNameForCorporateSearch(propertyName: string): boolean {
    return isValidPropertyNameForCorporateSearchRegex.test(propertyName);
}
export function listFormat<Items extends string[]>(items: Items, locale?: string): string {
    // @ts-ignore
    return new Intl.ListFormat(locale).format(items)
}

export function asserts(condition?: any): asserts condition {
    return condition;
}

export function blockEntityUse(entity: unknown): asserts entity is never {
}


export function fakeAssertsGuard<Type>(source: any): asserts source is Type {
    return;
}
export const guard = fakeAssertsGuard;

export function fakeGuard<Type>(source: any): source is Type {
    return true;
}


export function countArrayItemOcurrence<T extends Array<D>, D>(arr: T, item: D): number {
    return arr.filter((i) => lodash.isEqual(i, item)).length;
}

/**
* Returns the index of the last element in the array where predicate is true, and -1
* otherwise.
* @param array The source array to search in
* @param predicate find calls predicate once for each element of the array, in descending
* order, until it finds one where predicate returns true. If such an element is found,
* findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
*/
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array))
            return l;
    }
    return -1;
}

export function findLast<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): T | undefined {
    const lastIndex = findLastIndex(array, predicate);

    if (isValidNumber(lastIndex, 0)) {
        return array[lastIndex];
    }

    return;
}

export function isEqualIgnoringKeys<T extends object>(objA: T, objB: T, ignoredProps: (keyof T)[]): boolean {
    if (!isValidRef(objA) || !isValidRef(objB)) return false;

    for (const prop of keys(objA)) {
        if (ignoredProps.includes(prop as keyof T)) {
            continue
        }

        if (!isEqual(objA[prop], objB[prop])) {
            return false;
        }
    }

    return true;
}

export function callIfValidFunction<T extends (...args: any[]) => unknown>(func: T, ...args: Parameters<T>): ReturnType<T> | unknown {
    return isValidFunction(func) && func(...args);
}

export interface IAsyncTimeout {
    cancel: () => void,
    wait: Promise<unknown>
}

export interface INewPromise<T> {
    resolve: {
        (value: T | PromiseLike<T>): void;
        isResolved: boolean;
    }
    reject(reason?: unknown): void;
    promise: Promise<T>;
}

export function initPromise<T>(): INewPromise<T> {
    const output: Partial<INewPromise<T>> = {}

    output.promise = new Promise<T>((resolve: (value: T) => void, reject: (reason?: unknown) => void) => {
        assign(output, {
            resolve: resolve as typeof output.resolve,
            reject,
        })
    });

    resolve.isResolved = false;

    return {
        resolve,
        reject: output.reject!,
        promise: output.promise!,
    };

    function resolve(value: T | PromiseLike<T>): void {
        output.resolve?.(value);
    }
}

export function asyncTimeout(duration: number, limit?: number): IAsyncTimeout {
    let resolveTimeout: NodeJS.Timeout;
    let limitTimeout: NodeJS.Timeout;
    const wait = new Promise((resolve) => {
        resolveTimeout = setTimeout(resolve, duration);

        if (isValidNumber(limit)) {
            limitTimeout = setTimeout(function () {
                clearTimeout(resolveTimeout);
            }, limit);
        }
    });

    return {
        wait,
        cancel() {
            clearTimeout(resolveTimeout);
            isValidRef(limitTimeout) && clearTimeout(resolveTimeout);
        }
    }
}


export type Slice<T extends any[], From extends StringNumber, To extends StringNumber> =
    T extends [...ToTuple<From, unknown>, ...infer Remaining] ? Remaining extends [...ToTuple<Sub<To, From>, unknown>, ...infer Other] ? Remaining extends [...infer Items, ...Other] ? Items : never : Remaining : []
    ;
export type Sub<N extends StringNumber, OtherN extends StringNumber> = ToTuple<N> extends [...ToTuple<OtherN>, ...infer Result] ? `${Result['length']}` : never


export type IsTuple<T> = T extends [unknown, ...unknown[]] ? true : IsEqual<T, []>;

type IsLiteralNumber<T extends number> = IsEqual<T, number> extends true ? false : true;
type EcmaSlice<Tuple extends any[], Start extends number, End extends number> = [IsTuple<Tuple>, IsLiteralNumber<Start>, IsLiteralNumber<End>][number] extends infer Assertion ? IsEqual<Assertion, true> extends true ? Slice<Tuple, `${Start}`, `${End}`> : Tuple[number][] : never


export const ECMAScriptFunctions = {
    Array: {
        slice<InputT, Values extends Primitive, Start extends number, End extends number, Tuple extends any[] = InputT extends any[] ? InputT : never, Result = EcmaSlice<Tuple, Start, End>>(source: InputT & DeepInfer<InputT, Values>, start: Start, end: End): Result {
            fakeAssertsGuard<Tuple>(source);
            const sliced = source.slice(start, end);
            fakeAssertsGuard<Result>(sliced);
            return sliced;
        },
    },
    Object: {
        fromEntries<T extends [unknown, unknown][]>(source: T): FromEntries<T> {
            return Object.fromEntries(source) as unknown as FromEntries<T>;
        },
        entries<T, V extends Primitive>(source: DeepInfer<T, V>) {
            return Object.entries(source) as unknown as Entries<Assert<T, object>>;
        }
    },
};

export type ValidateEntries<T, Hash, TEntriesHash, IsDeepLiteral extends boolean = true> = UnionToIntersection<
    (T extends (object & unknown[]) ?
        & EntriesHash.CheckRequiredKeys<T, Hash>
        & TEntriesHash
        & EntriesHash.NonRepeatedKeys<T>
        & $$.NonNullableDeep<T>
        & (IsDeepLiteral extends true ? $$.DeepLiteral<T> : unknown)
        : $$<'Must be an array'>)
>;

type ValidateHash<T, Hash> = UnionToIntersection<
    (T extends (object) ?
        & $$.NonNullableDeep<T>
        // & $$.DeepLiteral<T>
        & $$.ExtendsObject<T, Hash>
        : $$<'Must be an hash'>)
>;


export function createHash<Hash extends object>() {
    return <T>(
        source:
            (Narrow<T> & ValidateHash<T, Hash>)): T => {
        return source as T;
    }
}


type TypedEntryFields<T, Hash, TEntriesHash extends [unknown, unknown][]> = UnionToIntersection<
    (
        T extends (object & unknown[]) ?
        & EntriesHash.CheckRequiredKeys<T, Hash>
        & EntriesHash.NonRepeatedKeys<T>
        & $$.NonNullableDeep<T>
        & $$.DeepLiteral<T>
        & $$.ExtendsObject<T, TEntriesHash>
        : $$<'Must be an array'>
    )
>;

export function typedEntryFields<Hash extends { [key in string | number]: number }>() {
    type TEntriesHash = EntriesHash<Hash>;

    return execute;

    function execute<T>(
        source: T
            // & CNarrow<T>
            & TEntriesHash
        // & TypedEntryFields<T, Hash, TEntriesHash>
    ): FromEntries<T>;
    function execute(
        source: any
    ): {} {
        return ECMAScriptFunctions.Object.fromEntries(source);
    }


    // return <T, Values extends Primitive>(
    //     source: T
    //         & DeepInfer<T, Values>
    //         & UnionToIntersection<
    //             & TypedEntryFields<T, Hash, TEntriesHash>
    //             & $$.NonRepeatedValuesInEntries<T>
    //         >
    // ): FromEntries<T> => ECMAScriptFunctions.Object.fromEntries(source as unknown as TEntriesHash) as FromEntries<T>;
}


export function createHashFromEntries<Hash extends object>() {
    type TEntriesHash = EntriesHash<Hash>;
    return <T, V extends Primitive>(
        source:
            (DeepInfer<T, V>
                & ValidateEntries<T, Hash, TEntriesHash>)): T extends TEntriesHash ? FromEntries<T> : never => {
        const result = Object.fromEntries(source as EntriesHash);
        fakeAssertsGuard<T extends TEntriesHash ? FromEntries<T> : never>(result);
        return result;
    }
}


export function createHashFromEntriesV2<Hash extends object>() {
    type TEntriesHash = EntriesHash<Hash>;

    return execute;

    function execute<T extends $Extends<T, TEntriesHash>>(
        source:
            & T
            & AfterNarrow<
                & ValidateEntries<T, Hash, TEntriesHash>
                & $$.NonRepeatedValuesInEntries<T>
            >
        ,
    ): FromEntries<T>;
    function execute<T>(source: T) {
        return source;
    }
}



type TAddGetFnNameClass = { getFnName: TGetFnName };



export enum EImplementAccessModifier {
    public = 'public',
    private = 'private',
    // protected = 'protected',
}
export interface IImplementProperty {
    isStatic: boolean;
    modifier: EImplementAccessModifier;
}
export interface IGenericImplementProperty<Modifier extends EImplementAccessModifier, IsStatic extends boolean = false> extends IImplementProperty {
    isStatic: IsStatic;
    modifier: Modifier;
}

export type TImplement<
    T extends { prototype: {} },
    E,
    InputMetadata extends TImplementMetadata<E>,
> =
    Implement._TImplement<T, E, InputMetadata>
    ;

export type TImplementMetadata<E, Property extends IImplementProperty = IImplementProperty> = { [key in keyof E]?: Property };
export type ImplementMetadata<E, Property extends IImplementProperty = IImplementProperty> = Required<TImplementMetadata<E, Property>>;



export declare namespace Implement {
    type Print<T extends unknown[]> =
        $$.Print<T>
        ;

    type _TImplement<
        T extends { prototype: {} },
        E,
        InputMetadata extends TImplementMetadata<E>,
        Metadata = Required<Implement.DefineMetadata<InputMetadata, E>>,
        StaticKeys extends keyof E = FindOnMetadata<Metadata, { isStatic: true }, E>,
        StaticE = Compute<Pick<E, StaticKeys>>,
        NonStaticE = Compute<Omit<E, StaticKeys>>,
        Verify =
        | TVerify<T, StaticE, Metadata, { isPrototype: false; oppositeSource: T['prototype'] }>
        | TVerify<T['prototype'], NonStaticE, Metadata, { isPrototype: true; oppositeSource: T }>
        ,
    > = Verify;

    type DefaultMetadataProperty = Explicit<IImplementProperty, {
        isStatic: true;
        modifier: EImplementAccessModifier.private;
    }>;

    type DefineMetadata<InputMetadata, E> =
        Explicit<
            TImplementMetadata<E>,
            & InputMetadata
            & { [key in Exclude<keyof E, keyof InputMetadata>]: DefaultMetadataProperty }
        >
        ;

    type FindOnMetadata<Metadata, Search extends Partial<IImplementProperty>, On> =
        Assert<FindKeysWithValueOf<Metadata, Search>, keyof On>
        ;
    type TVerify<
        T,
        E,
        Metadata,
        Info extends TImplementInfo,
        Extends = VerifyIfAttributeExtends<T, E, Info>,
        CorrectModifier = (
            IsNever<Extends> extends true ?
            | (keyof E extends infer Key ? VerifyModifier<T, Assert<Key, keyof Metadata>, Metadata> : never)
            : never
        ),
    > =
        | Extends
        | CorrectModifier
        | VerifyAttributeMetadata<T, E>
        ;

    type VerifyModifier<T, Property extends keyof Metadata, Metadata> =
        Property extends unknown ?
        Metadata[Property] extends infer MetadataProperty ?
        MetadataProperty extends IImplementProperty ?
        IsNever<Extract<keyof T, Property>> extends (MetadataProperty['modifier'] extends EImplementAccessModifier.private ? false : true) ?
        Print<['Property', `'${Assert<Property, string>}'`, 'must be', MetadataProperty['modifier'] extends EImplementAccessModifier.public ? 'public' : 'private']>
        : never
        : never
        : never
        : never
        ;


    type PrintNot<T extends boolean> = T extends false ? 'not' : '';
    type VerifyAttributeMetadata<
        T extends {},
        E extends {},
        TKeys extends string = Assert<ValueOf<{ [key in keyof E]: IsUnknown<GetByKey<T, key>> extends false ? key : never }>, string>,
        TMetadata extends HashObjectLiteralMetadata<T> = ObjectLiteralMetadata<T, TKeys>,
        EMetadata extends HashObjectLiteralMetadata<E> = ObjectLiteralMetadata<E, TKeys>,
    > =
        ValueOf<{
            [key in TKeys]:
            GetByKey<TMetadata, key> extends infer TMetadataProperty ?
            GetByKey<EMetadata, key> extends infer EMetadataProperty ?
            TMetadataProperty extends IObjectLiteralMetadata ?
            EMetadataProperty extends IObjectLiteralMetadata ?
            | (TMetadataProperty['isOptional'] extends EMetadataProperty['isOptional'] ? never : Print<['Property', `'${key}'`, 'must', PrintNot<EMetadataProperty['isOptional']>, 'be optional']>)
            | (TMetadataProperty['isReadonly'] extends EMetadataProperty['isReadonly'] ? never : Print<['Property', `'${key}'`, 'must', PrintNot<EMetadataProperty['isReadonly']>, 'be readonly']>)
            : never
            : never
            : never
            : never
        }>
        ;

    type TImplementInfo = { isPrototype: boolean; oppositeSource: object };
    type VerifyIfAttributeExtends<
        T,
        E,
        Info extends TImplementInfo,
        Result extends { key: unknown; value: unknown } = _TImplementPrivate<E, T>,
    > =
        IsNever<Result> extends false ?
        Result['key'] extends infer Key ?
        Key extends keyof E ?
        Key extends string ?
        HasKey<T, Key> extends false
        ? HasKey<Info['oppositeSource'], Key> extends true ? Print<['Property', `'${Key}'`, `must ${Info['isPrototype'] extends true ? 'not ' : ''}be static`]> : Print<['Property', `'${Key}'`, 'is missing in type', T, 'but required in type ', E]>
        : Print<["Type", GetByKey<T, Key>, "is not assignable to type ", E[Key], "in property", Key]>
        : never
        : never
        : never
        : never
        ;
}

const $pickGetFnName = pickKeys<TAddGetFnNameClass>().getFnName;

function thru<Fn extends (...params: unknown[]) => unknown>(fn: Fn) {
    return <Acc, Item>(acc: Acc, item: Item, index: number, items: Item[]) => {
        const source = [...items]
        items.splice(0)
        return fn(source) as unknown as ReturnType<Fn>
    }
}


export type TGetFnName = () => string;
export function AddGetFnName<T extends { name: string }>(source: T): (IsNever<(Extract<keyof T, keyof Pick<TAddGetFnNameClass, 'getFnName'>>)> extends true ? never : $$<['Method', keyof Pick<TAddGetFnNameClass, 'getFnName'>, 'must be private']>) | (IsNever<_TImplementPrivate<TAddGetFnNameClass, T>> extends true ? never : $$<['Missing', keyof Pick<TAddGetFnNameClass, 'getFnName'>, 'method']>) {
    fakeAssertsGuard<TAddGetFnNameClass>(source)
    /*
        Do
        names
            .map(name => ({ name }))
            .map(add('descriptor', item => Object.getOwnPropertyDescriptor(source, item.name)))
            .map(add('functions', ({ descriptor: { value, get, set } }) => {
                const $descriptor = ({
                    value,
                    get,
                    set,
                })
                return _.pickBy($descriptor, (item, name) => isValidFunction(item))
            } ))
        ;
    */


    getAllClassStaticMethods(source).filter(name => isValidFunction(Object.getOwnPropertyDescriptor(source, name).value)).map((name: keyof typeof source) => {
        function getFnName() {
            return `${source.name}::${name as string}`;
        }
        const context = new Proxy(source, {
            get: (_, property: string) => {
                if (property === $pickGetFnName) return getFnName;
                return source[property];
            },
        });
        const fn = (Object.getOwnPropertyDescriptor(source, name).value as TFunction).bind(context);

        source[name] = fn
    });

    return;
}

export const $TargetInstance = Symbol()

export function getTargetInstance(target: {}): unknown;
export function getTargetInstance(target: { [$TargetInstance]?: unknown }) {
    return target[$TargetInstance]
}

export interface StaticFn<T extends {} = {}> { prototype: T; getFnName?: never; }
export interface StaticClass<T extends {} = {}> extends StaticFn<T> { new(): T }

export type NativeStaticClass<T extends {} = {}> = (abstract new (...params: any[]) => T) & { prototype?: T; }

export function AddStaticClassNameOnStack<T extends StaticFn>(target: T): void
export function AddStaticClassNameOnStack<T extends StaticClass>(target: T): void {
    const instance = new target();

    (target as {} as { [$TargetInstance]: unknown })[$TargetInstance] = instance;

    const descriptors = Object.getOwnPropertyDescriptors(target);
    for (const property in descriptors)
        if (typeof descriptors[property].value === 'function') {
            const fn = descriptors[property].value;
            const fnName = buildFnName(target, property);
            const bound = fn.bind(instance);
            bound['fnName'] = fnName;
            target[property as keyof T] = bound as {} as T[keyof T];
        };
    ;
}

export function buildFnName<T extends StaticClass>(target: T, property: string) {
    const fnName = `${target.name}.${property}`
    return fnName;
}

export function addGetFnName<Fn extends TFunction & { name: FnName }, FnName extends string>(inputFN: Fn, name: FnName = inputFN.name) {
    function getFnName() {
        return `${name}`;
    }
    function createContext(previous) {
        const context = new Proxy(previous, {
            get: (_, property: string) => {
                if (property === $pickGetFnName) return getFnName;
                return previous[property];
            },
        });
        return context;
    }
    const $inputFN = new Proxy(inputFN, {
        apply: function (target, context, args) {
            return target.bind(createContext(context ?? {}))(...args)
        }
    });
    return $inputFN as Fn;
}

export function safeHasProp<O extends Object>(obj: O, prop: keyof O): boolean {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}


export function tryParseJSONObjectWithDefaultReturn(jsonString: string): object {
    const result = tryParseJSONObject(jsonString)
    return result === false
        ? {}
        : result
}

export function tryParseJSONObject(jsonString: string): object | false {
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { }

    return false;
};

/**
 * Esta funcao consegue pegar qualquer coisa e fazer o stringfy sem exceptions
 * @param input
 * @returns
 */
export function safeStringifyAnything<T>(input: T): string {
    if (input instanceof Error) {
        return safeStringify({
            message: input.message,
            name: input.name,
            stack: input.stack,
            ...Object.getOwnPropertyNames(input).reduce((acc, key) => {
                acc[key] = input[key];
                return acc;
            }, {})
        });
    }

    return safeStringifyJSON(input)
}

export function safeStringifyJSON<T>(input: T): string {
    if (typeof input === "string") return input;

    if (typeof input === 'undefined') {
        return 'undefined';
    } else if (typeof input === 'function') {
        return 'function';
    } else if (typeof input === 'symbol') {
        return 'symbol';
    }

    return safeStringify(input)
}

const trimIdents = (match: string, string?: string | null) => string || match.replace(/[^\t\r\n ]/g, ' ');

/**
 * Converts a JSONC (JSON with comments) to a JavaScript value.
 * Allows for trailing commas.
 *
 * @see https://gist.github.com/westc/32ae8971284b21dbc656926e7e210f5b
 *
 * @param {string} code The JSONC string that will be parsed and converted to a JS value.
 * @param {?(function(this:(Object|Array),string,any): any)=} reviver function
 * @returns {*}  Returns the JS equivalent of the JSONC code that is given.
 */
export function parseJSONC(code, reviver = undefined) {
  return JSON.parse(
    code.replace(
      /("(?:[^"\\]+|\\.)*")|\/\/[^\r\n]*|\/\*[^]*?\*\//g,
      trimIdents
    ).replace(
      /("([^"\\]+|\\.)*")|,\s*(?=[\}\]])/g,
      trimIdents
    ),
    reviver
  );
}

export function updatePrimitiveFields<T>(source: T, setter: Partial<T>) {
    for (const name in source) {
        if (typeof source[name] === 'object') continue;
        source[name] = setter[name] ?? source[name];
    }
    return source;
}

export function removeFileExtension(filename: string): string {
    const a = filename.split("."); a.pop();
    return a.join(".");
}

export function getFileExtension(filename: string, removeDot: boolean = false): string {
    return (removeDot ? '' : '.') + filename.split(".").pop();
}

export function isValidURL(input: string): boolean {
    try {
        new URL(input)
        return true;
    } catch {
        return false
    }
}

export function isValidURLByRegex(input: string | undefined): boolean {
    if(!input) { 
        return false;
    }
    const regex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?::\d{2,5})?(?:\/[^\s?#]*)?(?:\?[^\s#]*)?(?:#[^\s]*)?$/i
    return regex.test(input);
}

export function pad(source: string, token: string, size: number): string {
    return source
        .padStart(((size + source.length) / 2), token)
        .padEnd(size, token)
        ;
}

export type EnumValue = string | number;
export type EnumObject = { [key in string]: EnumValue };


type EnumValuesToConstant<T extends EnumObject, Constant> = { [key in ValueOf<T>]: Constant };

export function enumValuesToConstant<T extends EnumObject, Constant extends EnumValue>(source: T, constant: Constant): Compute<EnumValuesToConstant<T, Constant>> {
    const computed: EnumValuesToConstant<T, Constant> = lodash.chain(source).mapKeys().mapValues(() => constant).value() as EnumValuesToConstant<T, Constant>;
    return computed;
}

export function narrow<E>() {
    return <T>(source: $Narrow<T, E>) => source;
}

interface ICreateIndexedFind {
    isDynamic?: boolean;
    isToMany?: boolean;
}
type $$PrintErrorOnArray<Message> = [Message, ...unknown[]];
export function createIndexedSearch<Item extends {}, Order extends $Extends<Order, EOrder>, EOrder extends (keyof Item)[], Options extends ICreateIndexedFind>(
    items: Item[],
    fnOrder: ($: { [key in keyof Item]: key }) =>
        & Order
        & AfterNarrow<Order extends unknown ?
            & (
                Order extends EOrder ?
                [Exclude<EOrder[number], Order[number]>] extends [infer MissingKeys] ?
                IsEmpty<MissingKeys> extends false ?
                Options['isDynamic'] extends true ? $$PrintErrorOnArray<$$<['Missing the following keys', UnionToArray<MissingKeys>]>> : unknown
                : unknown
                : never
                : $$PrintErrorOnArray<$$<[`Indexes must be`, UnionToArray<EOrder[number]>]>>
            )
            & $$.NonRepeatedValues<Assert<Order, {}>>
            : unknown>,
    options: Options = {} as Options,
) {
    type TMappedField = keyof Item | typeof empty;
    type MappedFields = Map<TMappedField, MappedFields | true>;
    const mappedFields: MappedFields = new Map()
    const map = new Map()
    const empty = Symbol('empty');
    const order: EOrder = fnOrder(pickKeys<Item>()) as EOrder

    if (!options.isDynamic) {
        mapFields(order as TMappedField[])
    }
    type Search = Options['isDynamic'] extends true ? Partial<Item> : Pick<Item,
        // @ts-expect-error
        Order[number]
    >

    type InputSearch =
        Options['isDynamic'] extends true ? Search :
        // @ts-expect-error
        IsEqual<Order[number], UnionLast<Order[number]>> extends true ? Item[Order[number]] :
        Search
        ;
    type Computed = Options['isToMany'] extends true ? Item[] : Item;
    return (inputSearch: InputSearch): Computed => {
        const search = (order.length === 1 && !options.isDynamic) ? ({ [order[0]]: inputSearch }) as unknown as Search : inputSearch as Search;
        const fields: TMappedField[] = getFields(search);
        if (options.isDynamic && !hasFields(fields)) {
            mapFields(fields)
        }
        const computed = deepGet(map)(...fields.map(field => search[field as keyof Search]))
        if (options.isToMany) return (computed ? [...(computed as Map<unknown, unknown>).keys()] : []) as Computed
        return computed as Computed
    };

    function hasFields(fields: TMappedField[]) {
        return deepGet(mappedFields)(...fields);
    }
    function getFields(source: Search): TMappedField[] {
        const computed: (keyof Item)[] = keys(source);
        return (order as (keyof Item)[]).map(field => computed.includes(field) ? field : empty);
    }
    function mapFields(fields: TMappedField[]) {
        // @ts-expect-error
        deepSet(mappedFields)(...fields, true);
        items.forEach(item => {
            const path: (Item | Item[keyof Item] | typeof empty)[] = [...fields.map(field => item[field as keyof Item]), item];
            if (options.isToMany) {
                path.push(empty)
            }
            // @ts-expect-error
            deepSet(map)(...path)
        });
    }
}

export function isJSON(value: string): boolean {
    try {
        JSON.parse(value);
        return true;
    } catch (_e) {
        return false;
    }
}

export function stringSplice(target: string, idx: number, rem: number, insert: string) {
    return target.slice(0, idx) + insert + target.slice(idx + rem);
}


export type TAwaited<T> =
    T extends null | undefined ? T :
    T extends object & { then(onfulfilled: infer F): any } ?
    F extends ((value: infer V, ...args: any) => any) ?
    TAwaited<V> :
    never :
    T
    ;

export function safeHasOwnProperty<T extends object>(target: T, key: string): boolean {
    return isValidRef(target) && Object.hasOwnProperty.call(target, key);
}

const byteBinarySize: number = 1048576;
const byteSize: number = 1000000;

export function mbToByte(mb: number, binary: boolean = true): number {
    return mb * (binary ? byteBinarySize : byteSize);
}

export function bytesToMB(bytes: number, binary: boolean = true): number {
    return bytes / (binary ? byteBinarySize : byteSize);
}

// @ https://github.com/sindresorhus/pretty-bytes
const BYTE_UNITS = [
    'B',
    'kB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
];

const BIBYTE_UNITS = [
    'B',
    'kiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
];

const BIT_UNITS = [
    'b',
    'kbit',
    'Mbit',
    'Gbit',
    'Tbit',
    'Pbit',
    'Ebit',
    'Zbit',
    'Ybit',
];

const BIBIT_UNITS = [
    'b',
    'kibit',
    'Mibit',
    'Gibit',
    'Tibit',
    'Pibit',
    'Eibit',
    'Zibit',
    'Yibit',
];

/**
Formats the given number using `Number#toLocaleString`.
- If locale is a string, the value is expected to be a locale-key (for example: `de`).
- If locale is true, the system default locale is used for translation.
- If no value for locale is specified, the number is returned unmodified.
*/
const toLocaleString = (number: number, locale, options): string => {
    let result = number;
    if (typeof locale === 'string' || Array.isArray(locale)) {
        result = +number.toLocaleString(locale, options);
    } else if (locale === true || options !== undefined) {
        result = +number.toLocaleString(undefined, options);
    }

    return result.toString();
};

type TPrettyBytesOptions = {
    /**
     * Include plus sign for positive numbers. If the difference is exactly zero a space character will be prepended instead for better alignment.
     */
    signed?: boolean,
    /**
     * Format the number as bits instead of bytes. This can be useful when, for example, referring to bit rate.
     */
    bits?: boolean,
    /**
     * Format the number using the Binary Prefix instead of the SI Prefix. This can be useful for presenting memory amounts. However, this should not be used for presenting file sizes.
     */
    binary?: boolean,
    /**
     * Important: Only the number and decimal separator are localized. The unit title is not and will not be localized.
     *
     * - If true: Localize the output using the system/browser locale.
     * - If string: Expects a BCP 47 language tag (For example: en, de, …)
     * - If string[]: Expects a list of BCP 47 language tags (For example: en, de, …)
     */
    locale?: boolean | string | string[],
    /**
     * The minimum number of fraction digits to display.
     *
     * If neither minimumFractionDigits or maximumFractionDigits are set, the default behavior is to round to 3 significant digits.
     *
     * @example
    import prettyBytes from 'pretty-bytes';

    // Show the number with at least 3 fractional digits
    prettyBytes(1900, {minimumFractionDigits: 3});
    //=> '1.900 kB'

    prettyBytes(1900);
    //=> '1.9 kB'
     */
    minimumFractionDigits?: number;
    /**
     * The maximum number of fraction digits to display.
     *
     * If neither minimumFractionDigits or maximumFractionDigits are set, the default behavior is to round to 3 significant digits.
     * @example
     *
    import prettyBytes from 'pretty-bytes';

    // Show the number with at most 1 fractional digit
    prettyBytes(1920, {maximumFractionDigits: 1});
    //=> '1.9 kB'

    prettyBytes(1920);
    //=> '1.92 kB'`
     */
    maximumFractionDigits?: number;
}

export function prettyBytes(number: number, options: TPrettyBytesOptions = {}): string {
    if (!Number.isFinite(number)) {
        throw new TypeError(`Expected a finite number, got ${typeof number}: ${number}`);
    }

    options = {
        bits: false,
        binary: false,
        ...options,
    };

    const UNITS: string[] = options.bits
        ? (options.binary ? BIBIT_UNITS : BIT_UNITS)
        : (options.binary ? BIBYTE_UNITS : BYTE_UNITS);

    if (options.signed && number === 0) {
        return ` 0 ${UNITS[0]}`;
    }

    const isNegative: boolean = number < 0;
    const prefix: string = isNegative ? '-' : (options.signed ? '+' : '');

    if (isNegative) {
        number = -number;
    }

    let localeOptions: TPrettyBytesOptions;

    if (options.minimumFractionDigits !== undefined) {
        localeOptions = { minimumFractionDigits: options.minimumFractionDigits };
    }

    if (options.maximumFractionDigits !== undefined) {
        localeOptions = { maximumFractionDigits: options.maximumFractionDigits, ...localeOptions };
    }

    if (number < 1) {
        const numberString: string = toLocaleString(number, options.locale, localeOptions);
        return prefix + numberString + ' ' + UNITS[0];
    }

    const exponent: number = Math.min(Math.floor(options.binary ? Math.log(number) / Math.log(1024) : Math.log10(number) / 3), UNITS.length - 1);
    number /= (options.binary ? 1024 : 1000) ** exponent;

    if (!localeOptions) {
        number = +number.toPrecision(3);
    }

    const numberString: string = toLocaleString(Number(number), options.locale, localeOptions);

    const unit: string = UNITS[exponent];

    return prefix + numberString + ' ' + unit;
}

// /@ https://github.com/sindresorhus/pretty-bytes

export function first<T extends unknown[]>(items: T): T[0] {
    return items[0];
}

export function createOnePerTimeAsyncFn<A extends any[], B>(fn: (...params: A) => Promise<B>) {
    execute.promise = undefined as unknown as (Promise<B> | undefined);

    return execute;

    async function execute(...params: A) {
        if (execute.promise) return execute.promise;

        const config = initPromise<B>();
        execute.promise = config.promise;

        try {
            config.resolve(await fn(...params));
        } catch (err) {
            config.reject(err);
        }

        delete execute.promise;
    }
}

export function createOnePerTimeFunction(fn: () => Promise<void>) {
    let isFetching: boolean;

    return async () => {
        if (isFetching) return;
        isFetching = true;
        try {
            await fn();
        } catch { }
        isFetching = false;
    }
}

export function invertObj<O extends object, K extends keyof O, NK extends string = string>(object: O): Record<NK, K> {
    return typedClone(_invertObj(object) as Record<NK, K>)
}

export function freeze<T>(input: T): Readonly<T> {
    return Object.freeze<T>(input) as T
}

/**
 * Apply array unshift method in a possible invalid reference and return a valid array
 *
 * If is a valid array reference, creates a copy,
 * apply unshift on there and then return the copy,
 * otherwise(if is an undefined or null) return a
 * new array with the item.
 * @param target Source array
 * @param item Item to be added
 * @returns New Array
 */
export function safeUnshift<T extends Array<unknown>>(target: T, item: T[0]): T {
    let copy: T;
    return Array.isArray(target) ? (copy = [...target] as T, copy.unshift(item), copy) : [item] as T;
}


type DiffMap = TDeepMap<[property: string, values: unknown[]]>

export namespace Compare {
    interface DifferencesOf<T extends {} = {}> {
        map: Map<string, unknown[]>;
        isCompatible: boolean;
        json?: T;
    }

    export function differencesOf<T extends {}>(backup: T, ns: T, remote: T): DifferencesOf<DeepPartial<T>> {
        const differences = {
            ns: deepDifferences(backup, ns),
            remote: deepDifferences(backup, remote),
        }

        const map = mergeMaps(differences.remote, differences.ns);
        const isCompatible = isCompatibleOf(map);

        const computed: DifferencesOf = {
            map,
            isCompatible,
        }

        if (isCompatible) {
            const json = {};
            for (const [key, values] of map) _.set(json, key, last(values));
            computed.json = json;
        }

        return computed;

        function isCompatibleOf(map: DiffMap) {
            for (const values of map.values()) if (values.length === 3) return false;
            return true;
        }
    }

    export function mergeMaps<T>(...maps: Map<string, T[]>[]) {
        const map: Map<string, T[]> = new Map();
        const keys = new Set(maps.map(item => [...item.keys()]).flat());
        for (const map of maps) for (const key of map.keys()) keys.add(key);
        for (const key of keys) {
            const items: T[] = [];
            for (const item of maps) if (item.has(key)) item.get(key)!.map(value => items.push(value));
            map.set(key, deepUnique(items));
        }
        return map;
    }
}

export const { differencesOf } = Compare;

export function deepDifferences<T extends {}>(
    target: T,
    otherTarget: T,
    map: DiffMap = new Map(),
    property: string = '',
    shouldIgnoreListOrder = false,
): DiffMap {
    type Key = keyof T & string;
    const targetKeys: Key[] = keys(target);
    const otherTargetKeys: Key[] = keys(otherTarget);

    const set = new Set<Key>();

    targetKeys.forEach(run);
    otherTargetKeys.forEach(run);

    return map;

    function run(key: Key): void | {} {
        if (set.has(key)) return;
        set.add(key);

        const value = target[key]
        const otherValue = otherTarget[key]
        let item = [value, otherValue];
        let name = property ? `${property!}.${key}` : key

        if ((Array.isArray(target)) && (Array.isArray(otherTarget))) {
            name = `${property}[${key}]`;
            if (shouldIgnoreListOrder) {
                const from = execute(target, otherTarget);
                const to = execute(otherTarget, target);
                if (!(from.length + to.length)) return;
                return map.set(name, [from, to]);
            }
        }

        if (typeOf(value) !== typeOf(otherValue)) {
            return add();
        }

        if (isPrimitive(value)) {
            if (otherValue !== value) return add();
            return;
        }

        deepDifferences(value as {}, otherValue as {}, map, name, shouldIgnoreListOrder);

        function add() {
            return map.set(name, item);
        }

        function execute(target: {}[], otherTarget: {}[]) {
            return _.differenceWith(target, otherTarget, _.isEqual);
        }
    }
}


export namespace ValidateSchema {
    export interface Validator {
        isRequired: boolean;
        type: ETypeOf;
    }

    export type Validators<T> = RequiredNonNullable<
        { [key in keyof T]: Define<Validator, { type: ToTypeOf<NonNullable<T[key]>>; isRequired: [{}] extends [{ [$key in key]: T[key] }] ? false : true; }> }
    >;

    type RequiredNonNullable<T> = { [key in keyof T]-?: NonNullable<T[key]> } & {};


    export function schemaOf<T>(schema: Validators<T>) {
        return schema;
    }

    export function validatorOf<T>(schema: Validators<T>) {
        return (entity: T) => {
            execute(schema, entity)
            return entity;
        }
    }

    export function execute<T>(schema: Validators<T>, entity: Partial<T>): asserts entity is T {
        for (const name in schema) {
            const item = schema[name];

            const value = entity[name]

            if (isInvalid(value)) {
                if (item.isRequired) throw new Error(`Missing ${name} in entity`)
                else continue;
            }

            if (typeOf(value) !== item.type) throw new Error(`Invalid ${name} field in entity`);
        }
    }

}

export interface JsonDifferences<T> {
    from: Partial<T>;
    to: Partial<T>;
    isDifferent?: boolean;
}

export function jsonDifferences<T extends {}>(from: T, to: T, map = deepDifferences(from, to)): JsonDifferences<T> {
    return {
        from: pick(from),
        to: pick(to),
        isDifferent: !!map.size,
    }

    function pick(target: T) {
        return _.pick(target, [...map.keys()])
    }
}


export function deepUnique<T>(items: T[]): T[] {
    return _.uniqWith(items, _.isEqual);
}


interface GetToMergeNS<T extends {}> {
    isDifferent: boolean;
    isCompatible: boolean;
    toMerge?: DeepPartial<T>;
}


export function getToMergeNS<NS extends INonSerializable>(backup: NS, ns: NS, externalNS: NS): GetToMergeNS<NS> {
    if (ns.lastTouch === externalNS.lastTouch) return {
        isDifferent: false,
        isCompatible: true
    }

    const { isCompatible, map, json } = differencesOf(
        omitLastTouch(backup),
        omitLastTouch(ns),
        omitLastTouch(externalNS),
    );

    return {
        toMerge: json,
        isDifferent: !!map.size,
        isCompatible
    }

    function omitLastTouch<NS extends INonSerializable>(ns: NS): NS;
    function omitLastTouch<NS extends INonSerializable>(ns: NS) {
        return omit(ns, 'lastTouch');
    }
}



export function getInfo<T, Value>(lists: T[][], fn?: (item: T) => Value) {
    const map: TDeepMap<[value: T | Value, amount: number]> = new Map();
    const mapValues: TDeepMap<[item: T, amount: T | Value]> = new Map();
    const items: Set<T> = new Set();
    const differences: Set<T> = new Set();
    const common: Set<T> = new Set();

    lists.forEach(list => list.forEach(item => {
        const value: T | Value = getValue(item);
        const amount = getAmount(value);
        map.set(value, amount + 1);
    }));

    lists.forEach(list => list.forEach(item => {
        const value = getValue(item);
        const amount = getAmount(value);
        if (amount !== lists.length) differences.add(item);
        if (amount === lists.length) common.add(item);
        items.add(item)
    }));

    return {
        common,
        differences,
        items,
    };

    function getAmount(value: T | Value): number {
        const amount = map.get(value) ?? 0;
        return amount;
    }
    function getValue(item: T): T | Value {
        if (!mapValues.has(item)) mapValues.set(item, executeGetValue(item));
        return mapValues.get(item)!;
    }
    function executeGetValue(item: T): T | Value {
        let value: T | Value = item;
        if (fn) value = fn?.(item);
        return value;
    }
}


export function findGetOutput<T extends unknown[], V>(items: T, fn: (item: T[number], index: number, items: T) => V): V | undefined {
    let index: number = -1;
    while ((++index) < items.length) {
        const item = items[index];
        const out = fn(item, index, items);
        if (out) return out
    }
}

export function findGet<T extends unknown[], V>(items: T, fn: (item: T[number], index: number, items: T) => V): [V, T[number]] | undefined {
    let index: number = -1;
    while ((++index) < items.length) {
        const item = items[index];
        const out = fn(item, index, items);
        if (out) return [out, item as T[number]];
    }
}

export function hasWhiteSpace(text: string): boolean {
    return /\s/g.test(text);
}

export function mod(number: number, max: number): number {
    return (((number) % max) + max) % max;
}



/**
 * Divide when is different
 *
 * @example
 * // [["a"], ["b", "b"], ["a", "a", "a"], ["c"]]
 *
 * partitionOf(
 *     ['a', 'b', 'b', 'a', 'a', 'a', 'c'],
 *     item => item
 * )
 */
function partitionOf<Item, U>(items: Item[], fn: (item: Item, index: number, items: Item[]) => U) {
    const result: Item[][] = [];
    let expression: U | undefined;

    items.forEach((item, index, items) => {
        const current = fn(item, index, items)
        const isDifferent = !Object.is(expression, current);
        expression = current;
        if (isDifferent) result.push([]);
        last(result).push(item);
    });

    return result;
}


/**
 * Turns String.prototype.replace params into a RegExpMatchArray
 * @example 'first aaab third'.replace(/(?<content>aa)a(b)/, replacer(input => input.groups.content)) // 'first aa third'
 */
export function replacer(fn: (list: RegExpMatchArray) => string) {
    type MatchEnd = [index: number, input: string, groups?: { [key in string]: string }]
    type MatchStart = [match: string, ...$groups: string[]]
    type Match = [...start: MatchStart, ...end: MatchEnd];

    return (...items: Match) => {
        const list = toMatchArray(items);
        return fn(list);
    }

    function toMatchArray(items: Match): RegExpMatchArray;
    function toMatchArray(items: Match & { index?: {}; input?: {}; groups?: {} }) {
        const list = items as unknown[] as RegExpMatchArray
        const indexOfNumber = items.length + (typeof last(items) === 'number' ? -2 : -3);
        [items.index, items.input, items.groups] = items.splice(indexOfNumber);
        return list;
    }
}

export function Memoize<
    T extends StaticClass,
    Key extends keyof T & string,
    A extends any[],
    B,
>(
    source: T,
    key: Key,
    descriptor: TypedPropertyDescriptor<(...params: A) => B>,
) {
    descriptor.value = memoize(descriptor.value!);
}

export function LodashMemoize<
    T extends {},
    Key extends keyof T & string,
    A extends any[],
    B,
>(
    source: T,
    key: Key,
    descriptor: TypedPropertyDescriptor<(...params: A) => B>,
) {
    descriptor.value = lodash.memoize(descriptor.value!);
}


export function memoize<
    A extends any[],
    B,
>(
    fn: (...params: A) => B
) {
    const map = new Map<number, MapDeep<any[]>>();

    const target = {
        [fn!.name]() {
            const items = [...arguments];
            const args = items as never;
            const map = getMap(arguments.length);
            if (map.has(args)) return map.get(args)!;
            const output = fn!.apply(this, arguments as never);
            items.push(output);
            map.set(args);
            return output;
        }
    }[fn!.name]

    return target;

    function getMap(size: number) {
        if (!map.has(size)) map.set(size, new MapDeep());
        return map.get(size)!;
    }
}



export function ifValidExecute<T, U>(condition: T | Nullish, fn: (value: T) => U) {
    if (isValidRef(condition)) return fn(condition);
}

export function ifTrueExecute<T, U>(condition: T | Nullish | false | '' | 0, fn: (value: T) => U) {
    if (condition) return fn(condition);
}

export function ifTruthy<T, U>(condition: T | Nullish | false | '' | 0): condition is T {
    return !!condition
}


export const isTruthy = ifTruthy;

const reservedClassProps: string[] = ['constructor'];

export function getAllClassProperties(targetClass: new (...[]) => unknown): string[] {
    const allClassProperties: string[] = Object.getOwnPropertyNames(targetClass.prototype);

    return allClassProperties.filter(method => !reservedClassProps.includes(method))
}

export function getMethodsOfClass(targetClass: new (...[]) => unknown) {
    const allClassProperties: string[] = getAllClassProperties(targetClass);

    return allClassProperties.filter(method => {
        const propDescriptor = Object.getOwnPropertyDescriptor(targetClass.prototype, method);

        return isValidFunction(propDescriptor.value)
    });
}


namespace JoinURL {
    export interface Input {
        base?: string;
        names: string[];
        query?: { [key in string]: string };
    }

    export type InputNames = Define<Input, {
        base?: string;
        names: string[];
    }>

    export type InputQuery = Define<Required<Input>, {
        base: string;
        names: string[];
        query: { [key in string]: string };
    }>

    export interface InputWithURL extends Required<Input> { }
}

/**
 * Safe concats URL
 * @param base @example 'https://colmeia.me/foo'
 * @param names @example ['/bar', 'x', 'y', 'z']
 * @param query @example { a: 'test' }
 * @returns @example 'https://colmeia.me/foo/bar/x/y/z?a=test'
 */
export function joinURL<Input extends $Extends<Input, JoinURL.InputQuery | JoinURL.InputNames>>(input: Input): string;
export function joinURL({ base, names = [], query }: JoinURL.Input): string {
    if (!base) return path.posix.join(...names);
    const { origin, pathname } = new URL(base);
    const url = new URL(path.posix.join(pathname, ...names), origin);
    if (query) url.search = new URLSearchParams(query).toString();
    return url.href;
}

export namespace DefineURL {
    const protocol = 'tcp:';

    interface JoinPathsInput {
        pathnames: string[];
        search?: { [key in string]?: string };
    }

    interface PathInfo {
        pathname: string;
        search: { [key in string]: string };
    }

    export function joinPaths(input: JoinPathsInput): string {
        const url: URL = new URL(`${protocol}${path.posix.join(...input.pathnames)}`);
        if (input.search) url.search = new URLSearchParams(input.search as {}).toString();
        return url.href.slice(url.protocol.length);
    }

    export function getPathnameInfo(name: string): PathInfo {
        const url: URL = new URL(`${protocol}${name}`);
        return {
            pathname: url.pathname,
            search: Object.fromEntries(url.searchParams as {} as Iterable<[string, string]>)
        }
    }
}

function formatMessage(message: TemplateStringsArray | string, ...inputs: unknown[]): string {
    if (typeof message !== 'string') {
        return formatMessage(message.map((message, index) => inputs[index] ? [message, inputs[index]] : [message]).map(item => item.join('')).join(''));
    }
    return message.split('\n').map(item => item.trim()).filter(item => item).join('\n').trim().replace(formatMessage.escapePattern, (match, content) => (content) === '\n' ? '' : content);
}
formatMessage.escape = function formatMessageEscape(content: string) {
    return `[formatMessageEscape:${(content)}]`
}
formatMessage.escapePattern = new RegExp(createPatternOfMaker(formatMessage.escape), 'g');

//
//
export function createPatternOfMaker(fn: (content: string) => string) {
    const PLACEHOLDER = '____CreatePatternOfMaker_PLACEHOLDER____';
    const original = EscapeRegExp.escapeRegExp(fn(''))
    const formated = EscapeRegExp.escapeRegExp(fn(PLACEHOLDER));
    const endIndex = original.split('').findIndex((char, index) => char !== formated[index]);
    const start = original.slice(0, endIndex);
    const end = original.slice(endIndex);
    const pattern = `${start}(?<content>(?:(?!${end})[^])*)${end}`;
    return pattern;
}


formatMessage.BREAK_LINE = '<div class="break__line"></div>';

export {
    formatMessage
};

export function sanitize(target: string) {
    return [...target].map(item => item.normalize('NFD').charAt(0)).join('').toLowerCase();
}

export function getRandomNumbers() {
    return Math.random().toString().slice(2);
}

export function sumOf(items: number[]) {
    let sum = 0;
    for (const item of items) sum += item;
    return sum;
}

export function averageOf(items: number[]) {
    return sumOf(items) / items.length;
}


export interface MapEmplaceConfig<Key, Value> {
    insert?(key: Key, map: Map<Key, Value>): Value;
    update?(existing: Value, key: Key, map: Map<Key, Value>): Value;
}

export interface SafeMapEmplaceConfig<Key, Value> extends MapEmplaceConfig<Key, Value> {
    insert(key: Key): Value;
}


/**
 * Briefly based on map.prototype.emplace proposal
 */
export function mapEmplace<Key, Value>(map: Map<Key, Value>, config: SafeMapEmplaceConfig<Key, Value>): (key: Key) => Value;
export function mapEmplace<Key, Value>(map: Map<Key, Value>, config: MapEmplaceConfig<Key, Value>): (key: Key) => Value | undefined;
export function mapEmplace<Key, Value>(map: Map<Key, Value>, config: MapEmplaceConfig<Key, Value>): (key: Key) => Value | undefined {
    return getter;

    function getter(key: Key) {
        onGet(key);
        return map.get(key);
    }

    function onGet(key: Key): void {
        if (!map.has(key)) {
            if (config.insert) map.set(key, config.insert(key, map));
            return;
        }

        if (config.update) {
            map.set(key, config.update(map.get(key)!, key, map));
            return;
        }
    }
}


interface IntlSegmentData {
    /** A string containing the segment extracted from the original input string. */
    segment: string;
    /** The code unit index in the original input string at which the segment begins. */
    index: number;
    /** The complete input string that was segmented. */
    input: string;
    /**
     * A boolean value only if granularity is "word"; otherwise, undefined.
     * If granularity is "word", then isWordLike is true when the segment is word-like (i.e., consists of letters/numbers/ideographs/etc.); otherwise, false.
     */
    isWordLike?: boolean;
}


export const deepMapValues = deepMapChange((target, key, parentEntity, newValue) => {
    if ((newValue !== target) && (key !== undefined) && (parentEntity !== undefined)) {
        Reflect.set(parentEntity, key, newValue);
        return true;
    }
    return false;
});

export const deepMapKeys = deepMapChange<PropertyKey | undefined>((target, key, parentEntity, newKey) => {
    if ((newKey !== undefined) && (newKey !== key) && (key !== undefined) && (parentEntity !== undefined)) {
        Reflect.deleteProperty(parentEntity, key);
        Reflect.set(parentEntity, newKey, target);
        return true;
    }
    return false;
});

function deepMapChange<Out>(onChange: (value: unknown, key: PropertyKey | undefined, parentEntity: object | undefined, out: Out) => boolean) {
    return <T extends {}>(target: T, fn: (value: unknown, key: PropertyKey | undefined) => Out): T => {
        execute(target);
        return target;
        function execute(target: {}, key?: PropertyKey, parentEntity?: object) {
            const out = fn(target, key);
            if (onChange(target, key, parentEntity, out)) return;
            if (typeof target !== 'object') return;
            if (Array.isArray(target)) {
                target.forEach((item, index, parentEntity) => execute(item, index, parentEntity))
                return;
            }
            for (const key in target) {
                execute(Reflect.get(target, key), key, target);
            }
        }
    }
}

export function deepReplaceId<T extends {}>(input: T, from: string, to: string) {
    const clone = input;
    deepMapValues(clone, item => item === from ? to : item);
    deepMapKeys(clone, (item, key) => key === from ? to : key);
    return clone;
}


export function rangeOf(to: number): Generator<number>;
export function rangeOf(from: number, to?: number): Generator<number>;
export function* rangeOf(from: number, to?: number): Generator<number> {
    if (to === undefined) {
        to = from;
        from = 0;
    }
    let index = from;
    while (index < to) yield index++;
}


export function printJSON(content: { [key in string]: unknown }) {
    return Object.keys(content)
        .map(property => `[${property}: ${print(content[property])}]`)
        .join(' ')
        ;

    function print(target: unknown): string {
        if (Array.isArray(target)) return target.map(item => print(item)).join(', ');
        return JSON.stringify(target);
    }
}


export function dateNormalizer(dateStr: string): string {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    });
    return formattedDate; // Output: "03/01/2023"
}

export function createProcessor<T extends object>(target: T) {
    return new Proxy({}, {
        get(_: {}, property: PropertyKey) {
            const name = property as keyof T
            return {
                getter() {
                    return target[name];
                },
                setterByValue(value: T[keyof T]) {
                    target[name] = value
                },
                setterByEntity(value: T) {
                    target[name] = value[name];
                },
            }
        }
    }) as { [key in keyof T]: { getter(): T[key]; setterByValue(value: T[key]): void; setterByEntity(value: T): void } };
}

export function deepCloneJSON<T>(input: T): T {
    return JSON.parse(safeStringifyJSON(input));
}

/**
 * Mutates the $target object filtering valid values of $props
 *
 * @param target Object
 * @param props Object
 *
 * @example
 * const target = { b: 2 };
 * const props = { a: 1, b: null, c: undefined, d: false }
 * assignValidValues(target, props);
 *
 * console.log(target); // { a: 1, b: 2, d: false }
 */
export function assignValidValues<O extends object>(target: O, props: Partial<O>): void {
    Object.entries(props).forEach(([key, value]) => {
        if (isValidRef(value)) {
            target[key] = value;
        }
    });
}
export const asyncFilter = async <T>(list: T[], predicate: (t: T) => Promise<boolean>) => {
    const resolvedListPredicates = await Promise.all(list.map(predicate));
    return list.filter((item, idx) => resolvedListPredicates[idx]);
};

export namespace CreateCache {
    export interface CacheConfig<Id, Entity> {
        timeout: MS;
        searcher: (ids: Id[]) => Promise<Entity[]>;
        mapper: (entity: Entity) => Id;
    }

    export type PromiseExecutor<T> = (value: T) => void;
    export interface ILookupItem<Id, Entity> {
        id: Id;
        resolve: PromiseExecutor<Entity | undefined>;
        reject: PromiseExecutor<unknown>;
        promise?: Promise<Entity | undefined>;
    }

    export function run<Id, Entity>(config: CacheConfig<Id, Entity>) {
        const time = config.timeout;
        let timeout: NodeJS.Timeout;
        let map: Map<Id, ILookupItem<Id, Entity>> = new Map();

        search.getEntities = getEntities;
        search.getEntity = getEntity;

        return search;

        async function dispatch() {
            const previousMap = map;
            map = new Map();

            const names = [...previousMap.keys()];

            try {
                //
                const response = await makeRequest(names);
                //
                for (const name of names)
                    previousMap.get(name)?.resolve(response.get?.(name));
                ;
            } catch (err: unknown) {
                for (const name of names)
                    previousMap.get(name)?.reject(err);
                ;
            }
        }

        async function search<T extends Entity>(ids: Id[]): Promise<T[]>;
        async function search<T extends Entity>(id: Id): Promise<T | undefined>;
        async function search(ids: Id[] | Id) {
            if (Array.isArray(ids)) return getEntities(ids);
            return getEntity(ids);
        }

        async function getEntities<T extends Entity>(ids: Id[]): Promise<T[]> {
            const entities = (await Promise.all(ids.map(id => getEntity<T>(id)))).filter(isValidRef);
            return entities;
        }

        function getEntity<T extends Entity>(id: Id): Promise<T | undefined> {
            let item = map.get(id) as Nullable<ILookupItem<Id, T>>;
            if (item?.promise) return item.promise;

            const promise = new Promise<T | undefined>((resolve, reject) => {
                fakeAssertsGuard<Map<Id, ILookupItem<Id, T>>>(map);
                clearTimeout(timeout);
                timeout = setTimeout(dispatch, time) as unknown as NodeJS.Timeout;
                item = {
                    id,
                    resolve,
                    reject,
                }
                map.set(id, item);
            });

            if (item) item.promise = promise;

            return promise;
        }

        async function makeRequest(ids: Id[]) {
            const entities = (await config.searcher(ids));
            const map: DeepMap<[id: Id, entity: Entity]> = new Map();
            entities.forEach(entity => map.set(config.mapper(entity), entity));
            return map;
        }
    }

}

export const createCache = CreateCache.run;

export function getRecursiveMethods<T extends { [key in PropertyKey]?: any }>(target: T): (keyof T)[] {
    const props: PropertyKey[] = [];
    let obj = target;
    do {
        props.push(...Object.getOwnPropertyNames(obj));
        obj = Object.getPrototypeOf(obj);
    } while (obj && (obj.name !== '') && (obj.constructor.name !== Object.name));

    return props.sort().filter(name => {
        if (name === 'constructor') return false;
        if (typeof target[name] == 'function') return true;
        return false;
    });
}

const regexDoubleBrackets = /{{(?<id>.*?)}}/g;
export function replaceVariables(hash: { [from in string]: string }, text: string, afterReplace?: (text: string) => string) {
    regexDoubleBrackets.lastIndex = 0;
    if (!regexDoubleBrackets.test(text)) return text;
    return text.replace(regexDoubleBrackets, (match, $1) => {
        const content = hash[$1] ?? $1; //
        if (afterReplace) return afterReplace(content);
        return content;
    });
}

export function deepReplaceVariables(hash: { [from in string]: string }, content: object, afterReplace?: (text: string) => string) {
    deepMapValues(content, (item) => typeof item !== 'string' ? item : replaceVariables(hash, item, afterReplace));
    return content;
}

export function replaceVariablesByIndex(input: string, values: string[]): string {
    const pattern = /{{(.+?)}}/g;
    const matches = input.match(pattern);

    if (isValidArray(matches)) {
        matches.forEach((match, index) => {
            input = input.replace(match, values[index]);
        });
    }

    return input;
}

/**
 * If the input object is not an Array, this function converts the object to an array, all the key-values to 2-arrays [key, value] and then sort the array by the keys. All the process is done recursively so objects inside objects or arrays are also ordered. Once the array is created the method returns the JSON.stringify() of the sorted array.
 *
 * @param obj the object
 *
 * @returns a JSON stringify of the created sorted array
 */
export function objectToSortedEntries(obj: object | number | string): string {
    return JSON.stringify(objectToSortedEntries.objectToArraySortedByKey(obj));
}

export namespace objectToSortedEntries {
    function isObject(val: any): boolean {
        return val != null && typeof val === 'object' && !Array.isArray(val);
    }

    export function objectToArraySortedByKey(obj: any): any {
        if (!isObject(obj) && !Array.isArray(obj)) {
            return obj;
        }
        if (Array.isArray(obj)) {
            return obj.map((item) => {
                if (Array.isArray(item) || isObject(item)) {
                    return objectToArraySortedByKey(item);
                }
                return item;
            });
        }
        // if it is an object convert to array and sort
        return Object.keys(obj) // eslint-disable-line
            .sort()
            .map((key) => {
                return [key, objectToArraySortedByKey(obj[key])];
            })
            ;
    }
}

export function cloneAndTurnIntoAnotherClass<T, Class extends new (...any: any[]) => any>(instance: T, $class: Class): InstanceType<Class>;
export function cloneAndTurnIntoAnotherClass<T, Class extends new (...any: any[]) => any>(instance: T, $class: Class): T {
    const clone = Object.assign(Object.create(($class.prototype)), instance)
    return clone;
}


export function repeatListUntil<T>(items: T[], minSize: number) {
    if (!items.length) {
        return items;
    }

    if (items.length >= minSize) {
        return items;
    }

    const remaining = minSize % items.length;
    const amount = ((minSize / items.length) % 10) || minSize;

    console.log({
        minSize,
        amountItems: items.length,
        remaining,
        amount,
    })

    return [...Array.from({ length: amount }, () => items).flat(), ...items.slice(0, remaining)];
}

export function prefixPhoneWithPlus(phone: string): string {
    if (!phone.startsWith('+')) {
        return `+${phone}`;
    }
    return phone;
}

export function simpleTemplate(
    text: string,
    hash: { [name in string]: unknown },
    config: TextTemplate.TemplateVariableConfig,
): string {
    return _.template(
        text,
        {
            ...TextTemplate.defaultTemplateOptions, interpolate: TextTemplate.buildInterpolatePattern(config),
        })(
            hash
        );
}

export function createKeyFromTexts(texts: string[]): string {
    return texts.map(text => btoa(text)).join(':');
}

export function splitByFilter<T>(array: T[], filter: (number: T) => boolean): { filtered: T[], rest: T[] } {
    return array.reduce(
        (result, number) => {
            filter(number) ? result.filtered.push(number) : result.rest.push(number);
            return result;
        },
        { filtered: [], rest: [] }
    );
}

export type SimpleInterval = { start: number, end: number };
export function mergeIntervals(arr: Array<SimpleInterval>): Array<SimpleInterval> {
    // Test if the given set has at least one interval
    if (arr.length <= 0)
        return [];

    // Create an empty stack of intervals
    let s = [];

    // sort the intervals in increasing order of start time
    arr.sort((a, b) => (a.start - b.start));

    // push the first interval to stack
    s.push(arr[0]);

    // Start from the next interval and merge if necessary
    for (let i = 1; i < arr.length; i++) {
        // get interval from stack top
        let top = s[s.length - 1];

        // if current interval is not overlapping with stack
        // top, push it to the stack
        if (top.end < arr[i].start)
            s.push(arr[i]);

        // Otherwise update the ending time of top if ending
        // of current interval is more
        else if (top.end < arr[i].end) {
            top.end = arr[i].end;
            s.pop();
            s.push(top);
        }
    }

    // Print contents of stack
    return s;
}

/**
 * Calculates the byte length of a JSON representation of an object.
 *
 * @param obj The object to calculate the byte length for.
 * @returns The byte length of the JSON representation of the object.
 */
export function calculateObjectJSONByteLength(obj: {}): number {
    const str = safeStringifyAnything(obj);

    return Buffer.from(str).byteLength;
}

/**
 * Checks if the condition is truthy, and returns the corresponding value.
 * If the condition is falsy and an alternative value is provided, it returns the alternative value.
 *
 * @template T - The type of the value returned when the condition is truthy.
 * @template R - The type of the value returned when the condition is falsy, defaults to undefined.
 *
 * @param cond - The condition to check for truthiness.
 * @param value - The value to return if the condition is truthy.
 * @param ifFalsy - (Optional) The value to return if the condition is falsy. Defaults to undefined.
 *
 * @returns The value if the condition is truthy, otherwise returns the optional falsy value.
 */
export function getIfTruthy<T, R = undefined>(cond: any, value: T, ifFalsy?: R): T | R {
    return !!cond ? value : ifFalsy
}

export function coerceIntValue(value: any, fallback: number): number {
    return isNumeric(value) ? Number(value) : fallback;
}



/**
 * Restrict a `number` between a lower and upper bound
 * @param lowerBound
 * @param upperBound
 * @param number
 * @returns
 */
export function clamp(lowerBound: number, upperBound: number, number: number) {
    return Math.max(lowerBound, Math.min(upperBound, number));
}

export function isValidTrimmedStringAndSize(str: string, min: number, max?: number): boolean {
    return isValidRef(str)
        && isValidRef(str.length)
        && str.trim().length >= min
        && (isInvalid(max) || str.trim().length <= max);
}

export function isUndefinedOrEmptyString(str: string): boolean {
    return str === undefined || str.trim().length === 0;
}

export function mapToArray<T>(map: Map<any, T>): Array<T> {
    return [...map.values()]
}

export function arrayToMap<T>(arr: Array<T>, key: keyof T): Map<string, T> {
    const map: Map<string, T> = new Map();

    arr.forEach(a => {
        map.set(a[key as string], a);
    })

    return map;
}

export function isFromEnum<T extends {}>(_enum: T, value: unknown): value is ValueOf<T> {
    const items = Object.values(_enum);
    return items.includes(value);
}
export function getNestedValue<T>(obj: Object, key: string): T {
    return key.split('.').reduce((acc, part) => acc && acc[part], obj) as T;
}

export function parallel<T extends Array<unknown>>(...promises: T) {
    return Promise.all([...promises])
}

export function simpleSafeStringify(input: unknown, space?: number) {
    const processed = new Map<unknown, string>();

    return JSON.stringify(input, replacer, space);

    function replacer(name: string, value: unknown) {
        if (typeof value === 'object') {
            if (processed.has(value)) return processed.get(value)!;
            processed.set(value, `[Circular ${printName(value)}]`);
        }
        return value;
    }

    function printName(name: unknown) {
        return name?.constructor?.name ?? Object.prototype.toString.call(name).slice(8, -1);
    }
}

type TDeepIterateFn<R = void> = (key: string, value: any, hostObject: object | unknown[]) => R

export function deepIterateThroughObjectProperties(
    obj: object,
    iterator: TDeepIterateFn,
    maxIterations: number = Number.MAX_SAFE_INTEGER,
): void {
    const stack: (object | unknown[])[] = [obj];
    let i: number = 0;

    while (stack.length) {
        if (i >= maxIterations) break;
        ++i;

        const current = stack.pop();

        for (const key in current) {
            if (isObject(current[key])) {
                stack.push(current[key]);
            } else {
                iterator(key, current[key], current);
            }
        }

    }
}

export function stringifyObjectBufferValues(
    obj: object,
    stringifier?: TDeepIterateFn<string>
): void {
    return deepIterateThroughObjectProperties(
        obj,
        (key, value, hostObject) => {
            if (Buffer.isBuffer(value)) {
                hostObject[key] = stringifier
                    ? stringifier(key, value, hostObject)
                    : `[Buffer] byteLength ${value.byteLength}`;
            }
        },
    );
}

export function stringifyObjectBufferValuesSafe(
    obj: object,
    stringifier?: TDeepIterateFn<string>
) {
    try {
        return stringifyObjectBufferValues(obj, stringifier);
    } catch (e) {
        console.error(e);
    }
}

export function isObject<T>(ref: T): ref is T {
    return ref !== null && typeof ref === 'object' && Object.getPrototypeOf(ref) === isObject.objectProto;
}
isObject.objectProto = Object.getPrototypeOf({});
