

import {EAvailablePrebuiltEntity, EMLEntityType, EMLCLUTrainResultType, EOmniSenseMethods, IKBAppConfig} from "../../knowledge-base/clu-core-interfaces";
import { MetaTranslation } from '@colmeia/core/src/shared-business-rules/const-text/meta-translation';
import { constant } from "../../../business/constant";
import { defineTranslations, EAllSerializable } from "../all-serializables";


export const coordinatorDeletionMenu = defineTranslations({
    idSerializable: EAllSerializable.CoordinatorDeletion,
    fields: {
        coordinatorDeletionTitle: MetaTranslation.field(constant.serializableField.name, 'Deleção de coordinator'),
        coordinatorDeletionIcon: MetaTranslation.field(constant.serializableField.icon, 'Deleção de coordinator'),
    },
});

export const coordinatorDeletionTranslations = {
    ...coordinatorDeletionMenu,
};
