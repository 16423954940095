<ng-container *ngIf="!loading; else spinner">
  <div
    cdkDropList
    (cdkDropListDropped)="assetDropped($event)"
    class="ordenable-list"
    [class.is__edit__only]="isEditOnly"
    [class.has-add-button]="hasAddButton">
    <div class="ordenable-item ordenable-header">
      <div
        *ngIf="hasDrag"
        matTooltip="Ordenável"
        class="drag-handle drag-indicator"
      >
        <mat-icon>drag_indicator</mat-icon>
      </div>
      <div class="ordenable-item-content">
        <div class="field-content" [class.is__edit__only__title]="isEditOnly">
          {{ title }}
        </div>
      </div>
      <div class="ordenable-item-actions">
      </div>
    </div>
    <ng-container *ngFor="let item of descriptor.iteration; let index = index">
      <ng-container [ngSwitch]="item.type">

        <div *ngSwitchCase="'mark'"
          cdkDrag
          cdkDragBoundary=".ordenable-list"
          class="ordenable-item-base group-divider"
          [ngClass]="item.mark"
          [class.last-item]="item.mark === 'end'"
        >
          <span style="display: none;" cdkDragHandle></span>
          {{ item.mark === 'start' ? item.label : '' }}
        </div>

        <div *ngSwitchCase="'asset'" cdkDrag [cdkDragDisabled]="!hasDrag" [cdkDragData]="item.asset"
          cdkDragLockAxis="y"
          class="ordenable-item visible-placeholder"
          [class.is-in-group]="hasGroup(item.asset)"
        >
          <div *ngIf="!isEditOnly && hasDrag" class="drag-handle" cdkDragHandle>
            <mat-icon>drag_handle</mat-icon>
          </div>
          <div class="ordenable-item-content">
            <div
              class="field-type"
              *ngIf="!isEditOnly"
            >
              <span>{{ printColumnName(item.asset) }}</span>
              <mat-icon *ngIf="hasConditional(item)" matTooltip="Possui Exibição Condicional" matTooltipPosition="above">visibility</mat-icon>
            </div>

            <ng-container [ngSwitch]="item.asset.type">
              <ng-container *appSwitchCases="contentTextTypes">
                <pre class="field-content var-editor-content" [highlightTemplate]="item.asset.content" [safeVars]="safeVarsNamesArray"></pre>

                <div
                  *ngIf="item.asset.variablesTemplate?.messageIfNoBind && hasUnsafeVariable(item.asset)"
                  class="fallback-container"
                >
                  <div class="field-type">Fallback</div>

                  <div class="field-content var-editor-content fallback-value">
                    <pre [highlightTemplate]="item.asset.variablesTemplate.messageIfNoBind" [safeVars]="safeVarsNamesArray"></pre>
                  </div>
                </div>
              </ng-container>

              <hexagon *ngSwitchCase="assetTypeEnum.media" style="float:left;"  [hexagonHandler]="assetsContentsCache[item.asset.idAsset].hexagonHandler"></hexagon>

              <ng-container *ngSwitchCase="assetTypeEnum.contentAPICall">
                <div *ngIf="assetsContentsCache[item.asset.idAsset].routeName; else routeNotSelected">
                  <div class="field-content">{{ assetsContentsCache[item.asset.idAsset].routeName }}</div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="assetTypeEnum.NLG">

                <mat-chip-set aria-label="Agentes">
                  <ng-container *ngFor="let ag of featureEngeneerings">
                    <mat-chip>{{ ag.name }}</mat-chip>
                  </ng-container>
                </mat-chip-set>


              </ng-container>
            
              <ng-container *ngSwitchCase="$KAssetType.generativo">
                <div class="generativo">
                  <div *ngIf="prompt">
                    <app-code-editor language="plain-text" label="{{ prompt.nName }}" [content]="prompt.text" theme="vs"
                      readonly></app-code-editor>
                  </div>
                  <div *ngIf="assetsContentsCache[item.asset.idAsset].generativeHandlers">
                    <h4>Vetor e Chunks</h4>
                    <div class="row">
                      <app-ns-picker
                        [handler]="assetsContentsCache[item.asset.idAsset].generativeHandlers[0]"
                        disabled="true"></app-ns-picker>
                      <app-ns-picker
                        [handler]="assetsContentsCache[item.asset.idAsset].generativeHandlers[1]"
                        disabled="true"></app-ns-picker>
                    </div>
                  </div>


                  <!-- <div class="fine-tunning">
                    <mat-slide-toggle [checked]="useFineTunning" disabled
                      [matTooltip]="'Para alterar essa configuração vá para Base de Conhecimentos > Predição > Lista de Intençoes'">Usar
                      Fine Tunning para treinamento do modelo.</mat-slide-toggle>
                  </div> -->


                </div>
              </ng-container>
            </ng-container>
            <div *ngIf="isNsPickerPreviewType(item.asset.type)" class="ns-pickers-content">
              <app-ns-picker
                *ngIf="assetsContentsCache[item.asset.idAsset].handlerAux"
                [handler]="assetsContentsCache[item.asset.idAsset].handlerAux"
                [disabled]="true">
              </app-ns-picker>
              <app-ns-picker [handler]="assetsContentsCache[item.asset.idAsset].handler" [disabled]="true"></app-ns-picker>
            </div>
          </div>
          <div class="ordenable-item-actions">
            <ng-container *ngIf="handler.alloweedGrouping">
              <div [class.visible]="groupCreationOpened.includes(item.asset.idAsset)" class="create-group-container">
                <input
                  #createGroupInputName
                  placeholder="Mínimo 2 caracteres"
                  (input)="onInputGroupName()"
                  (keyup.esc)="hideCreationInputFor(item.asset)"
                  (keyup.enter)="createGroupInputName.value.length >= 2 && createAssetGroup(item.asset, createGroupInputName.value)">
                <button
                  class="mat-button-base"
                  [class.mat-button-disabled]="createGroupInputName.value.length < 2"
                  [disabled]="createGroupInputName.value.length < 2"
                  (click)="createAssetGroup(item.asset, createGroupInputName.value)">
                  <mat-icon>done</mat-icon>
                </button>
                <button
                  class="mat-button-base"
                  (click)="toggleShowGroupCreationInputFor(item.asset)">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <button
                *ngIf="!hasGroup(item.asset)"
                mat-icon-button
                class="action-button create-group mat-flat-button"
                color="primary"
                [matTooltip]="'Criar grupo'"
                (click)="toggleShowGroupCreationInputFor(item.asset); createGroupInputName.focus()">
                <mat-icon size="24">playlist_add_circle</mat-icon>
              </button>
            </ng-container>

            <button *ngIf="shouldEnableEmailHTMLTemplate(index)" class="action-button" mat-icon-button color="primary" matTooltip="Editar email" (click)="editEmail(item.emailAsset)">
              <mat-icon>mail</mat-icon>
            </button>


            <ng-container *ngIf="!isDisabled">
              <button
                class="action-button"
                mat-icon-button
                color="primary"
                matTooltip="Editar"
                *ngIf="hasEdit"
                (click)="toggleEditAsset(item.asset)"
              >
                <mat-icon>create</mat-icon>
              </button>
              <button
                class="action-button"
                mat-icon-button
                color="primary"
                *ngIf="hasDelete"
                matTooltip="Remover"
                (click)="removeAsset(item.asset)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </ng-container>

          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <button
    *ngIf="hasAddButton"
    mat-stroked-button
    color="primary"
    class="add-button"
    (click)="toggleAddAsset()"
    [disabled]="isDisabled || !canAddMoreAssets"
  >
    <mat-icon>playlist_add</mat-icon> Adicionar
  </button>
</ng-container>

<ng-template #spinner>
  <div class="bases-spinner">
    <app-dashboard-spinner></app-dashboard-spinner>
  </div>
</ng-template>

<ng-template #routeNotSelected>
  <app-alert dense outline type="warning">Rota não selecionada</app-alert>
</ng-template>
