import { IGenerativeVectorSplitterURLConfig } from '@colmeia/core/src/shared-business-rules/generative/generative-model';
import { IdAsset, IdIntent } from '@colmeia/core/src/shared-business-rules/knowledge-base/clu-core-interfaces';
import { IBatchSaveToDeployModelData, INewDeployProdDesription } from '@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-req-res';
import { ICheckTemplateApprovals } from '@colmeia/core/src/shared-business-rules/social-media/social-media-req-resp';
import { IWhatsAppSocialMediaConnectionServer } from '@colmeia/core/src/shared-business-rules/social-media/social-media.model';
import { IBaseGetTemplateProviderApprovals } from "@colmeia/core/src/shared-business-rules/social-media/template-req-resp";
import { IUserApprovalProfileServer } from '@colmeia/core/src/shared-business-rules/user-approval-profile/user-approval-profile';
import { MS } from '@colmeia/core/src/time/time-utl';
import { Define } from '@colmeia/core/src/tools/utility/types/entities/define';
import { TArrayID, TGlobalUID } from '../../core-constants/types';
import { TSpreedSheets } from '../../multi-media/file-interfaces';
import { IDeleteFileContentData, IProcessFileRequestData } from '../../request-interfaces/files-interfaces';
import { $ValueOf, SimpleDifferences } from "../../tools/utility-types";
import { Merger } from '../../tools/utility/types/entities/merger';
import { IntersectPartialExplicit } from '../../tools/utility/types/readable';
import { ICampaingExecutionRequest } from '../campaigns/campaign-req-resp';
import { ICampaingAction } from '../campaigns/campaign-type-model';
import { ICorporateSearchDatabaseProcessParams } from '../corporate-search/corporate-search-model';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader, IdContentGenerator, IdKB, IdVector } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from '../non-serializable-id/non-serializable-types';
import { IWAFJobSchema } from '../waf/waf.req-res';

export enum EJOBType {
    patchApply = 'patchApply',
    patchDependencyResolution = 'patchDependencyResolution',
    machineLearningTrainingProcessing = 'machineLearningTrainingProcessing',
    generativeTrainingIntentProcessing = 'generativeTrainingIntentProcessing',
    deleteAvatarFromSocialNetwork = 'deleteAvatarFromSocialNetwork',
    campaignExecute = 'CampaignExecute',
    cancelRunCampaign = 'CancelRunCampaign',
    rescheduleCampaign = 'rescheduleCampaign',
    deleteFileContents = 'deleteFileContents',
    deleteFile = 'deleteFile',
    deleteNS = 'deleteNS',
    processElasticSearchDatabase = 'processElasticSearchDatabase',
    fileUploadProcessor = 'fileUploadProcessor',
    downloadDatabaseUrl = 'downloadDatabaseUrl',
    processUntrainedUtterances = 'processUntrainedUtterances',
    sendAllProvidersToReview = 'sendAllProvidersToReview',
    checkTemplateApprovals = 'checkTemplateApprovals',
    dataExtractionLineToLine = 'dataExtractionLineToLine',
    userGroupProfileUpdate = 'userGroupProfileUpdate',
    approveSocialNetworkParticipationInBatch = 'approveSocialNetworkParticipationInBatch',
    backupCreateSnapshot = 'backupCreateSnapshot',
    backupRestoreSnapshot = 'backupRestoreSnapshot',
    updateWAFSchema = 'updateWAFSchema',
    migrateWhatsAppSMProvider = 'migrateWhatsAppSMProvider',
    fetchContentFromURLsInVector = 'fetchContentFromURLsInVector',
}

export type TEJobStatusArray = EJobStatus[]

export enum EJobStatus {
    tobestarted = 'tobestarted',
    ongoing = 'ongoing',
    finished = 'finished',
}


export interface IJobHeader extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.job
    jobType: EJOBType,
    status: EJobStatus,
    isJobStillInProgress?: boolean,
    percentComplete?: number;
    started: number, // clocktick
    ended: number, // clocktick;
    lastJobTouch: number,
    phaseName: string,
    message?: string;
    idNSTarget?: string;
    estimatedEndTime?: MS;
    isError: boolean,
    error: string,
    idPlayerStartedList: TArrayID;
    scheduleAt: number;
    attempts?: number;
};

export type TIJobServerArray = IJobServer[]

export interface IJobServer extends IJobHeader, INonSerializable {
    nsType: ENonSerializableObjectType.job
}

export type TMapAllJobTypes = Define<{ [key in EJOBType]: IntersectPartialExplicit<IJobServer, { jobType: key }> }, {
    [EJOBType.patchApply]: Job.Patch.Server,
    [EJOBType.machineLearningTrainingProcessing]: Job.MachineLearningTrainingProcessing.Server,
    [EJOBType.deleteAvatarFromSocialNetwork]: Job.DeleteAvatarFromSocialNetwork.Server
    [EJOBType.campaignExecute]: Job.Campaign.Execute.Server,
    [EJOBType.processElasticSearchDatabase]: Job.ProcessElasticSearchDatabase.Server
    [EJOBType.fileUploadProcessor]: Job.FileUploadProcessor.Server
    [EJOBType.deleteFile]: Job.DeleteFile.Server,
    [EJOBType.deleteNS]: Job.DeleteNS.Server,
    [EJOBType.cancelRunCampaign]: Job.CampaignCancel.Execute.Server,
    [EJOBType.rescheduleCampaign]: Job.RescheduleCampaign.Execute.Server,
    [EJOBType.downloadDatabaseUrl]: Job.DownloadDatabaseURL.Server,
    [EJOBType.deleteFileContents]: Job.DeleteDatabaseContents.Server,
    [EJOBType.processUntrainedUtterances]: Job.ProcessUntrainedUtterances.Server,
    [EJOBType.patchDependencyResolution]: Job.PatchResolution.Server,
    [EJOBType.sendAllProvidersToReview]: Job.SendAllProvidersToReview.Server,
    [EJOBType.checkTemplateApprovals]: Job.CheckTemplateApprovals.Server,
    [EJOBType.dataExtractionLineToLine]: Job.DataExtractionLineToLine.Server,
    [EJOBType.backupCreateSnapshot]: Job.BackupCreateSnapshot.Server,
    [EJOBType.backupRestoreSnapshot]: Job.BackupRestoreSnapshot.Server,
    [EJOBType.userGroupProfileUpdate]: Job.UserGroupProfileUpdate.Server
    [EJOBType.generativeTrainingIntentProcessing]: Job.GenerativeTrainingIntentProcessing.Server,
    [EJOBType.approveSocialNetworkParticipationInBatch]: Job.ApproveSocialNetworParticipationBatch.Server,
    [EJOBType.updateWAFSchema]: Job.WAFSchema.Server,
    [EJOBType.migrateWhatsAppSMProvider]: Job.MigrateWhatsAppSMProvider.Server,
    [EJOBType.fetchContentFromURLsInVector]: Job.FetchContentFromURLsInVector.Server,
}>;

export type TALLCampaignJobs = Job.Campaign.Execute.Server | Job.CampaignCancel.Execute.Server | Job.RescheduleCampaign.Execute.Server

export type GetJobType<JobType extends EJOBType> = JobType extends unknown ? TMapAllJobTypes[JobType] : never;

export type TJobServer = $ValueOf<TMapAllJobTypes>;

export type GetSpecificJobData<JOBType extends EJOBType> = SimpleDifferences<
    IntersectPartialExplicit<IJobServer, { jobType: JOBType }>,
    TMapAllJobTypes[JOBType]
>;

export interface IExecuteMassCommControl {
    tries: number;
    failures: number;
    failureTries: number;
}

export type IJobMachineLearningProcessingServer = Job.MachineLearningTrainingProcessing.Server;

export type IJobPatchHeader = Job.Patch.Header;
export type IJobPatchServer = Job.Patch.Server;

export type IJobPatchResolutionHeader = Job.PatchResolution.Header;
export type IJobPatchResolutionServer = Job.PatchResolution.Server;

export type IJobDeleteAvatarFromSocialNetworkData = Job.DeleteAvatarFromSocialNetwork.Data;
export type IJobDeleteAvatarFromSocialNetworkHeader = Job.DeleteAvatarFromSocialNetwork.Header;
export type IJobDeleteAvatarFromSocialNetworkServer = Job.DeleteAvatarFromSocialNetwork.Server;

export type IJobCampaignExecute = Job.Campaign.Execute;
export type IJobCampaignExecuteHeader = Job.Campaign.Execute.Header;
export type IJobCampaignExecuteServer = Job.Campaign.Execute.Server;
export type IJobCampaignExecuteLastPredicates = Job.Campaign.Execute.LastPredicates;

export type IJobCampaignCancelSchedule = Job.CampaignCancel.Execute.Server;
export type IJobUserGroupProfileUpdate = Job.UserGroupProfileUpdate.Server
export type IJobCampaignReschedule = Job.RescheduleCampaign.Execute.Server;

export type IJobProcessElasticSearchDatabaseData = Job.ProcessElasticSearchDatabase.Data;
export type IJobProcessElasticSearchDatabaseHeader = Job.ProcessElasticSearchDatabase.Header;
export type IJobProcessElasticSearchDatabaseServer = Job.ProcessElasticSearchDatabase.Server;

export type IJobFileUploadProcessorData = Job.FileUploadProcessor.Data;
export type IJobFileUploadProcessorHeader = Job.FileUploadProcessor.Header;
export type IJobFileUploadProcessorServer = Job.FileUploadProcessor.Server;

export type IJobDownloadDatabaseUrlHeader = Job.DownloadDatabaseURL.Header;
export type IJobDownloadDatabaseUrlServer = Job.DownloadDatabaseURL.Server;

export type IJobDeleteDatabaseContentsHeader = Job.DeleteDatabaseContents.Header;
export type IJobDeleteDatabaseContentsServer = Job.DeleteDatabaseContents.Server;

export type IJobDeleteFileData = Job.DeleteFile.Data;
export type IJobDeleteFileHeader = Job.DeleteFile.Header;
export type IJobDeleteFileServer = Job.DeleteFile.Server;

export type IJobDeleteNSData = Job.DeleteNS.Data;
export type IJobDeleteNSHeader = Job.DeleteNS.Header;
export type IJobDeleteNSServer = Job.DeleteNS.Server;

export type IJobDataExtractionLineToLineServer = Job.DataExtractionLineToLine.Server;
export type IJobUpdateWAFSchemaHeader = Job.WAFSchema.Header;
export type IJobUpdateWAFSchemaServer = Job.WAFSchema.Server;


export namespace Job {
    const jobTypeToTarget = defineJobTypeToTarget({
        [EJOBType.machineLearningTrainingProcessing]: job => job.idKB,
        [EJOBType.patchApply]: job => job.idPatch,
        [EJOBType.processUntrainedUtterances]: job => job.idKB,
        [EJOBType.deleteNS]: job => job.toDeleteId,
        [EJOBType.sendAllProvidersToReview]: job => job.idTemplate,
        [EJOBType.checkTemplateApprovals]: job => job.idConnection,
    });

    function defineJobTypeToTarget(entries: { [type in EJOBType]?: (job: TMapAllJobTypes[type]) => IdDep<ENonSerializableObjectType> | undefined }): { [type in EJOBType]?: (job: IJobServer) => IdDep<ENonSerializableObjectType> };
    function defineJobTypeToTarget(entries: { [type in EJOBType]?: (job: TMapAllJobTypes[type]) => IdDep<ENonSerializableObjectType> | undefined }) {
        return entries;
    }

    export function getIdNSTargetFromJob(job: IJobServer) {
        return jobTypeToTarget[job.jobType]?.(job);
    }
}

export declare namespace Job {

    interface JobWithTarget {
        idNSTarget: IdDep<ENonSerializableObjectType>;
    }

    namespace MachineLearningTrainingProcessing {
        interface Server extends IJobServer {
            jobType: EJOBType.machineLearningTrainingProcessing;
            idKB: IdDep<ENonSerializableObjectType.knowledgeBase>;
            trainedAt?: number;
        }
    }


    namespace Patch {
        interface Header extends IJobHeader {
            jobType: EJOBType.patchApply
            idPatch: string;
            idProvider: string;
        }

        interface Server extends Merger<Header, IJobServer> {
            newDeployDescription: INewDeployProdDesription;
        }
    }

    namespace PatchResolution {
        interface Header extends IJobHeader, IBatchSaveToDeployModelData {
            jobType: EJOBType.patchDependencyResolution
        }

        interface Server extends Merger<Header, IJobServer> {
        }
    }

    namespace DeleteAvatarFromSocialNetwork {
        interface Data {
            idGroupToBeRemoved: TGlobalUID,
            idAvatarPerformingRemoval: TGlobalUID,
            idAvatarToBeRemoved: TGlobalUID
        }
        interface Header extends Data {
            jobType: EJOBType.deleteAvatarFromSocialNetwork;
        }
        interface Server extends Merger<Header, IJobServer> { }
    }



    interface ICampaignJobBase {
        rowsLength: number
    }

    namespace Campaign {
        interface Execute extends ICampaignJobBase {
            req: ICampaingExecutionRequest;
            campaign: ICampaingAction;
            lastPredicates: Job.Campaign.Execute.LastPredicates;
        }
        namespace Execute {
            interface LastPredicates extends IExecuteMassCommControl {
                idFile?: string;
                cursor?: string;
                amountProcessed: number;
                isFailure: boolean;
                idCampaignExecution?: string;
            }
            interface Header extends Execute, IJobHeader {
                jobType: EJOBType.campaignExecute;
            }
            interface Server extends Merger<Header, IJobServer> { }
        }

    }

    namespace CampaignCancel {

        interface Execute {
            idRunCampaign: IdDep<ENonSerializableObjectType.runCampaing>;
        }

        namespace Execute {
            interface Header extends Execute, IJobHeader, ICampaignJobBase {
                jobType: EJOBType.cancelRunCampaign;
            }
            interface Server extends Merger<Header, IJobServer> { }
        }
    }

    namespace RescheduleCampaign {

        interface Execute {
            idRunCampaign: IdDep<ENonSerializableObjectType.runCampaing>;
            rescheduleTo: number;
        }

        namespace Execute {
            interface Header extends Execute, IJobHeader, ICampaignJobBase {
                jobType: EJOBType.rescheduleCampaign;
            }
            interface Server extends Merger<Header, IJobServer> { }
        }
    }


    namespace ProcessElasticSearchDatabase {
        interface Data {
            params: ICorporateSearchDatabaseProcessParams
        }

        interface Header extends Data {
            jobType: EJOBType.processElasticSearchDatabase,
        }
        interface Server extends Merger<Header, IJobServer> { }
    }


    namespace FileUploadProcessor {
        interface Data extends IProcessFileRequestData {
        }
        interface Header extends Data {
            jobType: EJOBType.fileUploadProcessor,
        }
        interface Server extends Merger<Header, IJobServer> { }
    }

    namespace DownloadDatabaseURL {
        interface Header {
            idFileDatabase: string
        }
        interface Server extends Merger<Header, IJobServer> {
            jobType: EJOBType.downloadDatabaseUrl
            downloadUrl?: string
        }
    }

    namespace DeleteDatabaseContents {

        interface Header extends IDeleteFileContentData {
        }
        interface Server extends Merger<Header, IJobServer> {
            jobType: EJOBType.deleteFileContents
        }

    }
    namespace DeleteFile {
        interface Data {
            idFile: string;
            idAvatarDeleting: string;
        }
        interface Header extends Data, IJobHeader {
            jobType: EJOBType.deleteFile
        }
        interface Server extends Merger<Header, IJobServer> { }
    }

    namespace DeleteNS {
        interface Data {
            toDeleteId: string;
            idAvatarDeleting?: string
        }
        interface Header extends Data, IJobHeader {
            jobType: EJOBType.deleteNS
        }
        interface Server extends Merger<Header, IJobServer> { }
    }

    namespace ProcessUntrainedUtterances {
        interface Header {
            jobType: EJOBType.processUntrainedUtterances;
        }
        interface Server extends Merger<Header, IJobServer> {
            idKB: IdDep<ENonSerializableObjectType.knowledgeBase>;
            trainedAt?: number;
        }

    }

    namespace SendAllProvidersToReview {
        interface Header {
            jobType: EJOBType.sendAllProvidersToReview;
        }
        interface Server extends Merger<Header, IJobServer>, IBaseGetTemplateProviderApprovals { }

    }

    namespace CheckTemplateApprovals {
        interface Header {
            jobType: EJOBType.checkTemplateApprovals;
        }

        interface Server extends Merger<Header, IJobServer>, ICheckTemplateApprovals { }
    }

    namespace DataExtractionLineToLine {
        interface Header {
            jobType: EJOBType.dataExtractionLineToLine;
        }

        interface Server extends Merger<Header, IJobServer> {
            sqlQuery: string,
            csvURL?: string,
            downloadFileType?: TSpreedSheets,
            lineCount?: number,
            //TODO (Solidade)
            // params: {}
        }
    }

    namespace BackupCreateSnapshot {
        interface Header {
            jobType: EJOBType.backupCreateSnapshot;
        }

        interface Server extends Merger<Header, IJobServer> {
            idBackupSnapshot: IdDep<ENonSerializableObjectType.backupSnapshot>;
        }
    }

    namespace UserGroupProfileUpdate {
        interface Header {
            jobType: EJOBType.userGroupProfileUpdate;
        }
        interface Server extends Merger<Header, IJobServer> {
            updatedUserGroupProfileIdNS: string,
            updatedUserGroupProfile: IUserApprovalProfileServer,
        }
    }

    namespace BackupRestoreSnapshot {
        interface Header {
            jobType: EJOBType.backupRestoreSnapshot;
        }

        interface Server extends Merger<Header, IJobServer> {
            idBackupSnapshot: IdDep<ENonSerializableObjectType.backupSnapshot>;
        }
    }

    namespace ApproveSocialNetworParticipationBatch {
        interface Header {
            jobType: EJOBType.approveSocialNetworkParticipationInBatch;
        }
        interface Server extends Merger<Header, IJobServer> {
            solicitationListNserIds: IdDep<ENonSerializableObjectType.yourTasks>[]
        }
    }

    namespace GenerativeTrainingIntentProcessing {
        interface Header {
            jobType: EJOBType.generativeTrainingIntentProcessing;
        }

        interface Server extends Merger<Header, IJobServer> {
            idKB: IdKB;
            idIntent: IdIntent;
            idAsset: IdAsset;

            hasExternalJob?: boolean;
            idExternalJob?: string;
            idContentGenerator: IdContentGenerator;
        }
    }

    namespace WAFSchema {
        interface Header {
            jobType: EJOBType.updateWAFSchema;
        }
        interface Server extends Merger<Header, IJobServer>, IWAFJobSchema { }
    }


    namespace MigrateWhatsAppSMProvider {
        interface Header {
            jobType: EJOBType.migrateWhatsAppSMProvider;
        }

        interface Server extends Merger<Header, IJobServer> {
            previousSM: IWhatsAppSocialMediaConnectionServer;
        }
    }

    namespace FetchContentFromURLsInVector {
        interface Header {
            jobType: EJOBType.fetchContentFromURLsInVector;
        }

        interface Server extends Merger<Header, IJobServer> {
            idVector: IdVector;
            urls: IGenerativeVectorSplitterURLConfig[];
        }
    }

}

