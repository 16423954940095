import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { kbErrors } from "@colmeia/core/src/shared-business-rules/const-text/views/knowledge-base";
import { IKnowledgeDB, IKnowledgeDBServer, CLU_NONE_INTENT } from "@colmeia/core/src/shared-business-rules/knowledge-base/kb-inferfaces";
import { KBProcessor } from "@colmeia/core/src/shared-business-rules/knowledge-base/kb-processor";
import { EOmniSenseMethods, IMLCreateIntent } from "@colmeia/core/src/shared-business-rules/knowledge-base/clu-core-interfaces";
import { isNSServer } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions";
import { defaultNserValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/nser";
import { getEnabledStrategies, getIntentsWithoutStrategiesEnabled, isIntentionChangeEnabled, getUsedStrategies, getValidIntentStrategiesConfigs, shouldTrainMachineLearning, hasEntityExtractorEnabled, hasEmbeddingProvider } from "@colmeia/core/src/shared-business-rules/social-cc/omini-sense-utils";
import { ITranslationConfig } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { isValidArray, isValidRef } from "@colmeia/core/src/tools/utility";


function isServer(nser: IKnowledgeDB): nser is IKnowledgeDBServer {
    return isNSServer(nser);
}

export function kbValidator(nser: IKnowledgeDB, friendly: FriendlyMessage): FriendlyMessage {
    defaultNserValidator(nser, friendly);

    if (isServer(nser)) {
        kbServerValidator(nser, friendly);
    }

    return friendly;
}

export function kbServerValidator(nser: IKnowledgeDBServer, friendly: FriendlyMessage): FriendlyMessage {
    // Não deletar essa linha, senão erros inesperados poderão acontecer
    const processor = KBProcessor.create(nser).asImmutable();
    const errors = [];
    const strategy = nser.app.osStrategy.strategy;
    if (!isValidArray(strategy) || getEnabledStrategies(strategy).length === 0) {
      errors.push("Habilite pelo menos uma estratégia");
    }

    const intentChangeStrategy = nser.app?.intentionChangeConfig?.strategy;
    if (isValidArray(intentChangeStrategy) && isIntentionChangeEnabled(nser) && getEnabledStrategies(intentChangeStrategy!).length === 0) {
        errors.push("Habilite pelo menos uma estratégia para mudança de assunto");
    }

    const disabledIntents = getIntentsWithoutStrategiesEnabled(nser).filter((intent) => intent.intentId !== CLU_NONE_INTENT);
    if (disabledIntents.length > 0) {
        const formattedIntents = disabledIntents.map((intent) => `"${intent.intentName}"`).join(", ");
        errors.push(`Habilite as seguintes intenções no card de Intenções: ${formattedIntents}`)
    }

    const intents = nser.app.intents;
    const disabledIntentsIds = new Set(disabledIntents.map(({intentId}) => intentId));
    const enabledIntents = intents.filter((intent) => !disabledIntentsIds.has(intent.intentId));
    const intentsWithoutValidStrategy = enabledIntents
        .filter((intent) => intent.intentId !== CLU_NONE_INTENT && getValidIntentStrategiesConfigs(intent.intentOSConfig).length === 0)
    if (intentsWithoutValidStrategy.length !== 0) {
        const formattedIntents = intentsWithoutValidStrategy.map((intent) => `"${intent.intentName}"`).join(", ");
        errors.push(`As seguintes intenções não estão configuradas: ${formattedIntents}`);
    }

    const usedStrategies = getUsedStrategies(nser);
    const hasConnection = isValidRef(nser.idConnection);
    if (usedStrategies.includes(EOmniSenseMethods.machineLearning) && !hasConnection) {
        errors.push('Adicione um provedor CLU');
    }

    // if (shouldTrainMachineLearning(nser)) {
    //     errors.push("Treine o modelo de machine learning");
    // }

    if (usedStrategies.includes(EOmniSenseMethods.semanticPrediction) && !hasEmbeddingProvider(nser)) {
        errors.push("Adicione um provedor de Embedding");
    }

    if (errors.length > 0) friendly.addReturnGenericErrorMessages(errors);
    return friendly;
}

export function normalizeIntent(intent: IMLCreateIntent): void {
    intent.intentName = intent.intentName.trim();
}
export function validateIntent(intent: IMLCreateIntent): ITranslationConfig | undefined {
    if (intent.intentName.trim() !== intent.intentName) return kbErrors.intentWithSpacesBetweenName;
}
