import { IContentBasicAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { TICSDisplayFieldArray } from '@colmeia/core/src/shared-business-rules/corporate-search/corporate-search-model';
import { EDelivery360Action } from '../../comm-interfaces/interaction-interfaces';
import { TArrayID } from '../../core-constants/types';
import { getUniqueStringID, typedClone, typedCloneLodash } from '../../tools/utility';
import { RequireOnlyOne, UnionToArray } from '../../tools/utility-types';
import { TIBPConditionalEvaluatorArray } from '../BPM/bpm-model';
import { EActionOnComplete } from '../knowledge-base/bot-transaction/bot-transaction';
import { EAddressFields, IMetaEngagement, ISmartAddressDisplayField } from '../metadata/meta-engagement';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from '../non-serializable-id/non-serializable-id-interfaces';
import { IBotActionAsset, initialAction } from './bot-action-model';
import { KBAssetType, TBasicAssetArray } from './bot-asset-model';
import { EActionTreeIncrementor, IBotSubject, TBotSubjectSearchArray } from './bot-interfaces';
import { I360Media, IConfigVoiceGenerator } from '@colmeia/core/src/core-constants/bot';
import { ColmeiaTemplateComponents } from '@colmeia/core/src/shared-business-rules/social-media/template-model';
import { EBotActionType } from './new-bot-action';

export type TENextGenBotElementTypeArray = Array<ENextGenBotElementType>;

export enum ENextGenBotElementType {
    root = 'botRoot',
    menuContainer = 'menuContainer',

    metadata = 'botMetadata',
    botMenuItem = 'botMenuItem',

    nlpTransaction = 'nlpTransaction',
    formTransaction = 'formTransaction',
    atendanceIsland = 'atendanceIsland'
}


export type TIBotElementArray = Array<IBotElement>;


export enum ENaviTrackerRules {
    replaceVariableSameCategory = 'repSameCat', // Sobreponha
    noReplace = 'noReplace' // Registre todos
}

export interface INaviTracker {
    idNSTrack: string;
    navTrackRule: ENaviTrackerRules // Em caso de Track de mesmo nível
    parameter: string;
}

export interface IBotElementData {
    includeOnAnalytics?: boolean;  // Incluir como tráfego no Analytics
    trackerRule?: INaviTracker;
    content?: string;
    botLevel: ENextGenBotElementType;
    events: TBasicAssetArray;
}

export interface IBotElement extends INonSerializableHeader, IBotElementData {
    nsType: ENonSerializableObjectType.bot,
}


export interface IConfigureCanonical {
    idCanonical: string;
    isSafe: boolean;
}

export type TIConfigureCanonicalArray = Array<IConfigureCanonical>;

export interface IConfigureCanonical {
    idCanonical: string;
    isSafe: boolean;
}
export interface IBotCanonical {
    canonicals: TIConfigureCanonicalArray;
    canonicalIds?: TArrayID; // isso provavelmente está deprecado
}
export type TBotRootArray = Array<IBotRoot>;
export interface IBotRoot extends IBotElement, IBotCanonical, IBotElementWithRenderOptions {
    botLevel: ENextGenBotElementType.root;
    firstAction: IBotActionAsset;
    posAction?: IBotActionAsset; // executa apenas se o firstAction for um Formulario formTransaction
    allBotSubject: TBotSubjectSearchArray;
    incrementor: EActionTreeIncrementor;
    voiceGeneratorConfig?: IConfigVoiceGenerator
}

export interface IBotElementWithReusableInfo extends IBotElement {
    reuseInfoExpireBHour: number;
    engagement?: IMetaEngagement;
}



// Atencao.. o idMetadata está só para o form pois o metadada da transaçao é uma estrutrua ligada
// a NLP
export interface IBotMetadata extends IBotElementWithReusableInfo {
    idMetadata: string;
}

export enum EMenuMode {
    Quick = 'qck',  // Quick
    Adaptative = 'adt', // Que se o menu for conforme com as regras de quick/lista, executa como quick lista
    Full = 'fll',
    Text = 'txt',
}


type TMenuModeDisplayItem = RequireOnlyOne<
    | {
        displayName: string;
    }
    | {
        isShowing: boolean;
    }
>;

export type TMenuModeDisplay = {
    [channel in EDelivery360Action]?: {
        [menu in EMenuMode]?: TMenuModeDisplayItem
    }
}

export const menuModeDisplayConfig: TMenuModeDisplay = {
    [EDelivery360Action.Delivery360WhatsApp]: {
        [EMenuMode.Adaptative]: {
            isShowing: true,
        },
        [EMenuMode.Full]: {
            displayName: 'Completo'
        },
        [EMenuMode.Quick]: {
            displayName: 'Botões'
        },
        [EMenuMode.Text]: {
            isShowing: true,
        },
    },
    [EDelivery360Action.DeliveryColmeia]: {
        [EMenuMode.Adaptative]: {
            isShowing: true,
        },
        [EMenuMode.Full]: {
            displayName: 'Carrossel'
        },
        [EMenuMode.Text]: {
            isShowing: true,
        },
    },
    [EDelivery360Action.Delivery360FBMessenger]: {
        [EMenuMode.Adaptative]: {
            isShowing: true,
        },
        [EMenuMode.Full]: {
            displayName: 'Completo',
        },
        [EMenuMode.Quick]: {
            displayName: 'Botões'
        },
        [EMenuMode.Text]: {
            isShowing: true,
        },
    },
    [EDelivery360Action.DeliveryInstagram]: {
        [EMenuMode.Adaptative]: {
            isShowing: true,
        },
        [EMenuMode.Full]: {
            displayName: 'Completo',
        },
        [EMenuMode.Quick]: {
            displayName: 'Botões'
        },
        [EMenuMode.Text]: {
            isShowing: true,
        },
    },

    [EDelivery360Action.Delivery360Email]: {
        [EMenuMode.Text]: {
            isShowing: true,
        },
    },
    [EDelivery360Action.Delivery360SMS]: {
        [EMenuMode.Text]: {
            isShowing: true,
        },
    },
    [EDelivery360Action.Delivery360RCS]: {
        [EMenuMode.Text]: {
            isShowing: true,
        },
        [EMenuMode.Quick]: {
            displayName: 'Botões'
        },
        [EMenuMode.Full]: {
            displayName: 'Carrossel'
        },
    },
    [EDelivery360Action.Delivery360Voice]: {
        [EMenuMode.Text]: {
            isShowing: true,
        },
    },

    [EDelivery360Action.DeliveryTelegram]: {
        [EMenuMode.Adaptative]: {
            isShowing: true,
        },
        [EMenuMode.Full]: {
            displayName: 'Completo',
        },
        [EMenuMode.Quick]: {
            displayName: 'Botões'
        },
        [EMenuMode.Text]: {
            isShowing: true,
        },
    },
}

export type TMenuMode = EMenuMode.Quick | EMenuMode.Full | EMenuMode.Text;

export function getMenuModes(): TMenuMode[] {
    const menuModes: UnionToArray<TMenuMode> = [EMenuMode.Quick, EMenuMode.Full, EMenuMode.Text];
    return menuModes;
}

export enum EWhatsappMenuMode {
    Normal = 'num',
    Enhanced = 'enc'
}

export type IConfigChannelRender = {
    [key in EDelivery360Action]: IMenuContainerDisplayOptions;
}

export interface IMenuContainerDisplayOptions {
    menuRenderConfigType: EMenuMode;
    whatsappNumericMenuMode?: EWhatsappMenuMode;
    /**
     * Use IBotElementWithRenderOptions selectorButton
     * @deprecated
     */
    readonly selectorTitle?: string;

    forceMenuRenderConfigType?: TMenuMode;
}

export const defaultDisplayOptions: Readonly<IMenuContainerDisplayOptions> = {
    whatsappNumericMenuMode: EWhatsappMenuMode.Enhanced,
    menuRenderConfigType: EMenuMode.Adaptative
};

export function initDefaultRenderOptions() {
    const defaultRenderOptions: IConfigChannelRender = {
        [EDelivery360Action.DeliveryTelegram]: {
            ...defaultDisplayOptions,
        },
        [EDelivery360Action.Delivery360FBMessenger]: {
            ...defaultDisplayOptions,
        },
        [EDelivery360Action.DeliveryInstagram]: {
            ...defaultDisplayOptions,
        },
        [EDelivery360Action.Delivery360WhatsApp]: {
            ...defaultDisplayOptions,
            menuRenderConfigType: EMenuMode.Text
        },
        [EDelivery360Action.Delivery360Email]: undefined,
        [EDelivery360Action.Delivery360SMS]: undefined,
        [EDelivery360Action.Delivery360RCS]:{
            ...defaultDisplayOptions,
            menuRenderConfigType: EMenuMode.Text
        },
        [EDelivery360Action.Delivery360Voice]: undefined,
        [EDelivery360Action.DeliveryColmeia]: undefined
    };
    return typedCloneLodash(defaultRenderOptions);
}

export const defaultRenderOptions = initDefaultRenderOptions();

export function getDefaultMenuContainerDisplayOptions() {
    const defaultMenuContainerDisplayOptions: IMenuContainerDisplayOptions = {
        menuRenderConfigType: EMenuMode.Adaptative,
    };

    return defaultMenuContainerDisplayOptions;
}




export interface IWhatsAppActionMessageTemplate {
    header?: ColmeiaTemplateComponents.Header.Basic;
    /**
     * Footer
     */
    footer?: ColmeiaTemplateComponents.Footer.Basic;
}

interface IRcsActionMessageTemplateConfig {
    header?:  ColmeiaTemplateComponents.RCSHeader.Basic;
}

export interface IRcsActionMessageTemplate {
    config: Array<IRcsActionMessageTemplateConfig>;
}

export interface IBotElementWithRenderOptions {
    renderOptions: IConfigChannelRender;
    multipleChoiceAdvancedTitle?: IContentBasicAsset; // Caso não estiver preenchido, processar o content
    selectorButton?: string;
    whatsApp?: IWhatsAppActionMessageTemplate;
    rcs?: IRcsActionMessageTemplate;
    providerType?: EDelivery360Action;
}

export interface ICorporateSearchRenderOptions extends IBotElementWithRenderOptions {
    title: TICSDisplayFieldArray;
    description: TICSDisplayFieldArray;
}

export interface ISmartAddressDynamicMenuRenderOptions extends IBotElementWithRenderOptions {
    title: ISmartAddressDisplayField[];
    description: ISmartAddressDisplayField[];
    truncateInfo: boolean;
}

/**
 * [{ idPropertyField: 'x' }, { idPropertyField: 'y' }]
 */



export interface IBotMenuContainer extends IBotElement, IBotElementWithRenderOptions {
    actionOnComplete: EActionOnComplete;
    botLevel: ENextGenBotElementType.menuContainer
}

export interface ITrackFunctioDetail {
    idPreFunction: string;
    parameter: string;
    //idPosFunction: string; // to be done later
}

export interface ITrackFunction {
    track?: ITrackFunctioDetail

}

export interface IBotMenuItem extends IBotElement, ITrackFunction {
    action: IBotActionAsset;
    botSubject?: IBotSubject;
    description?: string;
    section?: string;
    condDisplay?: TIBPConditionalEvaluatorArray;
    /**
     *
     * Essa flag só tem sentido quando é um menu virtual e é adicionado
     * opções adicionais como por exemplo: gotBot
     *
     * Essa configuração é feita no cliente em alguns engajamentos.
     */
    isAdditional?: boolean;
}


export type TNextGenBotServerArray = Array<INextGenBotServer>;

export interface INextGenBotServer extends INonSerializable, IBotElement {
    nsType: ENonSerializableObjectType.bot,
}
export interface IBotElementWithReusableInfoServer extends INextGenBotServer, IBotElementWithReusableInfo { } // Ok

export type TIBotRootServer = Array<IBotRootServer>;

export interface IBotRootServer extends INextGenBotServer, IBotRoot {
    botLevel: ENextGenBotElementType.root;
    variableTags?: TArrayID;
}; // OK

export function botRootTypeGuard(bot: INextGenBotServer): bot is IBotRootServer {
    return bot.botLevel === ENextGenBotElementType.root;
}
export function isBotMenuItem(bot: IBotElement): bot is IBotMenuItemServer {
    return bot.botLevel === ENextGenBotElementType.botMenuItem;
}

export interface IBotMetadataServer extends IBotElementWithReusableInfoServer, IBotMetadata { }; // OK

export type TBotMenuItemArray = Array<IBotMenuItemServer>;
export interface IBotMenuItemServer extends INextGenBotServer, IBotMenuItem {
    nsType: ENonSerializableObjectType.bot,
    botLevel: ENextGenBotElementType.botMenuItem;
}; // ok

export type TIBotMenuContainerServerArray = Array<IBotMenuContainerServer>;


export interface IBotMenuContainerServer extends INextGenBotServer, IBotMenuContainer {
    nsType: ENonSerializableObjectType.bot,
    botLevel: ENextGenBotElementType.menuContainer
};

export function getEmptyAction(): IBotActionAsset {
    const initialAction: IBotActionAsset = {
        idAsset: undefined,
        type: undefined,
        idElement: undefined,
        contentArray: []
    };
    return initialAction;
}

export function getInitialActionWithId(type?: EBotActionType): IBotActionAsset {
    return {
        ...typedClone<IBotActionAsset>(initialAction),
        idAsset: getUniqueStringID(10),
        type
    };
}


export interface IToBeSentBotMessage {
    message?: string
    idMedia?: string;
    type: KBAssetType;
}

export type TIToBeSentBotMessageArray = Array<IToBeSentBotMessage>
