
import { Component, Input } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { GenericSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';
import { isValidString, isValidTrimmedString } from '@colmeia/core/src/tools/utility';
import { CmChipListHandler } from 'app/components/foundation/cm-chip-list/cm-chip-list.handler';
import { OnChange } from 'app/model/client-utility';

@Component({
    selector: 'cm-chip-list',
    templateUrl: './cm-chip-list.component.html',
    styleUrls: ['./cm-chip-list.component.scss']
})
export class CmChipListComponent {

    get showItemAddTip() { return this.parameters.showItemAddTip }
    set showItemAddTip(value) { this.parameters.showItemAddTip = value }
    get items() { return this.parameters.items }
    get name() { return this.parameters.name }

    @OnChange()
    @Input()
    public handler: CmChipListHandler;
    get parameters() { return this.handler.getComponentParameter() }

    public onChangeHandler() {
        this.init();
    }

    public constructor() { }

    public init(): void {
    }

    get placeHolder() {
        const defaultPlaceHolder: string = `Novo ${this.name}`;
        if(!isValidTrimmedString(this.parameters?.placeHolder)) return defaultPlaceHolder;
        return this.parameters.placeHolder; 
    }

    removeItem(index: number): void {
        if(!this.parameters?.editable) return;
        try {
            this.items.splice(index, 1);
        } catch {} finally {
            this.parameters.clientCallback?.afterUpdateItems?.(
                this.items,
            );
        }
    }

    addItem(event: MatChipInputEvent): void {
        if (!this.parameters?.editable) return;
        try {
            const item = event.value?.trim();
            if(!isValidString(item)) return;

            const errorMessage = this.parameters.clientCallback?.onAddItem?.(
                item,
                this.items.length - 1,
                this.items,
            );

            if (errorMessage) {
                GenericSharedService.show({
                    isWarning: true,
                    message: errorMessage,
                });
                return;
            }

            this.items.push(item);

            this.showItemAddTip = false;

            event.input.value = "";


        } catch {} finally {
            this.parameters.clientCallback?.afterUpdateItems?.(
                this.items,
            );
        }
    }


}
