<div class="wrapper">
  <div class="top-container">
    <div class="top-left-content">
      <div class="header-button-row">
        @if (!shouldHideBBCode) {
          @if (hasSomeButtonEnabled(textStyleModifiers)) {
            <div class="button-section">
              @for (styleButton of textStyleModifiers; track styleButton) {
                <ng-container
                  [ngTemplateOutlet]="headerButton"
                  [ngTemplateOutletContext]="{button: styleButton}"
                />
              }
            </div>
          }

          @if (hasSomeButtonEnabled(elementButtons)) {
            <div class="button-section">
              @for (button of elementButtons; track button) {
                <ng-container
                  [ngTemplateOutlet]="headerButton"
                  [ngTemplateOutletContext]="{button: button}"
                />
              }
            </div>
          }
        }
        <div class="text-area-tools">
          <div class="l-left-tools">
            <button *ngIf="!shouldHideEmojis" class="toggle-emoji" [class.active]="varEditorUIConfig.showEmojiBox" (click)="toggleEmojiBox()">
              <mat-icon class="mat-22">tag_faces</mat-icon>
            </button>

            <button class="toggle-raw" [class.active]="varEditorUIConfig.viewRawCode" (click)="toggleViewRaw()">
              <mat-icon class="mat-24">raw_on</mat-icon>
            </button>

            <div class="font-size-buttons" [matTooltip]="varEditorUIConfig.fontSize+'%'" matTooltipPosition="above">
              <button class="font-size-button" (click)="updateTextSize(varEditorUIConfig.fontSize - textUpdateStep)">
                <mat-icon class="mat-14">text_decrease</mat-icon>
              </button>
              <button class="font-size-button" (click)="updateTextSize(varEditorUIConfig.fontSize + textUpdateStep)">
                <mat-icon class="mat-14">text_increase</mat-icon>
              </button>
              <button *ngIf="varEditorUIConfig.fontSize !== varEditorUIConfigDefault.fontSize" (click)="updateTextSize(varEditorUIConfigDefault.fontSize)" class="font-size-button l-reset">
                <mat-icon class="mat-14">restart_alt</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      @if (hasSomeButtonEnabled(contextualButtons)) {
        <div class="header-button-row">
          <div class="button-section animate-on-display">
            @for (button of contextualButtons; track button) {
              <ng-container
                [ngTemplateOutlet]="headerButton"
                [ngTemplateOutletContext]="{button: button}"
              />
            }
          </div>
        </div>
      }
    </div>
    <div class="top-right-content">
      <div class="text-length-count">
        <span *ngIf="rawTextCharCount !== amoutCharacters" class="chars-count" helperInfo="Alguns emojis ocupam mais de um espaço" hiIndicatorStyle="right: -20px;">caracteres: {{ rawTextCharCount }}</span>
        <span *ngIf="shouldShowCanonicalAlert()" class="chars-count" helperInfo="Certifique-se que o valor retornado pelos canônicos no momento do envio não irá ultrapassar o limite de caracteres" hiIndicatorStyle="right: -20px;" hiIcon="error_outline">canônicos: </span>
        {{ amoutCharacters }} / {{ maxCharsLength }}
      </div>
    </div>
  </div>
  <div class="content-wrapper">
    <div
      #tiptapContainer
      class="var-editor-tiptap var-editor-content">
    </div>
  </div>
</div>

<ng-template #mentionsListTpl>
  <mat-card class="variables-card">
    <ul class="variables-list">
      <ng-container *ngFor="let item of popoverVariablesList; index as i">
        <li (click)="selectVariable(item)" (mouseenter)="hoverSuggestion(i)" role="button" aria-pressed="false" class="variables-list-item" [class.selected]="selectedMentionIdx === i">{{ item.variableWithoutBrackets }}</li>
      </ng-container>
    </ul>
  </mat-card>
</ng-template>

<ng-template #headerButton let-button='button'>
  <button
    *ngIf="evaluateValueOrFunction(button.isEnabled)"
    [class.is-active]="button.isActive()"
    [class.has-text]="button.textLabel"
    (click)="button.onClick()"
    [matTooltip]="button.tooltip"
    matTooltipPosition="above"
  >
    @if (button.matIcon) {
      <mat-icon class="mat-18">{{ button.matIcon }}</mat-icon>
    }

    @if (button.textLabel) {
      {{ button.textLabel }}
    }
  </button>
</ng-template>

<ng-template #editLinkTpl let-data>
  <h3 mat-dialog-title>Editar link</h3>

  <mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Texto de exibição</mat-label>
      <input matInput [(ngModel)]="data.linkText">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>URL</mat-label>
      <input matInput placeholder="Digite o endereço do link" [(ngModel)]="data.url">
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="data">Salvar</button>
  </mat-dialog-actions>
</ng-template>
