import { typedScreenOptions,  typedFields } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { EBBCodeStyles } from "../../bbcode/bbcode-types";

export const bbCodeStylesTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.BBCodeFields,
    fields: typedFields<EBBCodeStyles>()({
        [EBBCodeStyles.Italic]: 1, // Itálico
        [EBBCodeStyles.Bold]: 2, // Negrito
        [EBBCodeStyles.Strikethrough]: 3, // Tachado
        [EBBCodeStyles.Code]: 4, // Código
        [EBBCodeStyles.Monospace]: 5, // Monoespaço
        [EBBCodeStyles.Menu]: 6, // Menu
        [EBBCodeStyles.Link]: 7, // Link
        [EBBCodeStyles.Heading1]: 8, // Título
        [EBBCodeStyles.Heading2]: 9, // Subtítulo
        [EBBCodeStyles.Heading3]: 10, // Título nível 3
        [EBBCodeStyles.Heading4]: 11, // Título nível 4
        [EBBCodeStyles.Heading5]: 12, // Título nível 5
        [EBBCodeStyles.Heading6]: 13, // Título nível 6
        [EBBCodeStyles.Base64Image]: 14, // Imagem
        [EBBCodeStyles.Table]: 15, // Tabela
        [EBBCodeStyles.TableRow]: 16, // Coluna
        [EBBCodeStyles.TableHeadingCell]: 17, // Célula de cabeçalho
        [EBBCodeStyles.TableContentCell]: 18, // Célula
        [EBBCodeStyles.UnorderedList]: 19, // Lista com pontos
        [EBBCodeStyles.OrderedList]: 20, // Lista numerada
        [EBBCodeStyles.ListItem]: 21, // Item de lista
    })
}));

export const bbCodeTranslations = {
    ...bbCodeStylesTranslations,
};
