<div
  class="ordenable-list agents__container"
  cdkDropList
  #agentsList="cdkDropList"
  [cdkDropListData]="agents"
  (cdkDropListDropped)="onDropOnList($event)"
  >
  <ng-container *ngFor="let item of agents; let index = index">

    <div cdkDrag [cdkDragData]="item" cdkDragLockAxis="y" cdkDragHandle  [expanded]="true" class="no-padding ordenable-item visible-placeholder drag-handle ordenable-item-content agente__container">
      <mat-icon class="drag-agente__handle">drag_handle</mat-icon>

      <mat-form-field appearance="fill" class="full-width no-padding-bottom agent-id">
        <mat-label>Agente</mat-label>
        <span matPrefix>
          <button mat-icon-button [copyToClipboard]="getAgentId(item)" [matTooltip]="'Clique para copiar o ID'">
            <mat-icon>fingerprint</mat-icon>
          </button>
        </span>
        <input [ngModel]="getAgentName(item)" matInput type="text" readonly>
        <span matSuffix>
          <button mat-icon-button [copyToClipboard]="getAgentId(item)" [matTooltip]="'Clique para copiar o nome'">
            <mat-icon>content_copy</mat-icon>
          </button>
        </span>
      </mat-form-field>

      <button
        mat-icon-button
        (click)="handleDeleteBtn(index)"
        color="warn"
        class="delete-button"
        >
        <span class="button-label">
          <mat-icon>delete</mat-icon>
        </span>

      </button>
    </div>

    <!-- <mat-expansion-panel >
      <mat-expansion-panel-header>
        <mat-panel-title class="button-label">
          Teste
        </mat-panel-title>
      </mat-expansion-panel-header>

    </mat-expansion-panel> -->
  </ng-container>
</div>

<div class="new-agent__container">
  <app-ns-picker #nsAgentPickerAdder style="margin: 0;" class="full-width" style="display: none;" *ngIf="agentPickerHandler" [handler]="agentPickerHandler"></app-ns-picker>

  <button
    *ngIf="agentPickerHandler"
    mat-stroked-button
    color="primary"
    style="width: 100%;"
    (click)="nsAgentPickerAdder.onOpenModal()"
  >
    <mat-icon class="mat-18">add</mat-icon>&nbsp; Adicionar
  </button>
</div>
