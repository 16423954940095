import { typedFields, typedScreenOptions } from "../../../tools/utility";
import { ENonSerializableObjectType } from "../../non-serializable-id/non-serializable-id-interfaces";
import { ETransformationType } from "../../production-deploy/transformation";
import { ITranslationConfig } from "../../translation/translation-engine";
import { EAllSerializable, getTranslationsFields } from "@colmeia/core/src/shared-business-rules/const-text/all-serializables";

export const nonSerializableFriendlyNameTranslations = getNonSerializableFriendlyNameTranslations();

export function getNonSerializableFriendlyNameTranslations() {
    const nonSerializableFriendlyNameTranslations: { [key in ENonSerializableObjectType]: ITranslationConfig } = {
        ...getTranslationsFields(typedScreenOptions({
            idSerializable: EAllSerializable.NonSerializableFriendlyName,
            fields: {
                [ENonSerializableObjectType.attendentServicePack]: 1,
                [ENonSerializableObjectType.formSchemma]: 2,
                [ENonSerializableObjectType.clientGeneralAnswer]: 3,
                [ENonSerializableObjectType.fileMetadata]: 4,
                [ENonSerializableObjectType.knowledgeBase]: 5,
                [ENonSerializableObjectType.utterance]: 6,
                [ENonSerializableObjectType.socialMediaConnection]: 8,
                [ENonSerializableObjectType.campaing]: 9,
                [ENonSerializableObjectType.connection]: 10,
                [ENonSerializableObjectType.contentGenerator]: 11,
                [ENonSerializableObjectType.canonical]: 12,
                [ENonSerializableObjectType.deployedServices]: 13,
                [ENonSerializableObjectType.colmeiaTags]: 14,
                [ENonSerializableObjectType.bot]: 15,
                [ENonSerializableObjectType.serviceIsland]: 16,
                [ENonSerializableObjectType.attendantAllowedAvatar]: 17,
                [ENonSerializableObjectType.runCampaing]: 18,
                [ENonSerializableObjectType.patchHeader]: 19,
                [ENonSerializableObjectType.patchItem]: 20,
                [ENonSerializableObjectType.deployHeader]: 21,
                [ENonSerializableObjectType.deployProdItem]: 22,
                [ENonSerializableObjectType.securityControl]: 23,
                [ENonSerializableObjectType.featureDeployedGroup]: 24,
                [ENonSerializableObjectType.connectionRoute]: 25,
                [ENonSerializableObjectType.bpmMarketingRoot]: 26,
                [ENonSerializableObjectType.bpmMarketingAction]: 27,
                [ENonSerializableObjectType.bpmMarketingPause]: 28,
                [ENonSerializableObjectType.bpmMarketingAcknowledge]: 29,
                [ENonSerializableObjectType.yourTasks]: 30,
                [ENonSerializableObjectType.analyticsBiReportItem]: 37,
                [ENonSerializableObjectType.dataExtractionQuery]: 38,
                [ENonSerializableObjectType.userApprovalProfile]: 39,
                [ENonSerializableObjectType.waba]: 41,
                [ENonSerializableObjectType.attCopilot]: 42,
                [ENonSerializableObjectType.paymentAccount]: 43,
                [ENonSerializableObjectType.paymentGateway]: 44,
            },
        })),
        ...getTranslationsFields(typedScreenOptions({
            idSerializable: EAllSerializable.NonSerializableFriendlyName1,
            fields: typedFields<ENonSerializableObjectType, { IsPartial: true; }>()({
                [ENonSerializableObjectType.deployTransformer]: 1,
                [ENonSerializableObjectType.faceCompany]: 2,
                [ENonSerializableObjectType.callcenterAtt]: 3,
                [ENonSerializableObjectType.presenceStatus]: 4,
                [ENonSerializableObjectType.NLPActivity]: 5,
                [ENonSerializableObjectType.attendanceCalendar]: 6,
                [ENonSerializableObjectType.realTimeFusion]: 7,
                [ENonSerializableObjectType.customEvents]: 8,
                [ENonSerializableObjectType.socialNetworkConfig]: 9,
                [ENonSerializableObjectType.graphElement]: 10,
                [ENonSerializableObjectType.activeCall1x1]: 11,
                [ENonSerializableObjectType.activeCallBatch]: 12,
                [ENonSerializableObjectType.userFunction]: 13,
                [ENonSerializableObjectType.colmeiaAPIModel]: 14,
                [ENonSerializableObjectType.job]: 15,
                [ENonSerializableObjectType.crmHeaderCloseState]: 16,
                [ENonSerializableObjectType.crmItemCloseState]: 17,
                [ENonSerializableObjectType.crmHeaderPhase]: 18,
                [ENonSerializableObjectType.crmItemPhase]: 19,
                [ENonSerializableObjectType.crmHeaderSeverity]: 20,
                [ENonSerializableObjectType.crmItemSeverity]: 21,
                [ENonSerializableObjectType.crmHeaderState]: 22,
                [ENonSerializableObjectType.crmItemState]: 23,
                [ENonSerializableObjectType.crmConfigPackage]: 24,
                [ENonSerializableObjectType.corporateSearchHeader]: 25,
                [ENonSerializableObjectType.corporateSearchBody]: 26,
                [ENonSerializableObjectType.smartFlowRTFParamHeader]: 27,
                [ENonSerializableObjectType.smartFlowRTFParamBody]: 28,
                [ENonSerializableObjectType.channelTemplate]: 29,
                [ENonSerializableObjectType.crmServiceConfigRoot]: 30,
                [ENonSerializableObjectType.crmServiceConfigAgentEvent]: 31,
                [ENonSerializableObjectType.crmServiceConfigNotification]: 32,
                [ENonSerializableObjectType.crmServiceConfigProcessingNode]: 33,
                [ENonSerializableObjectType.crmHeaderSupportLevel]: 34,
                [ENonSerializableObjectType.crmItemSupportLevel]: 35,
                [ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel]: 36,
                [ENonSerializableObjectType.crmItemUrgencyLevel]: 37,
                [ENonSerializableObjectType.crmExecution]: 38,
                [ENonSerializableObjectType.configurationSet]: 39,
                [ENonSerializableObjectType.catalog]: 40,
                [ENonSerializableObjectType.socialNetworkUserSettings]: 41,
                [ENonSerializableObjectType.bpmMarketingListener]: 42,
                [ENonSerializableObjectType.bpmMarketingEnd]: 43, // BPM - Marketing - End
                [ENonSerializableObjectType.metaBusinessPages]: 44, // Páginas de business da meta
                [ENonSerializableObjectType.billingContractItem]: 45,
                [ENonSerializableObjectType.billingContract]: 46,
                [ENonSerializableObjectType.product]: 47,

            }),
        })),
        ...getTranslationsFields(typedScreenOptions({
            idSerializable: EAllSerializable.NonSerializableFriendlyName2,
            fields: typedFields<ENonSerializableObjectType, { IsPartial: true; }>()({
                [ENonSerializableObjectType.wafSchema]: 1,
                [ENonSerializableObjectType.waf]: 2,
                [ENonSerializableObjectType.productModule]: 3,
                [ENonSerializableObjectType.backupSnapshot]: 4,
                [ENonSerializableObjectType.backupPackage]: 5,
                [ENonSerializableObjectType.billingClosing]: 6,
                [ENonSerializableObjectType.billingItem]: 7,
                [ENonSerializableObjectType.kbCorporateEntity]: 8,
                [ENonSerializableObjectType.contentVector]: 54,
                [ENonSerializableObjectType.contentVectorChunk]: 9,
                [ENonSerializableObjectType.generativePrompt]: 10,
                [ENonSerializableObjectType.contactList]: 11,
                [ENonSerializableObjectType.contactListItem]: 12,
                [ENonSerializableObjectType.transformer]: 13,
                [ENonSerializableObjectType.supremacyAgent]: 14,
            }),
        })),
    };

    return nonSerializableFriendlyNameTranslations;
}




const transformationFriendlyNameScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.Transformations,
    fields: typedFields<ETransformationType>()({
        [ETransformationType.group]: 2,
        [ETransformationType.provider]: 3,
        [ETransformationType.database]: 6,
        [ETransformationType.httpConnection]: 7,
        [ETransformationType.googleProvider]: 8,
    }),
});
export const transformationFriendlyNameTranslations = getTranslationsFields(transformationFriendlyNameScreenOptions);
