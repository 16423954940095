import { Injectable, Injector } from "@angular/core";
import { cloneDeep, getClock, isValidArray, objectShallowReplace, values } from "@colmeia/core/src/tools/barrel-tools";
import { IKanbanCardMovedEvent, KanbanService } from "app/services/kanban/kanban.base-service";
import { CRMTicketsService } from "../crm-tickets.service";
import { CRMUserConfigRepositoryService } from "./crm-user-config-repository.service";
import { ECRMTicketViewMode, ICardSortData, TIKanbanCardSortDataArray, TKanbanSortedCards } from "./crm-user-config.model";
import { CRMTicketKanbanService } from "../crm-kanban-view/crm-kanban.service";
import { ITicketKanbanCardData, ITicketKanbanColumnData } from "../crm-kanban-view/crm-kanban-shared.model";
import { moveItemInArray } from "@angular/cdk/drag-drop";
import { TKanbanCardArray } from "@colmeia/core/src/shared-business-rules/kanban/kanban-shared-model";

@Injectable({
    providedIn: 'root',
})
export class KanbanSortService {

    private kanbanSvc!: KanbanService<ITicketKanbanCardData, ITicketKanbanColumnData>;

    constructor(
        private ticketSvc: CRMTicketsService,
        private crmUserConfigRepositorySvc: CRMUserConfigRepositoryService,
    ) {
        // this.ticketSvc.$ticketsLoaded.subscribe(() => {
        //     this.handleTicketsLoad();
        // });
    }

    // private handleTicketsLoad() {
    //     const config = this.crmUserConfigRepositorySvc.getUserConfig();

    //     if (config.viewMode !== ECRMTicketViewMode.Kanban) return;

    //     this.syncronizeConfig();
    // }

    public setKanbanService(kanbanSvc: KanbanService<ITicketKanbanCardData, ITicketKanbanColumnData>) {
        this.kanbanSvc = kanbanSvc;

        this.kanbanSvc.columnsListUpdated$().subscribe(() => {
            this.createSortForNewColumns();
        });

        this.createSortForNewColumns();
    }

    /**
     * Essa função tem como propósito
     * gerar os arrays de sorts da view atual 
     */
    private createSortForNewColumns() {
        const kanbanSvc = this.kanbanSvc;
        if (!kanbanSvc) {
            return;
        }

        const columns = kanbanSvc.getVisibleColumns();

        const { kanban } = this.crmUserConfigRepositorySvc.getUserConfig();

        if (!kanban) return;

        const { sortedCards } = kanban;

        columns.forEach(column => {
            const id: string = column.getColumnID();
            const shouldUnshift: boolean = isValidArray(sortedCards[id]);
            const columnSorted = sortedCards[id] ||= [];

            const cards: TKanbanCardArray = column.getAllCards();
            const lastUpdatedSorted: TKanbanCardArray = cards.sort((a, b) => b.getData().updatedAt - a.getData().updatedAt);

            for (const card of lastUpdatedSorted) {
                const cardId = card.getCardID();
                const cardConfig = columnSorted.find(card => card.cardId === cardId);

                if (!cardConfig) {
                    const newData = { cardId, clockTick: getClock() };
                    shouldUnshift ? columnSorted.unshift(newData) : columnSorted.push(newData);
                } else {
                    cardConfig.clockTick = getClock();
                }
            }

        });

        this.crmUserConfigRepositorySvc.saveUserConfig();
        // config.kanban.sortedCards

    }

    public onCardMoved(config: TKanbanSortedCards, event: IKanbanCardMovedEvent): TKanbanSortedCards {
        const controller = new KanbanSortController(config);

        controller.onCardMoved(event);

        return controller.toJSON();

    }
}


export class KanbanSortController {

    private config: TKanbanSortedCards;

    constructor(_config: TKanbanSortedCards) {
        this.config = cloneDeep(_config);
    }

    toJSON() {
        return cloneDeep(this.config)
    }

    public onCardMoved(event: IKanbanCardMovedEvent) {
        const sourceColumnId = event.sourceColumn.getColumnID();
        const targetColumnId = event.targetColumn.getColumnID();
        const topCardIndex = this.getCardIndex(targetColumnId, event.targetTopCard?.getCardID());
        const targetArray: TIKanbanCardSortDataArray = this.config[targetColumnId];
        const targetCardIndex = this.getCardIndex(targetColumnId, event.card.getCardID());


        const isToAnotherColumn = sourceColumnId !== targetColumnId;

        if (isToAnotherColumn) {
            const sourcArray: TIKanbanCardSortDataArray = this.config[sourceColumnId];
            const sourceCardIndex = this.getCardIndex(sourceColumnId, event.card.getCardID());

            sourcArray.splice(sourceCardIndex, 1);

        }

        if (topCardIndex === -1) {
            moveItemInArray(targetArray, targetCardIndex, 0);
        } else {

            if (targetCardIndex === -1) {
                targetArray.splice(topCardIndex + 1, 0, { cardId: event.card.getCardID(), clockTick: getClock() });
            } else {
                moveItemInArray(targetArray, targetCardIndex, topCardIndex + 1);
            }

        }

    }

    private getCardIndex(columnId: string, cardId: string) {
        return this.getSetColumn(columnId).findIndex(card => card.cardId === cardId); // [cardId] ||= { weightOffset: 0, clockTick: getClock() };
    }

    private getSetColumn(columnId: string) {
        return this.config[columnId] ||= [];
    }

}