import { IBotMenuContainerServer } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { defaultFields } from "@colmeia/core/src/tools/utility";
import { MainHandler } from "app/handlers/main-handler";
import { TVarEditorVariables } from "app/handlers/var-editor.handler";
import { IComponentParameterWithOptionalClient } from "app/model/component-comm/basic";

export interface IBotMenuContainerRCSConfigurationClientCallback {

}


export interface IBotMenuContainerRCSConfigurationParameters extends IComponentParameterWithOptionalClient<IBotMenuContainerRCSConfigurationClientCallback> {
    entity: IBotMenuContainerServer;
    getVariables: () => TVarEditorVariables;
}

function initDefaultParameters() {
    const defaultParameters: Partial<IBotMenuContainerRCSConfigurationParameters> = {

    };
    return defaultParameters;
}

export class BotMenuContainerRCSConfigurationHandler extends MainHandler<IBotMenuContainerRCSConfigurationParameters> {
    constructor(parameter: IBotMenuContainerRCSConfigurationParameters) { defaultFields(parameter, initDefaultParameters()); super(parameter) }

}
