import { Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBotActionLGPD } from '@colmeia/core/src/shared-business-rules/bot/bot-action-model';
import { KBAssetType, IBasicAsset, KBAssetTypeClientOnly } from '@colmeia/core/src/shared-business-rules/bot/bot-asset-model';
import { IContentBasicAsset, EBotContentEvent } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { EBotActionType } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { IBPMValidatorActionOnError } from '@colmeia/core/src/shared-business-rules/BPM/bpm-action-model';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { IBotActionHandler } from 'app/components/dashboard/bot-action-editor/bot-action-editor.component';
import { IActionOnErrorEditorDialogReponse, IActionOnErrorHandler } from 'app/components/dashboard/new-condition-editor/action-on-error-editor/action-on-error-editor.model';
import { NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { IAssetAdderHandler } from 'app/model/dashboard/asset-adder.model';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { SnackMessageService } from 'app/services/snack-bar';

const minErrorCount = 1;

@Component({
    selector: 'app-action-on-error-editor',
    templateUrl: './action-on-error-editor.component.html',
    styleUrls: ['./action-on-error-editor.component.scss']
})
export class ActionOnErrorEditorComponent implements OnInit, OnChanges {

    public isDialog: boolean;

    @Input()
    handler: IActionOnErrorHandler;

    botActionHandler: IBotActionHandler;
    assetAdderHandler: IAssetAdderHandler;

    actionOnError: IBPMValidatorActionOnError

    constructor(
        private snackSvc: SnackMessageService,
        @Optional() private matDialogRef: MatDialogRef<ActionOnErrorEditorComponent, IActionOnErrorEditorDialogReponse>,
        private dashboardSvc: DashBoardService,
        @Optional() @Inject(MAT_DIALOG_DATA) private dialogHandler: IActionOnErrorHandler,
    ) { }

    ngOnInit() {
        this.isDialog = this.matDialogRef?.componentInstance instanceof ActionOnErrorEditorComponent;

        if (this.isDialog) {
            this.handler = this.dialogHandler;
        }

        this.init();

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.handler && !changes.handler.isFirstChange()) {
            this.init()
        }
    }

    private init() {
        if (!this.handler) return;

        this.actionOnError = this.handler.actionOnErrorConfig;

        this.assetAdderHandler = {
            assets: this.actionOnError.action.contentArray,
            assetTypesEnabled: [KBAssetType.content, KBAssetType.media, KBAssetTypeClientOnly.sticker],
            removeAsset: (index: number, assets: IBasicAsset[]) => {
                assets.splice(index, 1);
                return Promise.resolve(true)
            },
            saveAsset: (newAsset: IBasicAsset, assets: IBasicAsset[]) => {
                const index = assets.findIndex(ast => ast.idAsset === newAsset.idAsset);
                if (index > -1) {
                    assets[index] = newAsset;
                } else {
                    assets.push(newAsset);
                }
                (<IContentBasicAsset>newAsset).botContentType = EBotContentEvent.mainContent
                return Promise.resolve(true)
            }
        }

        this.botActionHandler = {
            botAction: this.actionOnError.action,
            actionTitle: 'ação',
            showContentArray: true,
            actionTypes: [
                EBotActionType.goBot,
                EBotActionType.contentGenerator,
                EBotActionType.goActionTree,
                EBotActionType.isEnd,
                EBotActionType.goHuman,
                ...this.handler.additionalBotAction || [],
            ],
            handlerAssets: this.assetAdderHandler,
            isEventAction: false,
            schemasForGoToProp: this.handler.schemasForGoToProp,
            allowConfigPreFunction: true,
        };
    }


    dialogSaveClick() {
        if (this.actionOnError.errorsCount < minErrorCount) {
            this.snackSvc.openError('A Quantidade de recorrência mínima é 1.');
            return;
        }

        this.matDialogRef.close({
            actionOnErrorConfig: this.handler.actionOnErrorConfig,
            saved: true,
        })
    }

}
