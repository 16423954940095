import { Injectable } from '@angular/core';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { IGeneralFormAnswer } from '@colmeia/core/src/general-form/general-form-answer';
import { ConditionsProcessor, IConditionalData } from '@colmeia/core/src/general-form/general-form-condition-processor';
import { ICheckFieldConditionResultWithAction } from '@colmeia/core/src/general-form/general-form-interface';
import { TServiceIslandArray } from '@colmeia/core/src/shared-business-rules/attendance-island/attendance-island';
import { IBPConditionalEvaluator, IBPMBasicConditionEvaluator, TIBPConditionalEvaluatorArray } from '@colmeia/core/src/shared-business-rules/BPM/bpm-model';
import { ETicketInteractionType, ICRMTicketDataWithCustomerName, ITicketInteractionStatus, TICRMTicketDataBasicArray, TICRMTicketItems, TTicketInteracitonArray } from '@colmeia/core/src/shared-business-rules/crm/crm-entities';
import { ECRMCanonicalItemsTypes } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config-canonical-model';
import { ECRMAgentAttributeUpdateControl, ICRMAgentAttributeUpdateForm, ICRMAgentAttributeUpdateItem, ICRMAttributePackageBase, ICRMProject, ICRMProjectAttributeConfig, TCRMAllPackagesItems, TICRMProjectArray } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config.model';
import { ECRMTicketRuntimeRequests, ICRMGetAgentIslandsRequest, ICRMGetAgentIslandsResponse, ICRMGetAgentTicketsRequest, ICRMGetAllTicketInteractionsRequest, ICRMGetAllTicketInteractionsResponse, ICRMGetAvatarPackagesRequest, ICRMGetAvatarPackagesResponse, ICRMTicketGetAttendanceTicketsRequest, ICRMTicketGetAttendanceTicketsResponse } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-runtime-req-res';
import { ICRMTimeWindowConfig, getDefaultTimeWindow } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-time-window.model';
import { getCRMStatusNameByType, getItemStatusKeyByType, getItemStatusNameKeyByType, itemTypeToConfigMapProp } from '@colmeia/core/src/shared-business-rules/crm/crm-utils';
import { IBasicCondition } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { getTicketBasicDataToComputedInfo } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { captalizeFirstLetter, isValidArray, typedClone } from '@colmeia/core/src/tools/barrel-tools';
import { UserFunctionsService } from 'app/components/dashboard/functions-page/services/user-functions.service';
import { ITicketInteractionStatusClient, TTicketInteractionClient } from 'app/crm-service-tickets-view/tickets-view-card-dialog/ticket-interaction/ticket-interaction.component';
import { AnnotationsModalHandler, EAnnotationsType } from 'app/handlers/annotations-modal-handler';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { AnnotationsService } from 'app/services/annotations.service';
import { AttendanceService, IRegistreService } from 'app/services/attendance.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { LookupService } from 'app/services/lookup.service';
import { RoutingService } from 'app/services/routing.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { cloneDeep } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CRMTicketNotificationService } from './crm-ticket-notification.service';
import { CRMTicketPersistenceService, IGetAllTicketsAndPackagesResponse } from './crm-ticket-persistence.service';
import { statusInteractionProps } from './crm-tickets-client.constants';
import { CRMTicketsDialogService } from './crm-tickets-dialog.service';



export interface IGetTicketsForAttendanceParams {
    idStartInteracrion: string;
    timeWindow?: ICRMTimeWindowConfig;
    useVisualizerIdForTitle: string;
    showAllCustomerTicketsToAgent?: boolean;
    showProjectKey?: boolean;
}


export interface ITicketParamsBase {
    ticketData: ICRMTicketDataWithCustomerName
}

export interface ITicketsLoaded {
    tickets: TICRMTicketItems;
    packages: TICRMProjectArray;
}

interface ILocalTicketConfig {
    dimensionFormConfig: ICRMAgentAttributeUpdateForm,
    itemConfig: ICRMAgentAttributeUpdateItem
}

interface ILocalItemData {
    type?: ECRMCanonicalItemsTypes;
    itemName: string;
    config?: TCRMAllPackagesItems,
}

@Injectable({
    providedIn: 'root'
})
export class CRMTicketsService {

    #ticketAdded: Subject<ITicketParamsBase> = new Subject();
    #ticketUpdate: Subject<ITicketParamsBase> = new Subject();
    #ticketsLoaded: Subject<ITicketsLoaded> = new Subject();

    get $ticketAdded(): Observable<ITicketParamsBase> { return this.#ticketAdded.asObservable(); }
    get $ticketUpdate(): Observable<ITicketParamsBase> { return this.#ticketUpdate.asObservable(); }
    get $ticketsLoaded(): Observable<ITicketsLoaded> { return this.#ticketsLoaded.asObservable(); }

    $listenTicketUpdate(idTicket: string): Observable<ITicketParamsBase> {
        return this.$ticketUpdate.pipe(filter(t => t.ticketData.idTicket === idTicket))
    }

    #itens: Map<string, ILocalItemData> = new Map();

    #packages: Map<string, ICRMProject> = new Map();
    #tickets: Map<string, ICRMTicketDataWithCustomerName> = new Map();
    #itensConfig: Map<string, ILocalTicketConfig> = new Map();

    get packages() { return [...this.#packages.values()]; }
    get tickets() { return [...this.#tickets.values()]; }

    constructor(
        private api: ServerCommunicationService,
        private routeSvc: RoutingService,
        private dashboardSvc: DashBoardService,
        private attendanceSvc: AttendanceService,
        private ticketDialogSvc: CRMTicketsDialogService,
        protected persistenceSvc: CRMTicketPersistenceService,
        private ticketNotificationSvc: CRMTicketNotificationService,
        private ticketPersistenceSvc: CRMTicketPersistenceService,
        private annotationsSvc: AnnotationsService,
        private lookupSvc: LookupService,
        private userFnSvc: UserFunctionsService,
    ) {
        this.ticketNotificationSvc.$newTicketsStream.subscribe((ticket) => {
            this.addTicket(ticket);
        });

        this.ticketNotificationSvc.$newTicketsInteractionStream.subscribe(({ ticketData }) => {
            this.updateTicket(ticketData);
        });
    }

    getTicket(idTicket: string) {
        return this.#tickets.get(idTicket);
    }

    async getAllTicketsAndPackages(req?: Partial<ICRMGetAgentTicketsRequest>): Promise<IGetAllTicketsAndPackagesResponse> {

        const { tickets, packages } = await this.ticketPersistenceSvc.getAllTicketsAndPackages(req);
        const allIslands = await this.getAllIslands();
        console.log("allIslands :>> ", allIslands);

        packages.forEach(pkg => {
            this.#packages.set(pkg.packageId, pkg)
        });

        tickets.forEach(ticket => {
            this.#tickets.set(ticket.idTicket, ticket)
        });

        this.buildItemsNames();

        this.#ticketsLoaded.next({ packages, tickets });

        return {
            packages,
            tickets,
        }
    }

    private buildItemsNames() {
        this.#itens.clear();

        this.#packages.forEach((project) => {
            this.#itens.set(project.packageId, { itemName: project.name });

            Object.values(project.packages).forEach((pkg: ICRMAttributePackageBase) => {
                this.#itens.set(pkg.headerPackageId, { itemName: pkg.name, type: pkg.type });

                const configKey = itemTypeToConfigMapProp[pkg.type];
                const config = project.config[configKey] as ICRMProjectAttributeConfig;

                pkg.items.forEach(item => {
                    this.#itens.set(item.itemId, { itemName: item.name, type: item.itemType, config: item });
                    this.#itensConfig.set(item.itemId, {
                        dimensionFormConfig: config.updateConfig.form,
                        itemConfig: config.updateConfig.items[item.itemId],
                    })
                })
            });
        });

        this.#tickets.forEach(ticket => {
            this.#itens.set(ticket.idTicket, { itemName: ticket.title });
        });
    }

    async getAllIslands(): Promise<TServiceIslandArray> {
        const result = await this.api.sendRequest<ICRMGetAgentIslandsRequest, ICRMGetAgentIslandsResponse>(ECRMTicketRuntimeRequests.getAgentIslands)({});

        return result.islands;
    }

    public async fetchAvatarPackages(): Promise<TICRMProjectArray> {
        const result = await this.api.sendRequest<ICRMGetAvatarPackagesRequest, ICRMGetAvatarPackagesResponse>(ECRMTicketRuntimeRequests.getAvatarPackages)({});

        return result?.packages || [];
    }

    public async getAllTicketInteractions(idTicket: string): Promise<TTicketInteracitonArray> {
        const result = await this.api.sendRequest<ICRMGetAllTicketInteractionsRequest, ICRMGetAllTicketInteractionsResponse>(ECRMTicketRuntimeRequests.getAllTicketInteractions)({ idTicket });

        result?.ticketInteractions.sort((a, b) => b.clockTick - a.clockTick);

        return result?.ticketInteractions || [];
    }

    /**
     * faz algumas modificações nas interações para facilitar a exibição no client
     */
    public parseTicketInteractionsForClient(interactions: TTicketInteracitonArray): TTicketInteractionClient[] {
        const newInteractions: TTicketInteractionClient[] = [];

        for (let interaction of interactions) {
            switch (interaction.interactionType) {
                case ETicketInteractionType.status:
                    const clientInteraction = this.parseStatusInteraction(interaction, interactions);

                    newInteractions.push(clientInteraction);
                    break;

                default:
                    newInteractions.push(interaction);
            }
        }

        return newInteractions;
    }

    private parseStatusInteraction(current: ITicketInteractionStatus, array: TTicketInteracitonArray): ITicketInteractionStatusClient {
        const newInteraction: ITicketInteractionStatusClient = typedClone(current);
        let lastStatusInteraction = undefined;

        // get last status interaction
        for (let i = array.indexOf(current) + 1; i < array.length; i++) {
            const item = array[i];

            if (item.interactionType === ETicketInteractionType.status) {
                lastStatusInteraction = item;
                break;
            }
        }

        if (lastStatusInteraction) {
            newInteraction.changedStatusProperties = this.getStatusInteractionChangedProps(lastStatusInteraction, current);
        } else {
            newInteraction.isFirst = true;
        }

        return newInteraction;
    }

    private getStatusInteractionChangedProps(a: ITicketInteractionStatus, b: ITicketInteractionStatus): string[] {
        const properties = [];
        const propNames: string[] = statusInteractionProps;

        for (let prop of propNames) {
            if (a[prop] !== b[prop]) {
                properties.push(prop);
            }
        }

        return properties;
    }

    async checkTicketsAccess(): Promise<boolean> {
        const { isAllowed } = await this.dashboardSvc.getRouteAccessInfo(
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.tickets.path
        );

        return isAllowed;
    }

    public navigateToTickets() {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.tickets.path
        );
    }

    public navigateOrOpenTicketsWindow() {
        if (this.ticketDialogSvc.findOpenedWindow()) {
            this.ticketDialogSvc.restoreWindow();
        } else {
            this.navigateToTickets();
        }
    }

    public async getTicketsForAttendance({
        idStartInteracrion,
        useVisualizerIdForTitle,
        timeWindow = getDefaultTimeWindow(),
        showAllCustomerTicketsToAgent,
        showProjectKey
    }: IGetTicketsForAttendanceParams): Promise<TICRMTicketDataBasicArray> {

        const attRegistry: IRegistreService = this.attendanceSvc.getAttendanceRegistryByIdInteraction(idStartInteracrion);
        const result = await this.api.sendRequest<ICRMTicketGetAttendanceTicketsRequest, ICRMTicketGetAttendanceTicketsResponse>(ECRMTicketRuntimeRequests.getAttendanceTickets)({
            timeWindow,
            includeAnotherAgentTickets: false,
            useVisualizerIdForTitle,
            showAllCustomerTicketsToAgent,
            showProjectKey,
            idCustomerAvatar: attRegistry.interaction.participant.idAvatar,
        });

        return result?.tickets || [];
    }

    public getSelectedTicketForAttendance(idStartInteractionId: TGlobalUID): string {
        return this.attendanceSvc.getAttendanceRegistryByIdInteraction(idStartInteractionId)?.interaction.idTicket;
    }

    public async updateTicketStatusID(idTicket: string, statusId: string, type: ECRMCanonicalItemsTypes): Promise<ICRMTicketDataWithCustomerName | undefined> {
        let idAnswer: TGlobalUID;

        const formConfig = this.getFormConfigToAnswer(statusId);

        if (formConfig) {
            idAnswer = await this.openDialogAndGetAnswer(idTicket, formConfig);

            if (!idAnswer) {
                return;
            }
        }

        const currentTicket = this.#tickets.get(idTicket);
        const newTicket = cloneDeep(currentTicket)

        const result = await this.persistenceSvc.updateTicketStatusItem(
            idTicket,
            type,
            statusId,
            idAnswer,
        );

        if (!!result) {
            const statusKey = getItemStatusKeyByType(type);
            newTicket.currentStatus[statusKey] = statusId;
            const statusName = getItemStatusNameKeyByType(type);
            newTicket.currentStatusNames[statusName] = this.getItemName(statusId);
            // this.#ticketUpdate.next({ ticketData: newTicket });

            this.updateTicket(newTicket)
            return newTicket;
        }
    }

    private getFormConfigToAnswer(targetStatusId: string): ICRMAgentAttributeUpdateForm {
        let { dimensionFormConfig, itemConfig } = this.getConfigForItem(targetStatusId);
        let formConfig: ICRMAgentAttributeUpdateForm = itemConfig.form || (itemConfig as unknown as ICRMAgentAttributeUpdateForm);

        if (!formConfig || formConfig.control === ECRMAgentAttributeUpdateControl.useFormHeader) {
            formConfig = dimensionFormConfig;
        }

        if (formConfig.control === ECRMAgentAttributeUpdateControl.free) {
            return undefined
        }

        return formConfig
    }

    public getConfigForItem(statusId: string): ILocalTicketConfig {
        const config = this.#itensConfig.get(statusId);
        return config
    }


    private async openDialogAndGetAnswer(idTicket: string, formConfig: ICRMAgentAttributeUpdateForm): Promise<TGlobalUID | null> {

        const ns = await this.lookupSvc.getNS(formConfig.register.idMetadata);

        return new Promise((resolve, reject) => {
            const answer: IGeneralFormAnswer = {
                idSchemma: ns.idNS,
                responses: [],
                text: '',
                primaryID: idTicket,
                json: undefined
            };

            const handler = new AnnotationsModalHandler({
                type: EAnnotationsType.allSchemas,
                persist: true,
                serializableId: null,
                selectedSchema: ns.schemma,
                onSaveAnnotationCallback: (answer) => {
                    resolve(answer.idNS);
                },
                validateAnswer: (answer) => {
                    answer.primaryID = idTicket;
                    return true;
                },
                clientCallback: {},
                hideCreateTab: false,
                engagement: formConfig.register.engagement,
                answer,
            });

            const dialogRef = this.annotationsSvc.openFor(handler);

            dialogRef.afterClosed().pipe(take(1)).subscribe(r => {
                !r && resolve(null);
            })

        });

    }


    /**
     * Retorna o nome de qualquer item do sistema que esteja em memória
     */
    getItemName(idItem: TGlobalUID): string {
        return this.#itens.get(idItem)?.itemName;
    }

    /**
     * Retorna o nome de qualquer item do sistema que esteja em memória
     */
    getItemLocalData(idItem: TGlobalUID): ILocalItemData {
        return this.#itens.get(idItem);
    }

    public async addTicket(ticketData: ICRMTicketDataWithCustomerName) {
        await this.loadPackageForTicketIfNeeded(ticketData);

        this.#tickets.set(ticketData.idTicket, ticketData);

        this.buildItemsNames();

        this.#ticketAdded.next({ ticketData });
    }

    async openTicket(idTicket: TGlobalUID) {
        const alreadyOpened = this.ticketDialogSvc.windowAlreadyOpenWindoeRef(idTicket);
        if(alreadyOpened) {
            alreadyOpened.restore();
            return;
        }

        const ticketData: ICRMTicketDataWithCustomerName = await this.persistenceSvc.getTicket(idTicket)
        await this.addTicket(ticketData);

        this.ticketDialogSvc.openTicketWindow(ticketData);
    }

    private async loadPackageForTicketIfNeeded(ticketData: ICRMTicketDataWithCustomerName) {
        const hasPackageInMemory = this.#packages.has(ticketData.idPackage);

        if (!hasPackageInMemory) {
            const [pkg] = await this.persistenceSvc.getPackages([ticketData.idPackage]);

            this.#packages.set(pkg.packageId, pkg);
        }
    }



    public async updateTicket(ticketData: ICRMTicketDataWithCustomerName) {
        const hasPackageInMemory = this.#packages.has(ticketData.idPackage);

        if (!hasPackageInMemory) {
            const [pkg] = await this.persistenceSvc.getPackages([ticketData.idPackage]);

            this.#packages.set(pkg.packageId, pkg);
        }

        this.#tickets.set(ticketData.idTicket, ticketData);

        this.buildItemsNames();

        this.#ticketUpdate.next({ ticketData });

    }

    public async refreshTicket(idTicket: TGlobalUID) {
        const ticketFreshData = await this.persistenceSvc.getTicket(idTicket);

        if (!ticketFreshData) return;

        await this.updateTicket(ticketFreshData);
    }

    public getProjectFromCache(idProject: string): ICRMProject {
        const fromMemory = this.#packages.get(idProject);
        return fromMemory
    }

    async getProject(idPackage: string): Promise<ICRMProject> {
        const fromMemory = this.getProjectFromCache(idPackage);

        if (fromMemory) return fromMemory;

        const [pkg] = await this.persistenceSvc.getPackages([idPackage])

        pkg && this.#packages.set(idPackage, pkg)

        return pkg;
    }

    public async validateAspectItemForTicket(ticketData: ICRMTicketDataWithCustomerName, targetItemId: TGlobalUID): Promise<{
        valid: boolean,
        errorMessages?: string[],
        /**
         * Todas as mensagens concatenadas com "\n"
         */
        errorMessage: string,
    }> {
        const itemConfig: ILocalTicketConfig = this.#itensConfig.get(targetItemId);

        return this.validateConditionsForTicket(ticketData, itemConfig.itemConfig.conditions);
    }

    public async validateConditionsForTicket(ticketData: ICRMTicketDataWithCustomerName, conditions: TIBPConditionalEvaluatorArray): Promise<{
        valid: boolean,
        errorMessages?: string[],
        /**
         * Todas as mensagens concatenadas com "\n"
         */
        errorMessage: string,
    }> {

        let isSuccess: boolean = true;
        const errorMessages: string[] = [];

        if (isValidArray(conditions)) {
            await Promise.all(
                conditions.map(async cond => {
                    const r = await this.validateConditionForTicket(ticketData, cond);

                    if (!r.isSuccess) {
                        isSuccess = false;
                        r.errorMsg ||= this.generateGenericErrorMessageByConditions(cond);
                    }

                    if (r.errorMsg) {
                        errorMessages.push(r.errorMsg);
                    }
                })
            );
        }

        return {
            errorMessages,
            errorMessage: errorMessages.join('\n'),
            valid: isSuccess,
        };
    }

    public async validateConditionForTicket(ticketData: ICRMTicketDataWithCustomerName, cond: IBPConditionalEvaluator) {
        const r = (await ConditionsProcessor.checkFieldCondition(cond, {
            currentValue: '',
            computed: getTicketBasicDataToComputedInfo(ticketData),
        }, (cond: IBasicCondition, v: IConditionalData) => {
            return this.userFnSvc.validate(cond, v)
        }) as ICheckFieldConditionResultWithAction);

        return r;
    }

    private generateGenericErrorMessageByConditions(cond: IBPMBasicConditionEvaluator): string {
        const message = cond.matchConditions.map(dracula => {
            const localItemData = this.#itens.get(dracula.condition);

            const icon = dracula.reverseCondition ? '=' : '≠';

            return `${captalizeFirstLetter(getCRMStatusNameByType(localItemData.type))} ${icon} ${localItemData.itemName}`
        }).join('\n');

        return message;
    }

}
