<h3 *ngIf="title" class="table-title">
  <span> {{ printText(title) }} </span>
</h3>

<div class="options--container">
  <div>
    <app-go-back enableOnHoverEffect="true" *ngIf="enableGoBackButton" [callBack]="parameters.goBackButtonCB" [executeCallbackOnly]="parameters.executeCallbackOnly"></app-go-back>
    <ng-content select="[leftOptions]"></ng-content>
  </div>
  <div>
    <div *ngIf="enableRefresh" class="refresh--button">
      <div class="refresh--icon enable_on_click" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </div>
    </div>
  </div>
</div>

<div class="table-container">
  <table mat-table *ngIf="showContent(); else spinner" [ngStyle]="style" [dataSource]="rows" class="table boxed-table"
    [class.fixed-table]="enableEqualSize" [ngClass]="_colorTableClass" [class.clickable-row]="hasRowClickHandler"
    cdkDropList [cdkDropListDisabled]="!enableChangePositions" [cdkDropListData]="rows"
    (cdkDropListDropped)="onDropTable($event)">
    <ng-container *ngFor="let column of columnNames">
      <ng-container [matColumnDef]="column">
  
        <th mat-header-cell *matHeaderCellDef> {{ getColumnText(column) }} </th>
  
        <td mat-cell *matCellDef="let element; let index" [ngStyle]="element[column].style">
          <ng-container *ngIf="element[column]">
  
  
            <div
              (click)="$event.stopPropagation(); onFieldClick(element[column], element, column)"
              [matTooltip]="element[column].onHoverShowText ? element[column].onHoverShowText : null"
              [ngSwitch]="element[column].type"
              [ngClass]="{ 'enable_on_click': element[column].enableOnClick || element[column].onClick }"
              *ngIf="isVisible(element[column])"
            >
              <div class="text-container">
                <div class="insideTd" mat-cell *ngSwitchCase="ECallCenterGenericRow.Text" 
                  [ngStyle]="{ 'color': getExpiredStyle(element[column]) }">
                  <app-bbcode-viewer *ngIf="element[column].shouldUseBBCode" [text]="printText(element[column].value)">
                  </app-bbcode-viewer>
                  <ng-container *ngIf="!element[column].shouldUseBBCode">
                    {{ printText(element[column].value) }}
                  </ng-container>
                </div>
                <div class="insideTd" mat-cell *ngSwitchCase="ECallCenterGenericRow.TinyIcon"
                  [ngStyle]="{ 'color': getTinyIconColor(element[column]) }">
    
                  <ng-container *ngIf="element[column].icon" [ngSwitch]="element[column].iconType">
                    <mat-icon
                      *ngSwitchDefault
                      class="insideTd--icon"
                    >{{ element[column].icon }}
                    </mat-icon>
                    <mat-icon
                      *ngSwitchCase="GenericTableRowFieldIconType.Svg"
                      class="insideTd--icon"
                      [svgIcon]="element[column].icon">
                    </mat-icon>
                    <img
                      *ngSwitchCase="GenericTableRowFieldIconType.Image"
                      class="insideTd--image"
                      [src]="element[column].icon"
                    />
                  </ng-container>
                  
                </div>
                <div class="insideTd" mat-cell *ngSwitchCase="ECallCenterGenericRow.IconButton">
                  <button mat-mini-fab color="primary">
                    <mat-icon
                      *ngIf="!element[column].iconType && element[column].icon"
                      class="insideTd--icon mat-20"
                    >
                      {{ element[column].icon }}
                    </mat-icon>
                    <mat-icon
                      *ngIf="(element[column].iconType === GenericTableRowFieldIconType.Svg) && element[column].icon"
                      class="insideTd--icon mat-20"
                      [svgIcon]="element[column].icon">
                    </mat-icon>
                  </button>
                </div>
    
                <div class="hexagon-td" mat-cell *ngSwitchCase="ECallCenterGenericRow.Handler">
                  <cm-dynamic-component *ngIf="element[column].handler" [handler]="element[column].handler"></cm-dynamic-component>
                </div>
                <div class="hexagon-td" mat-cell *ngSwitchCase="ECallCenterGenericRow.Hexagon">
                  <hexagon class="hexagon-field" [hexagonHandler]="element[column].handler"></hexagon>
                  <div class="name-field" *ngIf="element[column].text" target="_blank">{{ printText(element[column].text) }}
                  </div>
                </div>
                <div class="insideTd" mat-cell *ngSwitchCase="ECallCenterGenericRow.DateTime">
                  {{ printDateTime(element[column].time) }}
                </div>
                <div class="insideTd" mat-cell *ngSwitchCase="ECallCenterGenericRow.Date">
                  {{ printDate(element[column].time) }}
                </div>
                <div class="insideTd" mat-cell *ngSwitchCase="ECallCenterGenericRow.Select">
                  <mat-select (selectionChange)="selectChange(element,$event)">
                    <ng-container *ngFor="let option of element[column].options">
                      <mat-option [value]="option.value">
                        {{ option.text }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </div>
                <div class="insideTd" mat-cell *ngSwitchDefault></div>
    
                <div class="insideTd" mat-cell *ngSwitchCase="ECallCenterGenericRow.Dropdown">
                  <mat-icon 
                    class="insideTd--icon mat-20" 
                    [matMenuTriggerFor]="moreOptionsMenu"
                    style="font-size: 30px; cursor: pointer;"
                  >
                    more_vert
                  </mat-icon>
                  <mat-menu #moreOptionsMenu>
                    <li *ngFor="let option of element[column].options">
                      <button
                        mat-menu-item
                        class="mat-menu-item"
                        (click)="option.onClick()" 
                        [matTooltip]="option.helperText ? option.helperText : null"
                        matTooltipClass="mat-tooltip-info"
                        matTooltipPosition="left" 
                      >
                        <mat-icon 
                          class="insideTd--icon " 
                        >
                          {{option.icon}}
                        </mat-icon>
                        {{option.label}}
                      </button>
                    </li>
                  </mat-menu>
                </div>

                <span class="helper-info" [helperInfo]="element[column].helpIconInfo" hiHorizontalAlign="left"
                  [ngStyle]="{ 'display': showHelpIcon(element[column]) }"></span>
              </div>

            </div>
          </ng-container>
        </td>
      </ng-container>
    </ng-container>
  
    <ng-container *ngIf="enableExtraColumns()">
      <ng-container *ngFor="let column of extraColumns" [matColumnDef]="column.text">
        <th class="extra__column--th" mat-header-cell *matHeaderCellDef [attr.colspan]="column.size"> {{ column.text }}
        </th>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="getExtraColumnsTexts()"></tr>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
  
    <ng-container *ngIf="!enableChangePositions; else rowWithDrag">
      <tr mat-row *matRowDef="let row; columns: columnNames;" class="clickable-row" (click)="onRowClicked(row)"></tr>
    </ng-container>
    <ng-template #rowWithDrag>
      <tr mat-row *matRowDef="let row; columns: columnNames;" cdkDrag [cdkDragData]="row"></tr>
    </ng-template>
  </table>
</div>

<mat-paginator *ngIf="showNavigation" showFirstLastButtons [length]="(allRows && allRows.length) || 0"
  [pageSize]="paginationAsNumber" [pageSizeOptions]="paginationOptions" (page)="emitOnChangePagination($event)">
</mat-paginator>

<ng-template #spinner>
  <mat-spinner diameter="24" style="margin: 0 auto;"></mat-spinner>
</ng-template>