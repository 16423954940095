export interface IVersionControl {
    version: number;
    leastCompatible: number;
}

export const versionContol: IVersionControl = {
    version: 4.40,
    leastCompatible: null
};


export enum EVersionWarningType {
    willDownloadVersion, mustRestart
}

export interface IVersionWarning {
    warning: EVersionWarningType;
    desiredHash: string;
}

export enum EPlatformUpdateType {
    Browser = 'browser',
    Android = 'android',
    Ios = 'ios'
}
