import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IBindAgent, TIBindAgentArray } from '@colmeia/core/src/shared-business-rules/knowledge-base/clu-core-interfaces';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IColmeiaDialogComponentData } from 'app/components/dialogs/dialog/dialog.component';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { LookupService, TNSHashCacheImplementation } from 'app/services/lookup.service';
import { NSPickerComponent } from '../../ns-picker/ns-picker/ns-picker.component';
import { isValidArray } from '@colmeia/core/src/tools/barrel-tools';
import { getAgentsIdsArray } from '../../../../../../../core/src/shared-business-rules/knowledge-base/clu-core.functions';

/**
 * O comportamento desse componente 'e de modificar o array
 * portanto, se precisar, passe uma copia e use o onChange para atualizar
 * sua entidade
 */
export interface IKbFeatureEngineeringHandler {
    agentsBind: TIBindAgentArray;
    onChange?: (agentsBind: TIBindAgentArray) => any
}

@Component({
  selector: 'app-kb-feature-engineering',
  templateUrl: './kb-feature-engineering.component.html',
  styleUrl: './kb-feature-engineering.component.scss'
})
export class KbFeatureEngineeringComponent implements OnInit {

    public agentPickerHandler: NSPickerHandler;
    @ViewChild("nsAgentPickerAdder", { static: false }) nsAgentPickerAdder: NSPickerComponent;
    
    @Input()
    public handler: IKbFeatureEngineeringHandler;

    get agents(): TIBindAgentArray {
        return this.handler.agentsBind;
    }

    public nsCache: TNSHashCacheImplementation<INonSerializable> = this.lookupSvc.createNSHashCache();

    constructor(
		private dashboardSvc: DashBoardService,
        private lookupSvc: LookupService,
        @Optional() @Inject(MAT_DIALOG_DATA)  public dataToComponent: IColmeiaDialogComponentData<IKbFeatureEngineeringHandler>,
    ) {
        const par = dataToComponent?.getParamsToChildComponent();
        
        if(par) {
            this.handler = par;
        }
    }

    async ngOnInit() {
        this.agentPickerHandler = this.initAgentPickerHandler();

        const agents = getAgentsIdsArray(this.agents);

        if(isValidArray(agents)) {
            await this.nsCache.hydrate(agents);
        }

    }

    public onDropOnList(event: CdkDragDrop<number[]>) {
        moveItemInArray(this.agents, event.previousIndex, event.currentIndex);
        this.callChange();
    }

    private initAgentPickerHandler() {
		return this.dashboardSvc.easyCreateNSPickerHandler({
			nsType: ENonSerializableObjectType.supremacyAgent,
			title: "Selecione Agente(s)",
			useDemandedTag: false,
            selectedIds: [],
			clientCallback: {
                onSaveCallback: (nonSerializables, nsType) => {
                    const agents: TIBindAgentArray = nonSerializables?.map(ns => {
                        return {idSupremacyAgent: ns.idNS!}
                    }) || [];
                    this.nsCache.hydrateWith(nonSerializables);

                    this.agents.push(...agents);

                    if(isValidArray(agents)) {
                        this.callChange();
                    }

                    this.agentPickerHandler = this.initAgentPickerHandler();
                },
			},
            maxSelections: Number.MAX_SAFE_INTEGER
        }, { filter: (ns) => !this.agents.some( a => a.idSupremacyAgent === ns.idNS ) });
	}

    private callChange() {
        this.handler.onChange?.(this.agents);
    }

    getAgentId(agent: IBindAgent) {
        return agent.idSupremacyAgent
    }

    getAgentName(agent: IBindAgent): string {
        return this.nsCache.hash[agent.idSupremacyAgent]?.nName || '...'
    }

    handleDeleteBtn(index: number) {
        this.agents.splice(index, 1);
        this.callChange();
    }
}
