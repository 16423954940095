import { EColmeiaColorPallete, EColorVariant, EShapeStyle } from "../../brand-theme/theme-types";
import { getDefaultTicketAttrItemTheme, ITicketAttrThemeConfig } from "../crm-ticket-theme.model";
import { ECloseState, ECRMCanonicalItemsTypes, EServiceManagementPhases, ESeverityLevel, ESupportLevel, ETicketState, EUrgencyLevel } from "./crm-config-canonical-model";
import { ICRMAttributeItemBase, TCRMAllPackagesItems } from "./crm-config.model";

// futuramente vamos por padrão de cores aqui
interface ICRMTicketCanonicalConfigBase {
    theme: ITicketAttrThemeConfig
}

interface ITicketAttributeWithLevelConfig {
    level: number;
}

/**
 * 
 * Configurações de Status
 * 
 */

export interface ITicketStatusConfig extends ICRMTicketCanonicalConfigBase {
    isOpen: boolean;
}

export type TTicketStatusMeaning = { [status in ETicketState]: ITicketStatusConfig };

const dbTicketStatus: TTicketStatusMeaning = {
    [ETicketState.new]: {
        isOpen: true,
        theme: getDefaultTicketAttrItemTheme({
            color: EColmeiaColorPallete.accent,
            matIcon: 'new_releases',
            invertColor: true,
        }),
    },
    [ETicketState.inProgress]: {
        isOpen: true,
        theme: getDefaultTicketAttrItemTheme({
            color: EColmeiaColorPallete.accent,
            invertColor: true,
            matIcon: 'pending',
        }),
    },
    [ETicketState.OnHold]: {
        isOpen: true,
        theme: getDefaultTicketAttrItemTheme({
            color: EColmeiaColorPallete.alert,
            invertColor: true,
            matIcon: 'pause_circle',
        }),
    },
    [ETicketState.Closed]: {
        isOpen: false,
        theme: getDefaultTicketAttrItemTheme({
            color: EColmeiaColorPallete.info,
            invertColor: true,
            matIcon: 'do_not_disturb',
        }),
    },
    [ETicketState.Canceled]: {
        isOpen: false,
        theme: getDefaultTicketAttrItemTheme({
            color: EColmeiaColorPallete.warn,
            matIcon: 'cancel',
        }),
    },
    [ETicketState.reopened]: {
        isOpen: true,
        theme: getDefaultTicketAttrItemTheme({
            color: EColmeiaColorPallete.accent,
            invertColor: true,
            matIcon: 'restart_alt',
        }),
    },
    [ETicketState.waitingEscalation]: {
        isOpen: true,
        theme: getDefaultTicketAttrItemTheme({
            color: EColmeiaColorPallete.accent,
            invertColor: true,
            matIcon: 'hourglass_top',
        }),
    },
}

export function getTicketStatusCanonicalConfig(status: ETicketState): ITicketStatusConfig {
    return dbTicketStatus[status]
};

/**
 * 
 * Configurações de Status de fechamento
 * 
 */

export interface ICloseStatusConfig extends ICRMTicketCanonicalConfigBase {
    isSuccess: boolean;
    isExpired: boolean;
}

export type TSRCloseSatusMeaning = { [status in ECloseState]: ICloseStatusConfig };

const dbSRCloseStatusMeaning: TSRCloseSatusMeaning = {
    [ECloseState.workaround]: {
        isSuccess: true,
        isExpired: false,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'model_training',
            tagStyle: EShapeStyle.Basic,
            color: EColmeiaColorPallete.info,
            colorVariant: EColorVariant.v900,
        }),
    },
    [ECloseState.solved]: {
        isSuccess: true,
        isExpired: false,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'task_alt',
            tagStyle: EShapeStyle.Basic,
            color: EColmeiaColorPallete.success,
            invertColor: true,
            colorVariant: EColorVariant.v900,
        }),
    },
    [ECloseState.failed]: {
        isSuccess: false,
        isExpired: false,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'highlight_off',
            tagStyle: EShapeStyle.Basic,
            color: EColmeiaColorPallete.warn,
            colorVariant: EColorVariant.v900,
        }),
    },
    [ECloseState.closedByCaller]: {
        isSuccess: true,
        isExpired: false,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'person_off',
            tagStyle: EShapeStyle.Basic,
            color: EColmeiaColorPallete.alert,
            colorVariant: EColorVariant.v900,
            invertColor: true,
        }),
    },
    [ECloseState.closedByCustomerTimeout]: {
        isSuccess: false,
        isExpired: true,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'alarm',
            tagStyle: EShapeStyle.Basic,
            color: EColmeiaColorPallete.alert,
            invertColor: true,
            colorVariant: EColorVariant.v900,
        }),
    },
}

export function getTicketCloseStatusConfig(closeState: ECloseState): ICloseStatusConfig {
    return dbSRCloseStatusMeaning[closeState];
}

/**
 * 
 * Configurações de Fases
 * 
 */

export interface ICRMPhaseConfig extends ICRMTicketCanonicalConfigBase, ITicketAttributeWithLevelConfig { }

type TCRMPhaseConfigDB = { [phase in EServiceManagementPhases]: ICRMPhaseConfig };

const dbPhaseConfig: TCRMPhaseConfigDB = {
    [EServiceManagementPhases.detect]: {
        level: 0,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'radar',
            color: EColmeiaColorPallete.accent,
            colorVariant: EColorVariant.v100,
        }),
    },
    [EServiceManagementPhases.record]: {
        level: 1,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'app_registration',
            color: EColmeiaColorPallete.accent,
            colorVariant: EColorVariant.v100,
        }),
    },
    [EServiceManagementPhases.classify]: {
        level: 2,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'category',
            color: EColmeiaColorPallete.accent,
            colorVariant: EColorVariant.v100,
        }),
    },
    [EServiceManagementPhases.prioritize]: {
        level: 3,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'priority_high',
            color: EColmeiaColorPallete.accent,
            colorVariant: EColorVariant.v100,
        }),
    },
    [EServiceManagementPhases.investigate]: {
        level: 4,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'troubleshoot',
            color: EColmeiaColorPallete.accent,
            colorVariant: EColorVariant.v100,
        }),
    },
    [EServiceManagementPhases.diagnose]: {
        level: 5,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'troubleshoot',
            color: EColmeiaColorPallete.accent,
            colorVariant: EColorVariant.v100,
        }),
    },
    [EServiceManagementPhases.resolution]: {
        level: 6,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'troubleshoot',
            color: EColmeiaColorPallete.accent,
            colorVariant: EColorVariant.v100,
        }),
    },
    [EServiceManagementPhases.restore]: {
        level: 7,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'rotate_left',
            color: EColmeiaColorPallete.accent,
            colorVariant: EColorVariant.v100,
        }),
    },
    [EServiceManagementPhases.closure]: {
        level: 8,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'checklist',
            color: EColmeiaColorPallete.accent,
            colorVariant: EColorVariant.v100,
        }),
    },
}

export function getTicketPhaseConfig(phase: EServiceManagementPhases): ICRMPhaseConfig {
    return dbPhaseConfig[phase];
}

/**
 * 
 * Configurações de Nível de suporte
 * 
 */

export interface ICRMSupportLevelConfig extends ICRMTicketCanonicalConfigBase, ITicketAttributeWithLevelConfig { }

type TCRMSupportLevelConfigDB = { [supportLevel in ESupportLevel]: ICRMSupportLevelConfig };

const dbSupportLevelConfig: TCRMSupportLevelConfigDB = {
    [ESupportLevel.tier0]: {
        level: 0,
        theme: getDefaultTicketAttrItemTheme({
            utf8Icon: '⬢',
            color: EColmeiaColorPallete.primary,
            colorVariant: EColorVariant.A100,
        }),
    },
    [ESupportLevel.tier1]: {
        level: 2,
        theme: getDefaultTicketAttrItemTheme({
            utf8Icon: '⬢',
            color: EColmeiaColorPallete.primary,
            colorVariant: EColorVariant.A100,
        }),
    },
    [ESupportLevel.tier2]: {
        level: 4,
        theme: getDefaultTicketAttrItemTheme({
            utf8Icon: '⬢',
            color: EColmeiaColorPallete.primary,
            colorVariant: EColorVariant.A200,
        }),
    },
    [ESupportLevel.tier3]: {
        level: 6,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: '⬢',
            color: EColmeiaColorPallete.primary,
            colorVariant: EColorVariant.A400,
        }),
    },
    [ESupportLevel.tier4]: {
        level: 8,
        theme: getDefaultTicketAttrItemTheme({
            utf8Icon: '⬢',
            color: EColmeiaColorPallete.primary,
            colorVariant: EColorVariant.A700,
        }),
    },
}

export function getTicketSupportLevelConfig(supportLevel: ESupportLevel): ICRMSupportLevelConfig {
    return dbSupportLevelConfig[supportLevel];
}


/**
 * 
 * Configurações de Nível de urgencia
 * 
 */

export interface ICRMUrgencyLevelConfig extends ICRMTicketCanonicalConfigBase, ITicketAttributeWithLevelConfig { }

type TCRMUrgencyLevelConfigDB = { [urgencyLevel in EUrgencyLevel]: ICRMUrgencyLevelConfig };

const dbUrgencyLevelConfig: TCRMUrgencyLevelConfigDB = {
    [EUrgencyLevel.normal]: {
        level: 1,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'calendar_view_day',
            color: EColmeiaColorPallete.accent,
            tagStyle: EShapeStyle.Stroked,
        }),
    },
    [EUrgencyLevel.urgent]: {
        level: 9,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'priority_high',
            color: EColmeiaColorPallete.warn,
            tagStyle: EShapeStyle.Stroked,
        }),
    },
}

export function getTicketUrgencyLevelConfig(urgencyLevel: EUrgencyLevel): ICRMUrgencyLevelConfig {
    return dbUrgencyLevelConfig[urgencyLevel];
}

/**
 * 
 * Configurações de Nível de severidade
 * 
 */

export interface ICRMSeverityLevelConfig extends ICRMTicketCanonicalConfigBase, ITicketAttributeWithLevelConfig { }

type TCRMSeverityLevelConfigDB = { [severityLevel in ESeverityLevel]: ICRMSeverityLevelConfig };

const dbSeverityLevelConfig: TCRMSeverityLevelConfigDB = {
    [ESeverityLevel.severity1]: {
        level: 1,
        theme: getDefaultTicketAttrItemTheme({
            color: EColmeiaColorPallete.warn,
            tagStyle: EShapeStyle.Flat,
            matIcon: 'assignment_late',
        }),
    },
    [ESeverityLevel.severity2]: {
        level: 2,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'assignment_late',
            color: EColmeiaColorPallete.warn,
            tagStyle: EShapeStyle.Stroked,
        }),
    },
    [ESeverityLevel.severity3]: {
        level: 3,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'assignment_late',
            color: EColmeiaColorPallete.alert,
            tagStyle: EShapeStyle.Flat,
        }),
    },
    [ESeverityLevel.severity4]: {
        level: 4,
        theme: getDefaultTicketAttrItemTheme({
            matIcon: 'assignment_late',
            color: EColmeiaColorPallete.info,
            tagStyle: EShapeStyle.Stroked,
        }),
    },

}

export function getTicketSeverityLevelConfig(severityLevel: ESeverityLevel): ICRMSeverityLevelConfig {
    return dbSeverityLevelConfig[severityLevel];
}

export function getCRMTicketItemBaseConfigByType(base: TCRMAllPackagesItems): ICRMTicketCanonicalConfigBase {
    switch (base.itemType) {
        case ECRMCanonicalItemsTypes.CloseState:
            return getTicketCloseStatusConfig(base.closeState);
        case ECRMCanonicalItemsTypes.Phase:
            return getTicketPhaseConfig(base.phase);
        case ECRMCanonicalItemsTypes.Severity:
            return getTicketSeverityLevelConfig(base.severity);
        case ECRMCanonicalItemsTypes.SupportLevel:
            return getTicketSupportLevelConfig(base.supportLevel);
        case ECRMCanonicalItemsTypes.TicketState:
            return getTicketStatusCanonicalConfig(base.ticketState);
        case ECRMCanonicalItemsTypes.UrgencyLevel:
            return getTicketUrgencyLevelConfig(base.urgencyLevel);
    }
}