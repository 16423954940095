import { cloneDeep } from "lodash";
import { isObject, safeStringifyJSON } from "../../barrel-tools";

export class ObjectUtils {
    /**
     * getValuePathsInObject searches for a value in an object and return the path to it
     * ex: for obj = { a: { b: { c: 1 } } } it should return a.b.c
     * @param obj 
     * @param value 
     * @param path 
     * @returns 
     */
    static getValuePathsInObject(obj: any, value: any, path = ''): string[] {
        const paths: string[] = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newPath = path ? path + '.' + key : key;
                if (obj[key] === value) {
                    paths.push(newPath);
                } else if (typeof obj[key] === 'object') {
                    let results = ObjectUtils.getValuePathsInObject(obj[key], value, newPath);
                    if (results.length) {
                        paths.push(...results);
                    }
                }
            }
        }
        return paths;
    }

    /**
     * getAllValuePathsInObject searches for multiple values in an object and return the path to it
     * ex: getAllValuePathsInObject({ a: { b: { c: "1", d: "2" } } }, ["1", "2"]) -> ["a.b.c", "a.b.d"]
     * @param obj 
     * @param values 
     * @returns 
     */
    static getAllValuePathsInObject(obj: any, values: any[]): string[] {
        const allPaths: string[] = []
        for (const value of values) {
            const paths = ObjectUtils.getValuePathsInObject(obj, value)
            allPaths.push(...paths)
        }
        return allPaths
    }


    static sortObjectKeys<T extends object = any>(object: T): T {
        try {
            const keys = Object.keys(object);

            keys.sort();

            return keys.reduce<T>((newObj, sourceKey) => {

                newObj[sourceKey] = cloneDeep(object[sourceKey]);

                if (isObject(newObj[sourceKey])) {
                    newObj[sourceKey] = ObjectUtils.sortObjectKeys(newObj[sourceKey]);
                }

                return newObj
            }, {} as T);

        } catch (e) {
            console.error(e);
            return {} as T;
        }
    }

    static stringifyObjectWithSortingKeys<T extends object = any>(object: T): string {
        try {
            return safeStringifyJSON(ObjectUtils.sortObjectKeys(object));
        } catch (e) {
            console.error(e);
            return '';
        }
    }
}