import { INonSerializableHeader, INonSerializable, ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { TBasicAssetArray } from "../bot/bot-asset-model";
import { TArrayID } from "../../core-constants/types";
import { IConfigureCanonical } from "../bot/bot-model";


export enum EEventType {
    Bot = 'bot',
    Attendace = 'attendance',
}

export interface ICustomEventsClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.customEvents;
    type: EEventType;
    events: TBasicAssetArray;
    variableTags: TArrayID;
    /**
     * @deprecated Use $.canonicals
     */
    contextCanonicalIDS: TArrayID;
    canonicals?: IConfigureCanonical[];
}


export interface ICustomEventsServer extends ICustomEventsClient, INonSerializable {
    nsType: ENonSerializableObjectType.customEvents;
}

export function initAttendanceEventsServer(type: EEventType, name: string = ''): ICustomEventsClient {
    return {
        type,
        isVirtual: false,
        nsType: ENonSerializableObjectType.customEvents,
        contextCanonicalIDS: [],
        canonicals: [],
        events: [],
        nName: name,
        variableTags: []
    }
}
