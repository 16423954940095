import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    ViewChild
} from "@angular/core";
import { MatInput } from "@angular/material/input";
import { MatRadioChange } from "@angular/material/radio";
import {
    ISearchActiveCallResult,
    TISearchActiveCallResultArray
} from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";
import { IAgentSearrchActiveCallCustomer } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-req-resp";
import { TSResultRecord } from "@colmeia/core/src/shared-business-rules/corporate-search/corporate-search-results-interface";
import {
    EAttendanceHistorySearchType,
    ECustomerSearchContactType,
    gMaxAttendenceHistoryResultSet
} from "@colmeia/core/src/shared-business-rules/search";
import { getNoDDDPhone } from "@colmeia/core/src/shared-business-rules/social-cc/config-cell";
import { removeUniquizer } from "@colmeia/core/src/shared-business-rules/social-cc/social-cc-rules";
import { ETooltipInfoKey } from "@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model";
import { TISODateString } from "@colmeia/core/src/tools/date/date-utils.types";
import {
    assign, isNumeric, isValidArray,
    isValidFunction,
    isValidRef,
    isValidString,
    isValidTrimmedString,
    safeGetCleanedPhoneNumber,
    validateEmail
} from "@colmeia/core/src/tools/utility";
import { Compute, DefineReadableType, remountInterface } from "@colmeia/core/src/tools/utility-types";
import { AttendanceConversationService } from "app/components/dashboard/attendance-conversation-history-search/attendance-conversation.service";
import {
    GenericTableFieldCreator,
    GenericTableHandler,
    GetRowsOptions,
    IGenericTableGenericRowField,
    IGenericTableRowFieldSelect,
    IGenericTableRowFieldTinyIcon,
    TSimpleGenericTableConversor
} from "app/handlers/generic-table/generic-table.handler";
import { OnChange, SubscriptionGroup } from "app/model/client-utility";
import { AttendanceActiveEditCallApiService } from "app/services/attendance-active-edit-call.service";
import { merge, Observable, Subject, timer } from "rxjs";
import {
    concatMap,
    debounce,
    distinctUntilChanged,
    filter,
    map,
    take,
    tap
} from "rxjs/operators";
import { AttendanceConversationHistorySearchComponent } from "../dashboard/attendance-conversation-history-search/attendance-conversation-history-search.component";
import { InfoSquareProvider } from "../info-square/info-square-provider/info-square-provider.component";
import { InfoSquareStateService } from "../info-square/info-square-state.service";
import { IInfoSquareHandler } from "../info-square/info-square.handler.def";
import { InfoSquareService } from "../info-square/info-square.service";
import { fullfilCustomerCreationObject } from "./customer-finder.functions";
import {
    ECustomerFinderConsumers,
    ICustomerCreationFullfiledFields,
    ICustomerFinderAllowedSearchSources,
    ICustomerFindHandler,
    IListHasIdCampaignAction
} from "./customer-finder.handler.def";
import { IFormSchema } from "@colmeia/core/src/general-form/general-form-interface";

type TCustomerRows = DefineReadableType<
    TSimpleGenericTableConversor<
        Omit<ISearchActiveCallResult, "idAvatar" | "allAttendentCases" | "businessIDList">
    >,
    {
        target: IGenericTableGenericRowField;
        channel: IGenericTableGenericRowField;
        name: IGenericTableGenericRowField;
        email: IGenericTableGenericRowField;
        idSocialKey: IGenericTableGenericRowField;
        businessIDList?: IGenericTableGenericRowField;
        edit?: IGenericTableRowFieldTinyIcon;
    }
>;

type TAgentRows = DefineReadableType<
    TSimpleGenericTableConversor<IAgentSearrchActiveCallCustomer>,
    {
        agentName: IGenericTableGenericRowField;
        customerList: IGenericTableGenericRowField;
    }
>;

export type TSearchStreamDescriptor = {
    token: string;
    ignoreDebounce?: boolean;
    dateRange?: {
        beginDate: TISODateString;
        endDate: TISODateString;
        humanAttendanceOnly?: boolean;
    };
    searchType: EAttendanceHistorySearchType;
};

export type TCustomerResultArray =
    | IAgentSearrchActiveCallCustomer[]
    | TISearchActiveCallResultArray;

const mapContactType: Record<ECustomerSearchContactType, string> = {
    [ECustomerSearchContactType.name]: "Digite o nome do cliente",
    [ECustomerSearchContactType.phone]: "Digite o telefone. Ex.: 988778980 ou 5511988778980",
    [ECustomerSearchContactType.email]: "Digite o email do cliente",
    [ECustomerSearchContactType.businessID]: "Digite a chave de negócio",
};

@Component({
    selector: "app-customer-finder",
    templateUrl: "./customer-finder.component.html",
    styleUrls: ["./customer-finder.component.scss"],
})
export class CustomerFinderComponent
    implements OnInit, OnDestroy, AfterViewInit, ICustomerFindHandler {
    gMaxAttendenceHistoryResultSet: typeof gMaxAttendenceHistoryResultSet =
        gMaxAttendenceHistoryResultSet;


    @OnChange()
    @Input()
    handler: ICustomerFindHandler;
    onChangeHandler() {
        assign(this, this.handler);
        this.handler.slave = this;
    }

    onResult<T extends TCustomerResultArray = TCustomerResultArray>(items: T) {

    }

    disableStandardSearchOnInfoSquareClick = false;
    onInfoSquareRowClick?: (record: TSResultRecord, metadata: IFormSchema) => {};

    enableEditContact: boolean = false;
    slave?: this;

    @OnChange()
    @Input()
    target?: string;
    @Output() targetChange = new EventEmitter<ISearchActiveCallResult | string>();

    cleanedPhoneTarget: string = '';
    isProgrammaticChangeOnTarget: boolean = false;

    public handleTargetChange($event: string) {
        this.target = $event;

        if ($event === '') {
            this.cleanAdditionalPhoneTargetData();
            this.ereaseResult();
            return;
        }

        if(this.searchByRadio === ECustomerSearchContactType.phone) {
            if(this.isPhoneNumberLegacyFormat(this.target)) {
                this.cleanAdditionalPhoneTargetData();
                this.targetChange.emit(this.target);
                return;
            }
    
            const cleanedIfValidPhoneNumber: string | undefined = safeGetCleanedPhoneNumber(this.target, false);
    
            if(cleanedIfValidPhoneNumber) {
                this.cleanedPhoneTarget = cleanedIfValidPhoneNumber;
                this.isProgrammaticChangeOnTarget = true;

                this.target = $event; //<< Triggering onChangeTarget again
                this.targetChange.emit(this.target);
            } else {
                this.ereaseResult();
                this.cleanAdditionalPhoneTargetData();
            }
        } else {
            this.targetChange.emit(this.target);
            this.cleanAdditionalPhoneTargetData();
        }    
    }

    isPhoneNumberLegacyFormat(phoneNumber: string | undefined): boolean {
        return isValidString(phoneNumber) && phoneNumber.length < 10;
    }

    private cleanAdditionalPhoneTargetData() { 
        this.cleanedPhoneTarget = '';
        this.isProgrammaticChangeOnTarget = false;
    }

    public onChangeTarget() {
        let searchInput = this.target?.trim();
        if(this.shouldUseOriginalTargetValue) {
            searchInput = this.cleanedPhoneTarget; //<< Overrides value
            this.onSearchInput(searchInput);
            this.cleanAdditionalPhoneTargetData();
        } else {
            this.onSearchInput(searchInput);
        }
    }

    private get shouldUseOriginalTargetValue(): boolean {
        return this.isPhoneSearch 
            && isValidTrimmedString(this.cleanedPhoneTarget)
            && this.isProgrammaticChangeOnTarget;
    }

    @Input()
    maxSelections: number = 1;

    @Input()
    searchTypes: EAttendanceHistorySearchType[] = [
        EAttendanceHistorySearchType.byCustomer,
    ];

    @Input()
    allowedSearchSources: ICustomerFinderAllowedSearchSources = {
        standard: {
            customersWithOpenCaseOnly: false,
        },
    };

    @Input()
    searchType: EAttendanceHistorySearchType =
        EAttendanceHistorySearchType.byCustomer;

    @Input()
    listHasIdCampaignAction: IListHasIdCampaignAction = {
        async addContactToListCallback() {
            
        },
        listIdCampaignAction: ''
    };

    @Input() 
    openedFrom?: ECustomerFinderConsumers | undefined;

    @Input()
    beginDate: TISODateString;
    @Input()
    endDate: TISODateString;

    @Input()
    searchByDateRangeFired: boolean = false;

    @Output("onCustomerSelected")
    _onCustomerSelected: EventEmitter<ISearchActiveCallResult[]> =
        new EventEmitter();
    onCustomerSelected(_items: ISearchActiveCallResult[]) { }

    @Output("onCustomerCreationRequest")
    _onCustomerCreationRequest: EventEmitter<ICustomerCreationFullfiledFields> =
        new EventEmitter();
    onCustomerCreationRequest(
        _fulfilledFields: ICustomerCreationFullfiledFields
    ) { }

    @ViewChild("infoSquareProvider")
    infoSquareProvider: InfoSquareProvider;

    infoSquareHandler: IInfoSquareHandler = {
        onRowSelection: ([row]) => {
            this.handleInfoSquareRowClick(row);
        },
    };

    @ViewChild("targetInput")
    targetInput: MatInput;

    #subscriptions: SubscriptionGroup = new SubscriptionGroup();

    #searchStream$: Subject<TSearchStreamDescriptor> = new Subject();
    searching$: Subject<boolean> = new Subject();
    searchingObservable$: Observable<boolean> = this.searching$.asObservable();

    willSearch: boolean = false;
    #willSearch$: Subject<void> = new Subject();
    #resultStream$: Subject<TCustomerResultArray> = new Subject();
    resultStream$: Observable<TCustomerResultArray> =
        this.#resultStream$.asObservable();

    public searchResult: TCustomerResultArray | undefined = [];
    public searchAgentsResult: IAgentSearrchActiveCallCustomer[] = [];
    public infoSquareLoading: boolean = false;

    public debounceSearchMS: number = InfoSquareStateService.searchDebounceMS;
    public debounceSearchTransition: `${number}ms` = `${this.debounceSearchMS}ms`;
    public debounceSearchTransform: `scaleX(${number})` = "scaleX(0)";

    public searchResultTable: GenericTableHandler<TCustomerRows> | undefined;
    public searchAgentResultTable: GenericTableHandler<TAgentRows> | undefined;

    public mapRowToCustomer: WeakMap<TCustomerRows, ISearchActiveCallResult> =
        new WeakMap();

    public mapRowtoAgent: WeakMap<TAgentRows, IAgentSearrchActiveCallCustomer> =
        new WeakMap();

    public searchFired: boolean = false;
    public useInfoSquare: boolean = false;

    public helpTipKey: ETooltipInfoKey;

    @Input()
    public searchByRadio: ECustomerSearchContactType =
        ECustomerSearchContactType.phone;
    @Output() searchByRadioChange: EventEmitter<ECustomerSearchContactType> = new EventEmitter<ECustomerSearchContactType>()

    @Input() showInput: boolean;

    totalCustomers: number | undefined;

    @Input() isLoading?: () => boolean;

    isLoadingFromParent = () => this.parameters.isLoading?.();


    constructor(
        private attendanceConversationSvc: AttendanceConversationService,
        private attendanceActiveCallAPI: AttendanceActiveEditCallApiService,
        private infoSquareSvc: InfoSquareService,
        @Optional()
        private attendanceConversationHistory: AttendanceConversationHistorySearchComponent
    ) { }

    private ereaseResult() {
        this.searchResult = [];
        this.searchAgentsResult = [];
        this.searchAgentResultTable = undefined;
        this.searchResultTable = undefined;
    }

    ngOnInit() {
        this.initSearchStream();
        this.initCallbacksStreams();

        if (this.target) {
            this.searchByRadio = validateEmail(this.target)
                ? ECustomerSearchContactType.email
                : ECustomerSearchContactType.phone
            this.emitSearchByRadioChange();

            this.#searchStream$.next({
                token: this.target,
                ignoreDebounce: true,
                searchType: this.searchType,
            });
        }

        if (isValidRef(this.attendanceConversationHistory)) {
            this.#subscriptions
                .from(this.attendanceConversationHistory.searchTypeChanged)
                .subscribe(() => {
                    this.searchResult = [];
                    this.searchAgentsResult = [];
                    this.searchAgentResultTable = undefined;
                    this.searchResultTable = undefined;
                    this.target = undefined;
                    this.searchFired = false;
                    this.searchByRadio = ECustomerSearchContactType.name;
                    this.emitSearchByRadioChange();
                    this.totalCustomers = undefined;
                });
        }

        this.#subscriptions.from(this.infoSquareSvc.allAvailableCS$).subscribe((s) => {
            this.infoSquareLoading = s.loading;
        });
    }

    emitSearchByRadioChange() {
        this.searchByRadioChange.emit(this.searchByRadio);
    }

    private initSearchStream() {
        this.#subscriptions
            .from(this.#searchStream$)
            .pipe(
                distinctUntilChanged(),
                filter((descriptor) =>
                    descriptor.searchType === EAttendanceHistorySearchType.byDateInterval
                        ? true
                        : isValidString(descriptor.token, this.getMinimumSearchTokenLength())
                ),
                map((descriptor) => {
                    if (descriptor.token) {
                        descriptor.token = descriptor.token.trim();
                    }
                    return descriptor;
                }),
                tap((descriptor) => {
                    if (descriptor.ignoreDebounce) return;

                    this.willSearch = true;
                    this.#willSearch$.next()
                }),
                debounce((descriptor) =>
                    timer(descriptor.ignoreDebounce ? 0 : this.debounceSearchMS)
                ),
                filter(() => {
                    this.willSearch = false;
                    if (this.target === '') {
                        this.ereaseResult();
                        this.searching$.next(false);
                        return false;
                    }

                    return true
                }),
                tap((_) => {
                    this.searching$.next(true);
                }),
                concatMap((d) => this.getSearchResults(d))
            )
            .subscribe((result) => {
                this.searching$.next(false);

                if (this.target === '') {
                    this.ereaseResult();
                    return [];
                }

                if (isValidFunction(this.handler.onResult)) {
                    this.handler.onResult(result);
                }

                this.handleSearchResult(result);
                this.#resultStream$.next(result);
                this.searchFired = true;
                this.initTable(this.searchType);
            });
    }

    private getMinimumSearchTokenLength(): number {
        if (this.searchByRadio === ECustomerSearchContactType.businessID) {
            return 1;
        }

        return 2;
    }

    private handleSearchResult(result: TCustomerResultArray) {
        if (this.searchType === EAttendanceHistorySearchType.byAgent) {
            this.searchAgentsResult = result as IAgentSearrchActiveCallCustomer[];
            return;
        }
        this.searchResult = result;
    }

    private initCallbacksStreams() {
        this.#subscriptions
            .from(this._onCustomerSelected)
            .subscribe((i) => this.onCustomerSelected(i));
        this.#subscriptions
            .from(this._onCustomerCreationRequest)
            .subscribe((i) => this.onCustomerCreationRequest(i));
    }

    ngOnDestroy(): void {
        this.#subscriptions.destroy();
    }

    ngAfterViewInit(): void {
        this.#subscriptions
            .from(merge(this.infoSquareProvider.willSearch$, this.#willSearch$))
            .subscribe(() => {
                this.debounceSearchTransform = "scaleX(0)";
                this.debounceSearchTransition = "0ms";

                setTimeout(() => {
                    this.debounceSearchTransform = "scaleX(1)";
                    this.debounceSearchTransition = `${this.debounceSearchMS}ms`;
                }, 100);
            });

        this.#subscriptions
            .from(merge(this.infoSquareProvider.searching$, this.searching$))
            .subscribe(() => {
                this.debounceSearchTransform = "scaleX(0)";
                this.debounceSearchTransition = `${this.debounceSearchMS}ms`;
            });
    }

    private parentListHasIdCampaignAction() {
        if (this.isOpenedFromActiveCall()) {
            return {
                add: 'Adicionar à lista'
            };
        }
        return {};
    }

    private hasEditColumn() {
        if (this.enableEditContact) {
            return {
                edit: "Editar"
            }
        }
        return {}
    }

    private hasBusinessIDColumn() {
        if (this.searchResult?.some(customer => isValidArray((customer as ISearchActiveCallResult).businessIDList))) {
            return {
                businessIDList: "Chaves de negócio"
            }
        }
        return {}
    }

    public initTable(searchType: EAttendanceHistorySearchType): void {
        switch (searchType) {
            case EAttendanceHistorySearchType.byCustomer:
            case EAttendanceHistorySearchType.byDateInterval:
                this.searchResultTable = GenericTableHandler.factory({
                    clientCallback: this,
                    rowNames: {
                        name: "Nome do cliente",
                        target: "Alvo",
                        channel: "Canal",
                        email: "Email",
                        ...this.hasBusinessIDColumn(),
                        ...this.hasEditColumn(),
                        ...this.parentListHasIdCampaignAction()
                    },
                    enableEqualSize: true,
                    minWidth: 600,
                    // hideNavigation: true,
                });
                break;

            case EAttendanceHistorySearchType.byAgent:
                this.searchAgentResultTable =
                    GenericTableHandler.factory<TAgentRows>({
                        clientCallback: {
                            getRows: async () => {
                                return (
                                    this.searchAgentsResult?.map((agent) =>
                                        this.agentToRow(agent)
                                    ) || []
                                );
                            },
                            onRowClick: (row) => {
                                this.onAgentRowClick(row);
                            },
                        },
                        rowNames: {
                            agentName: "Nome do atendente",
                            customerList: "Número de Contatos",
                        },
                        enableEqualSize: true,
                        // hideNavigation: true,
                    });
        }
    }

    public async getRows(_options?: GetRowsOptions): Promise<TCustomerRows[]> {
        if (
            (this.searchType === EAttendanceHistorySearchType.byCustomer ||
                this.searchType === EAttendanceHistorySearchType.byAgent) &&
            !isValidString(this.target)
        )
            return [];

        return (
            this.searchResult?.map((customer) =>
                this.customerToRow(customer as ISearchActiveCallResult)
            ) || []
        );
    }

    public agentToRow(agent: IAgentSearrchActiveCallCustomer): TAgentRows {
        const row: TAgentRows = {
            agentName: GenericTableFieldCreator.text(agent.agentName),
            customerList: GenericTableFieldCreator.text(
                agent.customerList.length.toString()
            ),
        };

        this.mapRowtoAgent.set(row, agent);

        return row;
    }

    public customerToRow(customer: ISearchActiveCallResult): TCustomerRows {
        const row: TCustomerRows = {
            name: GenericTableFieldCreator.text(customer.name),
            target: GenericTableFieldCreator.text(customer.target),
            channel: GenericTableFieldCreator.text(customer.channel),
            email: GenericTableFieldCreator.text(
                removeUniquizer(customer.email)
            ),
            idSocialKey: GenericTableFieldCreator.text(""),
            ...this.hasBusinessID(customer),
            ...this.hasEditContact(customer),
            ...this.hasAddContactToList(customer)
        };
        this.mapRowToCustomer.set(row, customer);
        return row;
    }

    private hasAddContactToList(customer: ISearchActiveCallResult) {
        if (this.isOpenedFromActiveCall()) {
            return {
                add: {
                    ...GenericTableFieldCreator.icon('playlist_add', '#071816'),
                    onClick: () => {
                        this.listHasIdCampaignAction.addContactToListCallback(customer);
                    }
                }
            };
        }
        return {};
    }
    isOpenedFromActiveCall() {
        return isValidRef(this.parameters.openedFrom) && this.parameters.openedFrom === ECustomerFinderConsumers.ActiveCall;
    }

    private hasEditContact(customer: ISearchActiveCallResult) {
        if (this.enableEditContact) {
            return {
                edit: {
                    ...GenericTableFieldCreator.icon('edit', '#071816'),
                    onClick: () => {
                        this.targetChange.emit(customer);
                    }
                }
            }
        }
        return {};
    }

    private hasBusinessID(customer: ISearchActiveCallResult) {
        if (isValidArray(customer.businessIDList)) {
            return {
                businessIDList: GenericTableFieldCreator.text(`\n${[customer.businessIDList].join('\n\n')}\n`)
            }
        }
        return {};
    }

    public onAgentRowClick(row: TAgentRows) {
        const avatarInfo: IAgentSearrchActiveCallCustomer | undefined =
            this.mapRowtoAgent.get(row);

        this.searchResult = avatarInfo?.customerList;
        this.initTable(EAttendanceHistorySearchType.byCustomer);
    }

    public onRowClick(row: TCustomerRows) {
        const avatarInfo: ISearchActiveCallResult | undefined =
            this.mapRowToCustomer.get(row);
        if (avatarInfo) this._onCustomerSelected.emit([avatarInfo]);
    }

    public search(descriptor: TSearchStreamDescriptor) {
        this.#searchStream$.next(descriptor);
    }

    public onSearchInput(token: string | undefined) {
        if (this.searchType === EAttendanceHistorySearchType.byCustomer && token) {
            return this.search({ token, searchType: this.searchType });
        }

        if (this.isAgentSearch() && (!this.beginDate || !this.endDate) || !token) return;

        return this.search({
            token,
            searchType: this.searchType,
            dateRange: { beginDate: this.beginDate, endDate: this.endDate },
        });
    }

    triggerNormalSearch() {
        this.search({
            token: this.target!,
            ignoreDebounce: true,
            searchType: this.searchType,
        });
    }

    triggerInfoSquareSearch() {
        this.infoSquareProvider.state.search({
            token: this.target!,
            ignoreDebounce: true,
        });
    }

    handleInfoSquareRowClick(record: TSResultRecord) {
        const customerInfo: ICustomerCreationFullfiledFields = {
            name: "",
            email: "",
            phoneNumber: "",
            fbId: "",
            colmeiaId: "",
        };

        fullfilCustomerCreationObject(
            customerInfo,
            this.infoSquareProvider.state.configSearchDBItem
                .idPropertyToGlobalCanonicalMap,
            record.row
        );
        let searchToken: string = '';

        if (customerInfo.phoneNumber) {
            customerInfo.phoneNumber = getNoDDDPhone(customerInfo.phoneNumber) || ''
            searchToken = customerInfo.phoneNumber;
            this.searchByRadio = ECustomerSearchContactType.phone;
        } else if (isValidString(record.address) && isNumeric(record.address)) {
            searchToken = getNoDDDPhone(record.address) || '';
            customerInfo.phoneNumber = searchToken || ''
            this.searchByRadio = ECustomerSearchContactType.phone;
        }
        /**
         * Condição para clientes vindo do webchat
         */
        else if (isValidString(record.address)) {
            searchToken = record.address
            this.searchByRadio = ECustomerSearchContactType.phone;
        } else if (customerInfo.email) {
            searchToken = customerInfo.email;
            this.searchByRadio = ECustomerSearchContactType.email;

        } else if (customerInfo.name) {
            searchToken = customerInfo.name;
            this.searchByRadio = ECustomerSearchContactType.name;

        }

        this.onInfoSquareRowClick?.(
            record,
            this.infoSquareProvider.state.configSearchDBItem.configSearchMetadata
        );

        if (this.handler.disableStandardSearchOnInfoSquareClick) {
            return;
        }

        if (!isValidString(searchToken)) {
            this._onCustomerCreationRequest.next(customerInfo);
            return;
        }

        this.useInfoSquare = false;
        this.target = searchToken;

        this.#searchStream$.next({
            token: searchToken,
            ignoreDebounce: true,
            searchType: this.searchType,
        });

        this.#resultStream$.pipe(take(1)).subscribe((v) => {
            if (isValidArray(v)) {
                if (v.length === 1) {
                    return this._onCustomerSelected.emit(v as TISearchActiveCallResultArray);
                }
                return;
            };

            this._onCustomerCreationRequest.next(customerInfo);
        });
    }

    fetchingResults?: symbol;
    get isSearching() {
        return this.isFetchingResults || this.attendanceConversationSvc.isSearching;
    }
    get parameters() {
        return this.handler;
    }

    @Input()
    shouldDisableSearchingMessage: boolean | undefined

    get isFetchingResults() {
        return isValidRef(this.fetchingResults)
    }

    public async fetchSearchResults(descriptor: TSearchStreamDescriptor): Promise<TCustomerResultArray> {
        this.totalCustomers = undefined;

        switch (this.searchType) {
            case EAttendanceHistorySearchType.byCustomer:
                const result =
                    await this.attendanceActiveCallAPI.searchCustomersByTarget(
                        descriptor.token,
                        this.allowedSearchSources.standard
                            ?.customersWithOpenCaseOnly,
                        this.searchByRadio
                    );
                return result;

            case EAttendanceHistorySearchType.byDateInterval:
                const searchByDateResponse =
                    await this.attendanceActiveCallAPI.searchCustomersByDate(
                        descriptor.dateRange!.beginDate,
                        descriptor.dateRange!.endDate,
                        descriptor.dateRange!.humanAttendanceOnly!
                    );
                this.totalCustomers = searchByDateResponse.length;
                return searchByDateResponse;

            case EAttendanceHistorySearchType.byAgent:
                const response =
                    await this.attendanceActiveCallAPI.searchCustomersByAgentName(
                        descriptor.token,
                        descriptor.dateRange!.beginDate,
                        descriptor.dateRange!.endDate
                    );

                this.totalCustomers = response.totalCustomers;
                return response.agents;

            default:
                return [];
        }
    }

    public getSearchResults = (async (descriptor: TSearchStreamDescriptor) => {
        const fetching = Symbol();
        this.fetchingResults = fetching;

        let items: TCustomerResultArray;

        try {
            items = await this.fetchSearchResults(descriptor);
        } catch {
            items = [];
        }

        if (this.fetchingResults === fetching) {
            delete this.fetchingResults;
        }

        return items;
    })

    private radioGroupChangeEraser() {
        // this.target = undefined;
        this.searchResult = [];
        this.searchResultTable = undefined;
        this.searchFired = false;
    }

    public radioGroupHandler(e: MatRadioChange) {
        if (this.searchByRadio !== e.value) {
            this.radioGroupChangeEraser();
        }
        this.searchByRadio = e.value;
        // this.targetChange.emit(this.target);
        this.handleTargetChange(this.target);
        this.emitSearchByRadioChange();
    }

    public isAgentSearch() {
        return this.searchType === EAttendanceHistorySearchType.byAgent
            ? true
            : false;
    }

    public isCustomerSearch() {
        return this.searchType === EAttendanceHistorySearchType.byCustomer
    }

    public checkShowInput() {
        return isValidRef(this.searchType)
            ? this.searchType !== EAttendanceHistorySearchType.byDateInterval
            : true;
    }

    public showAgentsTable() {
        if (this.isAgentSearch() && this.searchResult && this.searchResult.length) {
            return false;
        } else if (
            this.searchAgentResultTable &&
            this.searchAgentsResult.length
        ) {
            return true;
        }
    }

    public handleGoToAgentsTableButton() {
        this.searchResult = [];
    }

    public handleInputPlaceHolder() {
        if (this.isAgentSearch()) {
            return "Digite o nome do atendente";
        }

        if (this.isCustomerSearch()) {
            return mapContactType[this.searchByRadio];
        }

        return "Pesquisar";
    }

    public isNotFoundResults(): boolean {
        return this.searchFired && isValidString(this.target) && !this.willSearch && this.searchResult?.length === 0 &&
            this.searchAgentsResult?.length === 0
    }

    get isPhoneSearch() {
        return this.searchByRadio === ECustomerSearchContactType.phone;
    }

    get phoneSearchTooltip(): string {
        return this.isPhoneSearch 
            ? "Obs.: o formato '+55 11 98877-8980' (whatsapp) também é aceito."
            : ''
    }

    public getResultStream() {
        return this.resultStream$;
    }
}
