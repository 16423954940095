import { Injectable } from "@angular/core";
import { ICRMAttributeItemBase } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config.model";
import { defaultTimeWindows, ICRMTimeWindowConfig } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-time-window.model";
import { IKanbanCard } from "@colmeia/core/src/shared-business-rules/kanban/kanban-shared-model";
import { cloneDeep, keys, sumOf, values } from "@colmeia/core/src/tools/barrel-tools";
import { ITicketKanbanCardData, ITicketKanbanColumnData } from "app/crm-service-tickets-view/crm-kanban-view/crm-kanban-shared.model";
import { TicketKanbanCard } from "app/crm-service-tickets-view/crm-kanban-view/crm-ticket.kanban-card";
import { ECRMTicketViewMode, IUserTicketUIConfig, TKanbanSortedCards } from "app/crm-service-tickets-view/crm-user-config/crm-user-config.model";
import { IKanbanCardMovedEvent, KanbanService } from "app/services/kanban/kanban.base-service";
import { CRMTicketsService } from "../crm-tickets.service";
import { KanbanSortService } from "./crm-kanban-sort.service";
import { CRMUserConfigRepositoryService } from "./crm-user-config-repository.service";

@Injectable({
    providedIn: 'root',
})
export class CRMUserConfigService {
    private userConfig!: IUserTicketUIConfig;

    private kanbanService: KanbanService<ITicketKanbanCardData, ITicketKanbanColumnData>;

    constructor(
        private sortSvc: KanbanSortService,
        private crmUserConfigRepositorySvc: CRMUserConfigRepositoryService,
        private crmTicketSvc: CRMTicketsService,
    ) {
        this.userConfig = this.crmUserConfigRepositorySvc.getUserConfig();

        this.listenColumnsChange();
    }

    public setKanbanService(kanbanSvc: KanbanService<ITicketKanbanCardData, ITicketKanbanColumnData>) {
        this.kanbanService = kanbanSvc;
        this.sortSvc.setKanbanService(kanbanSvc);
    }

    private listenColumnsChange() {
        this.crmTicketSvc.$ticketAdded
    }

    public toggleItemFilterActive(idItemPackage: string, itemId: string) {
        const selectedFilters = this.selectedFilters;

        const idx = selectedFilters[idItemPackage]?.indexOf(itemId)
        const isActive = idx >= 0;

        if (isActive) {
            const isUniq = selectedFilters[idItemPackage].length === 1;

            if (isUniq) {
                delete selectedFilters[idItemPackage];
            } else {
                selectedFilters[idItemPackage].splice(idx, 1);
            }
        } else {
            selectedFilters[idItemPackage] ||= [];

            selectedFilters[idItemPackage].push(itemId);
        }

        this.crmUserConfigRepositorySvc.saveUserConfig();
    }

    get selectedFilters() {
        if (!this.userConfig.kanban) return {};
        const selectedFilters = this.userConfig.kanban.selectedFilters[this.userConfig.selectedPackageId] ||= {};

        return selectedFilters;
    }

    cardFilterFn = (card: IKanbanCard<ITicketKanbanCardData>) => {
        const ticketData = card.getData();
        const allItemsPackages = values(ticketData.ticketPackage.packages);
        const allTicketStatusIds = values(ticketData.ticketData.currentStatus)
        const allTicketItemsPackages = allTicketStatusIds
            .map(ticketStatusItemId => {
                return allItemsPackages.find(pkg => {
                    return pkg.items.some((item: ICRMAttributeItemBase) => item.itemId === ticketStatusItemId)
                })
            })
            .filter(pkg => !!pkg);

        const allTicketItemsPackagesIds = allTicketItemsPackages.map(pkg => pkg.headerPackageId)
        const itemPkgFilterSelection = keys(this.selectedFilters);

        for (const packageId in this.selectedFilters) {

            if (itemPkgFilterSelection.length >= 0 && !allTicketItemsPackagesIds.includes(packageId)) {
                return false;
            }

            const statusIdsFromFilter = this.selectedFilters[packageId];

            if (!allTicketStatusIds.some(ticketStatusId => statusIdsFromFilter.includes(ticketStatusId))) {
                return false;
            }
        }

        return true;
    }

    get sortedCards(): TKanbanSortedCards {
        let config = this.userConfig.kanban.sortedCards ||= {};

        return config;
    }

    cardSortFn = (cardA: TicketKanbanCard, cardB: TicketKanbanCard, columnId: string) => {
        const sortedCards = this.sortedCards[columnId];
        const indexA = sortedCards.findIndex(card => card.cardId === cardA.getCardID())
        const index_NotD_B = sortedCards.findIndex(card => card.cardId === cardB.getCardID())

        return indexA - index_NotD_B; // haha
    }

    public setSelectedHeader(selectedHeaderId: string) {
        if (!this.userConfig.kanban) return;
        if (this.userConfig.kanban.selectedBoardId === selectedHeaderId) return;

        this.userConfig.kanban.selectedBoardId = selectedHeaderId;
        this.crmUserConfigRepositorySvc.saveUserConfig();
    }

    public getUserConfig() {
        return this.userConfig;
    }

    public setSelectedPackageId(packageId: string) {
        this.userConfig.selectedPackageId = packageId;

        this.crmUserConfigRepositorySvc.saveUserConfig();
    }


    public getActiveFiltersLength(): number {
        return sumOf(values(this.selectedFilters).map(filter => filter.length));
    }

    public getActiveFiltersLengthForGroup(itemPackageId: string): number {
        return this.selectedFilters[itemPackageId]?.length || 0;
    }

    public isActiveFilter(itemPackageId: string, statusId: string) {
        return this.selectedFilters[itemPackageId]?.includes(statusId);
    }

    public cardMoved(event: IKanbanCardMovedEvent): () => void {
        const config = this.crmUserConfigRepositorySvc.getUserConfig();
        const before = cloneDeep(config.kanban.sortedCards);
        config.kanban.sortedCards = this.sortSvc.onCardMoved(this.userConfig.kanban.sortedCards, event);

        return function rollback() {
            config.kanban.sortedCards = before;
        }
    }

    public resetFilters() {
        this.userConfig.kanban.selectedFilters = {};
        this.crmUserConfigRepositorySvc.saveUserConfig();
    }

    public getTimeWindowConfig(): ICRMTimeWindowConfig {
        return this.userConfig?.kanban?.timeWindow || defaultTimeWindows[0];
    }

    public setTimeWindwConfig(config: ICRMTimeWindowConfig): void {
        this.userConfig.kanban.timeWindow = config;
        this.crmUserConfigRepositorySvc.saveUserConfig();
    }

    public saveUserConfig() {
        this.crmUserConfigRepositorySvc.saveUserConfig();
    }

    public getViewMode(): ECRMTicketViewMode {
        return this.userConfig?.viewMode || ECRMTicketViewMode.Kanban;
    }

    public setViewMode(mode: ECRMTicketViewMode): void {
        this.userConfig.viewMode = mode;
        this.saveUserConfig();
    }
}
