import { FocusOrigin } from "@angular/cdk/a11y";
import { ESCAPE, hasModifierKey } from "@angular/cdk/keycodes";
import { GlobalPositionStrategy, OverlayRef } from "@angular/cdk/overlay";
import { DialogPosition } from "@angular/material/dialog";
import { isValidNumber, isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { ColmeiaWindowConfig } from "app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-config";
import { ColmeiaWindowContainer, _ColmeiaWindowContainerBase } from "app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-container";
import { IWindowTranslation } from "app/handlers/generic-dashboard-edit.handler";
import { moveEventsOf } from "app/model/client-utility";
import { fromEvent, merge, Observable, ReplaySubject, Subject, timer } from "rxjs";
import { filter, take, takeUntil } from "rxjs/operators";
import { EWindowStateChangeType } from "./colmeia-window.model";
import { ColmeiaWindowService, WINDOWS_Z_INDEX_LEVEL } from "./colmeia-window.service";

let uniqueId = 0;

export const enum WindowDialogState {
    OPEN,
    CLOSING,
    CLOSED,
}

/**
 * This is from version 13 of angular.
 * should be deprecated
 */
export class ColmeiaWindowDialogRefBase<T, R = any> {
    componentInstance: T;
    disableClose: boolean | undefined = this._containerInstance._config.disableClose;

    private readonly _afterOpened = new Subject<void>();
    private readonly _afterClosed = new Subject<R | undefined>();
    private readonly _beforeClosed = new Subject<R | undefined>();
    private _result: R | undefined;
    private _closeFallbackTimeout: NodeJS.Timeout;
    private _state = WindowDialogState.OPEN;

    constructor(
        private _overlayRef: OverlayRef,
        public _containerInstance: _ColmeiaWindowContainerBase,
        readonly id: string = `colmeia-window-dialog-${uniqueId++}`,
    ) {

        _containerInstance._id = id;

        _containerInstance._animationStateChanged
            .pipe(
                filter(event => event.state === 'opened'),
                take(1),
            )
            .subscribe(() => {
                this._afterOpened.next();
                this._afterOpened.complete();
            });

        _containerInstance._animationStateChanged
            .pipe(
                filter(event => event.state === 'closed'),
                take(1),
            )
            .subscribe(() => {
                clearTimeout(this._closeFallbackTimeout);
                this._finishDialogClose();
            });

        _overlayRef.detachments().subscribe(() => {
            this._beforeClosed.next(this._result);
            this._beforeClosed.complete();
            this._afterClosed.next(this._result);
            this._afterClosed.complete();
            this.componentInstance = null!;
            this._overlayRef.dispose();
        });

        _overlayRef
            .keydownEvents()
            .pipe(
                filter(event => {
                    return event.keyCode === ESCAPE && !this.disableClose && !hasModifierKey(event);
                }),
            )
            .subscribe(event => {
                event.preventDefault();
                _closeDialogVia(this, 'keyboard');
            });

        _overlayRef.backdropClick().subscribe(() => {
            if (this.disableClose) {
                this._containerInstance._recaptureFocus();
            } else {
                _closeDialogVia(this, 'mouse');
            }
        });
    }

    close(dialogResult?: R): void {
        this._result = dialogResult;

        this._containerInstance._animationStateChanged
            .pipe(
                filter(event => event.state === 'closing'),
                take(1),
            )
            .subscribe(event => {
                this._beforeClosed.next(dialogResult);
                this._beforeClosed.complete();
                this._overlayRef.detachBackdrop();

                this._closeFallbackTimeout = setTimeout(
                    () => this._finishDialogClose(),
                    event.totalTime + 100,
                );
            });

        this._state = WindowDialogState.CLOSING;
        this._containerInstance._startExitAnimation();
    }

    afterOpened(): Observable<void> {
        return this._afterOpened;
    }

    afterClosed(): Observable<R | undefined> {
        return this._afterClosed;
    }

    beforeClosed(): Observable<R | undefined> {
        return this._beforeClosed;
    }

    backdropClick(): Observable<MouseEvent> {
        return this._overlayRef.backdropClick();
    }

    keydownEvents(): Observable<KeyboardEvent> {
        return this._overlayRef.keydownEvents();
    }

    updatePosition(position?: DialogPosition): this {
        let strategy = this._getPositionStrategy();

        if (position && (position.left || position.right)) {
            position.left ? strategy.left(position.left) : strategy.right(position.right);
        } else {
            strategy.centerHorizontally();
        }

        if (position && (position.top || position.bottom)) {
            position.top ? strategy.top(position.top) : strategy.bottom(position.bottom);
        } else {
            strategy.centerVertically();
        }

        this._overlayRef.updatePosition();

        return this;
    }

    updateSize(width: string = '', height: string = ''): this {
        this._overlayRef.updateSize({ width, height });
        this._overlayRef.updatePosition();
        return this;
    }

    addPanelClass(classes: string | string[]): this {
        this._overlayRef.addPanelClass(classes);
        return this;
    }

    removePanelClass(classes: string | string[]): this {
        this._overlayRef.removePanelClass(classes);
        return this;
    }

    getState(): WindowDialogState {
        return this._state;
    }

    private _finishDialogClose() {
        this._state = WindowDialogState.CLOSED;
        this._overlayRef.dispose();
    }

    private _getPositionStrategy(): GlobalPositionStrategy {
        return this._overlayRef.getConfig().positionStrategy as GlobalPositionStrategy;
    }
}

export function _closeDialogVia<R>(ref: ColmeiaWindowDialogRefBase<R>, interactionType: FocusOrigin, result?: R) {
    if (ref._containerInstance !== undefined) {
        ref._containerInstance._closeInteractionType = interactionType;
    }
    return ref.close(result);
}

export class ColmeiaWindowRef<T = unknown, R = any, D = any> extends ColmeiaWindowDialogRefBase<T, R> {
    public group: string = "";
    public title: string = "";

    public isFocused: boolean = true;
    public hostElement: HTMLElement;
    public windowIdentifier: any;
    public parentIdentifier: any;

    private destroyed$: Subject<boolean> = new Subject();

    private _matDialogContainerElement: HTMLElement;
    private overlayPane: HTMLElement;
    private _moveTriggersElements: NodeListOf<HTMLElement>;
    private _windowTranslateState: IWindowTranslation = { x: 0, y: 0 };
    private _lastWindowTranslate: IWindowTranslation = { x: 0, y: 0 };

    private framesSectors: Record<number, number> = {};
    private _isVisible: boolean = true;
    public get isVisible(): boolean { return this._isVisible; }

    private _beforeRestore$: Subject<void> = new Subject();
    private _afterRestore$: Subject<void> = new Subject();
    private _afterMinimize$: Subject<void> = new Subject();
    private _afterOpenedReplay$: ReplaySubject<void> = new ReplaySubject(1);
    private _moveStart$: Subject<void> = new Subject();
    private _moveEnd$: Subject<void> = new Subject();

    public moveStart() { return this._moveStart$.asObservable(); }
    public moveEnd() { return this._moveEnd$.asObservable(); }

    public beforeRestore() { return this._beforeRestore$.asObservable().pipe(takeUntil(this.destroyed$)); }
    public afterRestore() { return this._afterRestore$.asObservable().pipe(takeUntil(this.destroyed$)); }
    public afterMinimize() { return this._afterMinimize$.asObservable().pipe(takeUntil(this.destroyed$)); }
    public afterOpenedReplay() { return this._afterOpenedReplay$.asObservable().pipe(takeUntil(this.destroyed$)); }

    public data: D;

    public detectChange: () => void

    private parentNode!: HTMLElement;

    constructor(
        public overlayRef: OverlayRef,
        public containerInstance: ColmeiaWindowContainer,
        public readonly windowIndex: number,
        private windowSvc: ColmeiaWindowService,
        private config: ColmeiaWindowConfig,
    ) {
        super(
            overlayRef,
            containerInstance,
            config.id
        );
        this.data = config.data;
        this.title = isValidString(config.title) ? config.title : '';
        this.group = isValidString(config.group) ? config.group : '';
        this.windowIdentifier = config.windowIdentifier;
        this.parentIdentifier = config.parentIdentifier;

        this.setInitialHTMLProps();

        super.afterOpened().pipe(take(1)).subscribe(() => {
            this.focus();
            this.setupTriggerEvents();
            this._afterOpenedReplay$.next();
        });

        this.domEvent(this.overlayPane, ["click", "mousedown", "touchstart"])
            .pipe(filter(() => this.windowSvc.currentActiveIndex !== this.windowIndex))
            .subscribe(() => {
                this.restore();
            });

        this.windowSvc.activeWindow$().pipe(takeUntil(this.destroyed$)).subscribe((currentActiveIndex) => {
            currentActiveIndex === this.windowIndex
                ? this.focus()
                : this.blur();
        });

        this.parentNode = this.overlayPane.parentNode as HTMLElement;

    }

    private setInitialHTMLProps() {
        this.overlayRef.hostElement.id = "window-svr-" + this.id;

        this.hostElement = document.getElementById(this.overlayRef.hostElement.id);
        this.hostElement.classList.add("is-dialog-window");

        if (isValidRef(this.config.wrapperClasses)) {
            isValidString(this.config.wrapperClasses)
                ? this.hostElement.classList.add(this.config.wrapperClasses as string)
                : this.hostElement.classList.add(...this.config.wrapperClasses)
        }

        this.hostElement.style.zIndex = `${WINDOWS_Z_INDEX_LEVEL + this.windowIndex}`;

        this._matDialogContainerElement = this.hostElement.querySelector("colmeia-window-container");
        this.overlayPane = this.overlayRef.overlayElement;
    }

    focus() {
        this.overlayPane.classList.add("focused");
    }

    blur() {
        this.overlayPane.classList.remove("focused");
    }

    private domEvent(target: HTMLElement, events: string[]) {
        return merge(...events.map(ev => fromEvent(target, ev))).pipe(takeUntil(this.destroyed$));
    }

    public setupTriggerEvents() {
        this.initMoveTrigger();
        this.initResizeTriggers();
    }

    public isCurrentActive() {
        return this.windowSvc.currentActiveIndex === this.windowIndex;
    }

    private initMoveTrigger() {
        this._moveTriggersElements = this.hostElement.querySelectorAll(".move-window-trigger");

        this._moveTriggersElements.forEach(el => {
            this.listenToDragEventsOf(el);
        });
    }

    private listenToDragEventsOf(el: HTMLElement) {
        let moving: boolean = false;

        moveEventsOf(el, {
            start: async () => {
                this._lastWindowTranslate = { x: 0, y: 0 };
                this._moveStart$.next();

                await this.runFrame(0);

                !this.isCurrentActive() && this.restore();
                this.overlayPane.classList.add("dragging");
            },
            move: async (_, windowTranslation) => {
                this._lastWindowTranslate = windowTranslation;

                await this.runFrame(1)
                const { x, y } = this._lastWindowTranslate;

                this._matDialogContainerElement.style.transform = `translate(${x}px, ${y}px)`;
            },
            end: async () => {

                this._moveEnd$.next();

                await this.runFrame(2)

                const { prefersReducedMotion } = this.windowSvc;
                this.overlayPane.classList.remove("dragging");

                if (!prefersReducedMotion) {
                    this.overlayPane.classList.add("moving");
                }

                const { x, y } = this._lastWindowTranslate;
                this.overlayPane.style.transform = `translate(${x}px, ${y}px)`;
                this._matDialogContainerElement.style.transform = `translate(0px, 0px)`;

                this._windowTranslateState = {
                    x: this._windowTranslateState.x + this._lastWindowTranslate.x,
                    y: this._windowTranslateState.y + this._lastWindowTranslate.y
                };

                await timer(!prefersReducedMotion ? 400 : 1).toPromise();
                await this.runFrame(3);

                if (!prefersReducedMotion) {
                    this.overlayPane.classList.remove("moving");
                }

                this.overlayPane.style.transform = `translate(0px, 0px)`;
                this.overlayPane.style.top = `${this._windowTranslateState.y}px`;
                this.overlayPane.style.left = `${this._windowTranslateState.x}px`;
                moving = false;
            }
        });
    }

    private initResizeTriggers() { }

    public _minimize() {
        this.minimizeContainer();
        this.blur();
        this._isVisible = false;
        !this.config.keepNodeSubTreeOnDOM && this.parentNode.removeChild(this.overlayPane);
    }

    public minimize(clickedOutsideDialog?: boolean) {
        if (this.config.disableMinizeAllWhileVisible && clickedOutsideDialog) return;
        this._minimize();
        this.windowSvc.updateState({
            windowIdx: this.windowIndex,
            type: EWindowStateChangeType.Minimized
        });

        if (this.windowSvc.currentActiveIndex === this.windowIndex) {
            this.windowSvc.shiftWindow();
        }
        this._afterMinimize$.next();
    }

    public _restore() {
        !this.config.keepNodeSubTreeOnDOM && this.parentNode.appendChild(this.overlayPane);
        this._beforeRestore$.next();
        this.restoreContainer();
        this.focus();
        this._isVisible = true;
        this._afterRestore$.next();
    }

    public restore() {
        this.windowSvc.updateState({
            windowIdx: this.windowIndex,
            type: EWindowStateChangeType.Restore
        });

        this._restore();
    }

    public minimizeContainer() {
        this.hostElement.classList.add('minimized');
    }

    public restoreContainer() {
        this.hostElement.classList.remove('minimized');
    }

    goTopOrMinimize() {
        if (this.windowSvc.currentActiveIndex === this.windowIndex) {
            this.minimize();
        } else {
            this.restore();
        }
    }

    close(result?: any): void {
        this.minimizeContainer();

        if (this.config.parentIdentifier) {

        }

        this.windowSvc.removeDashboardWindowRef(this);

        this.destroyed$.next(true);
        this.destroyed$.complete();

        super.close(result);
    }

    private runFrame(sector: number): Promise<void> {
        if (isValidNumber(this.framesSectors[sector], 0)) window.cancelAnimationFrame(this.framesSectors[sector]);

        return new Promise((resolve) => {
            this.framesSectors[sector] = window.requestAnimationFrame(() => {
                resolve();
                this.framesSectors[sector] = undefined;
            });
        });
    }
}

