export namespace AzureCLUCore {

    /**
     * Specifies the method used to interpret string offsets. For additional information see https://aka.ms/text-analytics-offsets.
     */
     export enum StringIndexType {
        /**
         * The offset and length values will correspond to UTF-16 code units. Use this option if your application is written in a language that support Unicode, for example Java, JavaScript.
         */
        Utf16CodeUnit = 'Utf16CodeUnit',
    }
    export enum Language {
        PortugueseBR = "pt-BR", 
        PortuguesePOR = "pt-PT", 
        EnglishUS = "en-US", 
        Afrikaans = "af", 
        Albanian = "sq", 
        Arabic = "ar", 
        Azerbaijani = "az", 
        Bengali = "bn", 
        Bulgarian = "bg", 
        Catalan = "ca", 
        ChineseCHN = "zh-CN", 
        ChineseHKG = "zh-HK", 
        ChineseTAI = "zh-TW", 
        Croatian = "hr", 
        Czech = "cs", 
        Danish = "da", 
        Dutch = "nl", 
        English = "en", 
        EnglishUK = "en-GB", 
        Estonian = "et", 
        Filipino = "fil", 
        Finnish = "fi", 
        French = "fr", 
        Georgian = "ka", 
        German = "de", 
        Greek = "el", 
        Gujarati = "gu", 
        Hausa = "ha", 
        Hebrew = "he", 
        Hindi = "hi", 
        Hungarian = "hu", 
        Indonesian = "id", 
        Irish = "ga", 
        Italian = "it", 
        Japanese = "ja", 
        Kannada = "kn", 
        Kazakh = "kk", 
        Kinyarwanda = "rw-RW", 
        Korean = "ko", 
        KyrgyzKyrgyzstan = "ky-KG", 
        Lao = "lo", 
        Latvian = "lv", 
        Lithuanian = "lt", 
        Macedonian = "mk", 
        Malay = "ms", 
        Malayalam = "ml", 
        Marathi = "mr", 
        Norwegian = "nb", 
        Persian = "fa", 
        Polish = "pl", 
        Punjabi = "pa", 
        Romanian = "ro", 
        Russian = "ru", 
        Serbian = "sr", 
        Slovak = "sk", 
        Slovenian = "sl", 
        Spanish = "es", 
        SpanishARG = "es-AR", 
        SpanishSPA = "es-ES", 
        SpanishMEX = "es-MX", 
        Swahili = "sw", 
        Swedish = "sv", 
        Tamil = "ta", 
        Telugu = "te", 
        Thai = "th", 
        Turkish = "tr", 
        Ukrainian = "uk", 
        Urdu = "ur", 
        Uzbek = "uz", 
        Vietnamese = "vi", 
        Zulu = "zu", 
    }
    
    /**
     * Represents the options used to create or update a project.
     */
     export interface ConversationalAnalysisAuthoringCreateProjectOptions {
        /**
         * The project description.
         */
        description: string;
        /**
         * The project language. This is BCP-47 representation of a language. For example, use "en" for English, "en-gb" for English (UK), "es" for Spanish etc.
         */
        language: AzureCLUCore.Language;
        /**
         * Whether the project would be used for multiple languages or not.
         */
        multilingual: boolean;
        /**
         * Represents the project kind.
         */
        projectKind: ProjectKind;
        /**
         * The new project name.
         */
        projectName: string;
        /**
         * The project settings.
         */
        settings: AzureCLUCore.ConversationalAnalysisAuthoringProjectSettings;

    }
    
    /**
     * Represents the settings used to define the project behavior.
     */
     export interface ConversationalAnalysisAuthoringProjectSettings {
        /**
         * The threshold of the intent with the highest confidence, at which the prediction will automatically be changed to "None". The value of the threshold should be between 0 and 1 inclusive.
         */
        confidenceThreshold: number;
    }
        
    /**
     * Represents the project kind.
     */
        export enum ProjectKind {
        /**
         * A project to build natural language into apps, bots, and IoT devices.
         */
        Conversation = 'Conversation',
        /**
         * A project to build conversation summarization models which are able to summarize long conversations.
         */
        CustomConversationSummarization = 'CustomConversationSummarization',
        /**
         * A project to connect and orchestrate Conversation, Custom question answering and LUIS projects together in one single project.
         */
        Orchestration = 'Orchestration',
    }
        
}