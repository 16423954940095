import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { EConfigurationSetRequest } from '@colmeia/core/src/request-interfaces/message-types';
import { ECSContentComparison, ICorporateSearchBodyServer } from '@colmeia/core/src/shared-business-rules/corporate-search/corporate-search-model';
import { TResultRecordArray, TSResultRecord } from "@colmeia/core/src/shared-business-rules/corporate-search/corporate-search-results-interface";
import { IConfigSearchData, IInfoSquareSearchRequest, IInfoSquareSearchResponse, TConfigSearchDB } from '@colmeia/core/src/shared-business-rules/info-square/info-square-req-resp';
import { secToMS } from '@colmeia/core/src/time/time-utl';
import { entries, isValidArray, isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { Optional } from '@colmeia/core/src/tools/utility-types';
import { SubscriptionGroup } from 'app/model/client-utility';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { concatMap, debounce, distinctUntilChanged, filter, map, tap, catchError } from "rxjs/operators";
import { IInfoSquareHandler } from './info-square.handler.def';
import { InfoSquareService } from './info-square.service';

export type TInfoSquareSearchStreamDescriptor = {
    token: string,
    comparison: ECSContentComparison,
    csBodyId: string,
    ignoreDebounce?: boolean,
    _searchPrimaryKey?: string,
}

@Injectable()
export class InfoSquareStateService {
    static searchDebounceMS: number = secToMS(2);
    static minSearchTokenLength: number = 0;
    private isCacheDisabled: boolean = true;

    #destroyed: boolean = false;

    #lastSearchDescriptor!: TInfoSquareSearchStreamDescriptor;

    #willSearch$: Subject<void> = new Subject();
    #searching$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    #disabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    #selectedCSBody$: Subject<ICorporateSearchBodyServer> = new Subject();
    #result$: Subject<TResultRecordArray> = new ReplaySubject(1);
    #resultSelected$: Subject<TSResultRecord> = new Subject();
    #availableComparisonTypes$: Subject<ECSContentComparison[]> = new ReplaySubject(1);
    #comparisonType$: Subject<ECSContentComparison> = new Subject();
    #selectedComparisonType$: Subject<ECSContentComparison> = new Subject();

    willSearch$: Observable<void> = this.#willSearch$.asObservable();
    searching$: Observable<boolean> = this.#searching$.asObservable();
    disabled$: Observable<boolean> = this.#disabled$.asObservable();
    result$: Observable<TResultRecordArray> = this.#result$.asObservable();
    resultSelected$: Observable<TSResultRecord> = this.#resultSelected$.asObservable();
    availableComparisonTypes$: Observable<ECSContentComparison[]> = this.#availableComparisonTypes$.asObservable();
    comparisonType$: Observable<ECSContentComparison> = this.#comparisonType$.asObservable();
    selectedComparisonType$: Observable<ECSContentComparison> = this.#selectedComparisonType$.asObservable();

    private _disabled: boolean = false;
    private _configSearchDB: TConfigSearchDB = {}
    private _availableCSBody: ICorporateSearchBodyServer[] = []
    private _availableComparisonTypes: ECSContentComparison[] = []
    private _selectedCSBody!: ICorporateSearchBodyServer;
    private _selectedComparisonType!: ECSContentComparison;
    private _result: TResultRecordArray = [];

    #searchStream$: Subject<TInfoSquareSearchStreamDescriptor> = new Subject();
    #subscriptions: SubscriptionGroup = new SubscriptionGroup();

    private tokenResultMap: Map<string, TResultRecordArray> = new Map();

    public handler: IInfoSquareHandler = {};

    constructor(
        private infoSquareSvc: InfoSquareService,
        private api: ServerCommunicationService,
        private messageSvc: SnackMessageService,
    ) {
        this.initAvailableConfigSearchs();
        this.initSearchStream();
        this.initSearchFireByConfigChanges();
    }

    private initAvailableConfigSearchs() {
        this.#subscriptions.from(this.infoSquareSvc.allAvailableCS$).subscribe((v) => {
            this._availableCSBody = v.availableCSBody;
            this._configSearchDB = v.configSearchDB;

            const [firstCSBody] = v.availableCSBody;

            if (!this._selectedCSBody && isValidRef(firstCSBody)) {
                this.setSelectedCSBody(firstCSBody);
            }
        });
    }

    private useCacheForDescriptor(d: TInfoSquareSearchStreamDescriptor): boolean {
        if (this.isCacheDisabled) return false;
        return this.tokenResultMap.has(d._searchPrimaryKey);
    }

    private initSearchStream() {
        this.#subscriptions.from(this.#searchStream$)
            .pipe(
                distinctUntilChanged(),
                filter(d => !this._disabled && isValidString(d.token, InfoSquareStateService.minSearchTokenLength)),
                map(d => {
                    d.token = d.token.trim();
                    d._searchPrimaryKey = this.getSearchCompositeKey(d);
                    return d;
                }),
                tap(d => {
                    this.#searching$.next(false)
                    !this.useCacheForDescriptor(d) && d.token.length
                        > 0 && this.#willSearch$.next()
                }),
                debounce(d => timer((this.useCacheForDescriptor(d) || d.ignoreDebounce || d.token === '') ? 0 : InfoSquareStateService.searchDebounceMS)),
                tap(d => {
                    !this.useCacheForDescriptor(d) && this.#searching$.next(true);
                    this.#lastSearchDescriptor = d;
                }),
                concatMap(d => this.executeSearch(d)),
                catchError((err, caught) => {
                    if (err) {
                        return of([]);
                    }

                    return caught;
                })
            ).subscribe((result) => {
                this.#searching$.next(false);

                if (!result) return;

                this._result = result;
                this.#result$.next(result);
            });
    }

    initSearchFireByConfigChanges() {

        const quickSearch = (overrides: Partial<TInfoSquareSearchStreamDescriptor>) => {
            this.#searchStream$.next({
                ...this.#lastSearchDescriptor,
                ...overrides,
                ignoreDebounce: true
            });
        }

        this.#subscriptions.from(this.#selectedCSBody$).pipe(distinctUntilChanged()).subscribe((csBody => {
            if (!(isValidString(this.#lastSearchDescriptor?.token) || this._selectedCSBody)) return;

            quickSearch({ csBodyId: csBody.idNS });
        }));

        this.#subscriptions.from(this.selectedComparisonType$).pipe(distinctUntilChanged()).subscribe((comparison => {
            quickSearch({ comparison });
        }));
    }

    get lastSearchDescriptor(): TInfoSquareSearchStreamDescriptor {
        return this.#lastSearchDescriptor;
    }

    get availableCSBody(): ICorporateSearchBodyServer[] {
        return this._availableCSBody;
    }

    get configSearchDB(): TConfigSearchDB {
        return this._configSearchDB;
    }

    get selectedCSBody(): ICorporateSearchBodyServer {
        return this._selectedCSBody;
    }

    get selectedComparison(): ECSContentComparison {
        return this._selectedComparisonType;
    }

    get configSearchDBItem(): IConfigSearchData {
        return this._configSearchDB[this._selectedCSBody.idNS];
    }

    private getAvailableComparisonTypes(csBodyIds: string[]): ECSContentComparison[] {
        const availableConfig: ECSContentComparison[] = entries(this._configSearchDB)
            .filter(([csBodyID]) => csBodyIds.includes(csBodyID))
            .reduce<ECSContentComparison[]>((arr, [, { configTagList }]) => {
                arr.push(...configTagList.filter(ctl => csBodyIds.includes(ctl.idCorporateSearchBody)).map(config => config.comparison))
                return arr
            }, []);

        return availableConfig;
    }

    setSelectedCSBody(value: ICorporateSearchBodyServer) {
        this._availableComparisonTypes = this.getAvailableComparisonTypes([value.idNS]);
        this.#availableComparisonTypes$.next(this._availableComparisonTypes);

        if (!(this._selectedComparisonType || this._availableComparisonTypes.includes(this._selectedComparisonType))) {
            this.setSelectedComparison(this._availableComparisonTypes[0]);
        }

        this._selectedCSBody = value;
        this.#selectedCSBody$.next(value);
    }

    setSelectedComparison(type: ECSContentComparison) {
        this._selectedComparisonType = type;
        this.#selectedComparisonType$.next(type);
    }

    get result(): TResultRecordArray {
        return this._result;
    }

    get disabled(): boolean {
        return this._disabled
    }

    set disabled(value: any) {
        this._disabled = coerceBooleanProperty(value);
    }

    public search(descriptor: Optional<Omit<TInfoSquareSearchStreamDescriptor, '_searchPrimaryKey'>, 'comparison' | 'csBodyId'>) {
        const comparison: ECSContentComparison = this.#lastSearchDescriptor?.comparison || this.selectedComparison;
        const csBodyId: string = this.#lastSearchDescriptor?.csBodyId || this._selectedCSBody?.idNS;

        if (!isValidRef(comparison) || !isValidRef(csBodyId)) return;

        this.#searchStream$.next({
            comparison,
            csBodyId,
            ...descriptor,
        });
    }

    private getSearchCompositeKey(searchDescriptor: TInfoSquareSearchStreamDescriptor): string {
        const { token, csBodyId, comparison } = searchDescriptor;
        return `${token};${csBodyId}${comparison}`;
    }

    private async executeSearch(searchDescriptor: TInfoSquareSearchStreamDescriptor): Promise<TResultRecordArray> {
        const { token, csBodyId, comparison, _searchPrimaryKey } = searchDescriptor;
        if (token === '') {
            return [];
        }

        const inCacheResult = this.tokenResultMap.get(_searchPrimaryKey);

        if (this.useCacheForDescriptor(searchDescriptor) && isValidRef(inCacheResult)) {
            return inCacheResult;
        }

        const response = await this.api.quick<IInfoSquareSearchRequest, IInfoSquareSearchResponse>()({
            requestType: EConfigurationSetRequest.executeSearch,
            idCorporateSearchBody: csBodyId,
            searchString: token,
            shouldMinimumOcurrence: 1,
            searchType: comparison,
        });
        const result: TResultRecordArray = response?.results;

        if (isValidArray(result)) {
            this.tokenResultMap.set(_searchPrimaryKey, result);
        }

        return result || [];
    }

    public destroy() {
        if (this.#destroyed) return;

        this.#subscriptions.destroy();
        this.#willSearch$.complete();
        this.#searching$.complete();
        this.#disabled$.complete();
        this.#resultSelected$.complete();
        this.#availableComparisonTypes$.complete();
        this.#comparisonType$.complete();
        this.#selectedComparisonType$.complete();

        this.#destroyed = true;
    }

    public selecteResults(results: TResultRecordArray) {
        this.handler.onRowSelection?.(results);
    }
}
